import { Badge, Card, Spin } from 'antd';
import { ReactNode, useState } from 'react';
import { ILineResourceType } from '../components/generic/UxLineResourceTypesTable';
import UxLineResourceTypeStatisticsTable from '../components/generic/UxLineResourceTypeStatisticsTable';
import { useAppSelector } from '../hooks';

const TableStatisticsEdit: React.FC = (props) => {
  const [currentResourceType, setCurrentResourceType] = useState<ILineResourceType>()
  useAppSelector((state) => {
    const resType = state.resourceType.data;
    if(resType && !currentResourceType){
      setCurrentResourceType({...resType, attributes: []})
    }
  });
  const {data: app, loading: loadingApp} = useAppSelector(state => state.application)
  if(app?.organization?.id && currentResourceType){
    return (
      <Card
      title={<div>
        <span>Edit Table Statistics </span><Badge
        count={currentResourceType.res_type_name}
        style={{ backgroundColor: '#52c41a' }}
      />
      </div>}
    >
        <UxLineResourceTypeStatisticsTable
          currentResourceType={currentResourceType}
          orgId={app!.organization.id}
        />
      </Card>
    )
  }else{
    return <Spin size="large" />
  }
}

export default TableStatisticsEdit
