import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchApplicationQuery } from "../queries/fetchApplication";
import { fetchApplicationDetails_application as applicationsDetails } from "../queries/__generated__/fetchApplicationDetails"
import { deleteApplicationMutation } from "../mutations/deleteApplication";
import { DeleteApplication, DeleteApplicationVariables } from "../mutations/__generated__/DeleteApplication";
import { ApolloError } from "@apollo/client";

interface applicationType {
  application: applicationsDetails;
}

type State = {
  loading: boolean;
  data: applicationsDetails | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchApplication = createAsyncThunk(
  "application/fetch",
  async (arg: { appId: string }) => {
    try{
      const res = await apolloClient.query<applicationType>({
        query: fetchApplicationQuery,
        variables: { appId: arg.appId },
      });
      return res.data.application ?? null;
    }catch(error){
      window.location.href='/applications'
    }
  }
);

export const deleteApplication = createAsyncThunk(
  "application/delete",
  async (arg: { appId: string }) => {
    await apolloClient.mutate<DeleteApplication, DeleteApplicationVariables>({ 
      // refetchQueries: [{query: fetchApplicationsQuery}],
      variables: { appId: arg.appId },
      mutation: deleteApplicationMutation 
    }).then((result) => { 
      // TODO : There is certainly a better way to do this
      alert(result?.data?.deleteApplication)
      window.location.reload()
      console.log(result) 
    })
    .catch(error => { console.log(error) });
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch
    [fetchApplication.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchApplication.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchApplication.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // delete
    [deleteApplication.pending.type]: (state, _) => {
      state.loading = true;
    },
    [deleteApplication.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [deleteApplication.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = applicationSlice;

export const applicationReducer = reducer;
