import { Alert, Button, Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { ILineResourceTypeStatistics } from "./UxLineResourceTypeStatisticsTable";
import { createResourceTypeStatisticsMutation } from "../../mutations/createResourceTypeStatistics";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  CreateResourceTypeStatistics,
  CreateResourceTypeStatisticsVariables,
} from "../../mutations/__generated__/CreateResourceTypeStatistics";
import {
  UpdateResourceTypeStatistics,
  UpdateResourceTypeStatisticsVariables,
} from "../../mutations/__generated__/UpdateResourceTypeStatistics";
import { updateResourceTypeStatisticsMutation } from "../../mutations/updateResourceTypeStatistics";
import { ResourceTypeStatisticsByResTypeQuery } from "../../queries/resourceTypeStatisticsByResType";
import ResourceTypeAttributeTypeSelect from "./LineResTypeAttributeTypeSelect";
import GroupingFunctionSelect from "./LineGroupingFunctionSelect";
import { flatten } from "../../utils/flatten";
import { option } from "ts-option";

interface Props {
  visible: boolean;
  orgId: string | null;
  mode: "create" | "edit";
  initialStatistics: ILineResourceTypeStatistics;
  resTypeId: string;
  onClose: () => void;
}

const UxResTypeStatisticsForm: React.FC<Props> = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const client = useApolloClient();
  const [errors, setErrors] = useState<string[]>([]);
  const [step, setStep] = useState("properties");
  const [CreateResourceTypeStatistics, { loading: loadingSaving, data: dataSaving, error: errorSaving}] = useMutation<
    CreateResourceTypeStatistics,
    CreateResourceTypeStatisticsVariables
  >(createResourceTypeStatisticsMutation, {errorPolicy: "all"});
  const [UpdateResourceTypeStatistics, { loading: loadingUpdating }] = useMutation<
    UpdateResourceTypeStatistics,
    UpdateResourceTypeStatisticsVariables
  >(updateResourceTypeStatisticsMutation);
  const [statistics, setStatistics] = useState<ILineResourceTypeStatistics>(
    props.initialStatistics
  );
  const [form] = useForm();

  const onClose = () => {
    setStep("properties");
    props.onClose();
  };

  const errorList = (errors || []).map((error) => (
    <Alert
      key={error}
      message={error}
      type="error"
      showIcon
      style={{ marginBottom: ".5em" }}
    />
  ));

  const onSave = async () => {
      if (props.mode === "create") {
        const input = {
          res_type_id: props.resTypeId,
          res_type_statistics_name: statistics.res_type_statistics_name,
          res_type_statistics_description: statistics.res_type_statistics_description,
          res_type_attribute_type_id: statistics.res_type_attribute_type_id,
          grp_function_id: statistics.grp_function_id,
        };

        try {
          let res = await CreateResourceTypeStatistics({
            variables: input,
            refetchQueries: [
              {
                query: ResourceTypeStatisticsByResTypeQuery,
                variables: {
                  resTypeId: props.resTypeId,
                },
              },
            ],
          });
          if (res.errors) {
            const errorMessages = flatten(
              res.errors.map((error) => {
                return option(error.extensions)
                  .map((ext) => {
                    if(ext["validation"]){
                      return flatten(
                        Object.keys(ext["validation"]).map(
                          (key) => ext["validation"][key] as string[]
                        )
                      )
                    }else{
                      let reason = JSON.parse(ext["reason"])
                      return flatten(
                        Object.keys(reason).map(
                          (key) => reason[key] as string[]
                        )
                      )
                    }
                  }
                  )
                  .getOrElseValue([error.message]);
              })
            );
    
            const cleanErrorMessages = errorMessages.map((message: any) =>
              message.replace("input.", "")
            );
    
            setErrors(cleanErrorMessages);
            
          } else {
            message.success(res.data?.createResourceTypeStatistics);
            onClose();
          }

        } catch (error) {
          message.error("Failed to create a statistics");
          console.error(error);
        }
      } else {
        const input = {
          config_sub_item_id: statistics.id!,
          res_type_statistics_name: statistics.res_type_statistics_name,
          res_type_statistics_description: statistics.res_type_statistics_description,
          res_type_attribute_type_id: statistics.res_type_attribute_type_id,
          grp_function_id: statistics.grp_function_id,
        };

        try{
          let res = await UpdateResourceTypeStatistics({
            variables: input,
            refetchQueries: [
              {
                query: ResourceTypeStatisticsByResTypeQuery,
                variables: {
                  resTypeId: props.resTypeId,
                },
              },
            ],
          });
          if (res.errors) {
            const errorMessages = flatten(
              res.errors.map((error) => {
                return option(error.extensions)
                  .map((ext) => {
                    if(ext["validation"]){
                      return flatten(
                        Object.keys(ext["validation"]).map(
                          (key) => ext["validation"][key] as string[]
                        )
                      )
                    }else{
                      let reason = JSON.parse(ext["reason"])
                      return flatten(
                        Object.keys(reason).map(
                          (key) => reason[key] as string[]
                        )
                      )
                    }
                  }
                  )
                  .getOrElseValue([error.message]);
              })
            );
    
            const cleanErrorMessages = errorMessages.map((message: any) =>
              message.replace("input.", "")
            );
    
            setErrors(cleanErrorMessages);

          } else {
            message.success(res.data?.updateResourceTypeStatistics);
            onClose();
          }
        } catch (error) {
          message.error("Failed to update a statistics");
          console.error(error);
        }
      }
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    setStatistics(props.initialStatistics);
    form.setFieldsValue(props.initialStatistics);
  }, [props]);

  return (
    <Modal
      title={props.mode + " statistics"}
      visible={props.visible}
      onCancel={onClose}
      width="850px"
      footer={[
        <>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={props.mode === "create" ? loadingSaving : loadingUpdating}
          >
            {"Save Statistic"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
    >
      {errorList}
      <Form {...formLayout} form={form} onFinish={() => onSave()}>
        <Form.Item
          label={"Statistic name"}
          name="res_type_statistics_name"
          required
        >
          <Input
            onChange={(elt) =>
              setStatistics({
                ...statistics,
                res_type_statistics_name: elt.target.value,
              })
            }
          />
        </Form.Item>
        <ResourceTypeAttributeTypeSelect label="Attribute"
          name="res_type_attribute_type_id" onChange={(val: string) => 
              setStatistics({
                ...statistics,
                res_type_attribute_type_id: val,
              })} resTypeId={props.resTypeId} />
        <GroupingFunctionSelect label="Aggregation"
          name="grp_function_id" onChange={(val: string) => 
            setStatistics({
              ...statistics,
              grp_function_id: val,
            })} />
        <Form.Item
          label={"Description"}
          name="res_type_statistics_description"
          required
        >
          <TextArea
            onChange={(elt) =>
              setStatistics({
                ...statistics,
                res_type_statistics_description: elt.target.value,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UxResTypeStatisticsForm;
