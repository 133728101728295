import { gql } from "@apollo/client";

export const CreateResourceTypeResourceMutation = gql`
    mutation CreateResourceTypeResource($binding_type: String
    $config_item_id: String!,
    $res_type_id: String,
    $res_type_name: String!,
    $org_id: String!,
    $res_type_resources_table_name: String!,
    $res_type_resources_description_down: String,
    $res_type_resources_description_up: String){
    createResourceTypeResource(input: {
      binding_type: $binding_type
      config_item_id: $config_item_id
      res_type_id: $res_type_id
      res_type_name: $res_type_name
      org_id: $org_id
      res_type_resources_table_name: $res_type_resources_table_name
      res_type_resources_description_down: $res_type_resources_description_down
      res_type_resources_description_up: $res_type_resources_description_up
    })
  }
`;