import {gql} from "@apollo/client"

export const fetchApplicationQuery = gql`
  query fetchApplicationDetails($appId: String!) {
    application(id: $appId) {
      id
      org_id
      app_name
      app_description
      app_client_name
      app_client_logo
      app_key
      organization {
        id
        org_name
        org_description
      }
      settings {
        id
        app_id
        app_setting_key
        app_setting_value
      }
      resourceTypes {
        id
        org_id
        res_type_name
        res_type_icon
        res_type_create_disabled
        res_type_user_map
        created_at
        attributes {
          linked_id
          res_type_attribute_type_order
          res_type_attribute_type_mandatory
          res_type_attribute_type_editable
          res_type_attribute_type_detail
          res_type_attribute_type_group
          specification {
            res_type_attribute_name
            res_type_attribute_code
            res_type_attribute_type
            res_type_attribute_constraints
          }
        }
        filters(first: 100) {
          data {
            id
            res_type_filter_name
          }
        }
        customActions(first: 100) {
          data {
            id
            action_type
            action_name
            action_description
            action_spec
            action_icon
            action_global
            action_public
          }
        }
      }
    }
  }
`;