import React, { ReactNode, useState } from "react";
import { Form, Table, Button, Tooltip, message, Popconfirm, Badge } from "antd";
import { ColumnsType } from "antd/lib/table";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';
import { ILineResourceType } from "./UxLineResourceTypesTable";
import { ResourceTypeCustomActionByResTypeQuery } from "../../queries/resourceTypeCustomActionByResType";
import { ResourceTypeCustomActionByResType, ResourceTypeCustomActionByResTypeVariables } from "../../queries/__generated__/ResourceTypeCustomActionByResType";
import { useMutation, useQuery } from "@apollo/client";
import UxResTypeCustomActionForm from "./UxResTypeCustomActionForm";
import { DetachResourceTypeCustomAction, DetachResourceTypeCustomActionVariables } from "../../mutations/__generated__/DetachResourceTypeCustomAction";
import { detachResourceTypeCustomActionMutation } from "../../mutations/detachResourceTypeCustomAction";
import { IconList } from "react-fa-icon-picker";
import LineResourceTypeCustomActionSearchAndAdd from "./LineCustomActionSearchAndAdd";


type INewLineResourceTypeCustomAction = ILineResourceTypeCustomAction | INewResourceTypeCustomAction;

export interface INewResourceTypeCustomAction {
  res_type_custom_action_name: string;
}

export interface ILineResourceTypeCustomAction {
  id: string | null;
  res_type_id: string | null;
  res_type_custom_action_type: string;
  res_type_custom_action_icon: IconList;
  res_type_custom_action_name: string;
  res_type_custom_action_spec: string | null;
  res_type_custom_action_global: boolean | null;
  res_type_custom_action_public: boolean | null;
  res_type_custom_action_description: string | null;
}

interface ILineResourceTypeCustomActionInternal {
  thisIsThatExtraRow: boolean;
}

// export interface ILineFormValues extends Partial<ILineResourceTypeCustomAction> {
  
// }

interface Props {
  currentResourceType: ILineResourceType | undefined;
  orgId: string;
}

interface IRTCustomActionFormState {
  type: "create" | "edit"
  custom_action: ILineResourceTypeCustomAction
  isModalVisible: boolean
  index: number | null
}

export const initialCustomAction: ILineResourceTypeCustomAction = {
  id: null,
  res_type_id: null,
  res_type_custom_action_type: '',
  res_type_custom_action_icon: '',
  res_type_custom_action_description: null,
  res_type_custom_action_name: '',
  res_type_custom_action_spec: '',
  res_type_custom_action_global: false,
  res_type_custom_action_public: false,
}

const isNormalRow = (row: ILineResourceTypeCustomAction | ILineResourceTypeCustomActionInternal): row is ILineResourceTypeCustomAction =>
  !("thisIsThatExtraRow" in row);

const UxLineResourceTypeCustomActionsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypeCustomActionsTable');
  const [form] = useForm();
  const [newCustomAction, setNewCustomAction] = useState('');
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
    ResourceTypeCustomActionByResType,
    ResourceTypeCustomActionByResTypeVariables
  >(ResourceTypeCustomActionByResTypeQuery, {
    variables: {
      resTypeId: props.currentResourceType?.id!
    },
    skip: props.currentResourceType?.id == null
  });

  const [DetachResourceTypeCustomAction, {loading: loadingDeleting}] = useMutation<DetachResourceTypeCustomAction, DetachResourceTypeCustomActionVariables>(detachResourceTypeCustomActionMutation)
  const [rTCustomActionFormState, setRTCustomActionFormState] = useState<IRTCustomActionFormState>({type: "create", custom_action: initialCustomAction, isModalVisible: false, index: null});

  const handleCancel = () => {
    setRTCustomActionFormState({...rTCustomActionFormState, isModalVisible: false});
  };

  const showCustomActionModal = (type: "create" | "edit", custom_action: ILineResourceTypeCustomAction, index: number | null) => {
    setRTCustomActionFormState({type, custom_action, isModalVisible: true, index})
  }

  const onLineResourceTypeCustomActionAdd = (item: ILineResourceTypeCustomAction) => {
    const newItem: ILineResourceTypeCustomAction = {
            id: item.id,
            res_type_id: props.currentResourceType?.id!,
            res_type_custom_action_type: item.res_type_custom_action_type,
            res_type_custom_action_icon: item.res_type_custom_action_icon,
            res_type_custom_action_description: item.res_type_custom_action_description,
            res_type_custom_action_name: item.res_type_custom_action_name,
            res_type_custom_action_spec: item.res_type_custom_action_spec ? item.res_type_custom_action_spec : '{"fields":[], "params":[{}], "dynamic_params":[{}], "path_variables":[{}], "headers":[{"key":"Accept","value":"*/*"},{"key":"Accept-Encoding","value":"gzip, deflate, br"}]}',
            res_type_custom_action_global: item.res_type_custom_action_global ? true : false,
            res_type_custom_action_public: item.res_type_custom_action_public ? true : false,
          };

    showCustomActionModal("create", newItem, null);
  };

  const onItemDelete = (index: number, item: ILineResourceTypeCustomAction) => {
    setToRemoveIndex(index)
    if(props.currentResourceType?.id){
      const input = {customActionId: item.id!, resTypeId: props.currentResourceType?.id};
      try{
        let res = DetachResourceTypeCustomAction({variables: input, refetchQueries: [{ query: ResourceTypeCustomActionByResTypeQuery,
          variables: {
            resTypeId: props.currentResourceType?.id
          } }]});
        res.then(res => {
          message.success(res.data?.detachCustomActionResourceType)
        }).catch(raison => {
          message.error("Failed to delete a custom_action")
          console.error(raison)
        })
      }catch(error){
        message.error("Failed to delete a custom_action")
        console.error(error)
      }
    }
    setToRemoveIndex(index)
  };

  const columns: ColumnsType<ILineResourceTypeCustomAction | ILineResourceTypeCustomActionInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Name"),
      key: "res_type_custom_action_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_custom_action_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <LineResourceTypeCustomActionSearchAndAdd
                excludeResTypeCustomActions={data?.resourceTypeCustomActionByResType.map((item: any) => item['id'])}
                onLineResourceTypeCustomActionAdd={onLineResourceTypeCustomActionAdd}
                inputStyle={{ width: "300px" }}
              />
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Description"),
      key: "res_type_custom_action_description",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_custom_action_description}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Type"),
      key: "res_type_custom_action_type",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_custom_action_type}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Specification"),
      key: "res_type_custom_action_spec",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {
              (row.res_type_custom_action_spec && row.res_type_custom_action_spec?.length > 20) ? <Tooltip title={row.res_type_custom_action_spec}>
              {(row.res_type_custom_action_spec?.substring(0, 20) + "....")}<QuestionCircleOutlined />
            </Tooltip> : row.res_type_custom_action_spec
            }
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Global"),
      key: "res_type_custom_action_global",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_custom_action_global ? <Badge
            count='Yes'
            style={{ backgroundColor: '#52c41a' }}
          /> : <Badge
          count='No'
          style={{ backgroundColor: 'rgb(255, 77, 79)' }}
        />}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Public"),
      key: "res_type_custom_action_public",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_custom_action_public ? <Badge
              count='Yes'
              style={{ backgroundColor: '#52c41a' }}
            /> : <Badge
            count='No'
            style={{ backgroundColor: 'rgb(255, 77, 79)' }}
          />}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{marginRight: '10px'}}
                loading={toRemoveIndex === index ? loadingDeleting : false}
              />
            </Popconfirm>
            <div style={{position: 'relative'}}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => showCustomActionModal("edit", row, index)}
              />
            </div>
          </div>
        ),
    },
  ];

  const dataSet = data?.resourceTypeCustomActionByResType.map((item:any) => ({
    id: item['id'],
    res_type_custom_action_type: item['action_type'],
    res_type_custom_action_icon: item['action_icon'],
    res_type_custom_action_description: item['action_description'],
    res_type_custom_action_name: item['action_name'],
    res_type_custom_action_spec: item['action_spec'],
    res_type_custom_action_global: item['action_global'] ? true : false,
    res_type_custom_action_public: item['action_public'] ? true : false,
  }))

  const lineResourceTypeCustomActionsWithExtra: (ILineResourceTypeCustomAction | ILineResourceTypeCustomActionInternal)[] = dataSet ? [
    ...dataSet,
    { thisIsThatExtraRow: true },
  ]: [];

  return (
    <>
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypeCustomActionsWithExtra}
        pagination={false}
        loading={loading}
        size="small"
      />
    </Form>
    <UxResTypeCustomActionForm
      visible={rTCustomActionFormState.isModalVisible}
      mode={rTCustomActionFormState.type}
      onClose={() => handleCancel()}
      initialCustomAction={rTCustomActionFormState.custom_action}
      resTypeId={props.currentResourceType?.id ?? ""}
      orgId={props.orgId}
    />
    </>
  );
};

export default UxLineResourceTypeCustomActionsTable;
