import { gql } from "@apollo/client";

export const updateResourceTypeFilterMutation = gql`
  mutation UpdateResourceTypeFilter($config_sub_item_id: String!,
    $attributes: [CustomResourceTypeFilterAttributeCreateOrUpdateInput],
    $res_type_filter_name: String!,
    $res_type_filter_description: String,
    $res_type_filter_logicaloperator: String) {
    updateResourceTypeFilter(input: {
      config_sub_item_id: $config_sub_item_id
      res_type_filter_name: $res_type_filter_name
      res_type_filter_description: $res_type_filter_description
      res_type_filter_logicaloperator: $res_type_filter_logicaloperator
      attributes: $attributes
    })
  }
`;
