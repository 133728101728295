import { configureStore } from '@reduxjs/toolkit'
import { meReducer } from './slices/meSlice'
import { organizationReducer } from './slices/organizationSlice'
import { organizationsReducer } from './slices/organizationsSlice'
import { applicationsReducer } from './slices/applicationsSlice'
import { resourceRecordReducer } from './slices/resourceRecordSlice'
import { resourceRecordsReducer } from './slices/resourceRecordsSlice'
import { verifyEmailReducer } from './slices/verifyEmail'
import { applicationReducer } from './slices/applicationSlice'
import { resourceTypesReducer } from './slices/resourceTypesSlice'
import { resourceTypeAttributesReducer } from './slices/resourceTypeAttributesSlice'
import { templatesReducer } from './slices/templatesSlice'
import { resourceTypeTemplatesReducer } from './slices/resourceTypeTemplatesSlice'
import { resourceTypeReducer } from './slices/resourceTypeSlice'

export const store = configureStore({
  reducer: {
    me: meReducer,
    verifyEmail: verifyEmailReducer,
    resourceRecord: resourceRecordReducer,
    resourceRecords: resourceRecordsReducer,
    resourceTypes: resourceTypesReducer,
    resourceType: resourceTypeReducer,
    resourceTypeAttributes: resourceTypeAttributesReducer,
    organizations: organizationsReducer,
    applications: applicationsReducer,
    application: applicationReducer,
    organization: organizationReducer,
    templates: templatesReducer,
    resourceTypeTemplates: resourceTypeTemplatesReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
