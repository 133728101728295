import { Modal, Form, Input, Select, Upload, Button, message, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { useState } from "react";
import { UploadChangeParam } from "antd/lib/upload";
import {
  CloudUploadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Me_me_organizations } from "../queries/__generated__/Me";
import { UploadFile } from "antd/lib/upload/interface";
import { useHistory } from "react-router";
import { CreateApplicationMutation } from "../mutations/createApplication";
import {
  CreateApplication,
  CreateApplicationVariables,
} from "../mutations/__generated__/CreateApplication";
import { useMutation } from "@apollo/client";
import "./UploadInput.less";
import { iconPickerBtnStyle, iconPickerContainerStyles } from './ApplicationDetailsEdit';
import { IconList, IconPicker } from 'react-fa-icon-picker'
import { useAppDispatch } from "../hooks";
import { fetchApplications } from "../slices/applicationsSlice";

interface Props {
  visible: boolean;
  organizations: Me_me_organizations[] | undefined;
  onClose: () => void;
}

interface IAppImport {
  org_id: string;
  res_type_name: string | null;
  name: string;
  icon: IconList;
  description: string;
  file: UploadFile | null;
  csv_delimiter: string;
}

const initApp = {
  org_id: "",
  res_type_name: "",
  name: "",
  icon: "",
  description: "",
  file: null,
  csv_delimiter: ";"
};

const AppImport: React.FC<Props> = (props) => {
  const [app, setApp] = useState<IAppImport>({org_id: "", name: "", icon: "FaBookOpen", description: "", file: null, csv_delimiter: "", res_type_name: ""})
  const [CreateApplication] = useMutation<
    CreateApplication,
    CreateApplicationVariables
  >(CreateApplicationMutation);
  const [fileType, setFileType] = useState('csv')
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = useForm();
  const history = useHistory();

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const reset = () => {
    form.resetFields();
  };

  const selectedOrg = props.organizations && props.organizations.length ? props.organizations?.filter(
    (org) => org.pivot?.admin || org.pivot?.superuser
  )[0] : null
  const defaultOrgId = selectedOrg ? selectedOrg.id : null

  const onValidationError = (errorFields: InternalNamePath[]) => {};

  const dispatch = useAppDispatch();

  const onSave = async () => {
    try {
      const input = {
        orgId: app.org_id || defaultOrgId || "",
        resTypeName: app.res_type_name,
        csvDelimiter: app.csv_delimiter,
        ...app
      };
      let res = await CreateApplication({ variables: input });
      message.success(res.data?.createApplication.message);
      window.location.href="/view/applications/" + res.data?.createApplication.id;
      dispatch(fetchApplications({ first: 100 }));
    } catch (error) {
      message.error("Failed to create an app");
      console.error(error);
    }
    onClose();
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const upProps = {
    beforeUpload: (file: UploadFile<any>) => {
      setApp({ ...app, file });
      return false;
    },
    onRemove: () => {
      setApp({ ...app, file: null });
    },
    name: "table_data",
    multiple: false,
    maxCount: 1,
    accept: fileType == "csv" ? ".csv,text/csv" : ".xlsx",
    onChange: (e: UploadChangeParam<UploadFile<any>>) =>
      setApp({ ...app, file: e.file }),
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      visible={props.visible}
      title="Create app from file"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <>
          <Button type="primary" icon={<SaveOutlined />} onClick={form.submit}>
            {"Save App"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
      width="800px"
    >
      <Form
        {...formLayout}
        form={form}
        onFinish={() => onSave()}
        onFinishFailed={({ errorFields }) =>
          onValidationError(errorFields.map((ef) => ef.name))
        }
      >
        <Form.Item label={"Select organization"} required name="org_id">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select an organization"
            onChange={(val: string) => setApp({ ...app, org_id: val })}
            defaultValue={defaultOrgId}
          >
            {props.organizations
              ?.filter((org) => org.pivot?.admin || org.pivot?.superuser)
              .map((org) => (
                <Option value={org.id}>{org.org_name}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label={"App name"} required name="app_name">
          <Input
            onChange={(elt) => setApp({ ...app, name: elt.target.value })}
          />
        </Form.Item>
        <Form.Item label={"Icon"} required name="app_client_logo">
          <IconPicker containerStyles={{...iconPickerContainerStyles, position: 'fixed'}} value={app.icon} buttonStyles={iconPickerBtnStyle} buttonIconStyles={{ display: "contents" }} onChange={(val) => setApp({...app, icon: val})} />
        </Form.Item>
        <Form.Item label="App description" name="app_description">
          <TextArea
            onChange={(elt) =>
              setApp({ ...app, description: elt.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="File type" valuePropName="checked" name="file_type">
          <Radio.Group onChange={e => {
            if(e.target.value == 'xlsx'){
              form.setFieldsValue({res_type_name: ''})
              setApp({ ...app, res_type_name: null })
            }
            setFileType(e.target.value)
          }} value={fileType}>
            <Radio value={'csv'}>CSV</Radio>
            <Radio value={'xlsx'}>XLSX</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          required
          name="upload"
          label="Upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload {...upProps}>
            <div className="ant-upload-container">
              <p className="ant-upload-dragger-icon">
                <CloudUploadOutlined />
              </p>
              <p>Select File</p>
            </div>
          </Upload>
        </Form.Item>
        {
          fileType == "csv" ? <>
            <Form.Item
            label="CSV Delimiter"
            name="csv_delimiter"
            rules={[{ required: false, message: "Please enter CSV delimiter" }]}
          >
            <Input
              defaultValue=";"
              onChange={(elt) =>
                setApp({ ...app, csv_delimiter: elt.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Table Name"
            name="res_type_name"
            rules={[{ required: true, message: "Please input your table name!" }]}
          >
            <Input
              onChange={(elt) =>
                setApp({ ...app, res_type_name: elt.target.value })
              }
            />
          </Form.Item>
        </> : null
        }
      </Form>
    </Modal>
  );
};

export default AppImport;
