import React, { useState } from 'react'
import {
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';

interface Props {
  text: string
}

const HiddenPassword: React.FC<Props> = (props) => {
  const [display, setDisplay] = useState(false)
  return (
    display ? <>{props.text} <EyeOutlined onClick={() => setDisplay(false)} title="Hide" /></> : <>xxxxxxxxx <EyeInvisibleOutlined onClick={() => setDisplay(true)} title="Show" /></>
  )
}

export default HiddenPassword