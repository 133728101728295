import { gql } from "@apollo/client";

export const updateOrganizationMutation = gql`
    mutation UpdateOrganization($orgId: String!, $orgParentId: String, $serverId: String, $orgName: String, $orgDescription: String, $orgAddress: String, $orgLogo: String ){
    updateOrganization(input: {
      config_item_id: $orgId
      org_parent_id: $orgParentId
      server_id: $serverId
      org_name: $orgName
      org_description: $orgDescription
      org_address: $orgAddress
      org_logo: $orgLogo 
    }) 
  }
`;