import React from 'react'
import { Pie } from '@ant-design/plots'

interface Props{
  dataChart: any
  attribute_x: {
    res_type_attribute_code: string;
    res_type_attribute_name: string;
  }
  attribute_y: {
    res_type_attribute_code: string;
    res_type_attribute_name: string;
  }
}

const ChartPie: React.FC<Props> = (props) => {
  const config = {
    appendPadding: 10,
    data: props.dataChart,
    angleField: props.attribute_y.res_type_attribute_code,
    colorField: props.attribute_x.res_type_attribute_code,
    tooltip: {
      customContent: (name: any, dataChart: any) =>
        `<div>${dataChart?.map((item: any) => {
          return `<div class="tooltip-chart" >
              <div class="tooltip-item">${props.attribute_x.res_type_attribute_name + ' : ' + item["data"][props.attribute_x.res_type_attribute_code]}</div>
            </div>`;
        })}</div>`,
    },
    radius: 0.9,
    label: {
      type: 'inner',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <Pie {...config} />
  )
}

export default ChartPie
