import { gql } from "@apollo/client";

export const storeResourceRecordMutation = gql`
  mutation StoreResourceRecord($orgId: String!, $resourceTypeId: String!, $resTypeResourceId: String, $drilledRecordId: String, $data: Json!, $user: Json) {
    storeResourceRecord(input: {
      org_id: $orgId
      res_type_id: $resourceTypeId
      res_type_binding_id : $resTypeResourceId
      drilled_record_id : $drilledRecordId
      data: $data
      user: $user
    })
  }
`;
