import { useEffect, useState } from "react"
import {useHistory, useParams} from "react-router-dom"
import { Form, Input, Button, Row, Col, Upload, Card } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { createOrganization, fetchOrganization, updateOrganization } from "../slices/organizationSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
// type LayoutType = Parameters<typeof Form>[0]['layout'];

type Props = {
  mode: "create" | "edit";
}

export const OrganizationForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {orgId} = useParams<{orgId: string}>();
  const organization = useAppSelector(state => state.organization.data)
  const loading = useAppSelector(state => state.organization.loading)

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const isEditForm = () => (props.mode === 'edit')

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        const promise =
          props.mode === "create"
            ? dispatch(
              createOrganization({
                orgName: data.org_name,
                orgAddress: data.org_address,
                orgDescription: data.org_description
              })
              )
            : dispatch(
                updateOrganization({
                  orgId: orgId,
                  orgName: data.org_name,
                  orgAddress: data.org_address,
                  orgDescription: data.org_description
                })
              );

        promise.then(
          (res) => {
            if(props.mode === "create"){
                history.push('/organizations')
            }
            console.log(res)
          }
        ).catch(err => console.log(err));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  useEffect(() => {
    if(orgId){
      dispatch(
        fetchOrganization({ orgId : orgId })
      );
      form.setFieldsValue(organization)
    }
  }, [orgId, organization])

  return (
    <Card
    bordered
      title={<h3>{(props.mode == 'create') ? 'Create' : 'Edit'} Organization {isEditForm() && organization ? `(${organization?.org_name})` : ''}</h3>}
      extra={
        <Button type="primary" onClick={onSubmit} loading={isEditForm() && loading ? true: false}>Save org</Button>
      }
    >
      <Form layout="vertical" form={form} initialValues={isEditForm() ? {org_address: organization?.org_address, org_name: organization?.org_name, org_description: organization?.org_description} : {}} size="large">
        <Row justify="space-around">
          <Col span={10}>
            <Form.Item label="Name" name="org_name">
              <Input placeholder="Organization name"/>
            </Form.Item>
            <Form.Item label="Description" name="org_description">
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="upload"
              label="Upload logo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="The logo of your organization"
            >
              <Upload name="logo" action="/upload.do" disabled listType="picture">
                <div style={{display: "flex", width: "100%", justifyContent: "center", padding: "10px", alignItems: "center"}}><UploadOutlined style={{marginRight: '10px'}}/><span>Click to upload</span></div>
              </Upload>
            </Form.Item>
            <Form.Item label="Address" name="org_address">
              <Input.TextArea placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
      </Form> 
    </Card>
  );
}