import React from 'react'
import { Area } from '@ant-design/plots'

interface Props{
  dataChart: any
  attribute_x: {
    res_type_attribute_code: string;
    res_type_attribute_name: string;
  }
  attribute_y: {
    res_type_attribute_code: string;
    res_type_attribute_name: string;
  }
}

const ChartArea: React.FC<Props> = (props) => {

  const config = {
    data: props.dataChart,
    xField: props.attribute_x.res_type_attribute_code,
    yField: props.attribute_y.res_type_attribute_code,
    xAxis: {
      title: {
        text: props.attribute_x.res_type_attribute_name,
        position: 'end'
      },
    },
    yAxis: {
      title: {
        text: props.attribute_y.res_type_attribute_name,
        position: 'end'
      },
    },
    tooltip: {
      customContent: (name: any, dataChart: any) =>
        `<div>${dataChart?.map((item: any) => {
          return `<div class="tooltip-chart" >
              <div class="tooltip-item">${props.attribute_x.res_type_attribute_name + ' : ' + item["data"][props.attribute_x.res_type_attribute_code]}</div>
              <div class="tooltip-item">${props.attribute_y.res_type_attribute_name + ' : ' + item["data"][props.attribute_y.res_type_attribute_code]}</div>
            </div>`;
        })}</div>`,
    },
    meta: {
      [props.attribute_x.res_type_attribute_name]: {
        alias: props.attribute_x.res_type_attribute_name,
      },
      [props.attribute_y.res_type_attribute_name]: {
        alias: 'Total Price',
      },
    },
  };

  return (
    <Area {...config} />
  )
}

export default ChartArea
