import { Button, message, Popconfirm, Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ResourceTypesByApp, ResourceTypesByAppVariables } from '../queries/__generated__/ResourceTypesByApp';
import { ResourceTypesByAppQuery } from '../queries/resourceTypesByApp';
import { IconList, IconPickerItem } from 'react-fa-icon-picker';
import { fontAwesomeList } from '../components/helpers/iconList';
import { DeleteResourceTypeFromApp, DeleteResourceTypeFromAppVariables } from '../mutations/__generated__/DeleteResourceTypeFromApp';
import { deleteResourceTypeFromAppMutation } from '../mutations/deleteResourceTypeFromApp';
import { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IResourceType } from '../components/generic/LineResourceTypeSearchAndAdd';


const TablesList: React.FC = (props) => {

const { appId } = useParams<{ appId: string }>();
const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
const history = useHistory();
const {data, loading, error } = useQuery<ResourceTypesByApp, ResourceTypesByAppVariables>(ResourceTypesByAppQuery, {
  variables: {
    appId: appId
  }
})
const [DeleteResourceTypeFromApp, {loading: loadingDeleting}] = useMutation<DeleteResourceTypeFromApp, DeleteResourceTypeFromAppVariables>(deleteResourceTypeFromAppMutation)

const removeResType = (index: number, resTypeId: string) => {
  setToRemoveIndex(index)
  const input = {
    resTypeId,
    appId
  };
  let res = DeleteResourceTypeFromApp({variables: input, refetchQueries: [{ query: ResourceTypesByAppQuery,
    variables: {
      appId: appId
    } }]});
  res.then(res => {
    message.success(res.data?.deleteResourceTypeFromApp)
  }).catch(raison => {
    message.error("Failed to remove table")
    console.error(raison)
  })
  setToRemoveIndex(index)
};

const columns: ColumnsType<IResourceType> = [
  {
    title: 'Icon',
    dataIndex: 'res_type_icon',
    key: 'res_type_icon',
    render: (text: any, record: any) => {
      return <IconPickerItem icon={text && fontAwesomeList.includes(text) ? text as IconList : "FaThLarge"} color="gray" size={24} containerStyles={{display: 'flex'}} />
    }
  },
  {
    title: 'Name',
    dataIndex: 'res_type_name',
    key: 'res_type_name',
    render: (text: string) => <span style={{textTransform: 'capitalize'}}>{text}</span>
  },
  {
    title: 'Description',
    dataIndex: 'res_type_description',
    key: 'res_type_description',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record: any, index) => (
      <Space size="middle">
        <Popconfirm title="Sure to remove?" onConfirm={() => removeResType(index, record.id)}>
          <Button loading={toRemoveIndex === index ? loadingDeleting : false} icon={<DeleteOutlined />}></Button>
        </Popconfirm>
        <Button onClick={() => history.push("/applications/"+appId+"/tables/"+record.id)} icon={<EditOutlined />}></Button>
      </Space>
    ),
  },
];
  return (
    <div>
      <Table columns={columns} dataSource={data?.resourceTypesByApp} loading={loading} />
    </div>
  )
}

export default TablesList
