import { Form, Input, Alert } from 'antd';
import React from 'react'
import UxAPICallSpecification from '../UxAPICallSpecification';

interface Props{
  type: string;
  spec: string | null;
  resTypeId: string;
  setSpec: (spec: string) => void;
}

const UxLineResTypeCustomActionSpecification: React.FC<Props> = (props) => {
  if(props.type == "plugin-call"){
    return <div>
          <Alert style={{marginBottom: '10px'}} message="The name argument expects a vendor and name in 'Composer' format. Here's an example: `vendor/name`." type="info" showIcon />
          <Form.Item label="Plugin Call" name={'res_type_custom_action_spec'}>
            <Input placeholder='Enter the plugin name' onChange={e => props.setSpec(JSON.stringify({package: e.target.value}))} />
          </Form.Item>
      </div>
  }if(props.type == "sql-query"){
    return <Form.Item label="SQL Query" name={'res_type_custom_action_spec'}>
      <Input placeholder='Enter the SQL' onChange={e => props.setSpec(e.target.value)} />
    </Form.Item>
  }if(props.type == "api-call"){
    return <UxAPICallSpecification setSpec={(spec) => props.setSpec(spec)} spec={props.spec} resTypeId={props.resTypeId} />
  }else{
    return <UxAPICallSpecification setSpec={(spec) => props.setSpec(spec)} spec={props.spec} resTypeId={props.resTypeId} />
  }
}

export default UxLineResTypeCustomActionSpecification