import { gql } from "@apollo/client"

export const ResourceTypeResourcesByResTypeQuery = gql`
  query ResourceTypeResourcesByResType($resTypeId: String!){
    resourceTypeResourcesByResType(res_type_id: $resTypeId) {
      id
      res_type_id
      res_type_parent_id
      res_type_resources_transitional_enabled
      res_type_resources_transitional_path
      res_type_resources_table_name
      res_type_resources_description_up
      res_type_resources_description_down
      sourceResource {
        id
        org_id
        res_type_name
        res_type_table_name
      }
      targetResource {
        id
        org_id
        res_type_name
        res_type_table_name
      }
    }
  }
`;
