import { Button, Input, Modal, Tabs, Select, message, Badge, Form } from 'antd';
import React, { useState } from 'react'
import {
  ArrowLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useForm } from 'antd/lib/form/Form';
import { InternalNamePath } from 'rc-field-form/lib/interface';
import UxLineResourceTypesTable, { ILineResourceType } from '../components/generic/UxLineResourceTypesTable';
import UxLineResourceTypeAttributesTable, { ILineFormValues, ILineResourceTypeAttribute } from '../components/generic/UxLineResourceTypeAttributesTable';
import { IconList } from 'react-fa-icon-picker'
import { useMutation } from '@apollo/client';
import { CreateResourceTypesMutation } from '../mutations/createResourceTypes';
import { CreateResourceTypes, CreateResourceTypesVariables } from '../mutations/__generated__/CreateResourceTypes';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchApplication } from '../slices/applicationSlice';
import LoadingPage from './Loading';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export interface ITableFromScratch {
  org_id: string;
  name: string;
  description: string;
  icon: IconList;
}

export interface ICurrentResourceType extends ILineResourceType{
  index: number;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TableFromScratch: React.FC<Props> = (props) => {
  const [step, setStep] = useState<"tables" | "fields">("tables");
  const [lineResourceTypes, setLineResourceTypes] = useState<ILineResourceType[]>([]);
  const [currentResourceType, setCurrentResourceType] = useState<ICurrentResourceType | undefined>()
  const [CreateResourceTypes, {loading}] = useMutation<CreateResourceTypes, CreateResourceTypesVariables>(CreateResourceTypesMutation)
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = useForm();

  const {data: app, loading: loadingApp} = useAppSelector(state => state.application)

  const reset = () => {
    form.resetFields();
    setLineResourceTypes([]);
    setStep("tables");
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const setAttributes = (data: ILineResourceTypeAttribute[] | null) => {
    let {index, ...updateRes} = JSON.parse(JSON.stringify(currentResourceType));
    updateRes = {...updateRes, attributes: data};
    const newItems = lineResourceTypes.map((oldItem, i) =>
      oldItem.id === currentResourceType?.id ? updateRes : oldItem
    );
    setLineResourceTypes(newItems);
}

  const onLineResourceTypeAdd = (item: ILineResourceType) => {
    setLineResourceTypes([...lineResourceTypes, item]);
  };

  const onLineResourceTypeChange = (index: number, newItem: ILineResourceType) => {
    const newItems = lineResourceTypes.map((oldItem, i) =>
      i === index ? newItem : oldItem
    );
    setLineResourceTypes(newItems);
  };

  const onLineResourceTypeRemove = (index: number) => {
    const newItems = lineResourceTypes.filter((_, i) => i !== index);
    setLineResourceTypes(newItems);
  };

  const onFieldSaveOrUpdate = (item: ILineResourceTypeAttribute, index: number | null) => {
    if(index !== null){
      onLineResourceTypeAttributeChange(index, item)
    }else{
      onLineResourceTypeAttributeAdd(item)
    }
  }

  const onLineResourceTypeAttributeAdd = (item: ILineResourceTypeAttribute) => {
    let updateRes = lineResourceTypes.find((_, i) => i === currentResourceType?.index)!
    updateRes = JSON.parse(JSON.stringify(updateRes));
    updateRes.attributes.push(item)
    const newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems)
  };

  const onLineResourceTypeAttributeChange = (index: number, newItem: ILineFormValues) => {
    let updateRes = lineResourceTypes.find((_, i) => i === currentResourceType?.index)!
    updateRes = JSON.parse(JSON.stringify(updateRes));
    if(newItem.res_type_attribute_type){
      updateRes.attributes[index].specification.res_type_attribute_type = newItem.res_type_attribute_type
    }
    const { res_type_attribute_type, ...updateNewItem} = {...newItem}
    updateRes.attributes[index] = updateNewItem as ILineResourceTypeAttribute
    let newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems)
  };

  const onLineResourceTypeAttributeRemove = (index: number) => {
    const updateRes = lineResourceTypes.find((_, i) => i === currentResourceType?.index)!
    updateRes.attributes.splice(index, 1)
    const newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems)
  };

  const onFieldsTabOpen = (item: ILineResourceType, indexRT: number) => {
    setCurrentResourceType({...item, index: indexRT})
    setStep('fields')
  }

  const onSave = async () => {
    try{
      const input = {appId: app?.id, resourceTypes: lineResourceTypes};
      let res = await CreateResourceTypes({variables: input});
      message.success(res.data?.createResourceTypes)
      onClose()
      history.push("/applications/"+app?.id+"/tables")
      dispatch(fetchApplication({appId: app!.id}))
    }catch(error){
      message.error("Failed to create an app")
      console.error(error)
    }
  }

  const onValidationError = (errorFields: InternalNamePath[]) => {

  }

  if(loadingApp || !app){
    return <LoadingPage />
  }else{
    return (
      <Modal
          title="App new tables"
          visible={props.visible}
          onCancel={onClose}
          footer={[
            <>
              {
                (step === "fields") && (<>
                  <Button
                  type="link"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => setStep("tables")}
                >
                  {"Back to tables"}
                </Button>
                </>)
              }
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={form.submit}
                loading={loading}
              >
                {"Save Tables"}
              </Button>
              <Button
                type="default"
                onClick={onClose}
              >
                {"Cancel"}
              </Button>
            </>
          ]}
          width="900px"
        >
          <Form
              {...formLayout}
              form={form}
              onFinish={() => onSave()}
              onFinishFailed={({ errorFields }) => onValidationError(errorFields.map(ef => ef.name))}
            >
              <Tabs
                size="small"
                activeKey={step}
  
                onChange={step => {
                  setStep(step as any);
                }}
              >
                  <Tabs.TabPane key="tables" tab="Tables">
                    <UxLineResourceTypesTable
                      lineResourceTypes={lineResourceTypes}
                      onLineAdd={onLineResourceTypeAdd}
                      onLineRemove={onLineResourceTypeRemove}
                      onLineChange={onLineResourceTypeChange}
                      onFieldsTabOpen={onFieldsTabOpen}
                      orgId={app.org_id}
                      />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="fields" tab={<div>
                    <span>Fields </span><Badge
                    count={(step === "fields") ? currentResourceType?.res_type_name : 0}
                    style={{ backgroundColor: '#52c41a' }}
                  />
                  </div>} disabled>
                    <UxLineResourceTypeAttributesTable
                      disableProperties={false}
                      onLineAdd={onLineResourceTypeAttributeAdd}
                      onLineRemove={onLineResourceTypeAttributeRemove}
                      onLineChange={onLineResourceTypeAttributeChange}
                      setAttributes={setAttributes}
                      onLineSaveOrUpdate={onFieldSaveOrUpdate}
                      currentResourceType={lineResourceTypes.find((_, i) => i === currentResourceType?.index)}
                      orgId={app.org_id}
                      saveMode='state'
                    />
                  </Tabs.TabPane>
              </Tabs>
            </Form>
        </Modal>
    )
  }
}

export default TableFromScratch
