import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchTemplatesQuery  } from "../queries/fetchTemplates";
import { fetchTemplates_applicationTemplates_data as applicationTemplates } from "../queries/__generated__/fetchTemplates"

interface templatesTypes {
  applicationTemplates: {
    data: applicationTemplates
  }
}

type State = {
  loading: boolean;
  data: applicationTemplates[] | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchTemplates = createAsyncThunk("templates/fetch", (arg: {orgId?: string, first: number}) =>
  apolloClient.query<templatesTypes>({ query: fetchTemplatesQuery, variables: {orgId: arg.orgId, first: arg.first} }).then((res) => res.data.applicationTemplates.data)
);

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTemplates.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchTemplates.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchTemplates.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = templatesSlice;

export const templatesReducer = reducer;
