import { gql } from "@apollo/client";

export const updateResourceTypeMutation = gql`
  mutation UpdateResourceType(
      $resTypeId: String!, 
      $resTypeName: String!, 
      $resTypeDescription: String,
      $resTypeIcon: String,
      $resTypeGalleryDisabled: Int,
      $resTypeHistoryDisabled: Int,
      $resTypeDetailsDisabled: Int,
      $resTypeRowsSelectable: Int,
      $resTypeDisplayRows: Int,
      $resTypeDatatableWidth: String,
      $resTypeFilterSql: String,
      $resTypeViewDisabled: Int,
      $resTypeEditDisabled: Int,
      $resTypeDeleteDisabled: Int,
      $resTypeDisabled: Int,
      $resTypeUserMap: Int,
      $resTypeUserRegistrationEmailTemplate: String!,
      $resTypeUserRegistrationOrgId: String!
    ) {
    updateResourceType(input: {
      config_item_id: $resTypeId
      res_type_name: $resTypeName
      res_type_description: $resTypeDescription
      res_type_icon: $resTypeIcon
      res_type_gallery_disabled: $resTypeGalleryDisabled
      res_type_history_disabled: $resTypeHistoryDisabled
      res_type_details_disabled: $resTypeDetailsDisabled
      res_type_rows_selectable: $resTypeRowsSelectable
      res_type_display_rows: $resTypeDisplayRows
      res_type_datatable_width: $resTypeDatatableWidth
      res_type_filter_sql: $resTypeFilterSql
      res_type_view_disabled: $resTypeViewDisabled
      res_type_edit_disabled: $resTypeEditDisabled
      res_type_delete_disabled: $resTypeDeleteDisabled
      res_type_disabled: $resTypeDisabled
      res_type_user_map: $resTypeUserMap
      res_type_user_registration_email_template: $resTypeUserRegistrationEmailTemplate
      res_type_user_registration_org_id: $resTypeUserRegistrationOrgId
    }){
      id
      message
    }
  }
`;
