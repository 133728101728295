import ProTable, { ProColumns } from "@ant-design/pro-table";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Drawer, Dropdown, Menu, message, Spin } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ResourceDataTrashQuery } from "../queries/resourceDataTrash";
import {
  ResourceDataTrash,
  ResourceDataTrashVariables,
} from "../queries/__generated__/ResourceDataTrash";
import {
  DeleteResourceDataTrash,
  DeleteResourceDataTrashVariables,
} from "../mutations/__generated__/DeleteResourceDataTrash";
import { UndoOutlined, DeleteOutlined } from "@ant-design/icons";

import { UntrashResourceDataTrashMutation } from "../mutations/untrashResourceDataTrash";
import {
  UntrashResourceDataTrash,
  UntrashResourceDataTrashVariables,
} from "../mutations/__generated__/UntrashResourceDataTrash";
import { fetchResourceRecords } from "../slices/resourceRecordsSlice";
import { DeleteResourceDataTrashMutation } from "../mutations/deleteResourceDataTrash";

interface ResourceLinkParams {
  applicationId: string;
  resourceTypeId: string;
}

interface Props {
  resourceTypeToColumns: ProColumns<any, "text">[];
  filterId: string | undefined;
  expandable: {
    expandedRowRender: (record: any) => JSX.Element;
  };
}

export const ResourceRecordTrash: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(
    (state) => state.application.data?.organization?.id
  );
  const { resourceTypeId, applicationId } =
    useParams<ResourceLinkParams>();

  const { data, loading, error } = useQuery<
    ResourceDataTrash,
    ResourceDataTrashVariables
  >(ResourceDataTrashQuery, {
    variables: {
      orgId: orgId!,
      resourceTypeId: resourceTypeId,
    },
  });

  const [UntrashResourceDataTrash, { loading: loadingUntrashResourceDataTrash }] =
    useMutation<UntrashResourceDataTrash, UntrashResourceDataTrashVariables>(
      UntrashResourceDataTrashMutation
    );

    const [DeleteResourceDataTrash, { loading: loadingDeleteResourceDataTrash }] =
    useMutation<DeleteResourceDataTrash, DeleteResourceDataTrashVariables>(
      DeleteResourceDataTrashMutation
    );

  const moreRestoreRecordActions = (record: any) => (
    <Menu mode="inline">
      <Menu.Item onClick={() => DeleteResourceDataTrash({
                variables: {
                  orgId: orgId!,
                  resTypeId: resourceTypeId,
                  recordId: record.id,
                }, refetchQueries: [{
                  query: ResourceDataTrashQuery,
                  variables: {
                    orgId: orgId!,
                    resourceTypeId: resourceTypeId,
                  }
                }]
              }).then((res) => {
                message.success(res.data?.destroyResourceDataTrash)
              })}>
          <DeleteOutlined /> Destroy
      </Menu.Item>
    </Menu>
  );

  function resourceTypeToColumns(): ProColumns[] {
    const actionsColumn: ProColumns = {
      title: "Actions",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={moreRestoreRecordActions(record)}
            onClick={() =>
              UntrashResourceDataTrash({
                variables: {
                  recordId: record.id,
                  orgId: orgId!,
                  resTypeId: resourceTypeId,
                }, refetchQueries: [{
                  query: ResourceDataTrashQuery,
                  variables: {
                    orgId: orgId!,
                    resourceTypeId: resourceTypeId,
                  }
                }]
              }).then((res) => {
                message.success(res.data?.untrashResourceDataTrash)
                dispatch(fetchResourceRecords({ orgId: orgId!, resourceTypeId, selectedFilterId: props.filterId }));
              })
            }
          >
            <UndoOutlined /> Restore
          </Dropdown.Button>
        );
      },
    };
    return [...props.resourceTypeToColumns, actionsColumn];
  }
  const columns: ProColumns[] = resourceTypeToColumns();
  const onClose = () =>
    history.push(
      `/view/applications/${applicationId}/tables/${resourceTypeId}`
    );

  return (
    <Drawer
      footer={
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Button onClick={onClose} type="primary">
            Close
          </Button>
        </div>
      }
      onClose={onClose}
      visible={true}
      width="60%"
    >
      {props.resourceTypeToColumns ? (
        <div>
          <h2>{"Trash"}</h2>
          <ProTable
            expandable={props.expandable}
            locale={{
              triggerDesc: "descend sort text",
              triggerAsc: "ascend sort text",
              cancelSort: "cancel sort text",
            }}
            dataSource={data?.resourceDataTrash || []}
            search={false}
            rowKey="id"
            columns={columns}
            loading={loading}
          ></ProTable>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      )}
    </Drawer>
  );
};
