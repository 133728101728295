import { gql } from "@apollo/client";

export const sendRegisterInvitationMutation = gql`
  mutation SendRegisterInvitation($orgId: String!, $email: [String!]!, $message: String) {
    sendRegisterInvitation(input: {
      org_id: $orgId
      email: $email
      message: $message
    })
  }
`;
