import React from "react";
import { Select, SelectProps } from "antd";
import { useQuery } from "@apollo/client";
import {
  LinkedResourceRecords,
  LinkedResourceRecordsVariables,
} from "../queries/__generated__/LinkedResourceRecords";
import { LinkedResourceRecordsQuery } from "../queries/linkedResourceRecords";

interface Props<VT> extends Omit<SelectProps<VT>, "options"> {
  linkedId: string;
}

export const ResourceRecordSelect: React.FC<Props<string>> = (props) => {
  const { data, loading, error } = useQuery<
    LinkedResourceRecords,
    LinkedResourceRecordsVariables
  >(LinkedResourceRecordsQuery, {
    variables: {
      linkedId: props.linkedId,
    },
    skip: !props.linkedId
  });

  const options = data ? data.linkedResourceRecords : [];

  return (
      <Select {...props} loading={loading}>
        {options.map((option) => (
          <Select.Option value={option.value}>{option.label}</Select.Option>
        ))}
      </Select>
  );
};
