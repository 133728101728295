import { Modal, Button, Select, message, Empty, Col, Row, Form, Input } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Me_me_organizations } from "../queries/__generated__/Me";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import Slider from "@ant-design/react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AppFromTemplate.less";
import SampleNextArrow from "../components/SampleNextArrow";
import SamplePrevArrow from "../components/SamplePrevArrow";
import { useMutation } from "@apollo/client";
import { CreateAppFromTemplateMutation } from "../mutations/createAppFromTemplate";
import {
  CreateAppFromTemplate,
  CreateAppFromTemplateVariables,
} from "../mutations/__generated__/CreateAppFromTemplate";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchTemplates } from "../slices/templatesSlice";
import LoadingPage from "./Loading";
import { useForm } from 'antd/lib/form/Form';
import { InternalNamePath } from "antd/lib/form/interface";
import SubscribeTemplateModal from "../components/SubscribeTemplateModal";
import moment from "moment";

interface Props {
  visible: boolean;
  organizations: Me_me_organizations[] | undefined;
  onClose: () => void;
}

const AppFromTemplate: React.FC<Props> = (props) => {
  const [tempId, setTempId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const {loading, data: templates} = useAppSelector((state) => state.templates);
  const [subscribeTemplateContent, setSubscribeTemplateContent] = useState<ReactNode>()
  const [CreateAppFromTemplate, {loading: loadingCreateApp}] = useMutation<
    CreateAppFromTemplate,
    CreateAppFromTemplateVariables
  >(CreateAppFromTemplateMutation);
  const [modalIsShow, setModalIsShow] = useState(false);
  const [form] = useForm();
  const { Option } = Select;

  const reset = () => {
    setTempId(tempId);
  };

  const handleCloseModal = () => {
    setModalIsShow(false)
  }

  const onSave = () => {
    var selectedTemplate = templates?.find(temp => temp.id == tempId)
    if(selectedTemplate?.price && selectedTemplate?.price > 0 && !selectedTemplate.is_paid){
      setSubscribeTemplateContent(<div>{"Your account will be charged "} <b>{"€"+selectedTemplate.price}</b> {" monthly for the App "} <b>{selectedTemplate.template_name}</b> {" starting "} <b>{moment().format('MMMM Do YYYY')}</b> {". You can cancel any time without fees before that time. Do you want to proceed?"}</div>)
      setModalIsShow(true)
    }else{
      createApp();
    }
  }

  const selectedOrg = props.organizations && props.organizations.length ? props.organizations?.filter(
    (org) => org.pivot?.admin || org.pivot?.superuser
  )[0] : null
  const defaultOrgId = selectedOrg ? selectedOrg.id : null

  const onChangeOrg = (orgId: string) => {
    dispatch(fetchTemplates({orgId, first: 20}))
  }

  const createApp = () => {
      try {
        form
        .validateFields()
        .then(async (data : {org_id: string; app_name: string}) => {
          const input: CreateAppFromTemplateVariables = {
            orgId: data.org_id || defaultOrgId || "",
            appName: data.app_name || "",
            appTempId: tempId,
            demoData: true
          };
          let res = await CreateAppFromTemplate({ variables: input });
          message.success(res.data?.createAppFromTemplate.message);
          onClose();
          window.location.href="/view/applications/" + res.data?.createAppFromTemplate.id;
        })
      } catch (error) {
        message.error("Failed to create an app");
        console.error(error);
      }
    onClose();
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {

  }

  useEffect(() => {
    dispatch(fetchTemplates({first: 20}))
  }, [dispatch])

  if(templates && templates?.length){
    return (
      <Modal
      visible={props.visible}
      title="Create app from template"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <>
          <Button
            type="primary"
            disabled={tempId ? false : true}
            icon={<SaveOutlined />}
            loading={loadingCreateApp}
            onClick={form.submit}
          >
            {"Continue"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
      width="900px"
    >
      <Form 
        form={form} 
        onFinish={() => onSave()}
        onFinishFailed={({ errorFields }) => onValidationError(errorFields.map(ef => ef.name))}
      >
        <Row justify="space-between">
          <Col span={12}>
            <Form.Item name="org_id" label="Select an organization" required>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select an organization"
                defaultValue={defaultOrgId}
                onChange={val => onChangeOrg(val)}
              >
                {props.organizations
                  ?.filter((org) => org.pivot?.admin || org.pivot?.superuser)
                  .map((org) => (
                    <Option value={org.id}>{org.org_name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
            <Form.Item name="app_name" label="App name">
              <Input max={80}/>
            </Form.Item>
          <Col span={12}>
          </Col>
        </Row>
      </Form>
      {loading ? <p>Loading...</p> : 
      <Slider {...settings}>
      {
      templates?.map((temp) => (
        <div key={temp.id}>
          <div
            className="temp-card"
            onClick={() => setTempId(temp.id)}
            style={{
              backgroundColor:
                tempId === temp.id
                  ? "hsla(195, 53%, 79%, 0.4)"
                  : "white",
            }}
          >
            <div
              className="temp-check"
              style={{
                visibility: tempId === temp.id ? "visible" : "hidden",
              }}
            >
              <IconPickerItem
                color="rgb(24, 144, 255)"
                icon="FaRegCheckCircle"
              />
            </div>
            {
              temp.stripe_price && !temp.is_paid ? <div className="temp-premium premium-color">
              <IconPickerItem
                color="white"
                icon="FaRegStar"
                size={15}
              />
              <span>Premium {temp.price+" € "}</span>
            </div> : temp.stripe_price && temp.is_paid ? <div className="temp-premium paid-color">
              <IconPickerItem
                color="white"
                icon="FaRegStar"
                size={15}
              />
              <span>Available</span>
            </div> : ''
            }
            <div className="temp-card-header">
              <div
                className="temp-icon"
              >
              <IconPickerItem
                color="white"
                icon={temp.template_logo as IconList}
              />
              </div>
            </div>
            <div className="temp-card-body">
              <h3>{temp.template_name}</h3>
              <div className="temp-card-content">
                <p>{temp.template_description}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
     }
     <SubscribeTemplateModal createApp={createApp} orgId={form.getFieldValue('org_id') ? form.getFieldValue('org_id') : defaultOrgId} priceId={templates?.find(temp => temp.id == tempId)?.stripe_price} content={subscribeTemplateContent} modalIsShow={modalIsShow} handleCloseModal={handleCloseModal}/>
    </Modal>
    )
  }else if(loading){
    return <LoadingPage />
  }else{
    return <Empty description="No template to display."></Empty>
  }
};

export default AppFromTemplate;
