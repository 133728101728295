import { gql } from "@apollo/client";

export const ResourceDataTrashQuery = gql`
  query ResourceDataTrash(
    $orgId: String!,
    $resourceTypeId: String!,
  ) {
    resourceDataTrash(
      input: { org_id: $orgId, res_type_id: $resourceTypeId, }
    )
  }
`;
