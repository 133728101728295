import { gql } from "@apollo/client";

export const updateResourceRecordMutation = gql`
  mutation UpdateResourceRecord($orgId: String!, $resourceTypeId: String!, $recordId: String!, $data: Json!, $user: Json) {
    updateResourceRecord(input: {
      org_id: $orgId
      res_type_id: $resourceTypeId
      record_id: $recordId
      data: $data
      user: $user
    })
  }
`;
