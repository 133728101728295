import React, { useEffect } from "react";
import { IAuthInfo } from "./Auth";
import LoadingPage from "./Loading";
import { useLocation, Link, Redirect } from "react-router-dom";
import { verifyEmail } from "../slices/verifyEmail";
import { useAppDispatch, useAppSelector } from "../hooks";
import { VerifyEmail_verifyEmail as VerifyEmailResponse } from "../mutations/__generated__/VerifyEmail";
import { setApolloToken } from "../apolloClient";
import { setAuthToken } from "../authToken";

interface Props{
  onFinish: (authInfo: IAuthInfo) => void;
}

const VerifyEmail: React.FC<Props> = (props) => {

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token')
  const dispatch = useAppDispatch();
  const {data, error, loading} = useAppSelector((state) => state.verifyEmail);

  const VerifyEmailRedirect = (data : VerifyEmailResponse | null) => {
    if(data?.user){
      const { id, email, first_name, last_name, lang, activeOrganization } = data?.user;
      const authToken = data?.access_token;

      if (authToken) {
        setApolloToken(authToken);
        setAuthToken(authToken);
      }

      props.onFinish({
        id,
        lang,
        email,
        first_name,
        last_name,
        active_organization: {
          id: activeOrganization?.id || null,
          org_name: activeOrganization?.org_name || null,
        }
      });
    }
  }

  useEffect(() => {
    if(token){
      dispatch(verifyEmail({token})).then(res => {
        if(res.type === 'verify/email/fulfilled'){
          const responseData = res.payload as VerifyEmailResponse | null
          VerifyEmailRedirect(responseData)
        }
      })
    }
  }, [token])

  if(loading){
    return <LoadingPage />; 
  }else if(error){
    return <div style={{textAlign: "center"}}>
      <h2>{error.message}</h2>
      <Link to="/" className="ant-btn ant-btn-primary">Go back to the home page</Link>
    </div>
  }else{
    return <Redirect to='/applications/create'  />
  }
}

export default VerifyEmail;