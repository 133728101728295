import React, { useEffect, useReducer } from "react";
import { Layout, Menu, Breadcrumb } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import { OrganizationsTable } from "../components/organizationsTable";
import ApplicationsList from "../pages/ApplicationsList";
import { OrganizationForm } from '../components/organizationForm';
import ApplicationCreate from '../pages/ApplicationCreate';
import ApplicationEdit from '../pages/ApplicationEdit';
import { deleteAuthToken } from "../authToken";
import SubMenu from 'antd/lib/menu/SubMenu';
import { UserOutlined } from "@ant-design/icons";
import logoFull from '../digintu-code-logo.png';
import Profile from '../pages/Profile';
import Billing from '../pages/Billing';
import Invoices from '../pages/Invoices';
import Subscriptions from '../pages/Subscriptions';
import InvoiceDetails from '../pages/InvoiceDetails';
import Basket from '../pages/Basket';
import SimpleBilling from '../pages/SimpleBilling';
import { fetchMe } from "../slices/meSlice";
import { useAppDispatch, useAppSelector } from "../hooks";

const { Header, Content, Footer, Sider } = Layout;

export const MainLayout = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.me.data)
  const onLogout = () => {
    deleteAuthToken();
    window.location.reload();
  };

  const location = useLocation();

  const breadcrumbNameMap: Record<string, string> = {
    '/applications': 'Applications',
    '/organizations': 'Organizations',
    '/profile': 'Profile',
    '/basket': 'Basket',
    '/billing': 'Billing',
  };

  const pathSnippets = window.location.pathname.split('/').filter(i => (i && breadcrumbNameMap[`/${i}`]));

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  useEffect(() => {
    forceUpdate();
    dispatch(fetchMe());
  }, [dispatch, location]);

    return (
      <Router>
        <Layout style={{minHeight: "100vh"}}>
          <Header style={{ position: 'fixed', zIndex: 1, width: '100%', boxShadow: '1px 1px 5px rgb(0 0 0 / 20%)', background: "white", color: "dimgray" }} onClick={forceUpdate}>
            <div style={{display: 'flex'}}>
              <div className='logo' style={{width: "20%"}}>
                <Link to="/">
                  <img src={logoFull} alt="logo" width={115}/>
                </Link>
              </div>
              <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}  style={{width: "80%"}}>
                <Menu.Item key="1">
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/organizations">Organizations</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/applications">Applications</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/basket">Basket</Link>
                </Menu.Item>
                <SubMenu style={{marginLeft: 'auto'}} key="SubMenu" icon={<UserOutlined />} title={user ? user?.first_name+' '+user?.last_name : "Profile"}>
                  <Menu.Item key="setting:4">
                    <Link to="/profile">Profile</Link>
                  </Menu.Item>
                  <Menu.Item key="setting:5">
                    <Link to="/billing">Billing</Link>
                  </Menu.Item>
                  <Menu.Item key="setting:1" onClick={() => onLogout()}>Logout</Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content className="site-layout">
            <Breadcrumb style={{ margin: '16px 0' }}>
              {breadcrumbItems}
            </Breadcrumb>
                <Switch>
                  <Route path="/" exact>
                    <ApplicationCreate />
                  </Route>
                  <Route path="/organizations" exact>
                    <OrganizationsTable />
                  </Route>
                  <Route path="/organizations/:orgId/edit">
                    <OrganizationForm mode="edit" />
                  </Route>
                  <Route path="/organizations/create">
                    <OrganizationForm mode="create" />
                  </Route>
                  <Route path="/applications/create">
                    <ApplicationCreate />
                  </Route>
                  <Route path="/applications/:appId">
                    <ApplicationEdit />
                  </Route>
                  <Route path="/basket">
                    <Basket />
                  </Route>
                  <Route path="/applications" exact>
                    <ApplicationsList />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/billing">
                    <SimpleBilling />
                  </Route>
                  <Route path="/invoices" exact>
                    <Invoices />
                  </Route>
                  <Route path="/invoices/:invoiceId">
                    <InvoiceDetails />
                  </Route>
                  <Route path="/subscriptions">
                    <Subscriptions />
                  </Route>
                  <Redirect to="/" />
                </Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Digintu.code © 2022</Footer>
        </Layout>
      </Router>
    )
}