import React from 'react'
import { AutoComplete, Form } from 'antd';
import { useQuery } from '@apollo/client';
import { autoCompleteInput, autoCompleteInputVariables } from '../queries/__generated__/autoCompleteInput';
import { AutoCompleteInputQuery } from '../queries/autoCompleteInput';

interface Props {
  constraints: any
  defaultFormItemProps: any
  disabled: boolean;
}

const AutoCompleteBySQLSelect: React.FC<Props> = (props) => {
  const { data, loading, error } = useQuery<
    autoCompleteInput,
    autoCompleteInputVariables
  >(AutoCompleteInputQuery, {
    variables: {
      sql: props.constraints.sql,
    },
    skip: (props.constraints.sql == null) || !props.constraints.sql,
  });
  
  const options: ({value: string, label: string} | {value: ''})[]  = data?.autoCompleteInput ? data.autoCompleteInput : []
  
  return (
    <Form.Item {...props.defaultFormItemProps}>
      <AutoComplete
        disabled={props.disabled}
        size={props.constraints.size}
        style={{ width: 200 }}
        options={options}
        placeholder="Default placeholder"
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  )
}

export default AutoCompleteBySQLSelect