import { gql } from "@apollo/client";

export const ResetPasswordMutation = gql`
  mutation ResetPassword($input: ResetPasswordInput!){
    resetPassword(input: $input){
      success
      message
    }
  }
`;
