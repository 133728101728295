import React from 'react';
import { IconPickerItem } from 'react-fa-icon-picker';

export default function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style,
        padding: '0px 0px 0px 30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,}}
        onClick={onClick}>
          <IconPickerItem color="gray" icon="FaChevronLeft" />
        </div>
    );
  }