
import React, { useState } from "react";

interface Props{
  children: string
}

const ReadMore:React.FC<Props> = ({ children }) => {
  const text: string = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const textStyle = {
    display: 'inline',
    width: '100%'
  }
    
  const readOrHide = {
    color: 'rgb(192,192,192)',
    cursor: 'pointer'
  }

  return (
    <p style={textStyle}>
      {isReadMore ? text.slice(0, 50) : text}
      <span onClick={toggleReadMore} style={readOrHide}>
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

export default ReadMore;