import { gql } from "@apollo/client";

export const CreateResetPasswordTokenMutation = gql`
  mutation CreateResetPasswordToken($email: String!){
    createResetPasswordToken(email: $email){
      success
      message
      data
    }
  }
`;
