import {
  Button,
  Input,
  Modal,
  Tabs,
  Form,
  Select,
  message,
  Badge,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { InternalNamePath } from "rc-field-form/lib/interface";
import UxLineResourceTypesTable, {
  ILineResourceType,
} from "../components/generic/UxLineResourceTypesTable";
import UxLineResourceTypeAttributesTable, {
  ILineFormValues,
  ILineResourceTypeAttribute,
} from "../components/generic/UxLineResourceTypeAttributesTable";
import { Me_me_organizations } from "../queries/__generated__/Me";
import { IconList, IconPicker } from "react-fa-icon-picker";
import { useMutation } from "@apollo/client";
import { CreateApplicationMutation } from "../mutations/createApplication";
import {
  CreateApplication,
  CreateApplicationVariables,
} from "../mutations/__generated__/CreateApplication";
import { useHistory } from "react-router";
import { isNil } from "lodash";
import {
  iconPickerBtnStyle,
  iconPickerContainerStyles,
} from "./ApplicationDetailsEdit";
import { useAppDispatch } from "../hooks";

interface Props {
  visible: boolean;
  organizations: Me_me_organizations[] | undefined;
  onClose: () => void;
  mode?: string;
}

export interface IAppFromScratch {
  org_id: string;
  name: string;
  description: string;
  icon: IconList;
}

export interface ICurrentResourceType extends ILineResourceType {
  index: number;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export interface ILineResourceTypeExclude {
  id: string | null;
  res_type_description: string | null;
  res_type_name: string;
  res_type_icon: string | null;
}

const initResType: ILineResourceType = {
  id: null,
  res_type_description: null,
  res_type_name: "",
  res_type_icon: null,
  attributes: [],
};

const initResTypeAttribute: ILineResourceTypeAttribute = {
  id: null,
  linked_id: null,
  res_type_id: null,
  res_type_attribute_type_mandatory: false,
  res_type_attribute_type_printable: false,
  res_type_attribute_type_editable: true,
  res_type_attribute_type_hidden: null,
  res_type_attribute_type_speech_input: null,
  res_type_attribute_type_default_value: null,
  res_type_attribute_type_formula: null,
  res_type_attribute_type_group: null,
  res_type_attribute_type_detail: false,
  specification: {
    id: null,
    res_type_attribute_name: "",
    res_type_attribute_type: "",
    res_type_attribute_description: null,
    res_type_attribute_constraints: null,
  },
};

const AppFromScratch: React.FC<Props> = (props) => {
  const [step, setStep] = useState<"properties" | "tables" | "fields">(
    "properties"
  );
  const [lineResourceTypes, setLineResourceTypes] = useState<
    ILineResourceType[]
  >([]);
  const [currentResourceType, setCurrentResourceType] = useState<
    ICurrentResourceType | undefined
  >();
  const [app, setApp] = useState<IAppFromScratch>({
    org_id: "",
    name: "",
    icon: "FaBookOpen",
    description: "",
  });
  const [CreateApplication, { loading }] = useMutation<
    CreateApplication,
    CreateApplicationVariables
  >(CreateApplicationMutation);
  const [loadingAnalyze, setLoadingAnalyze] = useState(false);
  const history = useHistory();
  const [form] = useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const [demoData, setDemoData] = useState(false);

  const reset = () => {
    form.resetFields();
    setLineResourceTypes([]);
    setStep("properties");
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const setAttributes = (data: ILineResourceTypeAttribute[] | null) => {
    let { index, ...updateRes } = JSON.parse(
      JSON.stringify(currentResourceType)
    );
    updateRes = { ...updateRes, attributes: data };
    const newItems = lineResourceTypes.map((oldItem, i) =>
      oldItem.id === currentResourceType?.id ? updateRes : oldItem
    );
    setLineResourceTypes(newItems);
  };

  const onLineResourceTypeAdd = (item: ILineResourceType) => {
    setLineResourceTypes([...lineResourceTypes, item]);
  };

  const onLineResourceTypeChange = (
    index: number,
    newItem: ILineResourceType
  ) => {
    const newItems = lineResourceTypes.map((oldItem, i) =>
      i === index ? newItem : oldItem
    );
    setLineResourceTypes(newItems);
  };

  const onLineResourceTypeRemove = (index: number) => {
    const newItems = lineResourceTypes.filter((_, i) => i !== index);
    setLineResourceTypes(newItems);
  };

  const onFieldSaveOrUpdate = (
    item: ILineResourceTypeAttribute,
    index: number | null
  ) => {
    if (index !== null) {
      onLineResourceTypeAttributeChange(index, item);
    } else {
      onLineResourceTypeAttributeAdd(item);
    }
  };

  const onLineResourceTypeAttributeAdd = (item: ILineResourceTypeAttribute) => {
    let updateRes = lineResourceTypes.find(
      (_, i) => i === currentResourceType?.index
    )!;
    updateRes = JSON.parse(JSON.stringify(updateRes));
    updateRes.attributes.push(item);
    const newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems);
  };

  const onLineResourceTypeAttributeChange = (
    index: number,
    newItem: ILineFormValues
  ) => {
    let updateRes = lineResourceTypes.find(
      (_, i) => i === currentResourceType?.index
    )!;
    updateRes = JSON.parse(JSON.stringify(updateRes));
    if (newItem.res_type_attribute_type) {
      updateRes.attributes[index].specification.res_type_attribute_type =
        newItem.res_type_attribute_type;
    }
    const { res_type_attribute_type, ...updateNewItem } = { ...newItem };
    updateRes.attributes[index] = updateNewItem as ILineResourceTypeAttribute;
    let newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems);
  };

  const onLineResourceTypeAttributeRemove = (index: number) => {
    const updateRes = lineResourceTypes.find(
      (_, i) => i === currentResourceType?.index
    )!;
    updateRes.attributes.splice(index, 1);
    const newItems = lineResourceTypes.map((oldRes, i) =>
      i === currentResourceType?.index ? updateRes : oldRes
    );
    setLineResourceTypes(newItems);
  };

  const dispatch = useAppDispatch();

  const onFieldsTabOpen = (item: ILineResourceType, indexRT: number) => {
    setCurrentResourceType({ ...item, index: indexRT });
    setStep("fields");
  };

  const onSave = async () => {
    try {
      const input = {
        orgId:
          app.org_id ||
          props.organizations?.filter(
            (org) => org.pivot?.admin || org.pivot?.superuser
          )[0].id ||
          "",
        ...app,
        demoData,
        resourceTypes: lineResourceTypes,
      };
      let res = await CreateApplication({ variables: input });
      message.success(res.data?.createApplication.message);
      onClose();
      window.location.href =
        "/view/applications/" + res.data?.createApplication.id;
    } catch (error) {
      message.error("Failed to create an app");
      console.error(error);
    }
  };

  const analyzeText = async (description: string) => {
    try {
      // fetch call
      setLoadingAnalyze(true);
      const response = await fetch(
        `https://schema-org-api.herokuapp.com/entities?text=${description}`
      );
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let res = await response.json();
      // map the response to match ILineResourceType stucture
      var i = 0;
      let resTypesData: ILineResourceType[] = res.RESULTS.map((item: any) => {
        i = 0;
        var itemProperties = item.properties.map((attr: any) => {
          i++;
          return {
            ...initResTypeAttribute,
            res_type_attribute_type_detail: i > 4 ? true : false,
            specification: {
              ...initResTypeAttribute.specification,
              res_type_attribute_name: attr.name,
              res_type_attribute_type: attr.type,
              res_type_attribute_description: attr.desc,
            },
          };
        });
        return {
          ...initResType,
          res_type_name: item.entity,
          attributes: itemProperties,
        };
      });
      setLineResourceTypes(resTypesData);
      // go to next tab
      setStep("tables");
    } catch (error) {
      message.error("Failed to analyze text");
      console.error(error);
    } finally {
      setLoadingAnalyze(false);
    }
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {};
  
  const selectedOrg = props.organizations && props.organizations.length ? props.organizations?.filter(
    (org) => org.pivot?.admin || org.pivot?.superuser
  )[0] : null
  const defaultOrgId = selectedOrg ? selectedOrg.id : null

  return (
    <Modal
      title={
        props.mode == "ai"
          ? "Add new app from text"
          : "Add new app from scratch"
      }
      visible={props.visible}
      onCancel={onClose}
      footer={[
        step === "properties" && (
          <>
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              loading={loadingAnalyze}
              onClick={() =>
                props.mode == "ai"
                  ? analyzeText(app.description)
                  : setStep("tables")
              }
            >
              {props.mode == "ai" ? "Analyze text" : "Add tables"}
            </Button>
            <Button type="default" onClick={onClose}>
              {"Cancel"}
            </Button>
          </>
        ),
        (step === "tables" || step === "fields") && (
          <>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() =>
                setStep(step === "tables" ? "properties" : "tables")
              }
            >
              {"Back to " + (step === "tables" ? "properties" : "tables")}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={form.submit}
              loading={loading}
            >
              {"Save App"}
            </Button>
            <Button type="default" onClick={onClose}>
              {"Cancel"}
            </Button>
          </>
        ),
      ]}
      width="900px"
    >
      <Tabs
        size="small"
        activeKey={step}
        onChange={(step) => {
          setStep(step as any);
        }}
      >
        <Tabs.TabPane key="properties" tab="App properties">
          <Form
            {...formLayout}
            form={form}
            onFinish={() => onSave()}
            onFinishFailed={({ errorFields }) =>
              onValidationError(errorFields.map((ef) => ef.name))
            }
          >
            <Form.Item label={"Select organization"} required name="org_id">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select an organization"
                onChange={(val: string) => setApp({ ...app, org_id: val })}
                defaultValue={defaultOrgId}
              >
                {props.organizations
                  ?.filter((org) => org.pivot?.admin || org.pivot?.superuser)
                  .map((org) => (
                    <Option value={org.id}>{org.org_name}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={"App name"} required name="app_name">
              <Input
                onChange={(elt) => setApp({ ...app, name: elt.target.value })}
              />
            </Form.Item>
            <Form.Item label={"Icon"} required name="app_client_logo">
              <IconPicker
                containerStyles={{
                  ...iconPickerContainerStyles,
                  position: "fixed",
                }}
                value={app.icon}
                buttonStyles={iconPickerBtnStyle}
                buttonIconStyles={{ display: "contents" }}
                onChange={(val) => setApp({ ...app, icon: val })}
              />
            </Form.Item>
            <Form.Item label="Generate demo data" name="demo_data">
              <Checkbox onChange={(elt) => setDemoData(elt.target.checked)} />
            </Form.Item>
            <Form.Item label={"Description"} required name="app_description">
              <TextArea
                onChange={(elt) =>
                  setApp({ ...app, description: elt.target.value })
                }
                placeholder="Tell us about the structure of your app. Use artificial intelligence to analyze language and suggest tables and fields."
                rows={5}
              />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key="tables" tab="Tables">
          <UxLineResourceTypesTable
            lineResourceTypes={lineResourceTypes}
            onLineAdd={onLineResourceTypeAdd}
            onLineRemove={onLineResourceTypeRemove}
            onLineChange={onLineResourceTypeChange}
            onFieldsTabOpen={onFieldsTabOpen}
            orgId={isNil(app.org_id) ? app.org_id : defaultOrgId!}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="fields"
          tab={
            <div>
              <span>Fields </span>
              <Badge
                count={
                  step === "fields" ? currentResourceType?.res_type_name : 0
                }
                style={{ backgroundColor: "#52c41a" }}
              />
            </div>
          }
          disabled
        >
          <UxLineResourceTypeAttributesTable
            disableProperties={false}
            onLineAdd={onLineResourceTypeAttributeAdd}
            onLineRemove={onLineResourceTypeAttributeRemove}
            onLineChange={onLineResourceTypeAttributeChange}
            setAttributes={setAttributes}
            onLineSaveOrUpdate={onFieldSaveOrUpdate}
            currentResourceType={lineResourceTypes.find(
              (_, i) => i === currentResourceType?.index
            )}
            orgId={isNil(app.org_id) ? app.org_id : defaultOrgId!}
            saveMode="state"
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default AppFromScratch;
