import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchApplicationsQuery  } from "../queries/fetchApplications";
import { fetchApplications_applications } from "../queries/__generated__/fetchApplications"

interface applicationsTypes {
  applications: {
    data: fetchApplications_applications
  }
}

type State = {
  loading: boolean;
  data: fetchApplications_applications | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchApplications = createAsyncThunk("applications/fetch", async (arg: {first: number}) => {
  const res = await apolloClient.query<applicationsTypes>({ query: fetchApplicationsQuery, variables: {first: arg.first} });
  return res.data.applications ?? null
}
);

const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchApplications.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchApplications.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchApplications.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = applicationsSlice;

export const applicationsReducer = reducer;
