import { Button, Table, Tag, Card, Col, Input, message, Popconfirm, Row, Select, Tooltip } from "antd";
import { WindowsOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "./Billing.css";
import { useMutation, useQuery } from "@apollo/client";
import { subscribeOrganizationMutation } from "../mutations/subscribeOrganization";
import { SubscribeOrganization, SubscribeOrganizationVariables } from "../mutations/__generated__/SubscribeOrganization";
import { useAppSelector } from "../hooks";
import {loadStripe} from '@stripe/stripe-js';
import { GetBillingPortalUrl, GetBillingPortalUrlVariables } from "../mutations/__generated__/GetBillingPortalUrl";
import { GetBillingPortalUrlMutation } from "../mutations/getBillingPortalUrl";
import { fetchSubscriptions, fetchSubscriptionsVariables } from "../queries/__generated__/fetchSubscriptions";
import { fetchSubscriptionsQuery } from "../queries/fetchSubscriptions";
import { CancelSubscription, CancelSubscriptionVariables } from "../mutations/__generated__/CancelSubscription";
import { CancelSubscriptionMutation } from "../mutations/cancelSubscription";
import { ResumeSubscription, ResumeSubscriptionVariables } from "../mutations/__generated__/ResumeSubscription";
import { ResumeSubscriptionMutation } from "../mutations/resumeSubscription";

import type { TableColumnsType } from 'antd';
import { Badge} from 'antd';
import moment from "moment";
import { RemoveTemplateSubscription, RemoveTemplateSubscriptionVariables } from "../mutations/__generated__/RemoveTemplateSubscription";
import { RemoveTemplateSubscriptionMutation } from "../mutations/removeTemplateSubscription";
import LoadingPage from "./Loading";

interface SubscriptionType {
  name: string;
  stripe_price: string;
  stripe_status: string;
  items: SubscriptionItemType[]
}

interface SubscriptionItemType {
  key: React.Key;
  date: string;
  name: string;
  price_amount: number;
  price_currency: string;
  price_recurring_interval: string;
}

const SimpleBilling: React.FC = () => {

  const organizations = useAppSelector(
    (state) => state.me.data?.organizations
  );

  const defaultOrg = organizations && organizations.length ? organizations?.filter(
    (org) => org.pivot?.admin || org.pivot?.superuser
  )[0] : null
  const plans = [{name: 'Stater', price_id: "price_1LvNhgJWk22alzyrp8TRJfvb"}]
  const [orgId, setOrgId] = useState(defaultOrg ? defaultOrg.id : null)

  const { data: dataSubscriptions, loading, error } = useQuery<
    fetchSubscriptions,
    fetchSubscriptionsVariables
  >(fetchSubscriptionsQuery, {
    variables: {
      orgId: orgId!,
    },
    skip: orgId == null
  });

  const [SubscribeOrganization, {loading: loadingSubscription}] = useMutation<
  SubscribeOrganization,
  SubscribeOrganizationVariables
  >(subscribeOrganizationMutation);

  const [GetBillingPortalUrl] = useMutation<
  GetBillingPortalUrl,
  GetBillingPortalUrlVariables
  >(GetBillingPortalUrlMutation);

  const [CancelSubscription, {loading: loadingCancel}] = useMutation<
  CancelSubscription,
  CancelSubscriptionVariables
  >(CancelSubscriptionMutation);

  const [ResumeSubscription, {loading: loadingResume}] = useMutation<
  ResumeSubscription,
  ResumeSubscriptionVariables
  >(ResumeSubscriptionMutation);

  const [RemoveTemplateSubscription, {loading: loadingRemoveTemplate}] = useMutation<
  RemoveTemplateSubscription,
  RemoveTemplateSubscriptionVariables
  >(RemoveTemplateSubscriptionMutation);

  const { Option } = Select;

  const stripePromise = loadStripe('pk_live_51KpHgMJWk22alzyrN1vBqS2ezYnSJorMfQNrNnqyvhrNb0KJyMygN5MXuPUPcNMOTqA7TQiP4qYZUOjfvTIZ9JSP00GGyF1vzT');

  const subscribeOrg = async (stripePriceId: string) => {
    if (orgId){
      try {
          const input: SubscribeOrganizationVariables = {
            orgId,
            priceId: stripePriceId
          };
          let res = await SubscribeOrganization({ variables: input });
            stripePromise.then(stripe => {
              if(res.data){
                stripe?.redirectToCheckout({ sessionId: JSON.parse(res.data.subscribeOrganization).id }).then(res => {
                  console.log(res.error.message)
                });
              }
            })
            message.success('Subscription redirection in few seconds !');
          // document.location.reload();   
      } catch (error) {
        message.error("Failed to subscribe organization");
        console.error(error);
      }
    }
  };

  const openBillingPortal = async (orgId: string | null) => {
    if(orgId){
      try {
        const input: GetBillingPortalUrlVariables = {
          orgId,
        };
        let res = await GetBillingPortalUrl({variables: input });
          if(res.data){
            window.location.href = res.data.getBillingPortalUrl
          }
          message.success('Billing Portal redirection in few seconds !');
        // document.location.reload();   
    } catch (error) {
      message.error("Failed to Open Billing Portal");
      console.error(error);
    }
    }
  }

  const cancelSubscription = async (orgId: string | null) => {
    if(orgId){
      try {
        const input: CancelSubscriptionVariables = {
          orgId,
        };
        let res = await CancelSubscription({variables: input, refetchQueries: [{
          query: fetchSubscriptionsQuery,
          variables: {
            orgId: orgId!,
          }}]});
          message.success(res.data?.cancelSubscription);
        // document.location.reload();   
    } catch (error) {
      message.error("Failed to cancel subscription");
      console.error(error);
    }
    }
  }

  const removeTemplateSubscription = async (stripe_price: string ) => {
    if(orgId){
      try {
        const input: RemoveTemplateSubscriptionVariables = {
          stripePrice: stripe_price,
          orgId
        };
        let res = await RemoveTemplateSubscription({variables: input, refetchQueries: [{
          query: fetchSubscriptionsQuery,
          variables: {
            orgId
          }}]});
        message.success(res.data?.removeTemplateSubscription);
        // document.location.reload();   
      } catch (error) {
        message.error("Failed to remove template subscription");
        console.error(error);
      }
    }
  }

  const resumeSubscription = async (orgId: string | null) => {
    if(orgId){
      try {
        const input: ResumeSubscriptionVariables = {
          orgId,
        };
        let res = await ResumeSubscription({variables: input, refetchQueries: [{
          query: fetchSubscriptionsQuery,
          variables: {
            orgId: orgId!,
          }}]});
          message.success(res.data?.resumeSubscription);
        // document.location.reload();   
    } catch (error) {
      message.error("Failed to resume subscription");
      console.error(error);
    }
    }
  }

  useEffect(() => {
    if(defaultOrg?.id){
      setOrgId(defaultOrg.id)
    }
  }, [defaultOrg])

  function currentPlan(stripePriceId: any){
    var plan = dataSubscriptions?.subscriptions.find(item => item.items.find((item:any) => item.stripe_price == stripePriceId))
    if(plan){
      return (plan.stripe_status == 'trialing' || plan.stripe_status == 'active')
    }else{
      return false
    }
  }

  const columns: TableColumnsType<SubscriptionType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_:any, record) => ((record.items.length > 1) ? record.name + ' + ' + (record.items.length - 1) + ' templates' : record.name)
    },
    {
      title: 'Total price',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (_: any, record) => 
      ((record.items.map(item => item.price_amount).reduce((total, curr)=>total+curr)/100).toLocaleString('en-US', {
        style: 'currency',
        currency: record.items[0].price_currency
      }) + ' / ' + record.items[0].price_recurring_interval)
    },
    {
      title: 'Status',
      dataIndex: 'stripe_status',
      key: 'stripe_status',
      render: (stripe_status: any) => <Tag color={stripe_status == "canceled" ? "red" : "green"}>
        {stripe_status}
      </Tag>
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (val: string) => <span>{moment(val).format("YYYY-MM-DD hh:mm:ss")}</span>
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => {
        return (record.stripe_status == 'canceled') ? '' : (record.stripe_status !== 'canceled') && (record.ends_at) && moment(record.ends_at).isAfter() ? <><Popconfirm placement="right" title="Sure to resume this plan?" onConfirm={() => resumeSubscription(orgId)} ><Button loading={loadingResume} size="middle" type='primary' style={{marginRight: '5px'}}>Resume</Button></Popconfirm> <Tooltip title={'This subscription will be canceled at '+moment(record.ends_at).format('MMMM Do YYYY, h:mm:ss')+' if you do not resume it'} color={'gold'} key={'gold'}>
        <QuestionCircleOutlined />
      </Tooltip></> : <Popconfirm placement="right" title="Sure to cancel this plan?" onConfirm={() => cancelSubscription(orgId)} ><Button loading={loadingCancel} size="middle" type='primary'  danger>Cancel</Button></Popconfirm>
      },
    },
  ];

  const expandedRowRender = (record: any) => {
    let selectedSub: SubscriptionType = dataSubscriptions?.subscriptions.find(sub => sub.id == record['id'])
    const columns: TableColumnsType<SubscriptionItemType> = [
      { 
        title: 'Created At', 
        dataIndex: 'created_at', 
        key: 'created_at',
        render: (val: string) => <span>{moment(val).format("YYYY-MM-DD hh:mm:ss")}</span> 
      },
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Price',
        key: 'price',
        render: (_: any, record) => (
          <span>
            {((record.price_amount)/100).toLocaleString('en-US', {
              style: 'currency',
              currency: record.price_currency
            }) + ' / ' + record.price_recurring_interval}
          </span>
        ),
      },
      {
        title: 'Status',
        key: 'state',
        render: () => selectedSub.stripe_status == 'canceled' ? (<span>
          <Badge status="error" />
          canceled
        </span>) : (
          <span>
            <Badge status="success" />
            Active
          </span>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, rec: any) => selectedSub.stripe_status == 'canceled' ? '': (
          <Popconfirm placement="right" title={<p>Sure to remove this template?<br/> All apps using this template will be disable.</p>} onConfirm={() => removeTemplateSubscription(rec['stripe_price'])} >
            <Button size="small" type="default" danger>
              Remove
            </Button>
          </Popconfirm>
        ),
      },
    ];


    return <Table columns={columns} dataSource={selectedSub.items ? selectedSub.items.filter((item: any) => plans.find(p => p.price_id != item.stripe_price)) : []} pagination={false} />;
  };

  if(loading){
    return <LoadingPage />
  }else{
    return (
      <div>
        <div style={{display: 'flex'}}>
          <Select 
            defaultValue={orgId} 
            style={{width: '250px', marginRight: '10px'}}
            placeholder="Select an organization"
            onChange={(val: string) => setOrgId(val)}
          >
            {organizations?.filter((org) => org.pivot?.admin || org.pivot?.superuser)?.map(org => <Option value={org.id}>{org.org_name}</Option>)}
          </Select>
          <Button type="primary" onClick={() => openBillingPortal(orgId)}>Manage Billing</Button>
        </div>
        <div className="plan-wrapper">
        <h2>Different plan</h2>
          <Row gutter={40}>
            <Col span={6}>
              <Card
                className="card-container" 
                title={
                  <div className="card-title">
                    <div className="plan-icon">
                      <WindowsOutlined />
                    </div>
                    <h2>{plans[0]['name']}</h2>
                    <p style={{textAlign: "center"}}><b>30 days free trial</b> <br/>then only $4,99/month. Cancel anytime</p>
                    <Popconfirm disabled={currentPlan(plans[0]['price_id'])} placement="right" title="Sure to subscribe to this plan?" onConfirm={() => subscribeOrg(plans[0]['price_id'])}>
                      <Button type="primary" size="large"
                      disabled={currentPlan(plans[0]['price_id'])} 
                      loading={loadingSubscription}
                      onClick={(e) => {
                        
                        e.stopPropagation();
                      }}>
                        {currentPlan(plans[0]['price_id']) ? "Current Plan" : "Choose this Plan"}
                      </Button>
                    </Popconfirm>
                  </div>
                }
                hoverable
              >
                <p style={{textAlign: 'center'}}>Access to all features with a fixed monthly price,</p>
              </Card>
            </Col>
          <Col span={18}>
            <h2>Subscriptions</h2>
            <Table dataSource={dataSubscriptions?.subscriptions.map(sub => {
              // Set the name of each subscription plan
              let finded = sub.items.find((item: any) => item.stripe_price == plans[0]['price_id'])
              let s = {...sub}
              if(finded){
                s.name = finded.name
              }
              return s
            } )} columns={columns} expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }} />
          </Col>
          </Row>
        </div>
      </div>
    );
  }

};

export default SimpleBilling;
