import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { storeResourceRecordMutation } from "../mutations/createResourceRecord";
import { ResourceRecordQuery } from "../queries/resourceRecord";
import { updateResourceRecordMutation } from "../mutations/updateResourceRecord";
import { ResourceRecord, ResourceRecordVariables } from "../queries/__generated__/ResourceRecord";
import { StoreResourceRecord, StoreResourceRecordVariables } from "../mutations/__generated__/StoreResourceRecord";
import { UpdateResourceRecord, UpdateResourceRecordVariables } from "../mutations/__generated__/UpdateResourceRecord";
import { ResourceRecordsQuery } from "../queries/resourceRecords";

import { createResourceDataTrashMutation } from "../mutations/createResourceDataTrash";
import { CreateResourceDataTrash, CreateResourceDataTrashVariables } from "../mutations/__generated__/CreateResourceDataTrash";


type State = {
  data: any | null;
  error: Error | null;
  loading: boolean;
};

const initialState: State = {
  data: null,
  error: null,
  loading: false,
};

export const fetchResourceRecord = createAsyncThunk(
  "resourceLink/fetch",
  async (arg: { recordId: string; orgId: string, resourceTypeId: string }, { getState }) => {
    const res = await apolloClient
      .query<ResourceRecord, ResourceRecordVariables>({
        query: ResourceRecordQuery,
        variables: {
          resourceTypeId: arg.resourceTypeId,
          orgId: arg.orgId,
          recordId: arg.recordId
        },
    });
    return res.data.resourceTypeRecords?.data[0] ?? null;
  }
)

export const createResourceRecord = createAsyncThunk(
  "resourceRecord/create",
  async (arg: { resourceTypeId: string; drilledRecordId: string | undefined, resTypeResourceId: string | undefined, orgId: string, data: any, user: any }) => {
    await apolloClient
      .mutate<StoreResourceRecord, StoreResourceRecordVariables>({
        mutation: storeResourceRecordMutation,
        variables: {
          drilledRecordId: arg.drilledRecordId,
          resTypeResourceId: arg.resTypeResourceId,
          resourceTypeId: arg.resourceTypeId,
          orgId: arg.orgId,
          data: arg.data,
          user: arg.user,
        },
      });
  }
);

export const updateResourceRecord = createAsyncThunk(
  "resourceRecord/update",
  async (arg: { resourceTypeId: string; orgId: string, recordId: string, data: any, user: any }) => {
    await apolloClient
      .mutate<UpdateResourceRecord, UpdateResourceRecordVariables>({
        mutation: updateResourceRecordMutation,
        variables: {
          resourceTypeId: arg.resourceTypeId,
          orgId: arg.orgId,
          recordId: arg.recordId,
          data: arg.data,
          user: arg.user,
        },
      });
  }
);

export const createResourceDataTrash = createAsyncThunk(
  "resourceRecord/delete",
  async (arg: { resourceTypeId: string; orgId: string, recordIds: string[] }) => {
    await apolloClient
      .mutate<CreateResourceDataTrash, CreateResourceDataTrashVariables>({
        mutation: createResourceDataTrashMutation,
        variables: {
          resourceTypeId: arg.resourceTypeId,
          orgId: arg.orgId,
          recordIds: arg.recordIds, 
        },
      });
  }
);

const resourceRecordSlice = createSlice({
  name: "resourceRecord",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch
    [fetchResourceRecord.pending.type]: (state, _) => {
      state.loading = true;
      state.data = null;
    },
    [fetchResourceRecord.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceRecord.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
    
    // create
    [createResourceRecord.pending.type]: (state, _) => {
      state.loading = true;
    },
    [createResourceRecord.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [createResourceRecord.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // update
    [updateResourceRecord.pending.type]: (state, _) => {
      state.loading = true;
    },
    [updateResourceRecord.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [updateResourceRecord.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // delete
    [createResourceDataTrash.pending.type]: (state, _) => {
      state.loading = true;
    },
    [createResourceDataTrash.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [createResourceDataTrash.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer } = resourceRecordSlice;

export const resourceRecordReducer = reducer;
