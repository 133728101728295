import { gql } from "@apollo/client";

export const RegisterOnInvitationMutation = gql`
mutation RegisterOnInvitation($invitationToken: String!, $firstName: String, $lastName: String, $password: String!){
  registerOnInvitation(input: {
  invitation_token: $invitationToken
  first_name: $firstName
  last_name: $lastName
  password: $password
  password_confirmation: $password
}) {
  tokens {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        lang
        email
        first_name
        last_name
        username
        created_at
        updated_at
        activeOrganization {
          id
          org_name
          pivot{
            admin
          }
        }
      }   
  }
  status
}
}`;