import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { ResourceTypeAttributes, ResourceTypeAttributesVariables, ResourceTypeAttributes_resourceTypeAttributesAll } from "../queries/__generated__/ResourceTypeAttributes";
import { ResourceTypeAttributesQuery } from "../queries/resourceTypeAttributes";

type State = {
  loading: boolean;
  data: ResourceTypeAttributes_resourceTypeAttributesAll[] | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchResourceTypeAttributes = createAsyncThunk("resourceTypeAttributes/fetch", async (arg: {orgId: string}) => {
  const res = await apolloClient.query<ResourceTypeAttributes, ResourceTypeAttributesVariables>({ 
    query: ResourceTypeAttributesQuery, 
    variables: {
      orgId: arg.orgId
    } 
  });
  return res.data.resourceTypeAttributesAll
  }
);

const resourceTypeAttributesSlice = createSlice({
  name: "resourceTypeAttributes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResourceTypeAttributes.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchResourceTypeAttributes.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceTypeAttributes.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = resourceTypeAttributesSlice;

export const resourceTypeAttributesReducer = reducer;
