import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { InternalNamePath } from 'antd/lib/form/interface';
import { executeCustomActionMutation } from '../mutations/executeCustomAction';
import { ExecuteCustomAction, ExecuteCustomActionVariables } from '../mutations/__generated__/ExecuteCustomAction';
import { fetchApplicationDetails_application_resourceTypes_attributes } from '../queries/__generated__/fetchApplicationDetails';
import { attributeToForm } from './helpers/resourceTypeToFormItems';
import { ICustomActionRecordData } from './resourceTable';

interface Props{
  customActionRecordData: ICustomActionRecordData | undefined;
  customActionForm: fetchApplicationDetails_application_resourceTypes_attributes [];
  modalIsShow: boolean;
  handleCloseModal: () => void;
  setCustomActionResponse: React.Dispatch<React.SetStateAction<string>>;
  setCustomActionResponseModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomActionFormModal: React.FC<Props> = (props) => {
  const [form] = useForm();
  const [ExecuteCustomAction, {loading}] = useMutation<
  ExecuteCustomAction,
  ExecuteCustomActionVariables
  >(executeCustomActionMutation);

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const reset = () => {
    form.resetFields();
  };

  const handleOk = () => {
    form
    .validateFields()
    .then(async (data) => {
      if(props.customActionRecordData){
        try {
          const input: ExecuteCustomActionVariables = {
            customActionId: props.customActionRecordData?.customActionId,
            orgId: props.customActionRecordData?.orgId,
            resTypeId: props.customActionRecordData?.resTypeId,
            recordId: props.customActionRecordData?.recordId,
            params: JSON.stringify(data)
          };
          let res = await ExecuteCustomAction({ variables: input });
          props.setCustomActionResponse(res.data?.executeCustomAction)
          props.setCustomActionResponseModalIsShow(true)
          // document.location.reload();
        } catch (error) {
          message.error("Failed to execute custom action");
          console.error(error);
        }
        reset()
        props.handleCloseModal()
      }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {};

  return (
      <Modal 
        title="Custom Action Form"
        onOk={form.submit}
        okText='Execute'
        confirmLoading={loading}
        onCancel={() => {
          reset()
          props.handleCloseModal()
        }}
        visible={props.modalIsShow}
      >
        <Form
          {...formLayout}
          form={form}
          onFinish={() => handleOk()}
          onFinishFailed={({ errorFields }) => {
              onValidationError(errorFields.map((ef) => ef.name))
            }
          }
        >
          {props.customActionForm && props.customActionForm.map(attribute => attributeToForm(attribute, form, 'create'))}
        </Form>
      </Modal>
  );
};

export default CustomActionFormModal;