import { Badge, Card, Spin } from 'antd';
import { ReactNode, useState } from 'react';
import UxLineResourceTypeFiltersTable from '../components/generic/UxLineResourceTypeFiltersTable'
import { ILineResourceType } from '../components/generic/UxLineResourceTypesTable';
import { useAppSelector } from '../hooks';

const TableFiltersEdit: React.FC = (props) => {
  const [currentResourceType, setCurrentResourceType] = useState<ILineResourceType>()
  useAppSelector((state) => {
    const resType = state.resourceType.data;
    if(resType && !currentResourceType){
      setCurrentResourceType({...resType, attributes: []})
    }
  });
  const {data: app, loading: loadingApp} = useAppSelector(state => state.application)

  if(app?.organization?.id && currentResourceType){
    return (
      <Card
      title={<div>
        <span>Edit table filters </span><Badge
        count={currentResourceType.res_type_name}
        style={{ backgroundColor: '#52c41a' }}
      />
      </div>}
    >
        <UxLineResourceTypeFiltersTable
          currentResourceType={currentResourceType}
          orgId={app!.organization.id}
        />
      </Card>
    )
  }else{
    return <Spin size="large" />
  }
}

export default TableFiltersEdit
