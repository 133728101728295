import React, { ReactNode, useState } from "react";
import { Form, Table, Button, Spin, Input, Space, message, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';
import { ILineResourceType } from "./UxLineResourceTypesTable";
import { ResourceTypeStatisticsByResTypeQuery } from "../../queries/resourceTypeStatisticsByResType";
import { ResourceTypeStatisticsByResType, ResourceTypeStatisticsByResTypeVariables, ResourceTypeStatisticsByResType_resourceTypeStatisticsByResType as IResourceTypeStatisticsByResTypeDetails  } from "../../queries/__generated__/ResourceTypeStatisticsByResType";
import { useMutation, useQuery } from "@apollo/client";
import UxResTypeStatisticsForm from "./UxResTypeStatisticsForm";
import { DeleteResourceTypeStatistics, DeleteResourceTypeStatisticsVariables } from "../../mutations/__generated__/DeleteResourceTypeStatistics";
import { deleteResourceTypeStatisticsMutation } from "../../mutations/deleteResourceTypeStatistics";


type INewLineResourceTypeStatistics = ILineResourceTypeStatistics | INewResourceTypeStatistics;

export interface INewResourceTypeStatistics {
  res_type_statistics_name: string;
}

export interface ILineResourceTypeStatistics {
  id: string | null;
  res_type_id: string | null;
  res_type_statistics_name: string;
  res_type_statistics_description: string | null;
  res_type_attribute_type_id: string;
  grp_function_id: string | null;
  grp_function?: {
    grp_function_name: string
  }
  attribute?: {
    specification: {
      res_type_attribute_name: string
    }
  }
}

interface ILineResourceTypeStatisticsInternal {
  thisIsThatExtraRow: boolean;
}

export interface ILineFormValues extends Partial<ILineResourceTypeStatistics> {
  res_type_statistics_description: string | null;
}

interface Props {
  currentResourceType: ILineResourceType | undefined;
  orgId: string;
}

interface IRTStatisticsFormState {
  type: "create" | "edit"
  statistics: ILineResourceTypeStatistics
  isModalVisible: boolean
  index: number | null
}

const initialStatistics = {
  id: null,
  res_type_id: null,
  res_type_statistics_name: '',
  res_type_statistics_description: null,
  res_type_attribute_type_id: '',
  grp_function_id: ''
}

const isNormalRow = (row: ILineResourceTypeStatistics | ILineResourceTypeStatisticsInternal): row is ILineResourceTypeStatistics =>
  !("thisIsThatExtraRow" in row);

const UxLineResourceTypeStatisticsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypeStatisticssTable');
  const [form] = useForm();
  const [newStatistics, setNewStatistics] = useState('');
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
    ResourceTypeStatisticsByResType,
    ResourceTypeStatisticsByResTypeVariables
  >(ResourceTypeStatisticsByResTypeQuery, {
    variables: {
      resTypeId: props.currentResourceType?.id!
    },
    skip: props.currentResourceType?.id == null
  });

  const [DeleteResourceTypeStatistics, {loading: loadingDeleting}] = useMutation<DeleteResourceTypeStatistics, DeleteResourceTypeStatisticsVariables>(deleteResourceTypeStatisticsMutation)
  const [rTStatisticsFormState, setRTStatisticsFormState] = useState<IRTStatisticsFormState>({type: "create", statistics: initialStatistics, isModalVisible: false, index: null});

  const handleCancel = () => {
    setRTStatisticsFormState({...rTStatisticsFormState, isModalVisible: false});
  };

  const showStatisticsModal = (type: "create" | "edit", statistics: ILineResourceTypeStatistics, index: number | null) => {
    setRTStatisticsFormState({type, statistics, isModalVisible: true, index})
  }

  const onLineResourceTypeStatisticsAdd = (item: INewLineResourceTypeStatistics) => {
    const newItem: ILineResourceTypeStatistics = {
            id: null,
            res_type_id: props.currentResourceType?.id!,
            res_type_statistics_name: item.res_type_statistics_name,
            res_type_statistics_description: null,
            res_type_attribute_type_id: '',
            grp_function_id: '',
          };

    showStatisticsModal("create", newItem, null);
  };

  const onItemDelete = (index: number, item: ILineResourceTypeStatistics) => {
    setToRemoveIndex(index)
    const input = {statisticsId: item.id!};
    try{
      let res = DeleteResourceTypeStatistics({variables: input, refetchQueries: [{ query: ResourceTypeStatisticsByResTypeQuery,
        variables: {
          resTypeId: props.currentResourceType?.id
        } }]});
      res.then(res => {
        message.success(res.data?.deleteResourceTypeStatistics)
      }).catch(raison => {
        message.error("Failed to delete a statistics")
        console.error(raison)
      })
    }catch(error){
      message.error("Failed to delete a statistics")
      console.error(error)
    }
    setToRemoveIndex(index)
  };

  const columns: ColumnsType<ILineResourceTypeStatistics | ILineResourceTypeStatisticsInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Statistic Name"),
      key: "res_type_statistics_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_statistics_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <Space direction="horizontal" size="small">
                <Input
                  placeholder={t("Enter statistic name...")}
                  onChange={e => setNewStatistics(e.target.value)}
                />
                <Button
                  type="primary"
                  loading={loading}
                  disabled={newStatistics === ''}
                  onClick={() => onLineResourceTypeStatisticsAdd({ res_type_statistics_name: newStatistics })}
                >
                  Add new
                </Button>
              </Space>
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Attribute"),
      key: "res_type_attribute_type_id",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.attribute?.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Agregation"),
      key: "grp_function_id",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.grp_function?.grp_function_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{marginRight: '10px'}}
                loading={toRemoveIndex === index ? loadingDeleting : false}
              />
            </Popconfirm>
            <div style={{position: 'relative'}}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => showStatisticsModal("edit", row, index)}
              />
            </div>
          </div>
        ),
    },
  ];

  const lineResourceTypeStatisticssWithExtra: (ILineResourceTypeStatistics | ILineResourceTypeStatisticsInternal)[] = data?.resourceTypeStatisticsByResType ? [
    ...data.resourceTypeStatisticsByResType,
    { thisIsThatExtraRow: true },
  ]: [];

  return (
    <>
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypeStatisticssWithExtra}
        pagination={false}
        loading={loading}
        size="small"
      />
    </Form>
    <UxResTypeStatisticsForm
      visible={rTStatisticsFormState.isModalVisible}
      mode={rTStatisticsFormState.type}
      onClose={() => handleCancel()}
      initialStatistics={rTStatisticsFormState.statistics}
      resTypeId={props.currentResourceType?.id ?? ""}
      orgId={props.orgId}
    />
    </>
  );
};

export default UxLineResourceTypeStatisticsTable;
