import React, { useState } from "react";
import { IChart } from "./ChartStats";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  ResourceTypeChartDataRecords,
  ResourceTypeChartDataRecordsVariables,
} from "../queries/__generated__/ResourceTypeChartDataRecords";
import { ResourceTypeChartDataRecordsQuery } from "../queries/resourceTypeChartDataRecords";
import { isNil } from "lodash";
import ChartLine from "./ChartLine";
import ChartColumn from "./ChartColumn";
import ChartBar from "./ChartBar";
import ChartPie from "./ChartPie";
import ChartArea from "./ChartArea";
import "./Chart.css"

interface Props {
  chartDetail: IChart;
  orgId: string | undefined;
  resTypeId: string;
  filterId: string | undefined;
  selectedChartId: string;
}

const Chart: React.FC<Props> = (props) => {
  const client = useApolloClient();
  const [dataChartDataRecords, setDataChartDataRecords] = useState([{data: []}, {data: []}])
  // const {
  //   data: dataChartDataRecords,
  //   loading: loadingChart,
  //   error,
  // } = useQuery<
  //   ResourceTypeChartDataRecords,
  //   ResourceTypeChartDataRecordsVariables
  // >(ResourceTypeChartDataRecordsQuery, {
  //   variables: {
  //     orgId: props.orgId!,
  //     resTypeId: props.resTypeId,
  //     selectedChartId: props.selectedChartId,
  //     selectedFilterId: props.filterId
  //   },
  //   skip: isNil(props.orgId),
  // });

  client.query<
    ResourceTypeChartDataRecords,
    ResourceTypeChartDataRecordsVariables
  >({
    query: ResourceTypeChartDataRecordsQuery,
    variables: {
      orgId: props.orgId!,
      resTypeId: props.resTypeId,
      selectedChartId: props.selectedChartId,
      selectedFilterId: props.filterId
    }
  }).then(res => {
    setDataChartDataRecords(res.data.resourceTypeChartDataRecords)
  })

  var dataChart = []
  var Xdata = !isNil(dataChartDataRecords[0]["data"]) ? dataChartDataRecords[0]["data"] : []
  var Ydata = !isNil(dataChartDataRecords[1]["data"]) ? dataChartDataRecords[1]["data"] : []

  for(var i = 0; i < Xdata.length; i++){
      dataChart.push(
        { 
          [props.chartDetail.attribute_x.specification.res_type_attribute_code] : Xdata[i], 
          [props.chartDetail.attribute_y.specification.res_type_attribute_code] : Ydata[i]
        }
      )
  }

  var titleStyle: React.CSSProperties = {
    color: "dimgray",
    textAlign: "center"
  }

  if(props.chartDetail.chart_type === "column"){
    return (
      <>
        <ChartColumn
          attribute_x={
            props.chartDetail.attribute_x.specification
          }
          attribute_y={
            props.chartDetail.attribute_y.specification
          }
          dataChart={dataChart}
        />
        <h3 style={titleStyle}>{props.chartDetail.res_type_chart_name}</h3>
      </>
    );
  }else if(props.chartDetail.chart_type === "bar"){
    return (
      <>
        <ChartBar
          attribute_x={
            props.chartDetail.attribute_x.specification
          }
          attribute_y={
            props.chartDetail.attribute_y.specification
          }
          dataChart={dataChart}
        />
        <h3 style={titleStyle}>{props.chartDetail.res_type_chart_name}</h3>
      </>
    );
  }else if(props.chartDetail.chart_type === "pie"){
    return (
      <>
        <ChartPie
          attribute_x={
            props.chartDetail.attribute_x.specification
          }
          attribute_y={
            props.chartDetail.attribute_y.specification
          }
          dataChart={dataChart}
        />
        <h3 style={titleStyle}>{props.chartDetail.res_type_chart_name}</h3>
      </>
    );
  }else if(props.chartDetail.chart_type === "area"){
    return (
      <>
        <ChartArea
          attribute_x={
            props.chartDetail.attribute_x.specification
          }
          attribute_y={
            props.chartDetail.attribute_y.specification
          }
          dataChart={dataChart}
        />
        <h3 style={titleStyle}>{props.chartDetail.res_type_chart_name}</h3>
      </>
    );
  }else{
    return (
      <>
        <ChartLine
          attribute_x={
            props.chartDetail.attribute_x.specification
          }
          attribute_y={
            props.chartDetail.attribute_y.specification
          }
          chartDataRecords={dataChartDataRecords}
        />
        <h3 style={titleStyle}>{props.chartDetail.res_type_chart_name}</h3>
      </>
    );
  }

};

export default Chart;
