import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { IconPicker } from "react-fa-icon-picker";
import { updateApplicationMutation } from "../mutations/updateApplication";
import { UpdateApplication, UpdateApplicationVariables } from "../mutations/__generated__/UpdateApplication";
import { fontAwesomeList } from "../components/helpers/iconList";
import { fetchApplication } from "../slices/applicationSlice";

export interface IApplication {
  app_id: string;
  app_name?: string | null;
  app_description?: string | null;
  app_client_name?: string | null;
  app_client_logo?: string | null;
}

export var iconPickerBtnStyle = { minHeight: '30px', width: '45px', height: '32px', borderColor: 'lightgray' }
export var iconPickerContainerStyles = { borderColor: 'white', boxShadow: "0px 0px 7px rgb(0 0 0 / 35%)" }

export const ApplicationDetailsEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { Option } = Select;
  const { appId } = useParams<{ appId: string }>();
  const [UpdateResourceType, { error, loading }] = useMutation<
    UpdateApplication,
    UpdateApplicationVariables
  >(updateApplicationMutation);

  const app = useAppSelector((state) => {
    const application = state.application.data;
    if (application) {
      form.setFieldsValue({
        app_name: application.app_name,
        app_description: application.app_description,
        app_client_name: application.app_client_name,
        app_client_logo: fontAwesomeList.includes(application.app_client_logo) ? application.app_client_logo : "FaThLarge"
      });
    }
    return application;
  });

  const onSave = () => {
    try {
      form
        .validateFields()
        .then(async (data: IApplication) => {
          let res = await UpdateResourceType({
            variables: {
              appId: appId,
              appName: data.app_name,
              appDescription: data?.app_description,
              appClientName: data?.app_client_name,
              appClientLogo: data?.app_client_logo,
            },
          });
          return res
        }).then((res) => {
          message.success(res.data?.updateApplication);
          dispatch(fetchApplication({appId}))
        });
    } catch (error) {
      message.error("Failed to edit application details");
      console.error(error);
    }
  };

  return (
    <Card
      title={"Edit app details"}
      extra={
        <Button
          style={{ marginTop: "1em" }}
          type="primary"
          icon={<SaveOutlined />}
          onClick={form.submit}
          loading={loading}
        >
          {"Save details"}
        </Button>
      }
    >
      <Form form={form} onFinish={() => onSave()}>
        <Row justify="space-around">
          <Col span={10}>
            <Form.Item label={"Application name"} required name="app_name">
              <Input />
            </Form.Item>
            <Form.Item label={"Application client name"} name="app_client_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={"Description"} name="app_description">
              <TextArea />
            </Form.Item>
            <Form.Item label={"Application icon"} name="app_client_logo" required>
              <IconPicker
                value={""}
                containerStyles={iconPickerContainerStyles}
                buttonStyles={iconPickerBtnStyle}
                buttonIconStyles={{ display: "contents" }}
                onChange={(val) => form.setFieldsValue({ app_client_logo: val })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
