import React from "react";
import { Row, Col, Card, Form, Input, Button, Alert, Space, Tooltip } from "antd";
import { UserOutlined, HomeOutlined, InfoCircleOutlined, MessageOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { RegisterInput } from "../../__generated__/globalTypes";

import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { IUserJoinOrg } from "../../pages/InvitationVerifyEmail";

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

interface Props {
  joinOrg?: IUserJoinOrg;
  verifyEmail: boolean;
  loading: boolean;
  onFinish: (input: RegisterInput) => void;
  errors: string[] | null;
  initialValues: Partial<RegisterInput>;
}

const RegistrationForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('Registration');
  const [form] = Form.useForm();
  const history = useHistory();

  const errorList = (props.errors || []).map((error) => (
    <Alert
      key={error}
      message={error}
      type="error"
      showIcon
      style={{ marginBottom: ".5em" }}
    />
  ));

  return (
    <Card title={t("Register to Digintu.code")}>
      {errorList}
      {props.verifyEmail ? <Alert message="Please confirm your account by following the send link in your mailbox" type="info" showIcon style={{ marginBottom: ".5em" }} /> : '' }
      <p>{t("Enter required details to register new user.")}</p>

      <Form
        {...layout}
        form={form}
        onFinish={(values) => props.onFinish({...values, lang: i18n.language} as RegisterInput)}
        initialValues={props.initialValues}
      >

        <Row>
          <Col sm={24} xl={12}>
            <Form.Item
              label={t("First name")}
              name="first_name"
              rules={[
                { required: true, message: t("Please input your first name") },
              ]}
            >
              <Input 
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>

            <Form.Item
              label={t("Last name")}
              name="last_name"
              rules={[
                { required: true, message: t("Please input your last name") },
              ]}
            >
              <Input 
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>

            <Form.Item
              initialValue={props.joinOrg?.email}
              label={t("E-mail")}
              name="email"
              rules={[{ required: true, message: t("Please input your email") }]}
            >
              <Input 
                disabled={props.joinOrg ? true : false}
                prefix={<MessageOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>

          </Col>

          <Col sm={24} xl={12}>

          <Form.Item
              initialValue={props.joinOrg?.org_name}
              label={t("Organization")}
              name="org_name"
              rules={[
                { required: true, message: t("Please input your organization") }
              ]}
            >
              <Input 
                disabled={props.joinOrg ? true : false}
                prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                suffix={
                  <Tooltip title="The name of your company, project or practice">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>

            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                { required: true, message: t("Please input your password") },
              ]}
            >
              <Input.Password 
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>

            <Form.Item
              label={t("Confirm password ")}
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: t("Please input password confirmation"),
                },
                {
                  validator(_, value) {
                    if (!value || form.getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("Password and confirmation you entered must match")
                    );
                  },
                },
              ]}
            >
              <Input.Password 
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>

          </Col>
        </Row>

        <Form.Item
          style={{ marginBottom: 0, textAlign: "right" }}
          wrapperCol={{ span: 24 }}
        >
          <Space>
            <Button size="large" type="ghost" onClick={() => history.goBack()}>
              {t("Cancel")}
            </Button>
            <Button
              size="large"
              loading={props.loading}
              type="primary"
              htmlType="submit"
            >
              {t("Register")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default RegistrationForm;
