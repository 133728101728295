import { gql } from "@apollo/client";

export const UntrashResourceDataTrashMutation = gql`
  mutation UntrashResourceDataTrash($orgId: String!, $resTypeId: String!, $recordId: String!){
    untrashResourceDataTrash(
      input: {
        org_id: $orgId
        res_type_id: $resTypeId
        record_id: $recordId
      }
    )
  }
`;