import React, { useState } from "react";
import { BinaryDataAttr } from "./resourceTable";
import {
  fetchFileBase64,
  fetchFileBase64Variables,
} from "../queries/__generated__/fetchFileBase64";
import { fetchFileBase64Query } from "../queries/fetchFileBase64";
import { useQuery } from "@apollo/client";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Spin, Image } from "antd";

interface Props {
  data: BinaryDataAttr;
}

const DisplayBinaryData: React.FC<Props> = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const { data, loading, error } = useQuery<
    fetchFileBase64,
    fetchFileBase64Variables
  >(fetchFileBase64Query, {
    variables: {
      id: props.data.id,
    },
  });

  return (
    <div>
      {loading ? (
        <Spin />
      ) : props.data.type === "image" ? (
        <Image
          width={35}
          src={`data:${props.data.mimetype};base64,${data?.fetchFileBase64?.code}`}
        />
      ) : (
        <Dropdown
          onVisibleChange={(visible) => {
            setIsPlaying(visible)
          }}
          destroyPopupOnHide={true}
          overlay={
            <Menu>
              <Menu.Item key="3">
                <audio
                  controls
                  loop
                  autoPlay
                  src={`data:${props.data.mimetype};base64,${data?.fetchFileBase64?.code}`}
                />
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          {isPlaying ? (
            <PauseCircleOutlined
              style={{ fontSize: "25px" }}
            />
          ) : (
            <PlayCircleOutlined
              style={{ fontSize: "25px" }}
            />
          )}
        </Dropdown>
      )}
    </div>
  );
};

export default DisplayBinaryData;
