import React from 'react'
import { useAppSelector } from '../hooks'

const Profile: React.FC = () => {
  const user = useAppSelector(state => state.me.data)
  return (
    <div>
      <ul>
        <li>Name : {user?.first_name+' '+user?.last_name}</li>
        <li>Email : {user?.email}</li>
        <li>Organizations : 
          <ul>
            {user?.organizations.map(org => <li>{org.org_name}</li>)}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Profile