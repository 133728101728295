import { gql } from "@apollo/client";

export const VersionControlQuery = gql`
  query VersionControl(
    $orgId: String!,
    $resourceTypeId: String!,
    $recordId: String!,
  ) {
    versionControl(
      input: { org_id: $orgId, res_type_id: $resourceTypeId, record_id: $recordId }
    )
  }
`;
