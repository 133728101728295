import { Button, Modal } from 'antd';
import React, { useState } from 'react';

interface Props{
  content: string;
  modalIsShow: boolean;
  handleCloseModal: () => void;
}

const CustomActionModal: React.FC<Props> = (props) => {

  return (
      <Modal title="Custom Action" visible={props.modalIsShow} onOk={props.handleCloseModal} onCancel={props.handleCloseModal}>
        {
          props.content.substring(0, 4) == '<html>' ? <div dangerouslySetInnerHTML={{__html: props.content}} /> : <pre>{props.content}</pre>
        }
      </Modal>
  );
};

export default CustomActionModal;