import {gql} from "@apollo/client"

export const fetchResourceTypeTemplatesQuery = gql`
  query fetchResourceTypeTemplates {
    resourceTypeTemplates(first: 10) {
      data {
        id
        template_name
        template_description
        template_logo
      }
    }
  }
`;