import React from "react";
import { Form, Select } from "antd";

import { useQuery } from "@apollo/client";
import {
  ResourceTypeAttributeTypeByResType,
  ResourceTypeAttributeTypeByResTypeVariables,
} from "../../queries/__generated__/ResourceTypeAttributeTypeByResType";
import { ResourceTypeAttributeTypeByResTypeQuery } from "../../queries/resourceTypeAttributeTypeByResType";

interface Props {
  onChange: (val: string) => void;
  resTypeId: string | null;
  inputStyle?: React.CSSProperties;
  label: string;
  name: string | string[];
  valType?: string;
}

const ResourceTypeAttributeTypeSelect: React.FC<Props> = (props) => {
  const { Option } = Select;

  const { data, loading, error } = useQuery<
    ResourceTypeAttributeTypeByResType,
    ResourceTypeAttributeTypeByResTypeVariables
  >(ResourceTypeAttributeTypeByResTypeQuery, {
    variables: {
      resTypeId: props.resTypeId!,
    },
    skip: props.resTypeId == null,
  });

  const attributes = (data?.resourceTypeAttributeTypeByResType || []);

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      required
    >
      <Select onChange={val => props.onChange(val)} style={props.inputStyle} placeholder={'Table field'}>
        {attributes.map((attr) => (
          <Option value={(props.valType && props.valType=="name") ? attr.specification.res_type_attribute_code : attr.id}>
            {attr.specification.res_type_attribute_name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ResourceTypeAttributeTypeSelect;
