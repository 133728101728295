import { gql } from "@apollo/client"

export const ResourceTypesByOrgQuery = gql`
  query ResourceTypesByOrg($orgId: String!){
    resourceTypesByOrg(org_id: $orgId) {
      id
      res_type_name
    }
  }
`;
