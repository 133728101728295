import { gql } from "@apollo/client";

export const ResourceTypesQuery = gql`
  query ResourceTypes($orgId: String!) {
    resourceTypesAll(org_id: $orgId) {
      id
      org_id
      res_type_name
      res_type_table_name
      res_type_description
      res_type_icon
      schema
      attributes {
        id
        linked_id
        res_type_id
        res_type_attribute_type_group
        res_type_attribute_type_detail
        res_type_attribute_type_default_value
        res_type_attribute_type_order
        res_type_attribute_type_mandatory
        specification {
          id
          res_type_attribute_name
          res_type_attribute_description
          res_type_attribute_code
          res_type_attribute_constraints
          res_type_attribute_type
        }
      }
    }
  }
`;
