import { gql } from "@apollo/client";

export const fetchFileBase64Query = gql`
  query fetchFileBase64($id: String!) {
    fetchFileBase64(id: $id){
      filename
      mimetype
      code
      id
    }
  }
`;
