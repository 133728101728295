import { gql } from "@apollo/client"

export const ResourceTypeChartByResTypeQuery = gql`
  query ResourceTypeChartByResType($resTypeId: String!){
    resourceTypeChartByResType(res_type_id: $resTypeId) {
      id
      res_type_id
      res_type_chart_name
      res_type_chart_description
      res_type_attribute_type_id_X
      res_type_attribute_type_id_Y
      grp_function_id
      grp_function{
        grp_function_name
      }
      chart_type
      attribute_x{
        specification {
          res_type_attribute_name
        }
      }
      attribute_y{
        specification {
          res_type_attribute_name
        }
      }
    }
  }
`;
