/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RegisterStatuses {
  MUST_VERIFY_EMAIL = "MUST_VERIFY_EMAIL",
  SUCCESS = "SUCCESS",
  USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
}

export interface CustomResourceTypeFilterAttributeCreateInput {
  res_type_filter_id?: string | null;
  res_type_attribute_type_id?: string | null;
  res_type_filter_attribute_condition: string;
}

export interface CustomResourceTypeFilterAttributeCreateOrUpdateInput {
  id?: string | null;
  res_type_filter_id?: string | null;
  res_type_attribute_type_id?: string | null;
  res_type_filter_attribute_condition: string;
}

export interface LoginInput {
  method: string;
  username: string;
  password?: string | null;
  qr_code?: string | null;
  pin?: string | null;
}

export interface RegisterInput {
  first_name: string;
  last_name: string;
  org_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface ResetPasswordInput {
  token?: string | null;
  email?: string | null;
  password?: string | null;
}

export interface ResourceTypeAppCreateInput {
  id?: string | null;
  res_type_name: string;
  res_type_description?: string | null;
  res_type_icon?: string | null;
  attributes?: (ResourceTypeAttributeTypeAppCreateInput | null)[] | null;
}

export interface ResourceTypeAttributeAppCreateInput {
  id?: string | null;
  res_meta_type_id?: string | null;
  res_type_attribute_code?: string | null;
  res_type_attribute_name: string;
  res_type_attribute_description?: string | null;
  res_type_attribute_type: string;
  res_type_attribute_constraints?: string | null;
}

export interface ResourceTypeAttributeTypeAppCreateInput {
  id?: string | null;
  res_type_id?: string | null;
  res_type_attribute_type_mandatory?: boolean | null;
  res_type_attribute_type_printable?: boolean | null;
  res_type_attribute_type_editable?: boolean | null;
  res_type_attribute_type_hidden?: boolean | null;
  res_type_attribute_type_speech_input?: string | null;
  res_type_attribute_type_default_value?: string | null;
  res_type_attribute_type_formula?: string | null;
  res_type_attribute_type_group?: string | null;
  res_type_attribute_type_detail?: boolean | null;
  linked_id?: string | null;
  specification?: ResourceTypeAttributeAppCreateInput | null;
}

export interface ResourceTypeFilterInput {
  id?: string | null;
  res_type_filter_name?: string | null;
  res_type_filter_description?: string | null;
  res_type_filter_logicaloperator?: string | null;
}

export interface VerifyEmailInput {
  token: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
