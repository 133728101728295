import {
  Button,
  Card,
  Checkbox,
  Form,
  Row,
  Col,
  Input,
  message,
  Divider,
  Alert,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useParams } from "react-router-dom";
import { fetchResourceType_resourceType } from "../queries/__generated__/fetchResourceType";
import { useMutation, useQuery } from "@apollo/client";
import { updateResourceTypeMutation } from "../mutations/updateResourceType";
import {
  UpdateResourceType,
  UpdateResourceTypeVariables,
} from "../mutations/__generated__/UpdateResourceType";
import { IconPicker } from "react-fa-icon-picker";
import { iconPickerBtnStyle, iconPickerContainerStyles } from "./ApplicationDetailsEdit";
import { fontAwesomeList } from "../components/helpers/iconList";
import { fetchResourceType } from "../slices/resourceTypeSlice";
import { useState } from "react";
import { ResourceTypesByOrg, ResourceTypesByOrgVariables } from "../queries/__generated__/ResourceTypesByOrg";
import { ResourceTypesByOrgQuery } from "../queries/resourceTypesByOrg";

export const TableSettingsEdit: React.FC = () => {
  const [form] = useForm();
  const { tableId } = useParams<{ tableId: string }>();
  const dispatch = useAppDispatch();
  const [UpdateResourceType, { error, loading }] = useMutation<
    UpdateResourceType,
    UpdateResourceTypeVariables
  >(updateResourceTypeMutation);
  const [newTableName, setNewTableName] = useState<string | null>(useAppSelector((state) => state.resourceType.data)?.res_type_name!)

  const resourceType = useAppSelector((state) => {
    const resType = state.resourceType.data;
    if (resType) {
      form.setFieldsValue({...resType, res_type_name: newTableName, res_type_icon: resType.res_type_icon && fontAwesomeList.includes(resType.res_type_icon) ? resType?.res_type_icon : "FaThLarge"});
    }
    return state.resourceType.data;
  });

  const { data: dataResourceTypes, loading: loadingResourceTypes, error: errorResourceTypes } = useQuery<
    ResourceTypesByOrg,
    ResourceTypesByOrgVariables
  >(ResourceTypesByOrgQuery, {
    variables: {
      orgId: resourceType?.org_id!,
    },
    skip: resourceType?.org_id == null,
  });

  const isIncorrectName = newTableName && newTableName != resourceType?.res_type_name && dataResourceTypes?.resourceTypesByOrg && dataResourceTypes.resourceTypesByOrg?.find(item => item.res_type_name.replace(/[' ']/g, '').toLocaleLowerCase() == newTableName.trim().replace(/[' ']/g, '').toLocaleLowerCase())

  const onSave = () => {
    try {

      if(isIncorrectName){
        message.warning('The table name is already in use. Please use a different one.');
        throw new Error('The table name is already in use. Please use a different one.');
      }

      form
        .validateFields()
        .then(async (data: fetchResourceType_resourceType) => {
          let res = await UpdateResourceType({
            variables: {
              resTypeId: tableId,
              resTypeName: data.res_type_name,
              resTypeDescription: data?.res_type_description,
              resTypeIcon: data?.res_type_icon,
              resTypeGalleryDisabled: data.res_type_gallery_disabled,
              resTypeHistoryDisabled: data.res_type_history_disabled,
              resTypeDetailsDisabled: data.res_type_details_disabled,
              resTypeRowsSelectable: data.res_type_rows_selectable,
              resTypeDisplayRows: data.res_type_display_rows,
              resTypeDatatableWidth: data.res_type_datatable_width,
              resTypeFilterSql: data.res_type_filter_sql,
              resTypeViewDisabled: data.res_type_view_disabled,
              resTypeEditDisabled: data.res_type_edit_disabled,
              resTypeDeleteDisabled: data.res_type_delete_disabled,
              resTypeDisabled: data.res_type_disabled,
              resTypeUserMap: data.res_type_user_map,
              resTypeUserRegistrationEmailTemplate:
                data.res_type_user_registration_email_template?.length > 0
                  ? data.res_type_user_registration_email_template
                  : "default1",
              resTypeUserRegistrationOrgId:
                resourceType?.res_type_user_registration_org_id !== undefined &&
                resourceType?.res_type_user_registration_org_id?.length > 0
                  ? resourceType?.res_type_user_registration_org_id
                  : "default2",
            },
          });
          return res
        }).then((res) => {
          message.success(res.data?.updateResourceType.message);
          if(resourceType?.id){
            dispatch(fetchResourceType({tableId: resourceType?.id}))
          }
        });
    } catch (error) {
      message.error("Failed to edit tables settings");
      console.error(error);
    }
  };

  return (
    <Card
      title={"Edit table settings"}
      extra={
        <Button
          style={{ marginTop: "1em" }}
          type="primary"
          icon={<SaveOutlined />}
          onClick={form.submit}
          loading={loading}
        >
          {"Save Settings"}
        </Button>
      }
    >
      <Form form={form} onFinish={() => onSave()}>
        <Row justify="space-around">
          <Col span={10}>
            {/*
            <Divider orientation="left">
              <h3>Table and Icon</h3>
            </Divider>
            */}
            {isIncorrectName ? <Alert type="warning" message={"The table name is already in use. Please use a different one."} showIcon style={{marginBottom: '10px'}}/> : ''}
            <Form.Item label={"Table name"} required name="res_type_name">
              <Input onChange={e => setNewTableName(e.target.value)} />
            </Form.Item>
            <Form.Item label={"Description"} name="res_type_description">
              <TextArea />
            </Form.Item>
            <Form.Item label={"Table icon"} name="res_type_icon">
              <IconPicker
                value={form.getFieldValue("res_type_icon")}
                containerStyles={iconPickerContainerStyles}
                buttonStyles={iconPickerBtnStyle}
                buttonIconStyles={{ display: "contents" }}
                onChange={(val) => form.setFieldsValue({ res_type_icon: val })}
              />
            </Form.Item>
            {/*
            <Divider orientation="left">
              <h3>Apperance</h3>
            </Divider>
            <Form.Item name="res_type_gallery_disabled" valuePropName="checked">
              <Checkbox>Show documents gallery</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_history_disabled" valuePropName="checked">
              <Checkbox>Show change history</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_details_disabled" valuePropName="checked">
              <Checkbox>Show row details</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_rows_selectable" valuePropName="checked">
              <Checkbox>Make rows selectable</Checkbox>
            </Form.Item>
            <Form.Item
              label={"Number of rows per page"}
              required
              name="res_type_display_rows"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Table width (px)"}
              required
              name="res_type_datatable_width"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Custom data rule (SQL)"}
              required
              name="res_type_filter_sql"
            >
              <TextArea />
            </Form.Item>
            <Form.Item label={"Table alias"}>
              <p>_DBID_LOCATION</p>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Divider orientation="left">
              <h3>Advanced table settings</h3>
            </Divider>
            <Form.Item name="res_type_view_disabled" valuePropName="checked">
              <Checkbox>Disable record view</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_edit_disabled" valuePropName="checked">
              <Checkbox>Disable record edit</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_delete_disabled" valuePropName="checked">
              <Checkbox>Disable record delete</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_disable" valuePropName="checked">
              <Checkbox>Hide the table</Checkbox>
            </Form.Item>
            <Form.Item name="res_type_user_map" valuePropName="checked">
              <Checkbox>Map each record to a account</Checkbox>
            </Form.Item>
            <Form.Item
              label={"Registration Email Template"}
              required
              name="res_type_user_registration_email_template"
            >
              <TextArea />
            </Form.Item>
            <Form.Item label={"Table alias"}>
              <p>_DBID_LOCATION</p>
            </Form.Item>
            */}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
