import { gql } from "@apollo/client";

export const createOrUpdateResTypeFieldMutation = gql`
  mutation CreateOrUpdateResTypeField($resTypeId: String!, $field: ResourceTypeAttributeTypeAppCreateInput!){
    createOrUpdateResTypeField(
      input: {
        res_type_id: $resTypeId
        field: $field
      }
    )
  }
`;