import { useQuery } from '@apollo/client';
import { Badge, Form, Select } from 'antd';
import React from 'react'
import { fetchLinkedFieldsQuery } from '../../queries/fetchLinkedFields';
import { ILineResourceTypeAttribute } from './UxLineResourceTypeAttributesTable';
import {
  fetchLinkedFields,
  fetchLinkedFieldsVariables,
} from "../../queries/__generated__/fetchLinkedFields";

interface Props {
  setField: (field: ILineResourceTypeAttribute) => void
  field: ILineResourceTypeAttribute
  orgId: string | null
  resTypeId: string
}

const LinkedFieldSelect: React.FC<Props> = (props) => {
  const { Option } = Select;
  const {data, loading, error } = useQuery<fetchLinkedFields, fetchLinkedFieldsVariables>(fetchLinkedFieldsQuery, {
    variables: {
      orgId: props.orgId!
    },
    skip: props.orgId === null
  })

  const links = data?.linkedFields?.filter(item => item.res_type_id !== props.resTypeId) ?? []

  return (
    <Form.Item label={'Linked field'} name="linked_id">
      <Select
        onChange={(val: string) => props.setField({...props.field, linked_id: val})}
      >
        {links.map(link => (
          <Option value={link.id}><div style={{display: "flex", justifyContent: "space-between"}}><span>{link.specification.res_type_attribute_name}</span><Badge
          count={link.resourceType ? link.resourceType.res_type_name : 0}
          style={{ backgroundColor: 'pink' }}
        /></div></Option>
          ))}
      </Select>
    </Form.Item>
  )
}

export default LinkedFieldSelect