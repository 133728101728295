import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { VerifyEmail as VerifyEmailResponse, VerifyEmailVariables } from "../mutations/__generated__/VerifyEmail";
import { VerifyEmailMutation } from "../mutations/verifyEmail";

type State = {
  loading: boolean;
  data: VerifyEmailResponse | null;
  error: {message: string, name: string, stack: string} | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const verifyEmail = createAsyncThunk("verify/email", async (arg: { token: string }) => {
      const res = await apolloClient
      .mutate<VerifyEmailResponse, VerifyEmailVariables>({ 
        mutation: VerifyEmailMutation,
        variables: {
          input: {token: arg.token}
        } 
      })
    return res?.data?.verifyEmail ?? null;
  }  
);

const verifyEmailSlice = createSlice({
  name: "verifyEmail",
  initialState,
  reducers: {},
  extraReducers: {
    [verifyEmail.pending.type]: (state) => {
      state.loading = true;
    },
    [verifyEmail.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    },
    [verifyEmail.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
      state.error = action.error
    },
  },
});

const { reducer, actions } = verifyEmailSlice;

export const verifyEmailReducer = reducer;
