import { gql } from "@apollo/client";

export const updateResourceTypeStatisticsMutation = gql`
  mutation UpdateResourceTypeStatistics($config_sub_item_id: String!,
    $res_type_statistics_name: String!,
    $res_type_statistics_description: String,
    $res_type_attribute_type_id: String!,
    $grp_function_id: String,
    ) {
    updateResourceTypeStatistics(input: {
      config_sub_item_id: $config_sub_item_id
      res_type_statistics_name: $res_type_statistics_name
      res_type_statistics_description: $res_type_statistics_description
      res_type_attribute_type_id: $res_type_attribute_type_id,
      grp_function_id: $grp_function_id,
    })
  }
`;
