import {gql} from "@apollo/client";

export const VerifyRegistrationInvitationQuery = gql`
  query VerifyRegistrationInvitation($token: String!){
    verifyRegistrationInvitation(token: $token){
      invitation_token
      org_name
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        lang
        email
        first_name
        last_name
        username
        created_at
        updated_at
        activeOrganization {
          id
          org_name
          pivot{
              admin
          }
        } 
      }
    }
  }
`;