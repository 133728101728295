import { gql } from "@apollo/client";

export const fetchOrganizationsQuery = gql`
    query fetchOrganizations{
    organizations(first: 10) {
      data {
          id
          org_parent_id
          server_id
          org_name
          org_description
          org_address
          org_logo
          org_graph_xml
          org_tree_json
          created_at
          updated_at
      }
      paginatorInfo 
      {
          lastItem
      }
    }
  }
`;