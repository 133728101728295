import { Button, Checkbox, Form, Input, message, Modal, Select, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { ILineResourceTypeCustomAction } from "./UxLineResourceTypeCustomActionsTable";
import { createResourceTypeCustomActionMutation } from "../../mutations/createResourceTypeCustomAction";
import { useMutation } from "@apollo/client";
import {
  CreateResourceTypeCustomAction,
  CreateResourceTypeCustomActionVariables,
} from "../../mutations/__generated__/CreateResourceTypeCustomAction";
import {
  UpdateResourceTypeCustomAction,
  UpdateResourceTypeCustomActionVariables,
} from "../../mutations/__generated__/UpdateResourceTypeCustomAction";
import { updateResourceTypeCustomActionMutation } from "../../mutations/updateResourceTypeCustomAction";
import { ResourceTypeCustomActionByResTypeQuery } from "../../queries/resourceTypeCustomActionByResType";
import UxLineResTypeCustomActionSpecification from "./UxLineResTypeCustomActionSpecification";
import { IconPicker } from "react-fa-icon-picker";
import { iconPickerBtnStyle, iconPickerContainerStyles } from "../../pages/ApplicationDetailsEdit";

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  orgId: string | null;
  mode: "create" | "edit";
  initialCustomAction: ILineResourceTypeCustomAction;
  resTypeId: string;
  onClose: () => void;
}

const UxResTypeCustomActionForm: React.FC<Props> = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [step, setStep] = useState("properties");
  const [CreateResourceTypeCustomAction, { loading: loadingSaving }] = useMutation<
    CreateResourceTypeCustomAction,
    CreateResourceTypeCustomActionVariables
  >(createResourceTypeCustomActionMutation);
  const [UpdateResourceTypeCustomAction, { loading: loadingUpdating }] = useMutation<
    UpdateResourceTypeCustomAction,
    UpdateResourceTypeCustomActionVariables
  >(updateResourceTypeCustomActionMutation);
  const [custom_action, setCustomAction] = useState<ILineResourceTypeCustomAction>(
    props.initialCustomAction
  );
  const [form] = useForm();

  const custom_action_types = [
    {name: "PlugIn Call", code: "plugin-call"}, 
    {name: "SQL Query", code: "sql-query"}, 
    {name: "API Call", code: "api-call"}
]

  const onClose = () => {
    setStep("properties");
    props.onClose();
  };

  const onSave = () => {
    try {
      if (props.mode === "create") {
        const input = {
          id: custom_action.id,
          res_type_id: props.resTypeId,
          res_type_custom_action_type: custom_action.res_type_custom_action_type,
          res_type_custom_action_icon: custom_action.res_type_custom_action_icon,
          res_type_custom_action_name: custom_action.res_type_custom_action_name,
          res_type_custom_action_description: custom_action.res_type_custom_action_description,
          res_type_custom_action_spec: custom_action.res_type_custom_action_spec,
          res_type_custom_action_global: custom_action.res_type_custom_action_global,
          res_type_custom_action_public: custom_action.res_type_custom_action_public,
        };
        let res = CreateResourceTypeCustomAction({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeCustomActionByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.createResourceTypeCustomAction);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to create a custom_action");
            console.error(raison);
          });
      } else {
        const input = {
          config_item_id: custom_action.id!,
          res_type_custom_action_type: custom_action.res_type_custom_action_type,
          res_type_custom_action_icon: custom_action.res_type_custom_action_icon,
          res_type_custom_action_name: custom_action.res_type_custom_action_name,
          res_type_custom_action_description: custom_action.res_type_custom_action_description,
          res_type_custom_action_spec: custom_action.res_type_custom_action_spec,
          res_type_custom_action_global: custom_action.res_type_custom_action_global,
          res_type_custom_action_public: custom_action.res_type_custom_action_public,
        };
        let res = UpdateResourceTypeCustomAction({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeCustomActionByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.updateResourceTypeCustomAction);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to update a custom_action");
            console.error(raison);
          });
      }
    } catch (error) {
      message.error("Failed to update a custom_action");
      console.error(error);
    }
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    setCustomAction(props.initialCustomAction);
    form.setFieldsValue(props.initialCustomAction);
    if(props.initialCustomAction.res_type_custom_action_type == "plugin-call" && props.initialCustomAction.res_type_custom_action_spec?.length){
      form.setFieldsValue({'res_type_custom_action_spec': JSON.parse(props.initialCustomAction.res_type_custom_action_spec).package})
    }
  }, [props]);

  return (
    <Modal
      title={<span style={{textTransform: "capitalize"}}>{props.mode + " custom action"}</span>}
      visible={props.visible}
      onCancel={onClose}
      width="850px"
      footer={[
        <>
          {step === "properties" && (
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={() => setStep("specifications")}
            >
              {"Specifications"}
            </Button>
          )}
          {step === "specifications" && (
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => setStep("properties")}
            >
              {"Back to properties"}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={props.mode === "create" ? loadingSaving : loadingUpdating}
          >
            {"Save Custom Action"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
    >
    <Form {...formLayout} form={form} onFinish={() => onSave()}>
      <Tabs
          activeKey={step}
          onChange={(step) => {
            setStep(step as any);
          }}
          defaultActiveKey="properties"
        >
        <TabPane tab="Properties" key="properties">
          <Form.Item
            label={"Name"}
            name="res_type_custom_action_name"
            required
          >
            <Input
              disabled={custom_action.res_type_custom_action_type=='plugin-call'}
              onChange={(elt) => 
                setCustomAction({
                  ...custom_action,
                  res_type_custom_action_name: elt.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Type"
            initialValue={custom_action_types[2].code}
            name="res_type_custom_action_type"
            tooltip={{ title: 'Changing the type will reset specifications', icon: <InfoCircleOutlined /> }}
            required
          >
            <Select onChange={(val: string) => 
                setCustomAction({
                  ...custom_action,
                  res_type_custom_action_type: val,
                })}>
              {custom_action_types.map(type => <Option value={type.code}>{type.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item label={"Icon"} required name="res_type_custom_action_icon">
              <IconPicker
                containerStyles={{
                  ...iconPickerContainerStyles,
                  position: "fixed",
                }}
                value={custom_action.res_type_custom_action_icon}
                buttonStyles={iconPickerBtnStyle}
                buttonIconStyles={{ display: "contents" }}
                onChange={(val) => setCustomAction({ ...custom_action, res_type_custom_action_icon: val })}
              />
            </Form.Item>
          <Form.Item
            label="Global"
            valuePropName="checked"
            name="res_type_custom_action_global"
          >
            <Checkbox onChange={e => setCustomAction({
                  ...custom_action,
                  res_type_custom_action_global: e.target.checked,
                })}/>
          </Form.Item>
          <Form.Item
            label="Public"
            valuePropName="checked"
            name="res_type_custom_action_public"
          >
            <Checkbox onChange={e => setCustomAction({
                  ...custom_action,
                  res_type_custom_action_public: e.target.checked,
                })}/>
          </Form.Item>
          <Form.Item
            label={"Description"}
            name="res_type_custom_action_description"
          >
            <TextArea
              onChange={(elt) =>
                setCustomAction({
                  ...custom_action,
                  res_type_custom_action_description: elt.target.value,
                })
              }
            />
          </Form.Item>
          </TabPane>
            <TabPane tab="Specifications" key={"specifications"}>
              <UxLineResTypeCustomActionSpecification setSpec={(spec) =>
                setCustomAction({
                  ...custom_action,
                  res_type_custom_action_spec: spec
                }) } type={custom_action.res_type_custom_action_type}
                spec={custom_action.res_type_custom_action_spec}
                resTypeId={props.resTypeId}
                />
            </TabPane>
          </Tabs>
        </Form>
    </Modal>
  );
};

export default UxResTypeCustomActionForm;
