import { gql } from "@apollo/client";

export const updateApplicationMutation = gql`
    mutation UpdateApplication($appId: String!, $appName: String, $appDescription: String, $appClientName: String, $appClientLogo: String ){
    updateApplication(input: {
      app_id: $appId
      app_name: $appName
      app_description: $appDescription
      app_client_name: $appClientName
      app_client_logo: $appClientLogo 
    }) 
  }
`;