import { Alert, Checkbox, DatePicker, Form, Input, Radio, Select, Slider, TimePicker, FormInstance } from "antd";
import { ReactNode } from "react";
import { fetchApplicationDetails_application_resourceTypes, fetchApplicationDetails_application_resourceTypes_attributes } from "../../queries/__generated__/fetchApplicationDetails";
import TextArea from "antd/lib/input/TextArea";
import { ResourceRecordSelect } from "../resourceRecordSelect";
import AutoCompleteBySQLSelect from "../AutoCompleteBySQLSelect";
import "../../pages/UploadInput.less";
import moment from "moment";
import { Rule } from "antd/lib/form";
import { UploadOneFile } from "../UploadOneFile";
import { UploadManyFiles } from "../UploadManyFiles";
import { FormItemProps } from "antd/es/form";

export function mapConstraints(constraints: string | null){
  let constraintsObj = {};
  if(constraints){
    constraints.split(";").forEach(str => {
      const [key, value] = str.split('=')
      let obj = {[key] : value}
      constraintsObj = {...constraintsObj, ...obj}
    });
  }
  return constraintsObj
}

interface IdefaultFormItemProps extends FormItemProps{
  rules: Rule[];
}

export function resourceTypeToFormItems(
  resourceType: fetchApplicationDetails_application_resourceTypes,
  form: FormInstance,
  mode: string
): ReactNode[] {

  return (
    [...resourceType.attributes]
      // order by res_type_attribute_type_order
      .sort(
        (a, b) =>
          (a.res_type_attribute_type_order ?? 0) -
          (b.res_type_attribute_type_order ?? 0)
      )
      // transform to inputs
      .map((attribute) => {
        return attributeToForm(attribute, form, mode)
      })
  );
}

export function attributeToForm(attribute: fetchApplicationDetails_application_resourceTypes_attributes, form: FormInstance, mode: string) {
    const resType: string = attribute.specification.res_type_attribute_type;
    const constraints: any = mapConstraints(attribute.specification.res_type_attribute_constraints)
    const defaultFormItemProps: IdefaultFormItemProps = {
      label: attribute.specification.res_type_attribute_name,
      name: attribute.specification.res_type_attribute_code!,
      style: {textTransform: 'capitalize'},
      rules: [
        {
          required: !!attribute.res_type_attribute_type_mandatory,
          message: `Please input ${attribute.specification.res_type_attribute_name}!`,
        },
      ],
    };
    const { Option } = Select;
    switch (resType) {
      case "text":
        if (attribute.linked_id) {
          // dynamic select from linked resource type
          return (
            <Form.Item {...defaultFormItemProps}>
              <ResourceRecordSelect 
                disabled={!attribute.res_type_attribute_type_editable}
                linkedId={attribute.linked_id}
              />
            </Form.Item>
          );
        } else {
          // plain text entry
          return (
            <Form.Item {...defaultFormItemProps}>
              <Input disabled={!attribute.res_type_attribute_type_editable} maxLength={constraints.maxlength} size={constraints.size} />
            </Form.Item>
          );
        }
      case "number":
        return (
          <Form.Item {...defaultFormItemProps}>
            <Input disabled={!attribute.res_type_attribute_type_editable} type={resType} />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item {...defaultFormItemProps}>
            <TextArea disabled={!attribute.res_type_attribute_type_editable} rows={constraints.rows} cols={constraints.cols} />
          </Form.Item>
        );
      case "datetime":
        return (
          <Form.Item {...defaultFormItemProps} initialValue={moment()}>
            <DatePicker disabled={!attribute.res_type_attribute_type_editable} format="YYYY-MM-DD HH:mm:ss" showTime />
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item {...defaultFormItemProps}>
            <DatePicker disabled={!attribute.res_type_attribute_type_editable} format="YYYY-MM-DD" />
          </Form.Item>
        );

      case "radio":
      case "select":
        const selectItems: { value: string; label: string }[] =
          attribute.specification.res_type_attribute_constraints
            ?.split(";")
            .map((str) => {
              const [value, label] = str.split("=");
              return { value, label };
            }) || [];
        return (
          <Form.Item {...defaultFormItemProps}>
            {selectItems.length > 6 ? (
              <Select disabled={!attribute.res_type_attribute_type_editable} options={selectItems} />
            ) : (
              <Radio.Group disabled={!attribute.res_type_attribute_type_editable} options={selectItems} />
            )}
          </Form.Item>
        );

      case "image":
      case "audio":
        return (
          <Form.Item {...defaultFormItemProps}>
            <UploadOneFile disabled={!attribute.res_type_attribute_type_editable} mode={mode} type={attribute.specification.res_type_attribute_type} form={form} code={attribute.specification.res_type_attribute_code}  />
          </Form.Item>
        )
      case "file":
        return (
          <Form.Item {...defaultFormItemProps}>
            <UploadManyFiles disabled={!attribute.res_type_attribute_type_editable} mode={mode} type={attribute.specification.res_type_attribute_type} form={form} code={attribute.specification.res_type_attribute_code} />
          </Form.Item>
        );
      case "selectRange":
          return (
            <Form.Item {...defaultFormItemProps}>
              <Slider disabled={!attribute.res_type_attribute_type_editable} min={parseInt(constraints.min)} max={parseInt(constraints.max)} defaultValue={10} />
            </Form.Item>
          );
      case "checkbox":
        return (
          <Form.Item {...defaultFormItemProps} valuePropName="checked">
            <Checkbox disabled={!attribute.res_type_attribute_type_editable} />
          </Form.Item>
        );
      case "time":
        return (
          <Form.Item {...defaultFormItemProps}>
            <TimePicker disabled={!attribute.res_type_attribute_type_editable} format="HH:mm:ss" />
          </Form.Item>
        );
      case "selectYear":
        return (
          <Form.Item {...defaultFormItemProps}>
            <DatePicker disabled={!attribute.res_type_attribute_type_editable} defaultValue={constraints.selected} picker="year" />
          </Form.Item>
        );
      case "selectMonth":
        return (
          <Form.Item {...defaultFormItemProps}>
            <DatePicker disabled={!attribute.res_type_attribute_type_editable} picker="month" />
          </Form.Item>
        );
      case "autocomplete":
        return (
          <AutoCompleteBySQLSelect disabled={!attribute.res_type_attribute_type_editable} defaultFormItemProps={defaultFormItemProps} constraints={constraints} />
        );
      case "url": {
        return (
          <>
            <Form.Item style={{justifyContent: "end"}}>
              <Alert
                message='Valid url must start with "http://" or "https://".'
                type="warning"
                showIcon
                closable
              />
            </Form.Item>
            <Form.Item {...{...defaultFormItemProps, rules: [...defaultFormItemProps.rules, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}}>
              <Input disabled={!attribute.res_type_attribute_type_editable} placeholder="http://example.com" />
            </Form.Item>
          </>
        )
      }
      case "password":
        return (
          <Form.Item {...defaultFormItemProps}>
            <Input disabled={!attribute.res_type_attribute_type_editable} type={resType} />
          </Form.Item>
        );
      case "email":
          return (
            <Form.Item {...{...defaultFormItemProps, rules: [...defaultFormItemProps.rules, { type: 'email' }]}}>
              <Input disabled={!attribute.res_type_attribute_type_editable} type={resType} />
            </Form.Item>
          );
      default:
        throw new Error(
          `Un-handled res_type_attribute_type: ${resType} in resource form!`
        );
    }
}