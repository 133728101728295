import React, { useState } from 'react';
import { Divider, Drawer, Space } from "antd";
import {
  ApartmentOutlined,
  InsertRowAboveOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import "./TableCreate.less"
import TableFromScratch from './TableFromScratch';
import TableFromTemplate from './TableFromTemplate';
import TableImport from './TableImport';

const TableCreate: React.FC = () => {
  const [actionVisible, setActionVisible] = useState<string | null>(null);

  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">
        <h3>Create a new table</h3>
      </Divider>
      <Space size={64} wrap={true} align="start">
        <div className="table-create-btn" onClick={() => setActionVisible("TableFromTemplate")}>
          <LikeOutlined />
          <h2>Build from template</h2>
          <p>Pick a common use case to start with, then add your own data</p>
        </div>
        <div className="table-create-btn" onClick={() => setActionVisible("TableFromScratch")}>
          <ApartmentOutlined />
          <h2>Start from scratch</h2>
          <p>Define the tables and fields to build your own solution</p>
        </div>
        <div className="table-create-btn" onClick={() => setActionVisible("TableImport")}>
          <InsertRowAboveOutlined />
          <h2>Import a spreadsheet</h2>
          <p>Turn your spreadsheet data into a new table</p>
        </div>
      </Space>
      <TableFromScratch
        visible={actionVisible === "TableFromScratch"}
        onClose={() => setActionVisible(null)}
      />
      <TableFromTemplate
        visible={actionVisible === "TableFromTemplate"}
        onClose={() => setActionVisible(null)}
      />
      <TableImport
        visible={actionVisible === "TableImport"}
        onClose={() => setActionVisible(null)}
      />
    </div>
  );
};

export default TableCreate;
