import { Modal, Button, Select, message, Empty, Col, Row, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import Slider from "@ant-design/react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AppFromTemplate.less";
import SampleNextArrow from "../components/SampleNextArrow";
import SamplePrevArrow from "../components/SamplePrevArrow";
import { useMutation } from "@apollo/client";
import { CreateResourceTypeFromTemplateMutation } from "../mutations/createResourceTypeFromTemplate";
import {
  CreateResourceTypeFromTemplate,
  CreateResourceTypeFromTemplateVariables,
} from "../mutations/__generated__/CreateResourceTypeFromTemplate";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchResourceTypeTemplates } from "../slices/resourceTypeTemplatesSlice";
import LoadingPage from "./Loading";
import { InternalNamePath } from "antd/lib/form/interface";


interface Props {
  visible: boolean;
  onClose: () => void;
}

const TableFromTemplate: React.FC<Props> = (props) => {
  const [tempId, setTempId] = useState<string | null>(null);
  const [tableName, setTableName] = useState("")
  const dispatch = useAppDispatch();
  const {loading, data: resourceTypeTemplates} = useAppSelector((state) => state.resourceTypeTemplates);
  const [CreateResourceTypeFromTemplate] = useMutation<
    CreateResourceTypeFromTemplate,
    CreateResourceTypeFromTemplateVariables
  >(CreateResourceTypeFromTemplateMutation);

  const {data: app, loading: loadingApp} = useAppSelector(state => state.application)

  const reset = () => {
    setTempId(null);
    setTableName("")
  };

  const onSave = async () => {
    try {
      if(app && tempId){
        const input: CreateResourceTypeFromTemplateVariables = {
          appId: app?.id,
          tableName: tableName,
          tableTempId: tempId
        };
        let res = await CreateResourceTypeFromTemplate({ variables: input });
        message.success(res.data?.createResourceTypeFromTemplate);
        reset()
        onClose();
      }
    } catch (error) {
      message.error("Failed to create an app");
      console.error(error);
    }
    onClose();
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {

  }

  useEffect(() => {
    dispatch(fetchResourceTypeTemplates())
  }, [dispatch])

  if(loadingApp || !app){
    return <LoadingPage />
  }else{
    return (
      <Modal
      visible={props.visible}
      title="Create table from template"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <>
          <Button
            type="primary"
            disabled={tempId ? false : true}
            icon={<SaveOutlined />}
            onClick={() => onSave()}
          >
            {"Continue"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
      width="900px"
    >
      {loading ? <p>Loading...</p> : 
      <>
      <Form.Item name={"table_name"} label="Table name" required>
        <Input max={80} onChange={e => setTableName(e.target.value)} />
      </Form.Item>
      <Slider {...settings}>
      {
      resourceTypeTemplates?.map((temp) => (
        <div key={temp.id}>
          <div
            className="temp-card"
            onClick={() => setTempId(temp.id)}
            style={{
              backgroundColor:
                tempId === temp.id
                  ? "hsla(195, 53%, 79%, 0.4)"
                  : "white",
            }}
          >
            <div
              className="temp-check"
              style={{
                visibility: tempId === temp.id ? "visible" : "hidden",
              }}
            >
              <IconPickerItem
                color="rgb(24, 144, 255)"
                icon="FaRegCheckCircle"
              />
            </div>
            <div className="temp-card-header">
              <div
                className="temp-icon"
              >
                <IconPickerItem
                  icon="FaChartPie"
                  color="#ffff"
                  size={36}
                  containerStyles={{ display: "flex" }}
                />
              </div>
            </div>
            <div className="temp-card-body">
              <h3>{temp.template_name}</h3>
              <div className="temp-card-content">
                <p>{temp.template_description}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
    </>
     }
    </Modal>
    )
  }
};

export default TableFromTemplate;
