import { gql } from "@apollo/client";

export const CreateApplicationMutation = gql`
    mutation CreateApplication($orgId: String,  $resTypeName: String, $name: String, $icon: String, $description: String, $demoData: Boolean, $resourceTypes: [ResourceTypeAppCreateInput], $file: Upload, $csvDelimiter: String){
    createApplication(input: {
      org_id: $orgId
      res_type_name: $resTypeName
      app_name: $name
      app_client_logo: $icon
      app_description: $description
      resource_types: $resourceTypes
      demo_data: $demoData
      file: $file
      csv_delimiter: $csvDelimiter
    }){
      id
      message
    } 
  }
`;