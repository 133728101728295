import ProTable, { ProColumns } from "@ant-design/pro-table";
import { useQuery } from "@apollo/client";
import { Button, Drawer, Progress, Spin, Tag } from "antd";
import { isNil } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { ResourceRecordsQuery } from "../queries/resourceRecords";
import { ResourceTypeQuery } from "../queries/resourceType";
import { ResourceRecords, ResourceRecordsVariables } from "../queries/__generated__/ResourceRecords";
import { ResourceType, ResourceTypeVariables, ResourceType_resourceType } from "../queries/__generated__/ResourceType";
import DisplayBinaryData from "./DisplayBinaryData";
import DisplayBinaryIcon from "./DisplayBinaryIcon";
import HiddenPassword from "./HiddenPassword";
import { BinaryDataAttr, isValidHttpUrl, LinkedAttr } from "./resourceTable";

interface ResourceLinkParams {
  applicationId: string,
  drilledRecordId: string;
  linkResTypeId: string;
  resourceTypeId: string;
  resTypeResourceId: string;
}

export const ResourceTypeLinkDetail: React.FC = () => {
  const history = useHistory();
  const orgId = useAppSelector((state) => state.application.data?.organization?.id)
  const routeMatch = useRouteMatch();
  const { applicationId, resourceTypeId, drilledRecordId, resTypeResourceId, linkResTypeId } =
    useParams<ResourceLinkParams>();

  
  const { data, loading, error } = useQuery<
  ResourceRecords, ResourceRecordsVariables
  >(ResourceRecordsQuery, {
    variables: {
      orgId: orgId!,
      resourceTypeId: linkResTypeId,
      drilledRecordId,
      resTypeResourceId,
      first: 1000,
      page: 1,
    },
  });

  // Request to get linkResTypeId table details
  const { loading: loadingResourceType, data: resourceTypeState, refetch } = useQuery<ResourceType, ResourceTypeVariables>(ResourceTypeQuery, {
    variables: {
      id: linkResTypeId
    }
  });

  function resourceTypeToColumns(
    resourceType: ResourceType_resourceType,
  ): ProColumns[] {

    const resourceColumns = [...resourceType.attributes]
    // order by res_type_attribute_type_order
    .sort(
      (a, b) =>
        (a.res_type_attribute_type_order ?? 0) -
        (b.res_type_attribute_type_order ?? 0)
    )
    // show only res_type_attribute_type_detail != 1
    .filter((attribute) => !attribute.res_type_attribute_type_detail)
    // transform for table
    .map((attribute) => {
      const render: ProColumns["render"] = attribute.linked_id
        ? (_, record) => {
            const linkedAttrJson =
              record[attribute.specification.res_type_attribute_code];
            if (linkedAttrJson) {
              const linkedAttr: LinkedAttr = JSON.parse(linkedAttrJson);
              return (
                <Link
                  to={`${routeMatch.url}/link/${linkedAttr.res_type_id}/${linkedAttr.id}`}
                >
                  {linkedAttr.value}
                </Link>
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "image"
        ? (_, record) => {
            const image =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(image)) {
              const imageAttr: BinaryDataAttr = JSON.parse(image);
              return (
                <DisplayBinaryData data={imageAttr} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "audio"
        ? (_, record) => {
            const audio =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(audio)) {
              const audioAttr: BinaryDataAttr = JSON.parse(audio);
              return (
                <DisplayBinaryData data={audioAttr} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "file"
        ? (_, record) => {
            const fileIds =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(fileIds)) {
              return (
                <DisplayBinaryIcon ids={fileIds} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "datetime"
        ? (_, record) => {
            const date =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(date)) {
              return moment(date).format("YYYY-MM-DD hh:mm:ss");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "date"
        ? (_, record) => {
            const date =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(date)) {
              return moment(date).format("YYYY-MM-DD");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "time"
        ? (_, record) => {
            const time =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(time)) {
              return moment(time, "HH:mm:ss").format("hh:mm:ss");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectYear"
        ? (_, record) => {
            const year =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(year)) {
              return moment(year).format("YYYY");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectMonth"
        ? (_, record) => {
            const month =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(month)) {
              return moment(month).format("YYYY-MM");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "radio"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <Tag>{text}</Tag>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "text"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <span>{text}</span>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "url"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return isValidHttpUrl(text) ? <a href={text} target="_blank">{text}</a> : <span>{text}</span>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "checkbox"
        ? (_, record) => {
            const val =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(val)) {
              return val ? <Tag color={"green"}>True</Tag> : <Tag color={"red"}>False</Tag>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "email"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <a href={"mailto:"+text}>{text}</a>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "password"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <HiddenPassword text={text} />
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectRange"
        ? (_, record) => {
            const val = record[attribute.specification.res_type_attribute_code];
            if (!isNil(val)) {
              return <Progress percent={val} format={percent => percent} size="small" />;
            } else {
              return "-";
            }
          }
        : undefined;
  
        const valueType =
          attribute.specification.res_type_attribute_type === "date"
            ? "date"
            : undefined;
  
        const sorter =
          attribute.specification.res_type_attribute_type === "text"
            ? // attribute.specification.res_type_attribute_type === "date"
              (a: any, b: any) => {
                return (
                  a[attribute.specification.res_type_attribute_code] -
                  b[attribute.specification.res_type_attribute_code]
                );
              }
            : undefined;
  
        const columnProps = {
          dataIndex: attribute.specification.res_type_attribute_code,
          title: attribute.specification.res_type_attribute_name,
          ellipsis: true,
          valueType,
          sorter,
          render,
        }
  
        if(attribute.specification.res_type_attribute_type === "checkbox"){
          return {...columnProps, filters: [
              {
                text: 'True',
                value: 1,
              },
              {
                text: 'False',
                value: 0,
              }
            ],
            onFilter: (value: string, record: any) => record[attribute.specification.res_type_attribute_code] === value,
          }
        }
        return columnProps;
      });
  
      const actionsColumn: ProColumns = {
        title: "Actions",
        render: (_, record) => {
          return (
            <Link to={`${routeMatch.url}/edit/${record.id}`}>
              <Button>Edit</Button>
            </Link>
          );
        },
      };
      //@ts-ignore
      return [...resourceColumns, actionsColumn];
  }

  const columns: ProColumns[] = resourceTypeState?.resourceType ? resourceTypeToColumns(resourceTypeState.resourceType) : [];

  const onClose = () => history.push(`/view/applications/${applicationId}/tables/${resourceTypeId}`);

  const toolbarActions = [
    <Link to={`${routeMatch.url}/new`}>
      <Button type="primary">Add record</Button>
    </Link>,
  ]

  return (
    <Drawer
      footer={<div
        style={{
          textAlign: 'left',
        }}
      >
        <Button onClick={onClose} type="primary">
          Close
        </Button>
      </div>}
      onClose={onClose}
      visible={true}
      width="60%"
    >
      {
        !loadingResourceType ? 
        <div>
          <h2>{!isNil(resourceTypeState) ? resourceTypeState.resourceType.res_type_name : ""}</h2>
          <ProTable
          // TODO Promise "adapter": params, pagination
          // request={() => {
          //   return Promise.resolve({
          //     data: resourceRecords?.data || undefined,
          //     success: resourceRecords ? true : false,
          //   });
          // }}
          locale={{ 
            triggerDesc: 'descend sort text',
            triggerAsc: 'ascend sort text', 
            cancelSort: 'cancel sort text'
          }}
          toolbar={{
            actions: !resourceTypeState?.resourceType.res_type_create_disabled
              ? toolbarActions
              : [],
          }}
          dataSource={data?.resourceTypeRecords?.data || []}
          // expandable={{ expandedRowRender }}
          search={false}
          rowKey="id"
          columns={columns}
          loading={loading}
          // pagination={{ locale: {  total: {total: "items/page"}} }}
          // columnState={{}}
          title={() => <h1>{resourceTypeState?.resourceType.res_type_name ?? ""}</h1>}
        ></ProTable>
          {/* {resourceDescriptions(resourceTypeState, resourceRecords?.data || [])} */}
        </div>: <div style={{textAlign: "center"}}><Spin size="large" /></div>
      }
    </Drawer>
  );
};
