import {gql} from "@apollo/client"

export const getMustSubscribeTemplatesQuery = gql`
  query getMustSubscribeTemplates ($appId: String, $first: Int!) {
    getMustSubscribeTemplates(app_id: $appId, first: $first) {
      data {
        id
        template_name
        template_description
        template_logo
        stripe_price
        is_paid
        price
      }
    }
  }
`;