import React, { useEffect, useState } from "react";
import { Button, AutoComplete, Input, Space } from "antd";

import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ResourceTypeCustomActions } from "../../queries/__generated__/ResourceTypeCustomActions";
import { ResourceTypeCustomActionsQuery } from "../../queries/resourceTypeCustomActions";
import { IconList } from "react-fa-icon-picker";
import { ILineResourceTypeCustomAction, initialCustomAction } from "./UxLineResourceTypeCustomActionsTable";

export interface IResourceTypeCustomAction {
  id: string | null;
  res_type_id: string | null;
  res_type_custom_action_type: string;
  res_type_custom_action_icon: IconList;
  res_type_custom_action_name: string;
  res_type_custom_action_spec: string | null;
  res_type_custom_action_global: boolean | null;
  res_type_custom_action_public: boolean | null;
  res_type_custom_action_description: string | null;
}

interface ISelection {
  resourceTypecustomActions: IResourceTypeCustomAction;
  value: string;
}

interface Props {
  onLineResourceTypeCustomActionAdd: (line: IResourceTypeCustomAction) => void;
  inputStyle?: React.CSSProperties;
  excludeResTypeCustomActions: string[];
}

const LineResourceTypeCustomActionSearchAndAdd: React.FC<Props> = (props) => {
  const { t } = useTranslation('LineResourceTypeCustomActionSearchAndAdd');
  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState('');
  const [selection, setSelection] = useState<IResourceTypeCustomAction | null>(null);
  const { data, loading, error } = useQuery<
  ResourceTypeCustomActions
>(ResourceTypeCustomActionsQuery);

const dataSet: ILineResourceTypeCustomAction[] = data?.resourceTypeCustomActions.map((item:any) => ({
  id: item['id'],
  res_type_custom_action_type: item['action_type'],
  res_type_custom_action_icon: item['action_icon'],
  res_type_custom_action_description: item['action_description'],
  res_type_custom_action_name: item['action_name'],
  res_type_custom_action_spec: item['action_spec'],
  res_type_custom_action_global: item['action_global'] ? true : false,
  res_type_custom_action_public: item['action_public'] ? true : false,
}))

  const options: (ISelection | {value: ''})[] = (dataSet || []).filter(item => props.excludeResTypeCustomActions.indexOf(item.id!) < 0
  ).map(item => {
      return {
        resourceTypecustomActions: {
          id: item.id,
          res_type_id: item.res_type_id,
          res_type_custom_action_type: item.res_type_custom_action_type,
          res_type_custom_action_icon: item.res_type_custom_action_icon,
          res_type_custom_action_description: item.res_type_custom_action_description,
          res_type_custom_action_name: item.res_type_custom_action_name,
          res_type_custom_action_spec: item.res_type_custom_action_spec,
          res_type_custom_action_global: item.res_type_custom_action_global ? true : false,
          res_type_custom_action_public: item.res_type_custom_action_public ? true : false,
        },
        value: item.res_type_custom_action_name,
      };
  });
  const onAdd = () => {
    if (selection) {
      props.onLineResourceTypeCustomActionAdd(selection);
    } else if (search) {
      props.onLineResourceTypeCustomActionAdd({...initialCustomAction, res_type_custom_action_name: search });
    }
  };

  const onSelect = (_: string, selected: any) => {
    setSelection((selected as ISelection).resourceTypecustomActions);
    setSearch(null);
  };

  const onSearch = (input: string) => {
    if (search !== input) { // only if input really changed
      setSelection(null);
      if (input.trim() === "") {
        setSearch(null);
      } else {
        setSearch(input);
      }
    }
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  const buttonContent = selection || search ? (selection ? t("Add selected") : t("Add new")) : t("Search");

  const buttonDisabled = !selection && !search;

  const dropShouldOpen = search !== null;

  return (
    <Space direction="horizontal" size="small">
      <AutoComplete
        value={value}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(inputValue, option) =>
          inputValue.trim() !== "" &&
          option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        open={dropShouldOpen}
      >
        <Input
          placeholder={t("Search table fields...")}
          style={props.inputStyle}
        />
      </AutoComplete>
      <Button
        type="primary"
        loading={loading}
        disabled={buttonDisabled}
        onClick={onAdd}
      >
        {buttonContent}
      </Button>
    </Space>
  );
};

export default LineResourceTypeCustomActionSearchAndAdd;
