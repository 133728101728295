import { Button, Form, Input, message, Modal, Select, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ILineResourceTypeFilter } from "./UxLineResourceTypeFiltersTable";
import { createResourceTypeFilterMutation } from "../../mutations/createResourceTypeFilter";
import { useMutation } from "@apollo/client";
import {
  CreateResourceTypeFilter,
  CreateResourceTypeFilterVariables,
} from "../../mutations/__generated__/CreateResourceTypeFilter";
import {
  UpdateResourceTypeFilter,
  UpdateResourceTypeFilterVariables,
} from "../../mutations/__generated__/UpdateResourceTypeFilter";
import { updateResourceTypeFilterMutation } from "../../mutations/updateResourceTypeFilter";
import { ResourceTypeFilterByResTypeQuery } from "../../queries/resourceTypeFilterByResType";
import UxLineResTypeFilterCondition, {
  ILineResourceTypeFilterCondition,
} from "./UxLineResTypeFilterCondition";

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  orgId: string | null;
  mode: "create" | "edit";
  initialFilter: ILineResourceTypeFilter;
  resTypeId: string;
  onClose: () => void;
}

const UxResTypeFilterForm: React.FC<Props> = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [step, setStep] = useState("properties");
  const [CreateResourceTypeFilter, { loading: loadingSaving }] = useMutation<
    CreateResourceTypeFilter,
    CreateResourceTypeFilterVariables
  >(createResourceTypeFilterMutation);
  const [UpdateResourceTypeFilter, { loading: loadingUpdating }] = useMutation<
    UpdateResourceTypeFilter,
    UpdateResourceTypeFilterVariables
  >(updateResourceTypeFilterMutation);
  const [filter, setFilter] = useState<ILineResourceTypeFilter>(
    props.initialFilter
  );
  const [form] = useForm();

  const onClose = () => {
    setStep("properties");
    props.onClose();
  };

  const onLineResourceTypeFilterConditionAdd = (
    item: ILineResourceTypeFilterCondition
  ) => {
    let updateFilter = JSON.parse(JSON.stringify(filter));
    updateFilter.attributes.push(item);
    setFilter(updateFilter);
  };

  const onLineResourceTypeFilterConditionRemove = (index: number) => {
    let updateRes = JSON.parse(JSON.stringify(filter));
    updateRes.attributes.splice(index, 1);
    setFilter(updateRes);
  };

  const onLineResourceTypeFilterConditionChange = (
    index: number,
    newItem: ILineResourceTypeFilterCondition
  ) => {
    let updateRes = JSON.parse(JSON.stringify(filter));
    updateRes.attributes[index] = newItem;
    setFilter(updateRes);
  };

  const onSave = () => {
    try {
      if (props.mode === "create") {
        const input = {
          res_type_id: props.resTypeId,
          res_type_filter_name: filter.res_type_filter_name,
          res_type_filter_description: filter.res_type_filter_description,
          res_type_filter_logicaloperator: filter.res_type_filter_logicaloperator,
          attributes: filter.attributes ? filter.attributes.map(item => {
            return {
              res_type_filter_id: item.res_type_filter_id,
              res_type_attribute_type_id: item.res_type_attribute_type_id,
              res_type_filter_attribute_condition: item.res_type_filter_attribute_condition ?? ''
            }
          }) : []
        };
        let res = CreateResourceTypeFilter({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeFilterByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.createResourceTypeFilter);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to create a filter");
            console.error(raison);
          });
      } else {
        const input = {
          config_sub_item_id: filter.id!,
          res_type_filter_name: filter.res_type_filter_name,
          res_type_filter_description: filter.res_type_filter_description,
          res_type_filter_logicaloperator: filter.res_type_filter_logicaloperator,
          attributes: filter.attributes ? filter.attributes.map(item => {
            return {
              id: item.id,
              res_type_filter_id: item.res_type_filter_id,
              res_type_attribute_type_id: item.res_type_attribute_type_id,
              res_type_filter_attribute_condition: item.res_type_filter_attribute_condition ?? ''
            }
          }) : []
        };
        let res = UpdateResourceTypeFilter({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeFilterByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.updateResourceTypeFilter);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to update a filter");
            console.error(raison);
          });
      }
    } catch (error) {
      message.error("Failed to update a filter");
      console.error(error);
    }
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    setFilter(props.initialFilter);
    form.setFieldsValue(props.initialFilter);
  }, [props]);

  return (
    <Modal
      title={props.mode + " filter"}
      visible={props.visible}
      onCancel={onClose}
      width="850px"
      footer={[
        <>
          {step === "properties" && (
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={() => setStep("conditions")}
            >
              {"Conditions"}
            </Button>
          )}
          {step === "conditions" && (
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => setStep("properties")}
            >
              {"Back to properties"}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={props.mode === "create" ? loadingSaving : loadingUpdating}
          >
            {"Save Filter"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
    >
      <Form {...formLayout} form={form} onFinish={() => onSave()}>
        <Tabs
          activeKey={step}
          onChange={(step) => {
            setStep(step as any);
          }}
          defaultActiveKey="properties"
        >
          <TabPane tab="Properties" key="properties">
            <Form.Item
              label={"Filter name"}
              name="res_type_filter_name"
              required
            >
              <Input
                onChange={(elt) =>
                  setFilter({
                    ...filter,
                    res_type_filter_name: elt.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Logic Operator"
              name="res_type_filter_logicaloperator"
              required
            >
              <Select onChange={(val: string) => 
                  setFilter({
                    ...filter,
                    res_type_filter_logicaloperator: val,
                  })}>
                <Option value={"AND"}>AND</Option>
                <Option value={"OR"}>OR</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={"Description"}
              name="res_type_filter_description"
              required
            >
              <TextArea
                onChange={(elt) =>
                  setFilter({
                    ...filter,
                    res_type_filter_description: elt.target.value,
                  })
                }
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Conditions" key={"conditions"}>
            <UxLineResTypeFilterCondition
              filter={filter}
              onLineRemove={onLineResourceTypeFilterConditionRemove}
              onLineAdd={onLineResourceTypeFilterConditionAdd}
              onLineChange={onLineResourceTypeFilterConditionChange}
            />
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default UxResTypeFilterForm;
