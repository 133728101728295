import React from "react";
import { Card, Form, Input, Button, Alert } from "antd";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export interface Props {
  onLogin: (username: string, password: string, method: string) => void;
  loading: boolean;
  error: string | undefined | null;
}

const LoginComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation('Login');
  const [form] = Form.useForm();

  const handleFinish = (values: any) => {
    props.onLogin(values.username, values.password, 'basic');
  };

  return (
    <Card title={t("Login to Digintu.code")} style={{boxShadow: 'rgb(0 0 0 / 20%) 1px 1px 10px'}}>
      {props.error && (
        <Alert
          message={props.error}
          type="error"
          showIcon
          style={{ marginBottom: "1em" }}
        />
      )}
      <p>{t("Enter required details to login.")}</p>

      <Form {...layout} form={form} onFinish={handleFinish}>
        <Form.Item
          label={t("Username")}
          name="username"
          rules={[{ required: true, message: t("Please input your email") }]}
        >
          <Input 
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>

        <Form.Item
          label={t("Password")}
          name="password"
          rules={[{ required: true, message: t("Please input your password") }]}
        >
          <Input.Password 
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
        <div style={{ marginBottom: 0, textAlign: 'end' }}>
            <Button size="large" type="link">
              <Link to="/register">{t("Register")}</Link>
            </Button>
            <Button size="large" loading={props.loading} type="primary" htmlType="submit">
              {t("Login")}
            </Button>
        </div>
        <div style={{ marginBottom: 0, textAlign: 'end' }}>
          <Button size="large" type="link">
            <Link to="/password-reset">{t("Forgot your password ?")}</Link>
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default LoginComponent;
