import { gql } from "@apollo/client"

export const ResourceTypeChartDataRecordsQuery = gql`
  query ResourceTypeChartDataRecords($orgId: String!, $resTypeId: String!, $selectedChartId: String!, $selectedFilterId: String){
    resourceTypeChartDataRecords(
      input: {
        org_id: $orgId, 
        res_type_id: $resTypeId, 
        selected_chart_id: $selectedChartId,
        selected_filter_id: $selectedFilterId
        }
      )
  }
`;
