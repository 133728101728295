import { gql } from "@apollo/client";

export const createResourceTypeStatisticsMutation = gql`
  mutation CreateResourceTypeStatistics($res_type_id: String!,
    $res_type_statistics_name: String!,
    $res_type_statistics_description: String,
    $res_type_attribute_type_id: String!,
    $grp_function_id: String,
    ) {
    createResourceTypeStatistics(input: {
      res_type_id: $res_type_id
      res_type_statistics_name: $res_type_statistics_name
      res_type_statistics_description: $res_type_statistics_description
      res_type_attribute_type_id: $res_type_attribute_type_id,
      grp_function_id: $grp_function_id,
    })
  }
`;
