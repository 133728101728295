import { gql } from "@apollo/client";

export const updateResourceTypeCustomActionMutation = gql`
  mutation UpdateResourceTypeCustomAction($config_item_id: String!,
    $res_type_custom_action_type: String!,
    $res_type_custom_action_icon: String,
    $res_type_custom_action_name: String!,
    $res_type_custom_action_description: String,
    $res_type_custom_action_spec: String,
    $res_type_custom_action_global: Boolean,
    $res_type_custom_action_public: Boolean,
    ) {
    updateResourceTypeCustomAction(input: {
      config_item_id: $config_item_id
      res_type_custom_action_type: $res_type_custom_action_type
      res_type_custom_action_icon: $res_type_custom_action_icon
      res_type_custom_action_name: $res_type_custom_action_name
      res_type_custom_action_description: $res_type_custom_action_description
      res_type_custom_action_spec: $res_type_custom_action_spec
      res_type_custom_action_global: $res_type_custom_action_global
      res_type_custom_action_public: $res_type_custom_action_public
    })
  }
`;
