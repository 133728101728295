import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  EllipsisOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { Space, Menu, Dropdown, message, Modal } from "antd";
import { fetchApplications } from "../slices/applicationsSlice";
import "./ApplicationsList.less";
import LoadingPage from "./Loading";
import { fetchMe } from "../slices/meSlice";
import { fetchApplications_applications_data as AppDetailsType } from "../queries/__generated__/fetchApplications";
import { deleteApplication } from "../slices/applicationSlice";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { fontAwesomeList } from "../components/helpers/iconList";
import { PlusOutlined } from '@ant-design/icons';
import ReSubscribeTemplateModal from "../components/ReSubscribeTemplateModal";
import ReadMore from "../components/ReadMore";
import RestrictionModal from "../components/RestrictionModal";

const ApplicationsList: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [appId, setAppId] = useState('')
  const [orgId, setOrgId] = useState('')
  const {data: applications, loading: loadingApps} = useAppSelector((state) => state.applications);
  const [modalIsShow, setModalIsShow] = useState(false);
  const { loading: meLoading, data: meData } = useAppSelector(
    (state) => state.me
  );

  const [open, setOpen] = useState(false);

  const showRestrictionModal = () => {
    setOpen(true);
  };

  const hideRestrictionModal = () => {
    setOpen(false);
  };

  const handleDeleteApp = (appId: string) => {
     dispatch(deleteApplication({appId}))
  }

  const handleCloseModal = () => {
    setModalIsShow(false)
  }

  const showResubscribeModal = (orgId:string, appId:string) => {
    setOrgId(orgId)
    setAppId(appId)
    setModalIsShow(true)
  }

  const menu = (app: AppDetailsType, userCanUpdate: number | undefined, userCanDelete: number | undefined) => (
    <Menu>
      {userCanUpdate ? <Menu.Item key="1" onClick={() => {
        if(app.is_disable){
          alert('This app is disable')
        }else{
          history.push('/applications/'+app.id)
        }
      }}>Edit</Menu.Item> : null}
      {userCanDelete ? <Menu.Item key="2" onClick={() => {
        if(app.is_disable){
          alert('This app is disable')
        }else{
          handleDeleteApp(app.id)
        }
      }}>Delete</Menu.Item> : null}
      {app.is_disable ? <Menu.Item key="3" onClick={() => {
        if(!app.organization?.is_subscribe){
          showRestrictionModal()
        }else{
          showResubscribeModal(app.org_id, app.id)
        }
      }}>Enable</Menu.Item> : ''}
    </Menu>
  );

  useEffect(() => {
    dispatch(fetchApplications({ first: 100 }));
    dispatch(fetchMe());
  }, [dispatch]);

  if (applications && meData) {
    return (
      <div>
        <ReSubscribeTemplateModal appId={appId} orgId={orgId} modalIsShow={modalIsShow} handleCloseModal={handleCloseModal}/>
        <RestrictionModal handleCloseModal={hideRestrictionModal} open={open} />
        <Space size={2} wrap={true} align="start">
          <div className="new-app-card">
            <div className="app-card-header">
              <div className="app-card-nav"></div>
              <Link to="/applications/create" className="app-icon" 
                style={{ backgroundColor: "transparent" }}>
                  <PlusOutlined />
              </Link>
            </div>
            <div className="app-card-body">
              <h3>Create new App</h3>
            </div>
          </div>
          {applications?.data.map((app) => {
            var appRights = meData.userAccessRights.find(
              right => (right.item_type === "application" && right.item_id === app.id)
              )
            var orgRihts = meData.organizations.find(
              (org) => org.id === app.organization?.id
            )?.pivot
            var userCanUpdate = orgRihts?.admin || orgRihts?.superuser || appRights?.update 
            var userCanDelete = orgRihts?.admin || orgRihts?.superuser || appRights?.delete
            return (
            <div className="app-card">
              <div className="app-card-header">
                <div className="app-card-nav">
                  {
                  (userCanUpdate || userCanDelete) ? (
                    <Dropdown overlay={menu(app, userCanUpdate, userCanDelete)}>
                      <EllipsisOutlined
                        style={{ fontSize: "1.5em", color: "white" }}
                      />
                    </Dropdown>
                  ) : (
                    ""
                  )}
                </div>
                <a
                onClick={e => {
                  if(app.is_disable){
                    e.preventDefault()
                    if(!app.organization?.is_subscribe){
                      showRestrictionModal()
                    }else{
                      showResubscribeModal(app.org_id, app.id)
                    }
                  }
                }}
                className={app.is_disable ? "app-disable-icon" : "app-icon"}
                  href={"/view/applications/" + app.id + "/view"}
                >
                  <IconPickerItem icon={fontAwesomeList.includes(app.app_client_logo) ? app.app_client_logo as IconList : "FaThLarge"} color="#ffff" size={30} containerStyles={{display: 'flex'}} />
                </a>
              </div>
              <div className="app-card-body">
                <h3>{app.app_name}</h3>
                <div className="app-card-content">
                  <ReadMore>
                    {app.app_description}
                  </ReadMore>
                  <h4>{app.organization?.org_name}</h4>
                </div>
              </div>
            </div>
          )})}
        </Space>
      </div>
    );
  } else {
    return <LoadingPage />;
  }
};

export default ApplicationsList;
