import { useQuery } from '@apollo/client';
import { Form, Select, AutoComplete, Input } from 'antd';
import React from 'react'
import { fetchGroupNamesQuery } from '../../queries/fetchGroupNames';
import { ILineResourceTypeAttribute } from './UxLineResourceTypeAttributesTable';
import {
  fetchGroupNames,
  fetchGroupNamesVariables,
} from "../../queries/__generated__/fetchGroupNames";
import { isNil } from 'lodash';

interface Props {
  setField: (field: ILineResourceTypeAttribute) => void
  field: ILineResourceTypeAttribute
  resTypeId: string | null
}

const GroupNameSelect: React.FC<Props> = (props) => {
  const {data, loading, error } = useQuery<fetchGroupNames, fetchGroupNamesVariables>(fetchGroupNamesQuery, {
    variables: {
      resTypeId: props.resTypeId!
    },
    skip: isNil(props.resTypeId)
  })

  const optionMapper = (item: string) => ({ value: item, label: item.toUpperCase() })

  const options = data ? data.resourceTypeGroups!.map(optionMapper) : [];

  const onSelect = (data: string) => {
    props.setField({...props.field, res_type_attribute_type_group: data})
  };

  return (
    <Form.Item label={'Group name'} name="res_type_attribute_type_group">
      <AutoComplete
        value={props.field.res_type_attribute_type_group ?? ""}
        options={options}
        onSelect={onSelect}
        onChange={(val: string) => props.setField({...props.field, res_type_attribute_type_group: val})}
        filterOption={(inputValue, option) =>
          inputValue.trim() !== "" &&
          option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
      >
        <Input
          placeholder='Search or add group name'
        />
      </AutoComplete>
    </Form.Item>
  )
}

export default GroupNameSelect