import { gql } from "@apollo/client";

export const createResourceDataTrashMutation = gql`
  mutation CreateResourceDataTrash($orgId: String!, 
                                   $resourceTypeId: String!, 
                                   $resTypeSheetId: String, 
                                   $selectedFilterId: String, 
                                   $resTypeBindingId: String,
                                   $drilledRecordId: String, 
                                   $recordIds: [String]) {
    createResourceDataTrash(input: {
      org_id: $orgId
      res_type_id: $resourceTypeId
      res_type_sheet_id: $resTypeSheetId
      selected_filter_id: $selectedFilterId
      res_type_binding_id: $resTypeBindingId,
      drilled_record_id: $drilledRecordId,
      record_ids: $recordIds
    })
  }
`;
