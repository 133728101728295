import { gql } from "@apollo/client";

export const CreateResourceTypeFromTemplateMutation = gql`
    mutation CreateResourceTypeFromTemplate($appId: String!, $tableTempId: String!, $tableName: String!){
    createResourceTypeFromTemplate(input: {
      app_id: $appId
      table_temp_id: $tableTempId
      table_name: $tableName
    })
  }
`;