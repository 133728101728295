import { Modal } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { createResourceDataTrash } from "../slices/resourceRecordSlice";
import { fetchResourceRecords } from "../slices/resourceRecordsSlice";

interface ResourceRecordParams {
  recordId: string;
  resourceTypeId: string;
}

export const ResourceRecordDelete: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const orgId = useAppSelector((state) => state.application.data?.organization?.id)
  const applicationId = useAppSelector((state) => state.application.data?.id)
  const [isModalVisible, setIsModalVisible] = useState(true);
  const { recordId, resourceTypeId} =
    useParams<ResourceRecordParams>();

  const onConfirm= () => {

    if(orgId){
      let promise = dispatch(
        createResourceDataTrash({
          orgId,
          resourceTypeId: resourceTypeId,
          recordIds: [recordId],
        })
      );
      promise.then(
        () => {
          setIsModalVisible(false);
          history.push(`/view/applications/${applicationId}/tables/${resourceTypeId}`);
          dispatch(fetchResourceRecords({ orgId, resourceTypeId }));
        },
        (error) => console.error(error)
      );      
    }
    
  }
  const onCancel = () => {
    setIsModalVisible(false);
    history.push(`/view/applications/${applicationId}/tables/${resourceTypeId}`);
  };

  return (
    <Modal
      visible={isModalVisible}
      title="Delete record"
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Yes"
      cancelText="No"
    >
     <p>Are you sure to delete this record?</p>
  </Modal>
  );
};
