import { useMutation } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { subscribeToTemplateMutation } from "../mutations/subscribeToTemplate";
import { SubscribeToTemplate, SubscribeToTemplateVariables } from '../mutations/__generated__/SubscribeToTemplate';
import { fetchTemplates } from '../slices/templatesSlice';

interface Props{
  content: ReactNode;
  orgId: string;
  priceId: string | null | undefined;
  modalIsShow: boolean;
  handleCloseModal: () => void;
  createApp: () => void;
}

const SubscribeTemplateModal: React.FC<Props> = (props) => {
  
  const [SubscribeToTemplate, {loading: loadingSubscription}] = useMutation<
  SubscribeToTemplate,
  SubscribeToTemplateVariables
  >(subscribeToTemplateMutation);
  const dispatch = useAppDispatch();

  const handleOk = async () => {
    if (props.priceId){
      try {
          const input: SubscribeToTemplateVariables = {
            orgId: props.orgId,
            priceId: props.priceId
          };
          let res = await SubscribeToTemplate({ variables: input });
          message.success(res.data?.subscribeToTemplate);
          props.createApp()

      } catch (error) {
        message.error("Failed to subscribe organization to template");
        console.error(error);
      }
      props.handleCloseModal()
    }
  };

  useEffect(() => {
    dispatch(fetchTemplates({first: 20}))
  }, [dispatch])

  return (
      <Modal title="Subscribe to this template" visible={props.modalIsShow} onOk={handleOk} onCancel={props.handleCloseModal} footer={[
        <Button key="back" onClick={props.handleCloseModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loadingSubscription} onClick={handleOk}>
          Subscribe
        </Button>,]}>
        { props.content }
      </Modal>
  );
};

export default SubscribeTemplateModal;