import React from 'react'
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload"
import { message, Upload } from 'antd';
import { useMutation } from '@apollo/client';
import { StoreResourceTypeRecordBinaryData, StoreResourceTypeRecordBinaryDataVariables } from '../mutations/__generated__/StoreResourceTypeRecordBinaryData';
import { StoreResourceTypeRecordBinaryDataMutation } from '../mutations/createResourceTypeRecordBinaryData';
import { ResourceTypeRecordBinaryDataQuery } from '../queries/resourceTypeRecordBinaryData';

interface Props {
  disabled: boolean;
  recordId: string;
  resourceTypeId: string;
  orgId: string | undefined;
}

export const GalleryUploadManyFiles: React.FC<Props> = (props) => {
  
  const listProps = () => {
    return {
      beforeUpload: (file: RcFile) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('File must smaller than 2MB!');
        }
        return !isLt2M ? Upload.LIST_IGNORE : false;
      },
      multiple: true,
      maxCount: 5,
    }
  }

  const [StoreResourceTypeRecordBinaryData] = useMutation<
  StoreResourceTypeRecordBinaryData,
  StoreResourceTypeRecordBinaryDataVariables
  >(StoreResourceTypeRecordBinaryDataMutation);

  const onFileUpload= async (files: any) => {
      try {
          const input: StoreResourceTypeRecordBinaryDataVariables = {
            recordId: props.recordId,
            resourceTypeId: props.resourceTypeId,
            orgId: props.orgId!,
            files
          };
          let res = await StoreResourceTypeRecordBinaryData({ variables: input, refetchQueries: [{query: ResourceTypeRecordBinaryDataQuery, variables: {
            recordId: props.recordId,
            resourceTypeId: props.resourceTypeId,
            orgId: props.orgId!,
            first: 10,
          },}] });
          message.success(res.data?.storeResourceTypeRecordBinaryData);      
      } catch (error) {
        message.error("Failed to upload file");
        console.error(error);
      }
  };

  return (
      <Upload disabled={props.disabled} fileList={[]} {...listProps()} onChange={info => {
        if(info){
          onFileUpload(info.file)
        }
      }}>
        <div className="ant-upload-container">
          <p className="ant-upload-dragger-icon">
            <CloudUploadOutlined />
          </p>
          <p>Select File</p>
        </div>
      </Upload>
  )
}
