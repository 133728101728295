import { gql } from "@apollo/client";

export const ResourceRecordQuery = gql`
  query ResourceRecord(
    $orgId: String!,
    $resourceTypeId: String!,
    $recordId: String!,
  ) {
    resourceTypeRecords (
      input: { org_id: $orgId, res_type_id: $resourceTypeId, record_id: $recordId }
      first: 1,
    ) {
      data
    }
  }
`;
