import { gql } from "@apollo/client";

export const UpdateResourceTypeResourceMutation = gql`
    mutation UpdateResourceTypeResource(
    $config_sub_item_id: String!,
    $res_type_resources_table_name: String,
    $res_type_resources_transitional_enabled: Int,
    $res_type_resources_description_down: String,
    $res_type_resources_description_up: String){
    updateResourceTypeResource(input: {
      config_sub_item_id: $config_sub_item_id
      res_type_resources_table_name: $res_type_resources_table_name
      res_type_resources_transitional_enabled: $res_type_resources_transitional_enabled
      res_type_resources_description_down: $res_type_resources_description_down
      res_type_resources_description_up: $res_type_resources_description_up
    })
  }
`;