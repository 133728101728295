import React, { useState } from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client/react";

import { apolloClient } from "./apolloClient";
import { AppLayout } from "./layouts/AppLayout";

import { MainLayout } from "./layouts/MainLayout";

import { store } from "./store";

import "./App.css";
import "./custom-antd.less";
import { IAuthInfo } from "./pages/Auth";
import { BrowserRouter, useHistory } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import { HomeLayout } from "./layouts/HomeLayout";

const App: React.FC = () => {
  const [auth, setAuth] = useState<IAuthInfo | null>(null)

  const onAuthDone: (authInfo: IAuthInfo) => void = authInfo => {
    setAuth(authInfo);
  }

  if(auth === null){
    return (
      <ApolloProvider client={apolloClient}>
        <BrowserRouter> 
          <Provider store={store}>
            <HomeLayout onFinish={onAuthDone} >
              <AuthRoutes onFinish={onAuthDone} />
            </HomeLayout>
          </Provider>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter> 
        <Provider store={store}>
        {
          (window.location.pathname.match(/^(\/view\/applications\/.*)/gi)) ? 
          <div className="app-layout">
            <AppLayout/>
          </div> 
          : 
          <MainLayout/>
        }
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  )
};

export default App;
