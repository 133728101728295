import { gql } from "@apollo/client";

export const StoreResourceTypeRecordBinaryDataMutation = gql`
    mutation StoreResourceTypeRecordBinaryData($recordId: String!, $resourceTypeId: String!, $orgId: String!, $files: Upload!){
    storeResourceTypeRecordBinaryData(input: {
      record_id: $recordId
      res_type_id: $resourceTypeId
      org_id: $orgId
      files: $files
    })
  }
`;