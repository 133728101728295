import {gql} from '@apollo/client'

export const deleteResourceTypeFromAppMutation = gql`
  mutation DeleteResourceTypeFromApp($resTypeId: String!, $appId: String!){
    deleteResourceTypeFromApp(
      input: {
        res_type_id: $resTypeId
        app_id: $appId
      }
    ) 
  }
`;