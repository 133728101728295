import {gql} from "@apollo/client";

export const fetchApplicationsQuery = gql`
  query fetchApplications($first: Int!){
    applications(first: $first) {
      data {
          id
          app_name
          org_id
          app_description
          app_client_name
          app_client_logo
          app_key
          is_disable
          organization {
            id
            is_subscribe
            org_name
          }
        }
        paginatorInfo 
        {
          firstItem
          lastItem
        }
      }
    }
`;