import { gql } from "@apollo/client";

export const deleteResourceTypeAttributeTypeMutation = gql`
  mutation DeleteResourceTypeAttributeType($resTypeId: String!, $fieldId: String!){
    deleteResourceTypeAttributeType(
      input: {
        config_item_id: $resTypeId
        config_sub_item_id: $fieldId
      }
    )
  }
`;