import { gql } from "@apollo/client"

export const ResourceTypeAttributeTypeByResTypeQuery = gql`
  query ResourceTypeAttributeTypeByResType($resTypeId: String!){
    resourceTypeAttributeTypeByResType(res_type_id: $resTypeId) {
      id
      linked_id
      res_type_id
      res_type_attribute_type_mandatory
      res_type_attribute_type_printable
      res_type_attribute_type_editable
      res_type_attribute_type_hidden
      res_type_attribute_type_speech_input
      res_type_attribute_type_default_value
      res_type_attribute_type_formula
      res_type_attribute_type_group
      res_type_attribute_type_detail
      specification {
        id
        res_type_attribute_name
        res_type_attribute_type
        res_type_attribute_constraints
        res_type_attribute_description
        res_type_attribute_code
      }
    }
  }
`;
