import { Divider, Drawer, Space } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  SettingOutlined,
  ProfileOutlined,
  PlusCircleOutlined,
  PlayCircleOutlined
} from "@ant-design/icons";
import { fetchApplication } from "../slices/applicationSlice";
import { Link, Route, useParams } from 'react-router-dom';
import "./ApplicationEdit.css"
import TablesList from './TablesList';
import TableEdit from './TableEdit';
import TableCreate from './TableCreate';
import { ApplicationDetailsEdit } from './ApplicationDetailsEdit';

const ApplicationEdit : React.FC = (props) => {
  const dispatch = useAppDispatch();
  const [drawerContent, setDrawerContent] = useState<ReactNode>()
  const [drawerTitle, setDrawerTitle] = useState<string>('')
  const [visible, setVisible] = useState(false);
  const { appId } = useParams<{ appId: string; }>();
  const application = useAppSelector(state => state.application)

  const onDrawerInfosHandle = (elt: ReactNode, drawerTitle: string) => {
    setDrawerTitle(drawerTitle)
    setDrawerContent(elt)
    setVisible(true);
  }

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(fetchApplication({appId}))
  }, [dispatch, appId]);

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h3>Edit app <b>{application.data?.app_name}</b></h3>
          <div style={{textAlign: 'center', paddingBottom: '10px'}}>
            <Space size={32} wrap={true} align="start">
              <a href={"/view/applications/"+appId+"/view"}>
                <div className="app-edit-nav">
                <PlayCircleOutlined />
                  <h4>Open</h4>
                </div>
              </a>
              <Link to={"/applications/"+appId}>
                <div className="app-edit-nav">
                  <SettingOutlined />
                  <h4>Settings</h4>
                </div>
              </Link>
              <Link to={"/applications/"+appId+"/tables"}>
                <div className="app-edit-nav">
                  <ProfileOutlined />
                  <h4>Tables</h4>
                </div>
              </Link>
              <Link to={"/applications/"+appId+"/create/tables"}>
                <div className="app-edit-nav">
                  <PlusCircleOutlined />
                  <h4>New table</h4>
                </div>
              </Link>
            </Space>
          </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div>
          <Route path="/applications/:appId" exact>
            <ApplicationDetailsEdit />
          </Route>
          <Route path="/applications/:appId/tables" exact>
            <TablesList />
          </Route>
          <Route path="/applications/:appId/tables/:tableId">
            <TableEdit onDrawerInfosHandle={onDrawerInfosHandle} />
          </Route>
          <Route path="/applications/:appId/create/tables" exact>
            <TableCreate />
          </Route>
        </div>
        <Drawer title={drawerTitle} width={500} placement="right" onClose={onClose} visible={visible}>
          {drawerContent}
        </Drawer>
      </div>
    </div>
  )
}

export default ApplicationEdit
