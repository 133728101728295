import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { MeQuery } from "../queries/me";
import { apolloClient } from "../apolloClient";
import { Me, Me_me } from "../queries/__generated__/Me";

type State = {
  loading: boolean;
  data: Me_me | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchMe = createAsyncThunk("me/fetch", () =>
  apolloClient.query<Me>({ query: MeQuery }).then((res) => res.data.me)
);

const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMe.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchMe.fulfilled.type]: (state, action) => {
      state.data = action.payload;
    },
    [fetchMe.rejected.type]: (_, action) => {
      console.error(action);
    },
  },
});

const { reducer, actions } = meSlice;

export const meReducer = reducer;
