import { gql } from "@apollo/client"

export const ResourceTypeAttributesQuery = gql`
  query ResourceTypeAttributes($orgId: String!){
    resourceTypeAttributesAll(org_id: $orgId) {
      id
      res_type_attribute_name
      res_type_attribute_description
      res_type_attribute_type
      res_type_attribute_constraints
    }
  }
`;
