import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { ResourceRecordsQuery } from "../queries/resourceRecords";
import {
  ResourceRecords,
  ResourceRecordsVariables,
  ResourceRecords_resourceTypeRecords,
} from "../queries/__generated__/ResourceRecords";

type State = {
  loading: boolean;
  data: ResourceRecords_resourceTypeRecords | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchResourceRecords = createAsyncThunk(
  "resourceRecords/fetch",
  async (arg: { resourceTypeId: string; orgId: string; drilledRecordId?: string; resTypeResourceId?: string; selectedFilterId?: string}, { getState }) => {
    const res = await apolloClient
      .query<ResourceRecords, ResourceRecordsVariables>({
        query: ResourceRecordsQuery,
        variables: {
          resourceTypeId: arg.resourceTypeId,
          orgId: arg.orgId,
          drilledRecordId: arg.drilledRecordId,
          resTypeResourceId: arg.resTypeResourceId,
          selectedFilterId: arg.selectedFilterId,
          first: 1000,
          page: 1,
        }, // TODO pagination & OrgID
      });

    return res.data.resourceTypeRecords;
  }
);

const meSlice = createSlice({
  name: "resourceRecords",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResourceRecords.pending.type]: (state, _) => {
      state.loading = true;
      state.data = null;
    },
    [fetchResourceRecords.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceRecords.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = meSlice;

export const resourceRecordsReducer = reducer;
