import {gql} from "@apollo/client";

export const ResourceTypeRecordBinaryDataQuery = gql`
  query ResourceTypeRecordBinaryData(
    $first: Int!, 
    $recordId: String!
    $resourceTypeId: String!,
    $orgId: String!,
  ){
    resourceTypeRecordBinaryData(
      input: {
        record_id: $recordId
        res_type_id: $resourceTypeId
        org_id: $orgId
      },
      first: $first
      ) {
      data {
          id
          filename
          size
          type
          public_access
          mimetype
          system_filename
          description
        }
        paginatorInfo 
        {
          firstItem
          lastItem
        }
      }
    }
`;