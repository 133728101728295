import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { ResourceTypes, ResourceTypesVariables, ResourceTypes_resourceTypesAll } from "../queries/__generated__/ResourceTypes";
import { ResourceTypesQuery } from "../queries/resourceTypes";

type State = {
  loading: boolean;
  data: ResourceTypes_resourceTypesAll[] | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchResourceTypes = createAsyncThunk("resourceTypes/fetch", async (arg: {orgId: string}) => {
  const res = await apolloClient.query<ResourceTypes, ResourceTypesVariables>({ 
    query: ResourceTypesQuery, 
    variables: {
      orgId: arg.orgId
    } 
  });
  return res.data.resourceTypesAll
  }
);

const resourceTypesSlice = createSlice({
  name: "resourceTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResourceTypes.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchResourceTypes.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceTypes.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = resourceTypesSlice;

export const resourceTypesReducer = reducer;
