import { Button, Input, Table } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from 'antd/lib/table';

export interface ILineOption {
  name: string;
  value: string;
}

interface ILineOptionInternal {
  thisIsThatExtraRow: boolean;
}

interface Props {
  onLineAdd: (item: ILineOption) => void;
  onLineRemove: (index: number, item: ILineOption) => void;
  dataOptions: ILineOption[]
}

const NameValueOptions: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<string>('');
  
  const isNormalRow = (
    row: ILineOption | ILineOptionInternal
  ): row is ILineOption => !("thisIsThatExtraRow" in row);
  
  const columns: ColumnsType<ILineOption | ILineOptionInternal
  > = [
    {
      title: 'Pos.',
      dataIndex: 'position',
      key: 'position',
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusOutlined />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <div style={{display: 'flex'}}>
                <Input required placeholder='Name ...' onChange={e => setName(e.target.value)}/>
                <Input required placeholder='Value ...' onChange={e => setValue(e.target.value)} />
                <Button 
                  type='primary'
                  disabled={(name && value) ? false : true}
                  onClick={() => onItemAdd({name, value})}
                  >Add</Button>
              </div>
            ),
          }
        );
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (_, row, index) => {
        if (isNormalRow(row)) {
          return (
            <React.Fragment>
              {row.value}
            </React.Fragment>
          );
        } else {
          // collapsed in "extra" row
          return { props: { colSpan: 0 } };
        }
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: "actions",
        render: (_, row, index) =>
          isNormalRow(row) && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => onItemDelete(index, row)}
                style={{ marginRight: "10px" }}
              />
            </div>
          ),
    }
  ];

  const onItemAdd = (item: ILineOption) => {
    props.onLineAdd(item)
  }
  const onItemDelete = (index: number, item: ILineOption) => {
    props.onLineRemove(index, item)
  }

  const lineOptionsWithExtra: (ILineOption | ILineOptionInternal)[] = props.dataOptions ? [...props.dataOptions, {thisIsThatExtraRow: true}] : [];
  
  return <Table dataSource={lineOptionsWithExtra} columns={columns} />;
};

export default NameValueOptions;
