import { Button, Input, Table, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import LineResTypeAttributeTypeSearch, { IResourceTypeAttributeType } from './LineResTypeAttributeTypeSearch';
import { ILineResourceTypeFilter } from './UxLineResourceTypeFiltersTable';
import { useForm } from 'antd/lib/form/Form';
import { ILineResourceTypeAttribute } from './UxLineResourceTypeAttributesTable';

interface Props{
  filter: ILineResourceTypeFilter;
  onLineAdd: (item: ILineResourceTypeFilterCondition) => void
  onLineRemove: (index: number) => void
  onLineChange: (index: number, newItem: ILineResourceTypeFilterCondition) => void
}

export interface ILineResourceTypeFilterCondition {
  id: string | null;
  res_type_filter_id: string | null;
  res_type_attribute_type_id: string | null;
  res_type_filter_attribute_condition: string | null;
  specification: IResourceTypeAttributeType;
}

interface ILineResourceTypeFilterConditionInternal {
  thisIsThatExtraRow: boolean;
}

const isNormalRow = (row: ILineResourceTypeFilterCondition | ILineResourceTypeFilterConditionInternal): row is ILineResourceTypeFilterCondition =>
  !("thisIsThatExtraRow" in row);

const UxLineResTypeFilterCondition: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypeFilterConditionsTable');
  const [form] = useForm();

  const onLineResourceTypeFilterConditionChange = (index: number) => {
    const allResourceTypeFilterConditions = Object.entries(form.getFieldsValue()).map(
      ([_, entry]) => entry
    );
    const changedLine = allResourceTypeFilterConditions[index] as ILineResourceTypeFilterCondition;
    props.onLineChange(index, {
      ...props.filter.attributes![index],
      ...changedLine,
    });
  }

  const onLineResourceTypeFilterConditionAdd = (item: ILineResourceTypeAttribute) => {
    const newItem: ILineResourceTypeFilterCondition = {
      id: null,
      res_type_filter_id: null,
      res_type_attribute_type_id: item.id,
      res_type_filter_attribute_condition: null,
      specification: {
        id: item.id!,
        res_type_id: item.res_type_id!,
        specification: {
          id: item.specification.id!,
          res_type_attribute_name: item.specification.res_type_attribute_name
        }
      }
    }
    props.onLineAdd(newItem)
  }

  const onLineResourceTypeFilterConditionRemove = (index: number) => {
    form.resetFields()
    props.onLineRemove(index)
  }

  const columns: ColumnsType<ILineResourceTypeFilterCondition | ILineResourceTypeFilterConditionInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusOutlined />;
      },
    },
    {
      title: t("Filter Name"),
      key: "res_type_attribute_type_id",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.specification.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <LineResTypeAttributeTypeSearch
                onLineResourceTypeAttributeTypeAdd={onLineResourceTypeFilterConditionAdd}
                resTypeId={props.filter.res_type_id}
                excludeResTypeAttributes={props.filter.attributes ? props.filter.attributes?.filter(item => item.specification.id && item.specification.id.length > 0).map(item => item.specification.id!) : []}
              />
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Condition"),
      key: "res_type_filter_attribute_condition",
      render: (_, row, index) => {
        return isNormalRow(row) ? (
          <Form.Item required name={[index, "res_type_filter_attribute_condition"]} initialValue={row.res_type_filter_attribute_condition}>
            <Input type={"text"} onChange={(e) => onLineResourceTypeFilterConditionChange(index)} />
          </Form.Item>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <Popconfirm title="Sure to delete?" onConfirm={() => onLineResourceTypeFilterConditionRemove(index)}>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              style={{marginRight: '10px'}}
            />
          </Popconfirm>
        ),
    },
  ];

  const lineResourceTypeFilterConditionWithExtra: (ILineResourceTypeFilterCondition | ILineResourceTypeFilterConditionInternal)[] = props.filter.attributes ? [
    ...props.filter.attributes,
    { thisIsThatExtraRow: true },
  ] : [];

  useEffect(() => {
    if (props.filter?.attributes?.length) {
      props.filter?.attributes.forEach((attr, i) => {
        form.setFields([{ name: [i, 'res_type_filter_attribute_condition'], value: attr.res_type_filter_attribute_condition }]);
      });
    }
  }, [props]);

  return (
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypeFilterConditionWithExtra}
        pagination={false}
        size="small"
      />
    </Form>
  )
}

export default UxLineResTypeFilterCondition