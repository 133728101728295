import { Card, Col, Row, Statistic } from "antd";
import { ArrowUpOutlined } from '@ant-design/icons';
import { isNil } from "lodash";
import React from "react";
import Chart from "./Chart";

export interface IChart {
  id: string;
  res_type_chart_name: string;
  res_type_chart_description: string;
  res_type_attribute_type_id_X: string;
  res_type_attribute_type_id_Y: string;
  grp_function_id: string;
  res_type_id: string;
  chart_type: string;
  attribute_x: {
    specification: {
      res_type_attribute_code: string;
      res_type_attribute_name: string;
    };
  };
  attribute_y: {
    specification: {
      res_type_attribute_code: string;
      res_type_attribute_name: string;
    };
  };
  grp_function: {
    grp_function_name: string;
  };
}

interface Props {
  chartsList: IChart[];
  statisticsList: any;
  orgId: string | undefined;
  resTypeId: string;
  filterId: string | undefined;
}

const ChartStats: React.FC<Props> = (props) => {
  const statistics = !isNil(props.statisticsList) && typeof(props.statisticsList) === 'string' 
    ? JSON.parse(props.statisticsList)
    : null;
  return (
    <>
      <Row gutter={15} style={{paddingBottom: 35}}>
        {props.chartsList.map((chart: IChart) => (
          !isNil(chart.attribute_x && chart.attribute_y)  && (props.resTypeId == chart.res_type_id) ? <Col style={{ height: 250, width: 400 }}>
          <Chart
            chartDetail={chart}
            orgId={props.orgId}
            resTypeId={props.resTypeId}
            selectedChartId={chart.id}
            filterId={props.filterId}
          />
        </Col> : null
        ))}
        {!isNil(statistics)
          ? statistics["aaData"].map((stat: any) => (
              <Col style={{ height: 250 }}>
                <Card>
                  <Statistic
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ArrowUpOutlined />}
                    title={stat["Name"]}
                    value={parseFloat(stat["Value"])}
                    precision={0}
                    suffix={stat["Description"]}
                  />
                </Card>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
};

export default ChartStats;
