import { gql } from "@apollo/client"

export const ResourceTypeStatisticsByResTypeQuery = gql`
  query ResourceTypeStatisticsByResType($resTypeId: String!){
    resourceTypeStatisticsByResType(res_type_id: $resTypeId) {
      id
      res_type_id
      res_type_statistics_name
      res_type_statistics_description
      res_type_attribute_type_id
      grp_function_id
      grp_function{
        grp_function_name
      }
      attribute{
        specification {
          res_type_attribute_name
        }
      }
    }
  }
`;
