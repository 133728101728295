import { gql } from "@apollo/client";

export const createResourceTypeFilterMutation = gql`
  mutation CreateResourceTypeFilter($res_type_id: String!,
    $attributes: [CustomResourceTypeFilterAttributeCreateInput],
    $res_type_filter_name: String!,
    $res_type_filter_description: String,
    $res_type_filter_logicaloperator: String) {
    createResourceTypeFilter(input: {
      res_type_id: $res_type_id
      res_type_filter_name: $res_type_filter_name
      res_type_filter_description: $res_type_filter_description
      res_type_filter_logicaloperator: $res_type_filter_logicaloperator
      attributes: $attributes
    })
  }
`;
