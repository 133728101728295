import { Alert, Button, Card, Col, Row } from 'antd'
import { PrinterOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import React from 'react'

const InvoiceDetails: React.FC = () => {
  return (
    <div>
      <h2>Invoice Details</h2>
      <Row gutter={40}>
        <Col span={6}>
          <Card title={<h2 style={{textAlign: 'center', margin: '0px'}}>$ 4.99</h2>}>
            <Alert message="Paid" type="success" showIcon />
            <div style={{textAlign: 'center', marginTop: '10px'}}>
              <Button type="primary" size='large' style={{marginRight: '10px'}}><PrinterOutlined /> Print</Button>
              <Button type="primary" size='large'><CloudDownloadOutlined /> Download</Button>
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <div style={{border: "1px solid lightgray", backgroundColor: "white"}}>
            <ul>
              <li>Details 1</li>
              <li>Details 2</li>
              <li>Details 3</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default InvoiceDetails