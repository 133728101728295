import { Button, Form, Input, message, Modal, Select, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ILineResourceTypeChart } from "./UxLineResourceTypeChartsTable";
import { createResourceTypeChartMutation } from "../../mutations/createResourceTypeChart";
import { useMutation } from "@apollo/client";
import {
  CreateResourceTypeChart,
  CreateResourceTypeChartVariables,
} from "../../mutations/__generated__/CreateResourceTypeChart";
import {
  UpdateResourceTypeChart,
  UpdateResourceTypeChartVariables,
} from "../../mutations/__generated__/UpdateResourceTypeChart";
import { updateResourceTypeChartMutation } from "../../mutations/updateResourceTypeChart";
import { ResourceTypeChartByResTypeQuery } from "../../queries/resourceTypeChartByResType";
import ResourceTypeAttributeTypeSelect from "./LineResTypeAttributeTypeSelect";
import GroupingFunctionSelect from "./LineGroupingFunctionSelect";

interface Props {
  visible: boolean;
  orgId: string | null;
  mode: "create" | "edit";
  initialChart: ILineResourceTypeChart;
  resTypeId: string;
  onClose: () => void;
}

const UxResTypeChartForm: React.FC<Props> = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [step, setStep] = useState("properties");
  const [CreateResourceTypeChart, { loading: loadingSaving }] = useMutation<
    CreateResourceTypeChart,
    CreateResourceTypeChartVariables
  >(createResourceTypeChartMutation);
  const [UpdateResourceTypeChart, { loading: loadingUpdating }] = useMutation<
    UpdateResourceTypeChart,
    UpdateResourceTypeChartVariables
  >(updateResourceTypeChartMutation);
  const [chart, setChart] = useState<ILineResourceTypeChart>(
    props.initialChart
  );
  const [form] = useForm();

  const chartTypes = ["line", "bar", "pie", "area", "column"]

  const onClose = () => {
    setStep("properties");
    props.onClose();
  };

  const onSave = () => {
    try {
      if (props.mode === "create") {
        const input = {
          res_type_id: props.resTypeId,
          res_type_chart_name: chart.res_type_chart_name,
          res_type_chart_description: chart.res_type_chart_description,
          res_type_attribute_type_id_X: chart.res_type_attribute_type_id_X,
          res_type_attribute_type_id_Y: chart.res_type_attribute_type_id_Y,
          grp_function_id: chart.grp_function_id,
          chart_type: chart.chart_type
        };
        let res = CreateResourceTypeChart({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeChartByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.createResourceTypeChart);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to create a chart");
            console.error(raison);
          });
      } else {
        const input = {
          config_sub_item_id: chart.id!,
          res_type_chart_name: chart.res_type_chart_name,
          res_type_chart_description: chart.res_type_chart_description,
          res_type_attribute_type_id_X: chart.res_type_attribute_type_id_X,
          res_type_attribute_type_id_Y: chart.res_type_attribute_type_id_Y,
          grp_function_id: chart.grp_function_id,
          chart_type: chart.chart_type
        };
        let res = UpdateResourceTypeChart({
          variables: input,
          refetchQueries: [
            {
              query: ResourceTypeChartByResTypeQuery,
              variables: {
                resTypeId: props.resTypeId,
              },
            },
          ],
        });
        res
          .then((res) => {
            message.success(res.data?.updateResourceTypeChart);
            onClose();
          })
          .catch((raison) => {
            message.error("Failed to update a chart");
            console.error(raison);
          });
      }
    } catch (error) {
      message.error("Failed to update a chart");
      console.error(error);
    }
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    setChart(props.initialChart);
    form.setFieldsValue(props.initialChart);
  }, [props]);

  return (
    <Modal
      title={props.mode + " chart"}
      visible={props.visible}
      onCancel={onClose}
      width="850px"
      footer={[
        <>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={props.mode === "create" ? loadingSaving : loadingUpdating}
          >
            {"Save Chart"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
    >
      <Form {...formLayout} form={form} onFinish={() => onSave()}>
        <Form.Item
          label={"Chart name"}
          name="res_type_chart_name"
          required
        >
          <Input
            onChange={(elt) =>
              setChart({
                ...chart,
                res_type_chart_name: elt.target.value,
              })
            }
          />
        </Form.Item>
        <ResourceTypeAttributeTypeSelect label="Attribute X"
          name="res_type_attribute_type_id_X" onChange={(val: string) => 
            setChart({
              ...chart,
              res_type_attribute_type_id_X: val,
            })} resTypeId={props.resTypeId} />
        <ResourceTypeAttributeTypeSelect label="Attribute Y"
          name="res_type_attribute_type_id_Y" onChange={(val: string) => 
            setChart({
              ...chart,
              res_type_attribute_type_id_Y: val,
            })} resTypeId={props.resTypeId} />
        <GroupingFunctionSelect label="Agregation"
        name="grp_function_id" onChange={(val: string) => 
            setChart({
              ...chart,
              grp_function_id: val,
            })} />
        <Form.Item
          label="Chart type"
          name="chart_type"
          required
        >
          <Select onChange={(val: string) => 
              setChart({
                ...chart,
                chart_type: val,
              })}>
            {chartTypes.map(type => <Option value={type}>{type}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={"Description"}
          name="res_type_chart_description"
        >
          <TextArea
            onChange={(elt) =>
              setChart({
                ...chart,
                res_type_chart_description: elt.target.value,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UxResTypeChartForm;
