import { Button, Drawer, Table, Space, Tag, Popconfirm, message } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import type { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { ResourceTypeRecordBinaryData, ResourceTypeRecordBinaryDataVariables, ResourceTypeRecordBinaryData_resourceTypeRecordBinaryData_data as IResourceTypeRecordBinaryData } from '../queries/__generated__/ResourceTypeRecordBinaryData';
import { ResourceTypeRecordBinaryDataQuery } from '../queries/resourceTypeRecordBinaryData';
import DisplayBinaryIcon from './DisplayBinaryIcon';
import { GalleryUploadManyFiles } from './GalleryUploadManyFiles';
import { DestroyResourceTypeRecordBinaryData, DestroyResourceTypeRecordBinaryDataVariables } from '../mutations/__generated__/DestroyResourceTypeRecordBinaryData';
import { DestroyResourceTypeRecordBinaryDataMutation } from '../mutations/destroyResourceTypeRecordBinaryData';
import { fetchResourceRecords } from '../slices/resourceRecordsSlice';

interface ResourceLinkParams {
  recordId: string;
  applicationId: string;
  resourceTypeId: string;
}

interface Props {
  filterId: string | undefined;
}

export const ResourceRecordFiles: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(
    (state) => state.application.data?.organization?.id
  );
  const { resourceTypeId, recordId, applicationId } =
    useParams<ResourceLinkParams>();

  const { data, loading, error } = useQuery<
    ResourceTypeRecordBinaryData,
    ResourceTypeRecordBinaryDataVariables
  >(ResourceTypeRecordBinaryDataQuery, {
    variables: {
      recordId: recordId,
      resourceTypeId: resourceTypeId,
      orgId: orgId!,
      first: 10,
    },
  });

  const [DestroyResourceTypeRecordBinaryData] = useMutation<
  DestroyResourceTypeRecordBinaryData,
  DestroyResourceTypeRecordBinaryDataVariables
  >(DestroyResourceTypeRecordBinaryDataMutation);

  const onDeleteBinaryData = async (binaryDataId: string) => {
      try {
          const input: DestroyResourceTypeRecordBinaryDataVariables = {
            recordId: recordId,
            resTypeId: resourceTypeId,
            orgId: orgId!,
            binaryDataId
          };
          let res = await DestroyResourceTypeRecordBinaryData({ variables: input, refetchQueries: [{query: ResourceTypeRecordBinaryDataQuery, variables: {
            recordId: recordId,
            resourceTypeId: resourceTypeId,
            orgId: orgId,
            first: 10,
          },}] });
          message.success(res.data?.destroyResourceTypeRecordBinaryData);    
          dispatch(fetchResourceRecords({ orgId: orgId!, resourceTypeId, selectedFilterId: props.filterId }));
      } catch (error) {
        message.error("Failed to delete binary data");
        console.error(error);
      }
  };


const columns: ColumnsType<IResourceTypeRecordBinaryData> = [
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
    render: (_, record) => <DisplayBinaryIcon ids={record.id} />,
  },
  {
    title: 'Filename',
    dataIndex: 'filename',
    key: 'filename',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Size',
    key: 'size',
    dataIndex: 'size',
  },
  {
    title: 'Public',
    key: 'public_access',
    dataIndex: 'public_access',
    render: (_, record) => record.public_access ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Popconfirm placement="top" title="Sure to delete file?" onConfirm={() => onDeleteBinaryData(record.id)}>
        <Button size="middle">
          <DeleteOutlined /> Delete
        </Button>
      </Popconfirm>
    ),
  },
];

  const onClose = () =>
  history.push(
    `/view/applications/${applicationId}/tables/${resourceTypeId}`
  );
  return (
        <Drawer
          footer={
            <div
              style={{
                textAlign: "left",
              }}
            >
              <Button onClick={onClose} type="primary">
                Close
              </Button>
            </div>
          }
          onClose={onClose}
          visible={true}
          width="60%"
        >
          <h3>Document gallery</h3>
          {<GalleryUploadManyFiles disabled={false} recordId={recordId} orgId={orgId} resourceTypeId={resourceTypeId}/>}
          {<Table columns={columns} dataSource={data?.resourceTypeRecordBinaryData?.data} />}
        </Drawer>
  )
}
