import { Modal } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom';

interface Props{
  open: boolean;
  handleCloseModal: () => void;
}

const RestrictionModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const hideRestrictionModal = () => {
    props.handleCloseModal();
  };

  return (
    <Modal
      title="Restriction"
      visible={props.open}
      onOk={() => history.push('/billing')}
      onCancel={hideRestrictionModal}
      okText="Open billing page"
      cancelText="Cancel"
    >
      <p>You must first subscribe your organization to <b>Starter Plan</b>.</p>
    </Modal>
  )
}

export default RestrictionModal