import React from "react";
import { Form, Table, Button, Input, Tooltip, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import LineResourceTypeSearchAndAdd, { INewLineResourceType, INewResourceType } from "./LineResourceTypeSearchAndAdd";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusOutlined, DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';
import { ILineResourceTypeAttribute } from "./UxLineResourceTypeAttributesTable";
import { IconList, IconPicker, IconPickerItem } from "react-fa-icon-picker";
import { iconPickerBtnStyle, iconPickerContainerStyles } from "../../pages/ApplicationDetailsEdit";
import { fontAwesomeList } from "../helpers/iconList";
import { isNil } from "lodash";

export interface ILineResourceType {
  id: string | null;
  res_type_description: string | null;
  res_type_name: string;
  res_type_icon: string | null;
  attributes: ILineResourceTypeAttribute[];
}

interface ILineResourceTypeInternal {
  thisIsThatExtraRow: boolean;
}

interface ILineFormValues extends Partial<ILineResourceType> {
  res_type_description: string;
  res_type_icon?: string;
}

interface Props {
  orgId: string;
  lineResourceTypes: ILineResourceType[];
  onLineAdd: (item: ILineResourceType) => void;
  onLineRemove: (index: number, item: ILineResourceType) => void;
  onLineChange: (index: number, item: ILineResourceType) => void;
  onFieldsTabOpen: (item: ILineResourceType, index: number) => void;
}

const isNormalRow = (row: ILineResourceType | ILineResourceTypeInternal): row is ILineResourceType =>
  !("thisIsThatExtraRow" in row);

const UxLineResourceTypesTable: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypesTable');
  const [form] = useForm();

  const onLineResourceTypeAdd = (item: INewLineResourceType) => {
    const newItem: ILineResourceType =
      "id" in item
        ? {
            id: item.id,
            res_type_description: item.res_type_description || "",
            res_type_name: item.res_type_name || "",
            res_type_icon: item.res_type_icon || "",
            attributes: [],
          }
        : {
            id: null,
            res_type_description: "",
            res_type_name: (item as INewResourceType).res_type_name,
            res_type_icon: "Boxes",
            attributes: [],
          };

    props.onLineAdd(newItem);
  };

  const onItemDelete = (index: number, item: ILineResourceType) => {
    props.onLineRemove(index, item);
  };

  const onFieldsTabOpen = (item: ILineResourceType, index: number) => {
    props.onFieldsTabOpen(item, index)
  }

  const onLineChanged = (index: number) => {
    const allFields = Object.entries(form.getFieldsValue()).map(([_, entry]) => entry);
    
    const changedLine = allFields[index] as ILineFormValues;

    props.onLineChange(index, { ...props.lineResourceTypes[index], ...changedLine });
  };

  const columns: ColumnsType<ILineResourceType | ILineResourceTypeInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusOutlined />;
      },
    },
    {
      title: t("Name"),
      key: "res_type_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_name}
            <br />
            <small>{row.res_type_description}</small>
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <LineResourceTypeSearchAndAdd
                orgId={props.orgId}
                excludeResTypes={props.lineResourceTypes.filter(item => item.id && item.id.length > 0).map(item => item.id!)}
                onLineResourceTypeAdd={onLineResourceTypeAdd}
                inputStyle={{ width: "300px" }}
              />
            ),
          }
        );
      },
    },
    {
      title: t("Description"),
      key: "res_type_description",
      render: (_, row, index) => {
        return isNormalRow(row) ? (
          <Form.Item name={[index, "res_type_description"]} initialValue={row.res_type_description}>
            <Input onChange={() => onLineChanged(index)} />
          </Form.Item>
        ) : (
          { props: { colSpan: 0 } }
        );
      },
    },
    {
      width: "15%",
      title: t("Icon"),
      key: "res_type_icon",
      render: (_, row, index) => {
        if (isNormalRow(row)) {
          if (row.id === null) {
            // only rows with brand new article can define "Unit"
            return (
              <Form.Item name={[index, "res_type_icon"]} initialValue={row.res_type_icon}>
                <IconPicker
                  value={''}
                  containerStyles={{...iconPickerContainerStyles, position: 'fixed'}}
                  buttonStyles={iconPickerBtnStyle}
                  buttonIconStyles={{ display: "contents" }}
                  onChange={() => onLineChanged(index)}
                />
              </Form.Item>
            );
          } else {
            return <React.Fragment><IconPickerItem icon={!isNil(row.res_type_icon) && fontAwesomeList.includes(row.res_type_icon) ? row.res_type_icon as IconList : "FaThLarge"} color="dimgray" size={30} containerStyles={{display: 'flex'}} /></React.Fragment>;
          }
        } else {
          // collapsed in "extra" row
          return { props: { colSpan: 0 } };
        }
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{marginRight: '10px'}}
              />
            </Popconfirm>
            <Tooltip placement="right" title="Add fields">
              <Button
                type="primary"
                icon={<UnorderedListOutlined />}
                onClick={() => onFieldsTabOpen(row, index)}
              />
            </Tooltip>
          </div>
        ),
    },
  ];

  const lineResourceTypesWithExtra: (ILineResourceType | ILineResourceTypeInternal)[] = [
    ...props.lineResourceTypes,
    { thisIsThatExtraRow: true },
  ];

  return (
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypesWithExtra}
        pagination={false}
        size="small"
      />
    </Form>
  );
};

export default UxLineResourceTypesTable;
