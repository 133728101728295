import { gql } from "@apollo/client";

export const CreateAppFromTemplateMutation = gql`
    mutation CreateAppFromTemplate($orgId: String, $appTempId: String, $appName: String, $demoData: Boolean){
    createAppFromTemplate(input: {
      org_id: $orgId
      app_temp_id: $appTempId
      app_name: $appName
      demo_data: $demoData
    }){
      id
      message
    } 
  }
`;