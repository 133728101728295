import { Button, Descriptions, Drawer, Spin } from "antd";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchApplicationDetails_application_resourceTypes } from "../queries/__generated__/fetchApplicationDetails";
import { fetchResourceRecord } from "../slices/resourceRecordSlice";

interface ResourceLinkParams {
  applicationId: string,
  linkResourceId: string;
  linkId: string;
  resourceTypeId: string;
}

export function resourceDescriptions(
  resourceType: fetchApplicationDetails_application_resourceTypes,
  resourceRecord: any
) {
  const items = [...resourceType.attributes]
    // order by res_type_attribute_type_order
    .sort(
      (a, b) =>
        (a.res_type_attribute_type_order ?? 0) -
        (b.res_type_attribute_type_order ?? 0)
    )
    .map((attribute) => {
      return (
        <Descriptions.Item
          label={attribute.specification.res_type_attribute_name}
        >
          {resourceRecord[attribute.specification.res_type_attribute_code]}
        </Descriptions.Item>
      );
    });

  return <Descriptions layout="vertical" bordered>{items}</Descriptions>;
}

export const ResourceLinkDetail: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const orgId = useAppSelector((state) => state.application.data?.organization?.id)
  const { applicationId, resourceTypeId, linkResourceId, linkId } =
    useParams<ResourceLinkParams>();

  useEffect(() => {
    if(orgId){
      dispatch(
        fetchResourceRecord({
          orgId,
          resourceTypeId: linkResourceId,
          recordId: linkId,
        })
      );
    }
  }, [linkResourceId, linkId, orgId]);

  const resourceTypeState = useAppSelector((state) =>
    state.application.data?.resourceTypes?.find((rt) => rt.id === linkResourceId)
  );
  const linkState = useAppSelector((state) => state.resourceRecord);

  const onClose = () => history.push(`/view/applications/${applicationId}/tables/${resourceTypeId}`);

  return (
    <Drawer
      footer={<div
        style={{
          textAlign: 'left',
        }}
      >
        <Button onClick={onClose} type="primary">
          Close
        </Button>
      </div>}
      onClose={onClose}
      visible={true}
      width="40%"
    >
      {resourceTypeState && linkState.data ? (
        <div>
          <h2>{resourceTypeState.res_type_name}</h2>
          {resourceDescriptions(resourceTypeState, linkState.data)}
        </div>
      ) : (
        <Spin size="large" style={{ position: "absolute", left: "50%", top: "50%" }} />
      )}
    </Drawer>
  );
};
