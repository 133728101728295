import React, { useEffect, useState } from "react";
import { Button, AutoComplete, Input, Space } from "antd";

import { ResourceTypes_resourceTypesAll } from "../../queries/__generated__/ResourceTypes";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchResourceTypes } from "../../slices/resourceTypesSlice";

export type INewLineResourceType = IResourceType | INewResourceType;

export interface IResourceType {
  id: string | null;
  res_type_description: string | null;
  res_type_name: string;
  res_type_table_name: string;
  res_type_icon: string | null;
}

export interface INewResourceType {
  id: string | null,
  res_type_name: string;
  res_type_table_name: string;
  res_type_description: string | null;
  res_type_icon: string | null;
}

interface ISelection {
  resourceType: IResourceType;
  value: string;
}

interface Props {
  onLineResourceTypeAdd: (line: INewResourceType) => void;
  orgId: string;
  inputStyle?: React.CSSProperties;
  excludeResTypes: string[];
}

const ResourceTypeSearchAndAdd: React.FC<Props> = (props) => {
  const { t } = useTranslation('ResourceTypeSearchAndAdd');
  const { loading, data } = useAppSelector(state => state.resourceTypes);
  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch()
  const [selection, setSelection] = useState<IResourceType | null>(null);

  const options: (ISelection | {value: ''})[] = (data || []).filter(item => props.excludeResTypes.indexOf(item.id) < 0).map((resourceType: ResourceTypes_resourceTypesAll) => {
      return {
        resourceType: {
          id: resourceType.id!,
          res_type_description: resourceType.res_type_description,
          res_type_name: resourceType.res_type_name,
          res_type_table_name: resourceType.res_type_table_name,
          res_type_icon: resourceType.res_type_icon,
        },
        value: resourceType.res_type_name!,
      };
  });
  const onAdd = () => {
    if (selection) {
      props.onLineResourceTypeAdd(selection);
    } else if (search) {
      props.onLineResourceTypeAdd({id: null, res_type_description: null, res_type_icon: null, res_type_name: search, res_type_table_name: search.replace(' ', '_').toUpperCase() });
    }
  };

  const onSelect = (_: string, selected: any) => {
    setSelection((selected as ISelection).resourceType);
    setSearch(null);
  };

  const onSearch = (input: string) => {
    if (search !== input) { // only if input really changed
      // setSelection(null);
      if (input.trim() === "") {
        setSearch(null);
      } else {
        setSearch(input);
      }
    }
  };
  const onChange = (val: string) => {
    let existingResType = (data || []).filter(item => props.excludeResTypes.indexOf(item.id) < 0).find(item => item.res_type_name.trim().replace(/[' ']/g, '').toLocaleLowerCase() == val.trim().replace(/[' ']/g, '').toLocaleLowerCase())
    if(existingResType){
      setSelection({ id: existingResType.id,
      res_type_description: existingResType.res_type_description,
      res_type_name: existingResType.res_type_name,
      res_type_table_name: existingResType.res_type_table_name,
      res_type_icon: existingResType.res_type_icon})
    }else{
      setSelection(null);
    }
    setValue(val);
  };

  useEffect(() => {
    if(props.orgId){
      dispatch(fetchResourceTypes({orgId: props.orgId}))
    }
    setValue('');
  }, [props.orgId])

  const buttonContent =
    selection || search ? (selection ? t("Add selected") : t("Add new")) : t("Search");

  const buttonDisabled = !selection && !search;

  const dropShouldOpen = search !== null;

  return (
    <Space direction="horizontal" size="small">
      <AutoComplete
        value={value}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(inputValue, option) =>
          inputValue.trim() !== "" &&
          option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        open={dropShouldOpen}
      >
        <Input
          placeholder={t("Search or add tables...")}
          style={props.inputStyle}
        />
      </AutoComplete>
      <Button
        type="primary"
        loading={loading}
        disabled={buttonDisabled}
        onClick={onAdd}
      >
        {buttonContent}
      </Button>
    </Space>
  );
};

export default ResourceTypeSearchAndAdd;
