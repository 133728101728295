import React from "react";
import { Form, Table, Button, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import ResourceTypeAttributeTypeSearchAndAdd from "./LineResTypeAttributeTypeSearch";
import { ILineResourceTypeAttribute } from "./UxLineResourceTypeAttributesTable";

interface ILineResourceTypeAttributeInternal {
  thisIsThatExtraRow: boolean;
}

export interface ILineFormValues extends Partial<ILineResourceTypeAttribute> {
  res_type_attribute_type?: string;
}

interface Props {
  onLineAdd: (item: ILineResourceTypeAttribute) => void;
  onLineRemove: (index: number, item: ILineResourceTypeAttribute) => void;
  attributes: ILineResourceTypeAttribute[];
  orgId: string;
  resTypeId: string;
}

const isNormalRow = (
  row: ILineResourceTypeAttribute | ILineResourceTypeAttributeInternal
): row is ILineResourceTypeAttribute => !("thisIsThatExtraRow" in row);


const SimpleUxLineResourceTypeAttributesTable: React.FC<Props> = (props) => {

  const { t } = useTranslation("UxLineResourceTypeAttributesTable");
  const [form] = useForm();

  const onLineResourceTypeAttributeAdd = ( item: ILineResourceTypeAttribute) => {
    props.onLineAdd(item);
  };

  const onItemDelete = (index: number, item: ILineResourceTypeAttribute) => {
    props.onLineRemove(index, item);
  };

  const columns: ColumnsType<
    ILineResourceTypeAttribute | ILineResourceTypeAttributeInternal
  > = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Field Name"),
      key: "res_type_attribute_name",
      render: (_, row) => {
        console.log('props.attributes')
        console.log(props.attributes)
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <ResourceTypeAttributeTypeSearchAndAdd
                excludeResTypeAttributes={props.attributes.filter(item => item.id && item.id.length > 0).map(item => item.specification.id!)}
                resTypeId={props.resTypeId}
                onLineResourceTypeAttributeTypeAdd={onLineResourceTypeAttributeAdd}
                inputStyle={{ width: "300px" }}
              />
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Type"),
      key: "res_type_attribute_type",
      render: (_, row, index) => {
        if (isNormalRow(row)) {
          return (
            <React.Fragment>
              {row.specification.res_type_attribute_type}
            </React.Fragment>
          );
        } else {
          // collapsed in "extra" row
          return { props: { colSpan: 0 } };
        }
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{ marginRight: "10px" }}
              />
            </Popconfirm>
          </div>
        ),
    },
  ];

  const lineResourceTypeAttributesWithExtra: (
    | ILineResourceTypeAttribute
    | ILineResourceTypeAttributeInternal
  )[] = props.attributes
      ? [...props.attributes, { thisIsThatExtraRow: true }]
      : [{ thisIsThatExtraRow: true }];

    return (
      <>
        <Form form={form}>
          <Table
            className="ux-line-items-table"
            columns={columns}
            dataSource={lineResourceTypeAttributesWithExtra}
            pagination={false}
            size="small"
          />
        </Form>
      </>
    );
};

export default SimpleUxLineResourceTypeAttributesTable;
