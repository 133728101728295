import React, { ReactNode, useEffect, useState } from "react";
import { Form, Button, Checkbox, Modal, Select, Input, Drawer, Row, Col, Slider, DatePicker, InputNumber, Badge, message, Alert, AutoComplete } from "antd";
import { useForm } from "antd/lib/form/Form";

import { ArrowRightOutlined, ArrowLeftOutlined, SaveOutlined, EditOutlined, QuestionCircleTwoTone } from "@ant-design/icons";

import { Tabs } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { ILineResourceTypeAttribute } from "./UxLineResourceTypeAttributesTable";
import { CreateOrUpdateResTypeField, CreateOrUpdateResTypeFieldVariables } from "../../mutations/__generated__/CreateOrUpdateResTypeField";
import { createOrUpdateResTypeFieldMutation } from "../../mutations/createOrUpdateResTypeField";
import NameValueOptions, { ILineOption } from "./NameValueOptions";
import GroupNameSelect from "./GroupNameSelect";
import LinkedFieldSelect from "./LinkedFieldSelect";
import { fetchGroupNamesQuery } from "../../queries/fetchGroupNames";
import { ResourceTypeAttributeTypeByResTypeQuery } from "../../queries/resourceTypeAttributeTypeByResType";
import { ResourceTypeAttributesByOrg, ResourceTypeAttributesByOrgVariables } from "../../queries/__generated__/ResourceTypeAttributesByOrg";
import { ResourceTypeAttributesByOrgQuery } from "../../queries/resourceTypeAttributeByOrg";

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  orgId: string | null;
  mode: "create" | "edit";
  saveMode: 'database' | 'state';
  properties: {label: string; value: string;}[];
  initialField: ILineResourceTypeAttribute;
  resTypeId: string;
  onLineSaveOrUpdate: (item: ILineResourceTypeAttribute) => void;
  onClose: () => void;
}
const UxResTypeFieldForm: React.FC<Props> = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [dataOptions, setDataOptions] = useState([]);
  const [form] = useForm();
  const [formContraints] = useForm()
  const [field, setField] = useState<ILineResourceTypeAttribute>(props.initialField);
  const [step, setStep] = useState("properties");
  const [drawerInfos, setDrawerInfos] = useState<{content: ReactNode; title: string}>({title: "", content: <p></p>});
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [CreateOrUpdateResTypeField, {loading}] = useMutation<CreateOrUpdateResTypeField, CreateOrUpdateResTypeFieldVariables>(createOrUpdateResTypeFieldMutation)

  const { data: dataAttributes, loading: loadingAttributes, error: errorAttributes } = useQuery<
    ResourceTypeAttributesByOrg,
    ResourceTypeAttributesByOrgVariables
  >(ResourceTypeAttributesByOrgQuery, {
    variables: {
      orgId: props.orgId!,
    },
    skip: props.orgId == null,
  });

  const types = [
    {label: 'Text',  value: 'text', support_contraints: true},
    {label: 'Date',  value: 'date', support_contraints: false},
    {label: 'Time',  value: 'time', support_contraints: false},
    {label: 'Datetime',  value: 'datetime', support_contraints: false},
    {label: 'Textarea',  value: 'textarea', support_contraints: true},
    {label: 'Select Range',  value: 'selectRange', support_contraints: true},
    {label: 'Select',  value: 'select', support_contraints: true},
    {label: 'Password',  value: 'password', support_contraints: false},
    {label: 'Email',  value: 'email', support_contraints: false},
    {label: 'Url',  value: 'url', support_contraints: false},
    {label: 'Select Year',  value: 'selectYear', support_contraints: false},
    {label: 'Select Month',  value: 'selectMonth', support_contraints: false},
    {label: 'Checkbox',  value: 'checkbox', support_contraints: false},
    {label: 'Radio',  value: 'radio', support_contraints: true},
    {label: 'Autocomplete',  value: 'autocomplete', support_contraints: true},
    {label: 'File',  value: 'file', support_contraints: false},
    {label: 'Audio',  value: 'audio', support_contraints: false},
    {label: 'Image',  value: 'image', support_contraints: false},
    {label: 'Number',  value: 'number', support_contraints: false},
  ];

  const semantic_types = 
  [{'label': 'address', 'value': 'address'},
  {'label': 'am pm', 'value': 'amPm'},
  {'label': 'asciify', 'value': 'asciify'},
  {'label': 'bank account number', 'value': 'bankAccountNumber'},
  {'label': 'biased number between', 'value': 'biasedNumberBetween'},
  {'label': 'boolean', 'value': 'boolean'},
  {'label': 'bothify', 'value': 'bothify'},
  {'label': 'bs', 'value': 'bs'},
  {'label': 'building number', 'value': 'buildingNumber'},
  {'label': 'catch phrase', 'value': 'catchPhrase'},
  {'label': 'century', 'value': 'century'},
  {'label': 'chrome', 'value': 'chrome'},
  {'label': 'city', 'value': 'city'},
  {'label': 'city suffix', 'value': 'citySuffix'},
  {'label': 'color name', 'value': 'colorName'},
  {'label': 'company', 'value': 'company'},
  {'label': 'company email', 'value': 'companyEmail'},
  {'label': 'company suffix', 'value': 'companySuffix'},
  {'label': 'country', 'value': 'country'},
  {'label': 'country code', 'value': 'countryCode'},
  {'label': 'country is oalpha3', 'value': 'countryISOAlpha3'},
  {'label': 'credit card details', 'value': 'creditCardDetails'},
  {'label': 'credit card expiration date', 'value': 'creditCardExpirationDate'},
  {'label': 'credit card expiration date string',
   'value': 'creditCardExpirationDateString'},
  {'label': 'credit card number', 'value': 'creditCardNumber'},
  {'label': 'credit card type', 'value': 'creditCardType'},
  {'label': 'currency code', 'value': 'currencyCode'},
  {'label': 'date', 'value': 'date'},
  {'label': 'date time', 'value': 'dateTime'},
  {'label': 'date time ad', 'value': 'dateTimeAD'},
  {'label': 'date time ad', 'value': 'dateTimeAd'},
  {'label': 'date time between', 'value': 'dateTimeBetween'},
  {'label': 'date time in interval', 'value': 'dateTimeInInterval'},
  {'label': 'date time this century', 'value': 'dateTimeThisCentury'},
  {'label': 'date time this decade', 'value': 'dateTimeThisDecade'},
  {'label': 'date time this month', 'value': 'dateTimeThisMonth'},
  {'label': 'date time this year', 'value': 'dateTimeThisYear'},
  {'label': 'day of month', 'value': 'dayOfMonth'},
  {'label': 'day of week', 'value': 'dayOfWeek'},
  {'label': 'domain name', 'value': 'domainName'},
  {'label': 'domain word', 'value': 'domainWord'},
  {'label': 'e164 phone number', 'value': 'e164PhoneNumber'},
  {'label': 'ean13', 'value': 'ean13'},
  {'label': 'ean8', 'value': 'ean8'},
  {'label': 'email', 'value': 'email'},
  {'label': 'file', 'value': 'file'},
  {'label': 'file extension', 'value': 'fileExtension'},
  {'label': 'firefox', 'value': 'firefox'},
  {'label': 'first name', 'value': 'firstName'},
  {'label': 'first name female', 'value': 'firstNameFemale'},
  {'label': 'first name male', 'value': 'firstNameMale'},
  {'label': 'free email', 'value': 'freeEmail'},
  {'label': 'free email domain', 'value': 'freeEmailDomain'},
  {'label': 'hex color', 'value': 'hexColor'},
  {'label': 'iban', 'value': 'iban'},
  {'label': 'image', 'value': 'image'},
  {'label': 'image url', 'value': 'imageUrl'},
  {'label': 'internet explorer', 'value': 'internetExplorer'},
  {'label': 'ipv4', 'value': 'ipv4'},
  {'label': 'ipv6', 'value': 'ipv6'},
  {'label': 'isbn10', 'value': 'isbn10'},
  {'label': 'isbn13', 'value': 'isbn13'},
  {'label': 'iso8601', 'value': 'iso8601'},
  {'label': 'job title', 'value': 'jobTitle'},
  {'label': 'language code', 'value': 'languageCode'},
  {'label': 'last name', 'value': 'lastName'},
  {'label': 'latitude', 'value': 'latitude'},
  {'label': 'lexify', 'value': 'lexify'},
  {'label': 'linux platform token', 'value': 'linuxPlatformToken'},
  {'label': 'linux processor', 'value': 'linuxProcessor'},
  {'label': 'locale', 'value': 'locale'},
  {'label': 'local ipv4', 'value': 'localIpv4'},
  {'label': 'longitude', 'value': 'longitude'},
  {'label': 'mac address', 'value': 'macAddress'},
  {'label': 'mac platform token', 'value': 'macPlatformToken'},
  {'label': 'mac processor', 'value': 'macProcessor'},
  {'label': 'md5', 'value': 'md5'},
  {'label': 'mime type', 'value': 'mimeType'},
  {'label': 'month', 'value': 'month'},
  {'label': 'month name', 'value': 'monthName'},
  {'label': 'name', 'value': 'name'},
  {'label': 'number between', 'value': 'numberBetween'},
  {'label': 'numerify', 'value': 'numerify'},
  {'label': 'opera', 'value': 'opera'},
  {'label': 'optional', 'value': 'optional'},
  {'label': 'paragraph', 'value': 'paragraph'},
  {'label': 'paragraphs', 'value': 'paragraphs'},
  {'label': 'passthrough', 'value': 'passthrough'},
  {'label': 'password', 'value': 'password'},
  {'label': 'phone number', 'value': 'phoneNumber'},
  {'label': 'postcode', 'value': 'postcode'},
  {'label': 'random ascii', 'value': 'randomAscii'},
  {'label': 'random digit', 'value': 'randomDigit'},
  {'label': 'random digit not', 'value': 'randomDigitNot'},
  {'label': 'random digit not null', 'value': 'randomDigitNotNull'},
  {'label': 'random element', 'value': 'randomElement'},
  {'label': 'random elements', 'value': 'randomElements'},
  {'label': 'random float', 'value': 'randomFloat'},
  {'label': 'random html', 'value': 'randomHtml'},
  {'label': 'random key', 'value': 'randomKey'},
  {'label': 'random letter', 'value': 'randomLetter'},
  {'label': 'random number', 'value': 'randomNumber'},
  {'label': 'real text', 'value': 'realText'},
  {'label': 'regexify', 'value': 'regexify'},
  {'label': 'rgb color', 'value': 'rgbColor'},
  {'label': 'rgb color as array', 'value': 'rgbColorAsArray'},
  {'label': 'rgb css color', 'value': 'rgbCssColor'},
  {'label': 'safari', 'value': 'safari'},
  {'label': 'safe color name', 'value': 'safeColorName'},
  {'label': 'safe email', 'value': 'safeEmail'},
  {'label': 'safe email domain', 'value': 'safeEmailDomain'},
  {'label': 'safe hex color', 'value': 'safeHexColor'},
  {'label': 'secondary address', 'value': 'secondaryAddress'},
  {'label': 'sentence', 'value': 'sentence'},
  {'label': 'sentences', 'value': 'sentences'},
  {'label': 'sha1', 'value': 'sha1'},
  {'label': 'sha256', 'value': 'sha256'},
  {'label': 'shuffle', 'value': 'shuffle'},
  {'label': 'shuffle array', 'value': 'shuffleArray'},
  {'label': 'shuffle string', 'value': 'shuffleString'},
  {'label': 'slug', 'value': 'slug'},
  {'label': 'state', 'value': 'state'},
  {'label': 'street address', 'value': 'streetAddress'},
  {'label': 'street name', 'value': 'streetName'},
  {'label': 'street suffix', 'value': 'streetSuffix'},
  {'label': 'swift bic number', 'value': 'swiftBicNumber'},
  {'label': 'text', 'value': 'text'},
  {'label': 'time', 'value': 'time'},
  {'label': 'timezone', 'value': 'timezone'},
  {'label': 'title', 'value': 'title'},
  {'label': 'title female', 'value': 'titleFemale'},
  {'label': 'title male', 'value': 'titleMale'},
  {'label': 'tld', 'value': 'tld'},
  {'label': 'to lower', 'value': 'toLower'},
  {'label': 'to upper', 'value': 'toUpper'},
  {'label': 'unique', 'value': 'unique'},
  {'label': 'unix time', 'value': 'unixTime'},
  {'label': 'url', 'value': 'url'},
  {'label': 'user agent', 'value': 'userAgent'},
  {'label': 'user name', 'value': 'userName'},
  {'label': 'uuid', 'value': 'uuid'},
  {'label': 'valid', 'value': 'valid'},
  {'label': 'vat', 'value': 'vat'},
  {'label': 'windows platform token', 'value': 'windowsPlatformToken'},
  {'label': 'word', 'value': 'word'},
  {'label': 'words', 'value': 'words'},
  {'label': 'year', 'value': 'year'}];

  const speechs = [
    {label: 'Français', value: 'fr'},
    {label: 'Anglais', value: 'en'}
  ]

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onLineOptionAdd = (item: ILineOption) => {
    let contraints = field.specification.res_type_attribute_constraints;
    let updateDataOptions = JSON.parse(JSON.stringify(dataOptions));
    if(contraints && contraints.includes(item.name+'=')){
      updateDataOptions = updateDataOptions.map((op: ILineOption) => op.name === item.name ? item : op)
    }else{
      updateDataOptions.push(item)
    }
    setDataOptions(updateDataOptions)
    setContraints(item.name+'='+item.value);
  }
  
  const onLineOptionRemove = (index: number, item: ILineOption) => {
    let updateDataOptions = JSON.parse(JSON.stringify(dataOptions));
    updateDataOptions.splice(index, 1)
    setDataOptions(updateDataOptions)
    removeContraints(item.name)
  }

  const removeContraints = (key: string) => {
    let contraints = field.specification.res_type_attribute_constraints;
    let newarr1 = contraints?.split(';')?.map((v, i) => v.includes(key+'=') ? '' : v)
    if(newarr1){
      contraints = newarr1?.filter(v => v.includes('=')).join(';')
    }
    setField({...field, specification: {...field?.specification, res_type_attribute_constraints: contraints}})
  }

  const setContraints = (ct: string) => {
    let contraints = field.specification.res_type_attribute_constraints;
    ct.split(';').forEach(elt => {
      let arr = elt.split('=');
      let key = arr[0];
      let val = arr[1];
      if(contraints){
        if(contraints.includes(';') && contraints.includes(key+'=')){
          let newarr1 = contraints?.split(';')?.map((v, i) => v.includes(key+'=') ? key+'='+val : v)
          contraints = newarr1?.join(';')
        }else{
          contraints = contraints.includes(key+'=') ? key+'='+val : contraints+';'+key+'='+val
        }
      }else{
        contraints = ct
      }
    })
    
    setField({...field, specification: {...field?.specification, res_type_attribute_constraints: contraints}})
  }

  const contraintsList = <div>
  <ul>
    <li><b>text:</b>max length and size properties define a text field that allows max maxlength  characters, and has a screen width of size  characters.</li>
    <li><b>textarea:</b>colums and rows properties define a textarea with max columns characters horizontally and max rows  characters vertically.</li>
    <li><b>date, time, datetime:</b>do not support constraints</li>
    <li><b>selectRange:</b>min,max, and default define a select input box with a list of interger values starting from min  till max , with a default selected value.</li>
    <li><b>select:</b> name-value pairs define a select input box with a list of values value1, value2, value3;  displayed as name1, name2, name3.</li>
    <li><b>password:</b> does not support constraints</li>
    <li><b>email:</b> does not support constraints</li>
    <li><b>url:</b> does not support constraintst</li>
    <li><b>selectYear:</b> does not support constraints</li>
    <li><b>selectMonth:</b> does not support constraints; months are display as January, February, March, ...; each having the numeric value 1, 2, 3, ...</li>
    <li><b>checkbox:</b> does not support contraints</li>
    <li><b>radio:</b> name-value pairs define the values of the radio. Each value has a display name that can contain html code, where = should be replaced by ~</li>
    <li><b>autocomplete:</b> max length, size=XY and SQL=select A as label, B as value from C  define the SQL query that provides the list of values in a text input field of given size.</li>
    <li><b>file, audio, image, number:</b> do not support constraints</li>
  </ul>
</div>

const typesList = <div>
  <ul>
    <li><b>text:</b> single line text input field.</li>
    <li><b>textarea:</b> multiple lines text input area.</li>
    <li><b>date:</b> date with a calender picker.</li>
    <li><b>time:</b> time with a clock picker.</li>
    <li><b>datetime:</b> date and time with calender picker.</li>
    <li><b>selectRange:</b> select box with a range of numerical values.</li>
    <li><b>select:</b> select box with a list of values.</li>
    <li><b>password:</b> password input field.</li>
    <li><b>email:</b> email input field.</li>
    <li><b>url:</b> url input field.</li>
    <li><b>selectYear:</b> select box with a range of years.</li>
    <li><b>selectMonth:</b> select box with a range of months.</li>
    <li><b>checkbox:</b> checkbox input.</li>
    <li><b>radio:</b> radio button with a list of options.</li>
    <li><b>autocomplete:</b> single line text input with autocomplete SQL data source.</li>
    <li><b>file:</b> single or multiple files input.</li>
    <li><b>audio:</b> single audio file input.</li>
    <li><b>image:</b> single or multiple image files input.</li>
    <li><b>number:</b> number input.</li>
  </ul>
</div>

const onItemContraints = () => {
  setDrawerInfos({content: contraintsList, title: 'Contraints List'})
  setVisibleDrawer(true)
}

const onItemTypes = () => {
  setDrawerInfos({content: typesList, title: 'Types List'})
  setVisibleDrawer(true)
}

const onClose = () => {
  setStep("properties")
  props.onClose()
}

const isIncorrectName = field.specification.res_type_attribute_name && field.specification.res_type_attribute_name != props.initialField.specification.res_type_attribute_name && dataAttributes?.resourceTypeAttributesByOrg && dataAttributes?.resourceTypeAttributesByOrg.find(item => item.res_type_attribute_name.replace(/[' ']/g, '').toLocaleLowerCase() == field.specification.res_type_attribute_name.trim().replace(/[' ']/g, '').toLocaleLowerCase())

const onSave = () => {
  if(props.saveMode === 'database'){
    const input = {field, resTypeId: props.resTypeId};
    try{
      if(isIncorrectName){
        message.warning('The attribute name is already in use. Please use a different one.');
        throw new Error('The attribute name is already in use. Please use a different one.');
      }

      let res = CreateOrUpdateResTypeField({variables: input, refetchQueries: [{ query: fetchGroupNamesQuery,
        variables: {
          resTypeId: props.resTypeId
        } },
      {
        query: ResourceTypeAttributeTypeByResTypeQuery,
        variables: {
          resTypeId: props.resTypeId,
        }
      }
      ]});
      res.then(res => {
        if(res.data?.createOrUpdateResTypeField){
          message.success(res.data?.createOrUpdateResTypeField)
          props.onLineSaveOrUpdate(field);
          onClose()
        }
      }).catch(raison => {
        message.error("Failed to create a field")
        console.error(raison)
      })
    }catch(error){
      message.error("Failed to create a field")
      console.error(error)
    }
  }else{
    props.onLineSaveOrUpdate(field);
    onClose()
  }
}

useEffect(() => {
  setField(props.initialField);
  form.setFieldsValue({...props.initialField, ...props.initialField?.specification})
  
}, [props]);


  return (
    <Modal 
      title={<><span>{props.mode + " field"}</span> <Badge
      count={field.specification.id ? 'pre-existing' : 'new'}
      style={{ backgroundColor: field.specification.id ? 'orange' : '#52c41a' }}
    /></>} 
      visible={props.visible}
      onCancel={onClose}
      width="850px"
      footer={[
        <>
        {
          (step === "properties") && (<Button
            type="primary"
            icon={<ArrowRightOutlined />}
            onClick={() => setStep("settings")}
          >
            {"Settings"}
          </Button>)
        }
        {
          (step === "settings" || step === "customization") && (
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => setStep("properties")}
            >
              {"Back to properties"}
            </Button>
          )
        }
        {
          (step === "customization") && (
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => setStep("settings")}
            >
              {"Back to settings"}
            </Button>
          )
        }
        <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={props.saveMode === 'database' ? loading : false}
          >
            {"Save Field"}
          </Button>
          <Button
            type="default"
            onClick={onClose}
          >
            {"Cancel"}
          </Button>
        </>
      ]}
      >
      <Form 
        {...formLayout}
        form={form}
        onFinish={() => onSave()}
        >
        {field.specification.id ? <Alert message="This is a pre-existing field. Changes will affect all Apps which currently use it." type="warning" showIcon closable /> : ''}
      <Tabs 
      activeKey={step}
      onChange={step => {
        setStep(step as any);
      }}
      defaultActiveKey="properties">
          <TabPane tab="Properties" key="properties">
          {isIncorrectName ? <Alert type="warning" message={"The attribute name is already in use. Please use a different one."} showIcon style={{marginBottom: '10px'}}/> : ''}
            <Form.Item label={'Field name'} name="res_type_attribute_name" required>
              <Input onChange={(elt) => setField({...field, specification: {...field?.specification, res_type_attribute_name: elt.target.value}})} />
            </Form.Item>
            <Row style={{display: 'flex'}} justify={"end"}>
              <Col style={{position: "relative"}} span={21}>
                <QuestionCircleTwoTone onClick={() => onItemTypes()} style={{right: '0px', position: 'absolute', top: '-16px'}} />
                <Form.Item label={'Type'} name="res_type_attribute_type" required>
                  <Select
                    onChange={(val: string) => {
                      formContraints.resetFields()
                      setDataOptions([])
                      setField({...field, specification: {...field?.specification, res_type_attribute_type: val, res_type_attribute_constraints: null}})
                    }}
                  >
                    {types.map(type => (
                      <Option value={type.value}>{type.label}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col style={{position: "relative"}} span={1} offset={1}>
                <QuestionCircleTwoTone onClick={() => onItemContraints()} style={{right: '0px', position: 'absolute', top: '-16px'}} />
                <Button
                  disabled={field.specification.res_type_attribute_type ? !types.find(t => t.value == field.specification.res_type_attribute_type)?.support_contraints : true}
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setStep("customization")}
                />
              </Col>
            </Row>
            <Form.Item label={'Visibility'}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {props.properties.map(pp => (
                <Form.Item name={pp.value} valuePropName="checked">
                  <Checkbox onChange={(e) => setField({...field, [pp.value]: e.target.checked})}>{pp.label}</Checkbox>
                </Form.Item>
              ))}
            </div>
            </Form.Item>
            <Form.Item label={'Description'} name="res_type_attribute_description">
              <TextArea
                onChange={(elt) => setField({...field, specification: {...field?.specification, res_type_attribute_description: elt.target.value}})}
              />
            </Form.Item>
        </TabPane>
        <TabPane tab="Settings" key="settings">
          {/*
          <Form.Item label={'Default value'} name="res_type_attribute_type_default_value">
            <Input onChange={(elt) => setField({...field, res_type_attribute_type_default_value: elt.target.value})} />
          </Form.Item>
          <Form.Item label={'Formula'} name="res_type_attribute_type_formula">
            <Input onChange={(elt) => setField({...field, res_type_attribute_type_formula: elt.target.value})} />
          </Form.Item>
          */}
          <GroupNameSelect setField={setField} field={field} resTypeId={props.resTypeId} />
          <LinkedFieldSelect setField={setField} field={field} orgId={props.orgId} resTypeId={props.resTypeId} />
          <Form.Item label={'Field Semantic'} name="res_type_attribute_type_speech_input">
            <Select
              onChange={(val: string) => setField({...field, res_type_attribute_type_speech_input: val})}
            >
              {semantic_types.map(type => (
                <Option value={type.value}>{type.label}</Option>
                ))}
            </Select>
          </Form.Item>
        </TabPane>
        <TabPane tab={<div>
          <span>Customization </span><Badge
            count={(step === "customization") ? field?.specification?.res_type_attribute_type : 0}
            style={{ backgroundColor: '#52c41a' }}
          />
          </div>} key="customization" disabled>
          <Form
          {...formLayout}
          form={formContraints}
          >
            {
              (field.specification.res_type_attribute_type === "text") ? (
                <>
                  <Form.Item name='maxlength' label={'Max length'}>
                    <Select
                      onChange={(val: string) => setContraints('maxlength='+val)}
                    >
                      {Array(80).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name='size' label={'Size'}>
                    <Select
                      onChange={(val: string) => setContraints('size='+val)}
                    >
                      {Array(80).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (field.specification.res_type_attribute_type === "textarea") ? (
                <>
                  <Form.Item name='rows' label={'Rows'}>
                    <Select
                      onChange={(val: string) => setContraints('rows='+val)}
                    >
                      {Array(255).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name='columns' label={'Columns'}>
                    <Select
                      onChange={(val: string) => setContraints('cols='+val)}
                    >
                      {Array(80).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (field.specification.res_type_attribute_type === "selectRange") ? (
                <>
                  <Form.Item label={'Select Range'}>
                    <Slider range={{ draggableTrack: true }} defaultValue={[0, 100]} onChange={(values:[number, number]) => {
                      setContraints('min='+values[0].toString()+';max='+values[1].toString())
                    }} />
                  </Form.Item>
                  <Form.Item label={'Default value'}>
                    <InputNumber min={1} max={100} defaultValue={1} onChange={(val) => setContraints('selected='+val.toString())} />
                  </Form.Item>
                </>
              ) : (field.specification.res_type_attribute_type === "autocomplete") ? (
                <>
                  <Form.Item label={'SQL Statement'}>
                    <Input name="sql" onChange={(e) => setContraints('sql='+e.target.value)} />
                  </Form.Item>
                  <Form.Item label={'Max Length'}>
                    <Select
                      onChange={(val: string) => setContraints('maxlength='+val)}
                    >
                      {Array(16).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'Size'}>
                    <Select
                      onChange={(val: string) => setContraints('size='+val)}
                    >
                      {Array(16).fill('').map((x,i) =>i).map((v) => (
                        <Option value={v}>{v}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (field.specification.res_type_attribute_type === "select" || field.specification.res_type_attribute_type === "radio") ? (
                <NameValueOptions 
                  onLineAdd={onLineOptionAdd}
                  onLineRemove={onLineOptionRemove}
                  dataOptions={dataOptions}
                />
              ) : 'undefined'
            }
          </Form>
        </TabPane>
      </Tabs>
      <Drawer title={drawerInfos.title} width={500} placement="right" onClose={() => setVisibleDrawer(false)} visible={visibleDrawer}>
        {drawerInfos.content}
      </Drawer>
    </Form>
    </Modal>
  )
}

export default UxResTypeFieldForm;