import { Carousel, Layout, Menu, Button, Grid, Row, Col, Card, Collapse } from 'antd';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { AreaChartOutlined, WindowsOutlined, CheckCircleTwoTone} from "@ant-design/icons";
import "../App.css"
import '../pages/HomePage.css'

const { Header, Content, Footer, Sider } = Layout;

export const HomePage = () => {

  const { Panel } = Collapse;

    return (
      <div>
        <Carousel autoplaySpeed={4000} autoplay>
          <div>
            <div className='slide-background' style={{backgroundImage: 'url("./img/img1.jpeg")'}}>
              <div className='slide-overlay'>
                <div className='slide-container'>
                  <h1>Do more of what you like.</h1>
                  <h3>Bring all your apps in one place</h3>
                  <h4>with Digintu.code</h4>
                  <Button size='large' type="primary"><Link to="/login">Try for free</Link></Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='slide-background' style={{backgroundImage: 'url("./img/img2.jpg")'}}>
              <div className='slide-overlay'>
                <div className='slide-container'>
                  <h1>Pre-built customizable business apps</h1>
                  <h3>Manage your tasks, clients, sessions, invoices, or projects with grace and ease.</h3>
                  <Button size='large' type="primary"><Link to="/login">Try for free</Link></Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='slide-background' style={{backgroundImage: 'url("./img/img3.jpg")'}}>
              <div className='slide-overlay'>
                <div className='slide-container'>
                  <h1>Non-tech users build their own business apps visually.</h1>
                  <h1>Without writing a line of code.</h1>
                  <h3>All in one place inside a browser.</h3>
                  <Button size='large' type="primary"><Link to="/login">Try for free</Link></Button>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
          <Row gutter={10} style={{margin: '35px 0px'}}>
            <Col md={12} style={{display: 'flex', alignItems: "center"}}>
              <div>
                <h1 style={{fontSize: '28px', fontWeight: 'bold'}}>LOW CODE</h1>
                <p>Bring your ideas to life. Create forms, reports and implement business logic without programming.</p>
                <p>Digintu.code provides an easy-to-use, intuitive, web-based interface for non-technical users.</p>
                <p>Citizen developers can rapidly create, customize and share data-rich applications.</p>
                <Button size='large' type="primary"><Link to="/login">Try for free</Link></Button>
              </div>
            </Col>
            <Col md={12} style={{textAlign: 'end'}}>
              <img style={{width: '80%'}} src="./img/img4.jpg" alt="" />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12} style={{textAlign: 'start'}}>
              <img style={{width: '80%'}} src="./img/img5.jpg" alt="" />
            </Col>
            <Col md={12} style={{display: 'flex', alignItems: "center", textAlign: "end", justifyContent: "end"}}>
              <div>
                <h1 style={{fontSize: '28px', fontWeight: 'bold'}}>DIGITAL TRANSFORMATION</h1>
                <p>Digintu.code transforms small practices into digital enterprises.</p>
                <p>Build and customize business apps for managing workflows and people.</p>
                <Button size='large' type="primary"><Link to="/login">Try for free</Link></Button>
              </div>
            </Col>
          </Row>
          {/*
          <div className="plan-wrapper">
          <h1>Flexible pricing</h1>
            <Row gutter={40}>
              <Col md={6} sm={12}>
                <Card
                  className="card-container"
                  title={
                    <div className="card-title">
                      <div className="plan-icon">
                        <AreaChartOutlined />
                      </div>
                      <h2>Basic</h2>
                      <p>Free</p>
                      <Button type="primary" size="large">
                        <Link to="/login">Choose this plan</Link>
                      </Button>
                    </div>
                  }
                  hoverable
                >
                  <ul>
                    <li><CheckCircleTwoTone className="li-icon" />5 Tables for free.</li>
                    <li><CheckCircleTwoTone className="li-icon" />100 MB binary data storage for free.</li>
                    <li><CheckCircleTwoTone className="li-icon" />10,000 data records in all tables for free.</li>
                  </ul>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card
                  className="card-container" 
                  title={
                    <div className="card-title">
                      <div className="plan-icon">
                        <WindowsOutlined />
                      </div>
                      <h2>Starter</h2>
                      <p>$4,99 per month</p>
                      <Button type='primary' size="large">
                        <Link to="/login">Choose this plan</Link>
                      </Button>
                    </div>
                  }
                  hoverable
                >
                  <ul>
                    <li><CheckCircleTwoTone className="li-icon" />10 Tables</li>
                    <li><CheckCircleTwoTone className="li-icon" />2 GB binary data storage</li>
                    <li><CheckCircleTwoTone className="li-icon" />500,000 data records in all tables</li>
                  </ul>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card
                  className="card-container"
                  title={
                    <div className="card-title">
                      <div className="plan-icon">
                        <AreaChartOutlined />
                      </div>
                      <h2>Pro</h2>
                      <p>$9,99 per month</p>
                      <Button type="primary" size="large">
                        <Link to="/login">Choose this plan</Link>
                      </Button>
                    </div>
                  }
                  hoverable
                >
                  <ul>
                    <li><CheckCircleTwoTone className="li-icon" />50 Tables</li>
                    <li><CheckCircleTwoTone className="li-icon" />10 GB binary data storage</li>
                    <li><CheckCircleTwoTone className="li-icon" />1,000,000 data records in all tables</li>
                  </ul>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card
                  className="card-container"
                  title={
                    <div className="card-title">
                      <div className="plan-icon">
                        <WindowsOutlined />
                      </div>
                      <h2>Business</h2>
                      <p>$14,99 per month</p>
                      <Button type="primary" size="large">
                        <Link to="/login">Choose this plan</Link>
                      </Button>
                    </div>
                  }
                  hoverable
                >
                  <ul>
                    <li><CheckCircleTwoTone className="li-icon" />100 Tables</li>
                    <li><CheckCircleTwoTone className="li-icon" />20 GB binary data storage</li>
                    <li><CheckCircleTwoTone className="li-icon" />2,000,000 data records in all tables</li>
                  </ul>
                </Card>
              </Col>
            </Row>
          </div>
          */}
          <Row gutter={10} style={{margin: '0px 0px 70px 0px'}}>
            <Col md={12} style={{display: 'flex', alignItems: "center", width: "100%"}}>
            <Collapse accordion style={{width: '100%'}}>
              <Panel header="1. Start with a pre-built App template" key="1">
                <p>Patient Portal, Appointment Scheduling, Recruitment Management, Knowledge Base, Custom CRM</p>
              </Panel>
              <Panel header="2. Customize to fit your needs" key="2">
                <p>Design data-rich web-based applications for your practice. No programming. Only clicks.</p>
              </Panel>
              <Panel header="3. Import your current data" key="3">
                <p>Forms for data entry, charts, statistics are automatically generated.</p>
              </Panel>
            </Collapse>
            </Col>
            <Col md={12}>
              <div style={{textAlign: 'right'}}>
                <img style={{width: '80%'}} src="./img/img6.png" alt="" />
              </div>
            </Col>
          </Row>
      </div>
    )
}