import { useMutation, useQuery } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import moment from 'moment';
import React from 'react'
import { useAppDispatch } from '../hooks';
import { subscribeToTemplatesMutation } from '../mutations/subscribeToTemplates';
import { SubscribeToTemplates, SubscribeToTemplatesVariables } from '../mutations/__generated__/SubscribeToTemplates';
import LoadingPage from '../pages/Loading';
import { getMustSubscribeTemplatesQuery } from '../queries/getMustSubscribeTemplates';
import { getMustSubscribeTemplates, getMustSubscribeTemplatesVariables } from '../queries/__generated__/getMustSubscribeTemplates';
import { fetchApplications } from '../slices/applicationsSlice';

interface Props{
  appId: string;
  orgId: string;
  modalIsShow: boolean;
  handleCloseModal: () => void;
}

const ReSubscribeTemplateModal: React.FC<Props>  = (props) => {
  const [SubscribeToTemplates, {loading: loadingSubscription}] = useMutation<
  SubscribeToTemplates,
  SubscribeToTemplatesVariables
  >(subscribeToTemplatesMutation);
  const dispatch = useAppDispatch();

  const { data, loading, error } = useQuery<
    getMustSubscribeTemplates,
    getMustSubscribeTemplatesVariables
  >(getMustSubscribeTemplatesQuery, {
    variables: {
      first: 100,
      appId: props.appId,
    },
    skip: (props.appId == null) || !props.appId,
  });

  const templates = data?.getMustSubscribeTemplates?.data
  const handleOk = async () => {
    if (templates){
      try {
          const input: SubscribeToTemplatesVariables = {
            orgId: props.orgId,
            priceIds: templates.map(item => item.stripe_price!)
          };
          let res = await SubscribeToTemplates({ variables: input });
          message.success(res.data?.subscribeToTemplates);
          dispatch(fetchApplications({ first: 100 }));
          props.handleCloseModal()

      } catch (error) {
        message.error("Failed to subscribe organization to templates");
        console.error(error);
      }
      props.handleCloseModal()
    }
  }

  if(loading){
    return <LoadingPage />
  }else{
    return (
      <Modal title="Subscribe to this template" visible={props.modalIsShow} onOk={handleOk} onCancel={props.handleCloseModal} footer={[
        <Button key="back" onClick={props.handleCloseModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loadingSubscription} onClick={handleOk}>
          Subscribe
        </Button>,]}>
        {
          templates?.map(temp => <div><hr/>{"Your account will be charged "} <b>{"€"+temp.price}</b> {" monthly for the App "} <b>{temp.template_name}</b> {" starting "} <b>{moment().format('MMMM Do YYYY')}</b> {". You can cancel any time without fees before that time. Do you want to proceed?"}</div>)
        }
      </Modal>
    )
  }
}

export default ReSubscribeTemplateModal