import { gql } from "@apollo/client";

export const ResourceRecordsQuery = gql`
  query ResourceRecords(
    $orgId: String!,
    $resourceTypeId: String!,
    $drilledRecordId: String,
    $resTypeResourceId: String,
    $selectedFilterId: String,
    $first: Int!,
    $page: Int!
  ) {
    resourceTypeRecords (
      input: { 
        org_id: $orgId, 
        res_type_id: $resourceTypeId, 
        selected_filter_id: $selectedFilterId,
        res_type_binding_id: $resTypeResourceId, 
        drilled_record_id: $drilledRecordId 
        }
      first: $first,
      page: $page
    ) {
      data
      paginatorInfo {
        lastItem
      }
    }
  }
`;
