import { Button, Table, Tag } from 'antd';
import React from 'react'

const Subscriptions: React.FC = () => {
  const dataSource = [
    {
      key: '1',
      number: 'SUB1234',
      type: "monthly",
      status: "In Progress",
      plan: "starter",
      total: "4.99"
    },
    {
      key: '2',
      number: 'SUB5645',
      type: "monthly",
      status: "cancel",
      plan: "business",
      total: "4.99"
    },
    {
      key: '2',
      number: 'SUB6567',
      type: "monthly",
      status: "cancel",
      plan: "pro",
      total: "4.99"
    },
  ];
  
  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => <Tag color={status == "cancel" ? "red" : "green"}>
        {status}
      </Tag>
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan: any) => <Tag color={"green"}>
        {plan}
      </Tag>
    },
    {
      title: 'Amount Due',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <Button size="middle" type='primary' danger>Cancel</Button>
      ),
    },
  ];
  
  return (
    <div>
      <h2>Subscriptions</h2>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default Subscriptions