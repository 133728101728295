import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchResourceTypeQuery } from "../queries/fetchResourceType";
import { fetchResourceType_resourceType as resourceTypesDetails } from "../queries/__generated__/fetchResourceType"
import { deleteResourceTypeMutation } from "../mutations/deleteResourceType";
import { DeleteResourceType, DeleteResourceTypeVariables } from "../mutations/__generated__/DeleteResourceType";

interface resourceTypeType {
  resourceType: resourceTypesDetails;
}

type State = {
  loading: boolean;
  data: resourceTypesDetails | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchResourceType = createAsyncThunk(
  "resourceType/fetch",
  async (arg: { tableId: string }) => {
    const res = await apolloClient.query<resourceTypeType>({
      query: fetchResourceTypeQuery,
      variables: { resTypeId: arg.tableId },
    });
    return res.data.resourceType ?? null;
  }
);

export const deleteResourceType = createAsyncThunk(
  "resourceType/delete",
  async (arg: { tableId: string }) => {
    await apolloClient.mutate<DeleteResourceType, DeleteResourceTypeVariables>({ 
      // refetchQueries: [{query: fetchResourceTypesQuery}],
      variables: { resTypeId: arg.tableId },
      mutation: deleteResourceTypeMutation 
    }).then((result) => { 
      // TODO : There is certainly a better way to do this
      alert(result?.data?.deleteResourceType)
      window.location.reload()
      console.log(result) 
    })
    .catch(error => { console.log(error) });
  }
);

const resourceTypeSlice = createSlice({
  name: "resourceType",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch
    [fetchResourceType.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchResourceType.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceType.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // delete
    [deleteResourceType.pending.type]: (state, _) => {
      state.loading = true;
    },
    [deleteResourceType.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [deleteResourceType.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = resourceTypeSlice;

export const resourceTypeReducer = reducer;
