import React from "react";
import { Row, Spin, Col } from "antd";

interface Props {
  message?: string;
}

const LoadingPage: React.FC<Props> = (props) => {
  return (
    <Row justify="center" align="middle" style={{ height: "50vh", textAlign: "center" }}>
      <Col span={24} >
        <Spin size="large" />
        {props.message ? (
          <h3 style={{ width: "100%", marginTop: "15px" }}>
            {props.message}
          </h3>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export default LoadingPage;
