import React, { useState } from 'react'
import { Badge, Button, message, Popconfirm, Table, Tabs } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteOutlined } from "@ant-design/icons";
import { ILineResourceTypeCustomAction } from '../components/generic/UxLineResourceTypeCustomActionsTable';
import { ColumnsType } from 'antd/lib/table';
import { UnusedResourceTypeCustomActions } from '../queries/__generated__/UnusedResourceTypeCustomActions';
import { UnusedResourceTypeCustomActionsQuery } from '../queries/unusedResourceTypeCustomActions';
import { DeleteCustomAction, DeleteCustomActionVariables } from '../mutations/__generated__/DeleteCustomAction';
import { deleteCustomActionMutation } from '../mutations/deleteCustomAction';

interface Props {

}

const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};

const dataSource = [
  {
    // key: '1',
    // name: 'Names',
    // code: 'NAMES,
    // type: 'type',
  },
];

const columns_demo = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
];

const Basket:React.FC<Props> = () => {
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
  UnusedResourceTypeCustomActions
>(UnusedResourceTypeCustomActionsQuery);

  const [DeleteCustomAction, {loading: loadingDeleting}] = useMutation<DeleteCustomAction, DeleteCustomActionVariables>(deleteCustomActionMutation)

const columns : ColumnsType<ILineResourceTypeCustomAction>= [
  {
    title: 'Name',
    dataIndex: 'res_type_custom_action_name',
    key: 'res_type_custom_action_name',
  },
  {
    title: 'Type',
    dataIndex: 'res_type_custom_action_type',
    key: 'res_type_custom_action_type',
  },
  {
    width: "15%",
    title: "Global",
    key: "res_type_custom_action_global",
    render: (_, row) => {
      return (
        <React.Fragment>
          {row.res_type_custom_action_global ? <Badge
          count='Yes'
          style={{ backgroundColor: '#52c41a' }}
        /> : <Badge
        count='No'
        style={{ backgroundColor: 'rgb(255, 77, 79)' }}
      />}
        </React.Fragment>
      )
    },
  },
  {
    width: "15%",
    title: "Public",
    key: "res_type_custom_action_public",
    render: (_, row) => {
      return (
        <React.Fragment>
          {row.res_type_custom_action_public ? <Badge
            count='Yes'
            style={{ backgroundColor: '#52c41a' }}
          /> : <Badge
          count='No'
          style={{ backgroundColor: 'rgb(255, 77, 79)' }}
        />}
        </React.Fragment>
      )
    },
  },
  {
    title: 'Created At',
    dataIndex: 'res_type_custom_action_created_at',
    key: 'res_type_custom_action_created_at',
  },
  {
    key: "actions",
    render: (_, row, index) =>
      (
        <div style={{display: 'flex'}}>
          <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              style={{marginRight: '10px'}}
              loading={toRemoveIndex === index ? loadingDeleting : false}
            />
          </Popconfirm>
        </div>
      ),
  },
];

const onItemDelete = (index: number, item: ILineResourceTypeCustomAction) => {
  setToRemoveIndex(index)
  const input = {customActionId: item.id!};
  try{
    let res = DeleteCustomAction({variables: input, refetchQueries: [{ query: UnusedResourceTypeCustomActionsQuery }]});
    res.then(res => {
      message.success(res.data?.deleteCustomAction)
    }).catch(raison => {
      message.error("Failed to delete a custom_action")
      console.error(raison)
    })
  }catch(error){
    message.error("Failed to delete a custom_action")
    console.error(error)
  }
  setToRemoveIndex(index)
};

const dataSet: ILineResourceTypeCustomAction[] = data?.unusedResourceTypeCustomActions.map((item:any) => ({
  id: item['id'],
  res_type_custom_action_type: item['action_type'],
  res_type_custom_action_icon: item['action_icon'],
  res_type_custom_action_description: item['action_description'],
  res_type_custom_action_name: item['action_name'],
  res_type_custom_action_spec: item['action_spec'],
  res_type_custom_action_global: item['action_global'] ? true : false,
  res_type_custom_action_public: item['action_public'] ? true : false,
  res_type_custom_action_created_at: item['created_at'],
}))

  return (
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <TabPane tab="Unused custom actions" key="1">
          <Table dataSource={dataSet} columns={columns} />
        </TabPane>
        <TabPane tab="Unused tables" key="2">
          <Table dataSource={dataSource} columns={columns_demo} />
        </TabPane>
        <TabPane tab="Unused attributes" key="3">
          <Table dataSource={dataSource} columns={columns_demo} />
        </TabPane>
      </Tabs>
  )
}

export default Basket