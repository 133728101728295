import { gql } from "@apollo/client";

export const AutoCompleteInputQuery = gql`
    query autoCompleteInput($sql: String!){
      autoCompleteInput(
        sql: $sql
      ){
        label
        value
      }
    }
`;