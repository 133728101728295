import ProTable, { ProColumns } from "@ant-design/pro-table";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Drawer, Dropdown, Menu, message, Spin } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { VersionControlQuery } from "../queries/versionControl";
import {
  VersionControl,
  VersionControlVariables,
} from "../queries/__generated__/VersionControl";
import {
  DeleteVersionControl,
  DeleteVersionControlVariables,
} from "../mutations/__generated__/DeleteVersionControl";
import { UndoOutlined, DeleteOutlined } from "@ant-design/icons";

import { RestoreVersionControlMutation } from "../mutations/restoreVersionControl";
import {
  RestoreVersionControl,
  RestoreVersionControlVariables,
} from "../mutations/__generated__/RestoreVersionControl";
import { fetchResourceRecords } from "../slices/resourceRecordsSlice";
import { DeleteVersionControlMutation } from "../mutations/deleteVersionControl";

interface ResourceLinkParams {
  recordId: string;
  applicationId: string;
  resourceTypeId: string;
}

interface Props {
  resourceTypeToColumns: ProColumns<any, "text">[];
  filterId: string | undefined;
  expandable: {
    expandedRowRender: (record: any) => JSX.Element;
  };
}

export const ResourceRecordHistory: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(
    (state) => state.application.data?.organization?.id
  );
  const { resourceTypeId, recordId, applicationId } =
    useParams<ResourceLinkParams>();

  const { data, loading, error } = useQuery<
    VersionControl,
    VersionControlVariables
  >(VersionControlQuery, {
    variables: {
      orgId: orgId!,
      resourceTypeId: resourceTypeId,
      recordId: recordId,
    },
  });

  const [RestoreVersionControl, { loading: loadingRestoreVersion }] =
    useMutation<RestoreVersionControl, RestoreVersionControlVariables>(
      RestoreVersionControlMutation
    );

    const [DeleteVersionControl, { loading: loadingDeleteVersion }] =
    useMutation<DeleteVersionControl, DeleteVersionControlVariables>(
      DeleteVersionControlMutation
    );

  const moreRestoreRecordActions = (record: any) => (
    <Menu mode="inline">
      <Menu.Item onClick={() => DeleteVersionControl({
                variables: {
                  recordId: recordId,
                  orgId: orgId!,
                  resTypeId: resourceTypeId,
                  versionId: record.id,
                }, refetchQueries: [{
                  query: VersionControlQuery,
                  variables: {
                    orgId: orgId!,
                    resourceTypeId: resourceTypeId,
                    recordId: recordId,
                  }
                }]
              }).then((res) => {
                message.success(res.data?.destroyVersionControl)
              })}>
          <DeleteOutlined /> Destroy
      </Menu.Item>
    </Menu>
  );

  function resourceTypeToColumns(): ProColumns[] {
    const actionsColumn: ProColumns = {
      title: "Actions",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={moreRestoreRecordActions(record)}
            onClick={() =>
              RestoreVersionControl({
                variables: {
                  recordId: recordId,
                  orgId: orgId!,
                  resTypeId: resourceTypeId,
                  versionId: record.id,
                },
              }).then((res) => {
                message.success(res.data?.restoreVersionControl)
                dispatch(fetchResourceRecords({ orgId: orgId!, resourceTypeId, selectedFilterId: props.filterId }));
              })
            }
          >
            <UndoOutlined /> Restore
          </Dropdown.Button>
        );
      },
    };
    return [...props.resourceTypeToColumns, actionsColumn];
  }
  const columns: ProColumns[] = resourceTypeToColumns();
  const onClose = () =>
    history.push(
      `/view/applications/${applicationId}/tables/${resourceTypeId}`
    );

  return (
    <Drawer
      footer={
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Button onClick={onClose} type="primary">
            Close
          </Button>
        </div>
      }
      onClose={onClose}
      visible={true}
      width="60%"
    >
      {props.resourceTypeToColumns ? (
        <div>
          <h2>{"History"}</h2>
          <ProTable
            expandable={props.expandable}
            locale={{
              triggerDesc: "descend sort text",
              triggerAsc: "ascend sort text",
              cancelSort: "cancel sort text",
            }}
            dataSource={data?.versionControl || []}
            search={false}
            rowKey="id"
            columns={columns}
            loading={loading}
          ></ProTable>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      )}
    </Drawer>
  );
};
