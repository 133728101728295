import { gql } from "@apollo/client";

export const DeleteResourceDataTrashMutation = gql`
  mutation DeleteResourceDataTrash($orgId: String!, $resTypeId: String!, $recordId: String!){
    destroyResourceDataTrash(
      input: {
        org_id: $orgId
        res_type_id: $resTypeId
        record_id: $recordId
      }
    )
  }
`;