import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import React from "react";
import { apolloClient } from "../apolloClient";
import { fetchFilesDataQuery } from "../queries/fetchFilesData";
import {
  fetchFilesData,
  fetchFilesDataVariables,
  fetchFilesData_fetchFilesData as FileData
} from "../queries/__generated__/fetchFilesData";
import { saveAs } from "file-saver";
import { fetchFileBase64 } from "../queries/__generated__/fetchFileBase64";
import { fetchFileBase64Query } from "../queries/fetchFileBase64";

interface Props {
  ids: string;
}

export const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const DisplayBinaryIcon: React.FC<Props> = (props) => {
  const { data, loading, error } = useQuery<
    fetchFilesData,
    fetchFilesDataVariables
  >(fetchFilesDataQuery, {
    variables: {
      id: props.ids,
    },
  });

  const downloadFile = (item: FileData) => {
    apolloClient
      .query<fetchFileBase64>({ query: fetchFileBase64Query, variables: { id: item.id} })
      .then((res) => {
        const base64Data = res.data.fetchFileBase64?.code!;
        const blob = b64toBlob(base64Data, item.mimetype)
        saveAs(blob, item.filename)
      });
  };

  return (
    <div>
      {loading ? (
        <Spin />
      ) : data?.fetchFilesData ? (
        data?.fetchFilesData?.map((item) => (
            <img style={{ cursor: "pointer", marginRight: "7px" }}
            title={item.filename}
            onClick={() => downloadFile(item)} src={`data:${item?.mimetype};base64,${item?.code}`} />
        ))
      ) : '-'}
    </div>
  );
};

export default DisplayBinaryIcon;
