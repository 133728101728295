import { gql } from "@apollo/client";

export const MeQuery = gql`
  query Me {
    me {
      id
      email
      first_name
      last_name
      lang
      organizations {
        id
        org_name
        is_subscribe
        pivot{
            admin
            superuser
        }
      }   
      activeOrganization {
        id
        org_name
        pivot{
            admin
        }
      }
      userAccessRights{
        item_type
        item_id
        view
        insert
        update
        delete
      }
    }
  }
`;
