import { gql } from "@apollo/client";

export const LinkedResourceRecordsQuery = gql`
    query LinkedResourceRecords($linkedId: String!){
      linkedResourceRecords(
        linked_id: $linkedId
      ){
        label
        value
      }
    }
`;