import { gql } from "@apollo/client"

export const ResourceTypeAttributesByOrgQuery = gql`
  query ResourceTypeAttributesByOrg($orgId: String!){
    resourceTypeAttributesByOrg(org_id: $orgId) {
      id
      res_type_attribute_name
    }
  }
`;
