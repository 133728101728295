import { Dropdown, Menu, Tooltip } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import React from 'react'
import { fetchApplicationDetails_application_resourceTypes } from '../queries/__generated__/fetchApplicationDetails';

interface Props{
  resourceType: fetchApplicationDetails_application_resourceTypes | undefined;
  filterRecords: (filterId: string | undefined) => void;
}

const FilterBtn: React.FC<Props> = (props) => {

  const menu = (
    <Menu>
      { props.resourceType?.filters?.data.map((filter, i) => <Menu.Item key={i} onClick={() => props.filterRecords(filter.id)}>{filter.res_type_filter_name}</Menu.Item>) }
      <Menu.Item key="" onClick={() => props.filterRecords(undefined)}>Refresh Data</Menu.Item>
    </Menu>
  );
  return (
    <Tooltip placement="top" title={'Filters'}>
      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <FilterOutlined style={{fontSize: '18px', marginLeft: '10px'}} />
      </Dropdown>
    </Tooltip>
  )
}

export default FilterBtn