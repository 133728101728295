import React, { useState } from "react";
import { Row, Col } from "antd";
import { setApolloToken } from "../apolloClient";
import { useQuery, useMutation } from "@apollo/client";

import { MeQuery } from "../queries/me";
import { Me } from "../queries/__generated__/Me";
import LoginComponent from "../components/login/Login";
import LoadingPage from "./Loading";
import { LoginMutation } from "../mutations/login";
import {
  Login as LoginResult,
  LoginVariables,
} from "../mutations/__generated__/Login";
import { getAuthToken, setAuthToken } from "../authToken";
import { RegisterStatuses } from "../__generated__/globalTypes";

export interface IActiveOrganization {
  id: string | null;
  org_name: string | null;
} 

export interface IAuthInfo {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  lang: string | null;
  active_organization: IActiveOrganization;
}

export const AuthContext = React.createContext<IAuthInfo>({
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  lang: "",
  active_organization: {
    id: null,
    org_name: null,
  },
});

interface Props {
  onFinish: (authInfo: IAuthInfo) => void;
}

const AuthPage: React.FC<Props> = (props) => {
  const authToken = getAuthToken();
  const [loginMessage, setLoginMessage] = useState("");

  setApolloToken(authToken || "");

  const { loading: meLoading, data: meData } = useQuery<Me>(MeQuery);

  const [useLogin, { loading: loginLoading, error: loginError }] = useMutation<
    LoginResult,
    LoginVariables
  >(LoginMutation);

  const useLoginHandle = (username: string, password: string, method: string) => {
    useLogin({ variables: { input: { username, password, method } } }).then(
      ({ data }) => {

        if (data?.login.status==RegisterStatuses.USER_DOES_NOT_EXIST){
          setLoginMessage("User does not exists");
        }else{
          const authToken = data?.login.access_token;
          if (authToken) {
            setApolloToken(authToken);
            setAuthToken(authToken);
            const { id, email, first_name, last_name, lang, activeOrganization } = data!.login?.user!;
            props.onFinish({
              id,
              lang,
              email,
              first_name,
              last_name,
              active_organization: {
                id: activeOrganization?.id || null,
                org_name: activeOrganization?.org_name || null,
              }
            });
          }

        }
        
      },
      (error) => console.error("Login error", error)
    );
  };

  if (meLoading) {
    return <LoadingPage />;
  } else if (meData) {
    const { id, email, first_name, last_name, lang, activeOrganization } = meData.me;

    props.onFinish({
      id,
      lang,
      email,
      first_name,
      last_name,
      active_organization: {
        id: activeOrganization?.id || null,
        org_name: activeOrganization?.org_name || null,
      }
    });

    return <LoadingPage />;
  } else
    return (
      <Row justify="center" align="middle" style={{ height: "70vh" }}>
        <Col xs={20} sm={16} md={12} lg={8} xl={6}>
          <LoginComponent
            loading={loginLoading}
            error={loginError?.graphQLErrors[0]?.extensions ? loginError?.graphQLErrors[0]?.extensions["reason"] : (loginMessage!=="" ? loginMessage : loginError?.message)}
            onLogin={useLoginHandle}
          />
        </Col>
      </Row>
    );
};

export default AuthPage;
