import {gql} from "@apollo/client"

export const fetchTemplatesQuery = gql`
  query fetchTemplates ($orgId: String, $first: Int!) {
    applicationTemplates(org_id: $orgId, first: $first) {
      data {
        id
        template_name
        template_description
        template_logo
        stripe_price
        is_paid
        price
      }
    }
  }
`;