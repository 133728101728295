import React, { useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { BasicLayout, MenuDataItem } from "@ant-design/pro-layout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { ResourceTable } from "../components/resourceTable";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchMe } from "../slices/meSlice";
import ButtonGroup from "antd/lib/button/button-group";
import { Button, Empty, Popconfirm, message, Menu, Dropdown, Spin } from "antd";
import { MoreOutlined, DatabaseOutlined, LogoutOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteAuthToken } from "../authToken";
import { fetchApplication } from "../slices/applicationSlice";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { fontAwesomeList } from "../components/helpers/iconList";
import { useMutation } from "@apollo/client";
import { SeedApplicationMutation } from "../mutations/seedApplication";
import {
  SeedApplication,
  SeedApplicationVariables,
} from "../mutations/__generated__/SeedApplication";
import { useHistory } from "react-router-dom";
import { EmptyApplication, EmptyApplicationVariables } from "../mutations/__generated__/EmptyApplication";
import { EmptyApplicationMutation } from "../mutations/emptyApplication";

export const AppLayout = () => {
  const dispatch = useAppDispatch();
  const urlArr = window.location.pathname.split("/");
  const appId = urlArr[urlArr.indexOf("applications") + 1];
  const application = useAppSelector((state) => state.application);
  const firstResId = useAppSelector((state) => state.application.data?.resourceTypes[0].id);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchMe());
    dispatch(fetchApplication({ appId }));
  }, [dispatch, appId]);

  const onLogout = () => {
    deleteAuthToken();
    window.location.reload();
  };

  const [SeedApplication] = useMutation<
  SeedApplication,
  SeedApplicationVariables
  >(SeedApplicationMutation);

  const [EmptyApplication] = useMutation<
  EmptyApplication,
  EmptyApplicationVariables
  >(EmptyApplicationMutation);

  const onSeedApp = async () => {
    if (application?.data?.id){
      try {
          const input: SeedApplicationVariables = {
            appId: application?.data?.id
          };
          let res = await SeedApplication({ variables: input });
          message.success(res.data?.seedApplication);
          document.location.reload();      
      } catch (error) {
        message.error("Failed to add ramdom data");
        console.error(error);
      }
    }
  };

  const onDeleteAppData = async () => {
    if (application?.data?.id){
      try {
          const input: EmptyApplicationVariables = {
            appId: application?.data?.id
          };
          let res = await EmptyApplication({ variables: input });
          message.success(res.data?.emptyApplication);
          document.location.reload();      
      } catch (error) {
        message.error("Failed to delete data");
        console.error(error);
      }
    }
  };

  const menuData: MenuDataItem[] =
    application?.data !== null
      ? application?.data?.resourceTypes.map((rt) => ({
          name: rt.res_type_name,
          path: `/${rt.id}`,
          key: rt.id,
          hideInMenu: false,
          icon: (
            <IconPickerItem
              containerStyles={{
                display: "inline-block",
                color: "inherit",
                lineHeight: 0,
                verticalAlign: "-0.125em",
                marginRight: '5px'
              }}
              size={14}
              icon={
                rt.res_type_icon && fontAwesomeList.includes(rt.res_type_icon)
                  ? rt.res_type_icon as IconList
                  : "FaChevronRight"
              }
            />
          ),
        }))
      : [];

  const moreActions = () => (
    <Menu mode="inline">
      <Menu.Item>
        <Popconfirm placement="right" title="Sure to add demo data?" onConfirm={() => onSeedApp()}>
          <Button
            type="link"
            icon={<DatabaseOutlined />}
            onClick={(e) => {
              
              e.stopPropagation();
            }}
          >
            Add random data
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm placement="right" title="Sure to delete all data from this app?" onConfirm={() => onDeleteAppData()}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              
              e.stopPropagation();
            }}
          >
            Delete all data
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Button
            type="link"
            icon={<LogoutOutlined />}
            onClick={() => onLogout()}
          >
            Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  
if(firstResId){
  return (
    <Router>
      <BasicLayout
        logo={<IconPickerItem icon={application?.data?.app_client_logo && fontAwesomeList.includes(application?.data?.app_client_logo) ? application?.data?.app_client_logo as IconList : "FaThLarge"} color="#ffff" size={30} containerStyles={{display: 'flex'}} />}
        title={application?.data?.app_name && (application?.data?.app_name.length > 9) ? application?.data?.app_name.slice(0, 9).concat('...') : application?.data?.app_name }
        className="top-layout"
        menuDataRender={() => menuData}
        menuItemRender={(menuItem, defaultDom) => {
          return (
            <Link style={{textTransform: 'capitalize'}} to={`/view/applications/${appId}/tables/${menuItem.key!}`}>
              {defaultDom}
            </Link>
          );
        }}
        menu={{
          loading: application.loading,
        }}
      >
        <div style={{ margin: "0px 0px 10px 0px"}}>
        <ButtonGroup style={{ margin: "0px 5px"}}>
          <a href="/applications">
              <Button type="default">Go to app list</Button>
          </a>
        </ButtonGroup>
        <ButtonGroup style={{ margin: "0px 5px"}}>
          <a href={"/applications/" + appId + "/tables" } >
            <Button type="primary">Edit this app</Button>
          </a>
          <Dropdown overlay={moreActions()} trigger={["click"]}>
            <Button
              type="primary"
              ghost
              icon={<MoreOutlined />}
              onClick={(e) => e.stopPropagation()}
            ></Button>
          </Dropdown>
        </ButtonGroup>
        </div>
        <Switch>
          <Route path="/view/applications/:applicationId/tables/:resourceTypeId">
            <ResourceTable />
          </Route>
          <Route path="*">
          <Redirect to={`/view/applications/${appId}/tables/${firstResId}`} />
          </Route>
          <Redirect to={`/view/applications/${appId}`} />
        </Switch>
      </BasicLayout>
    </Router>
    );
  }else{
    return <Spin size="large" />
  }
};
