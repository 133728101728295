import { gql } from "@apollo/client";

export const RemoveTemplateSubscriptionMutation = gql`
  mutation RemoveTemplateSubscription($stripePrice: String!, $orgId: String!){
    removeTemplateSubscription(input: {
      stripe_price: $stripePrice
      org_id: $orgId
    })
  }
`;
