import { gql } from "@apollo/client";

export const ResourceTypesByAppQuery = gql`
  query ResourceTypesByApp($appId: String!) {
    resourceTypesByApp(app_id: $appId) {
      id
      org_id
      res_type_icon
      res_type_name
      res_type_table_name
      res_type_description
      res_type_icon
      created_at
    }
  }
`;
