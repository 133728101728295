import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { HomePage } from "./pages/HomePage";

import AuthPage, { IAuthInfo } from "./pages/Auth";
import RegisterPage from "./pages/Register";
import VerifyEmail from "./pages/VerifyEmail";
import InvitationVerifyEmail from "./pages/InvitationVerifyEmail";
import PasswordReset from "./pages/PasswordReset";

interface Props {
  onFinish: (authInfo: IAuthInfo) => void;
}

const AuthRoutes: React.FC<Props> = (props) => (
  <Switch>
    <Route exact path="/register" render={() => <RegisterPage onFinish={props.onFinish} />} />
    <Route path="/email-verify" render={() => <VerifyEmail onFinish={props.onFinish} />} />
    <Route path="/invitation-email-verify" render={() => <InvitationVerifyEmail onFinish={props.onFinish} />} />
    <Route path="/login" render={() => <AuthPage onFinish={props.onFinish} />} />
    <Route exact path="/password-reset" render={() => <PasswordReset />} />
    <Route path="/password-reset/:token" render={() => <PasswordReset />} />
    <Route path="/" render={() => <HomePage />} />
    <Redirect to="/" />
  </Switch>
);

export default AuthRoutes;
