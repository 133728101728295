import { gql } from "@apollo/client";

export const VerifyEmailMutation = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
	verifyEmail(input: $input) {
    access_token
    refresh_token
    expires_in
    token_type
    user {
      id
      lang
      email
      first_name
      last_name
      username
      created_at
      updated_at
      activeOrganization {
        id
        org_name
        pivot{
            admin
        }
      } 
    }
  }
}
`;