import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchResourceTypeTemplatesQuery  } from "../queries/fetchResourceTypeTemplates";
import { fetchResourceTypeTemplates_resourceTypeTemplates_data as resourceTypeTemplates } from "../queries/__generated__/fetchResourceTypeTemplates"

interface templatesTypes {
  resourceTypeTemplates: {
    data: resourceTypeTemplates
  }
}

type State = {
  loading: boolean;
  data: resourceTypeTemplates[] | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchResourceTypeTemplates = createAsyncThunk("resourceTypeTemplates/fetch", () =>
  apolloClient.query<templatesTypes>({ query: fetchResourceTypeTemplatesQuery, variables: {} }).then((res) => res.data.resourceTypeTemplates.data)
);

const resourceTypeTemplatesSlice = createSlice({
  name: "resourceTypeTemplates",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResourceTypeTemplates.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchResourceTypeTemplates.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchResourceTypeTemplates.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = resourceTypeTemplatesSlice;

export const resourceTypeTemplatesReducer = reducer;
