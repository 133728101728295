import { gql } from "@apollo/client";

export const DestroyResourceTypeRecordBinaryDataMutation = gql`
  mutation DestroyResourceTypeRecordBinaryData($orgId: String!, $resTypeId: String!, $recordId: String!, $binaryDataId: String!){
    destroyResourceTypeRecordBinaryData(
      input: {
        org_id: $orgId
        res_type_id: $resTypeId
        record_id: $recordId
        binary_data_id: $binaryDataId
      }
    )
  }
`;