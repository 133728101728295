import { gql } from "@apollo/client"

export const ResourceTypeFilterByResTypeQuery = gql`
  query ResourceTypeFilterByResType($resTypeId: String!){
    resourceTypeFilterByResType(res_type_id: $resTypeId) {
      id
      res_type_id
      res_type_filter_name
      res_type_filter_description
      res_type_filter_logicaloperator
      attributes{
        id
        res_type_filter_id
        res_type_attribute_type_id
        res_type_filter_attribute_condition
        specification {
          id
          res_type_id
          specification {
            id
            res_type_attribute_name
          }
        }
      }
    }
  }
`;
