import React, { ReactNode, useState } from "react";
import { Form, Table, Button, Spin, Input, Space, message, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';
import { ILineResourceType } from "./UxLineResourceTypesTable";
import { ResourceTypeFilterByResTypeQuery } from "../../queries/resourceTypeFilterByResType";
import { ResourceTypeFilterByResType, ResourceTypeFilterByResTypeVariables, ResourceTypeFilterByResType_resourceTypeFilterByResType as IResourceTypeFilterByResTypeDetails  } from "../../queries/__generated__/ResourceTypeFilterByResType";
import { useMutation, useQuery } from "@apollo/client";
import UxResTypeFilterForm from "./UxResTypeFilterForm";
import { DeleteResourceTypeFilter, DeleteResourceTypeFilterVariables } from "../../mutations/__generated__/DeleteResourceTypeFilter";
import { deleteResourceTypeFilterMutation } from "../../mutations/deleteResourceTypeFilter";
import { ILineResourceTypeFilterCondition } from "./UxLineResTypeFilterCondition";


type INewLineResourceTypeFilter = ILineResourceTypeFilter | INewResourceTypeFilter;

export interface INewResourceTypeFilter {
  res_type_filter_name: string;
}

export interface ILineResourceTypeFilter {
  id: string | null;
  res_type_id: string | null;
  res_type_filter_name: string;
  res_type_filter_description: string | null;
  res_type_filter_logicaloperator: string | null;
  attributes: ILineResourceTypeFilterCondition[] | null;
}

interface ILineResourceTypeFilterInternal {
  thisIsThatExtraRow: boolean;
}

export interface ILineFormValues extends Partial<ILineResourceTypeFilter> {
  res_type_filter_description: string | null;
  res_type_filter_logicaloperator: string | null;
}

interface Props {
  currentResourceType: ILineResourceType | undefined;
  orgId: string;
}

interface IRTFilterFormState {
  type: "create" | "edit"
  filter: ILineResourceTypeFilter
  isModalVisible: boolean
  index: number | null
}

const initialFilter = {
  id: null,
  res_type_id: null,
  res_type_filter_name: '',
  res_type_filter_description: null,
  res_type_filter_logicaloperator: null,
  attributes: []
}

const isNormalRow = (row: ILineResourceTypeFilter | ILineResourceTypeFilterInternal): row is ILineResourceTypeFilter =>
  !("thisIsThatExtraRow" in row);

const UxLineResourceTypeFiltersTable: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypeFiltersTable');
  const [form] = useForm();
  const [newFilter, setNewFilter] = useState('');
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
    ResourceTypeFilterByResType,
    ResourceTypeFilterByResTypeVariables
  >(ResourceTypeFilterByResTypeQuery, {
    variables: {
      resTypeId: props.currentResourceType?.id!
    },
    skip: props.currentResourceType?.id == null
  });

  const [DeleteResourceTypeFilter, {loading: loadingDeleting}] = useMutation<DeleteResourceTypeFilter, DeleteResourceTypeFilterVariables>(deleteResourceTypeFilterMutation)
  const [rTFilterFormState, setRTFilterFormState] = useState<IRTFilterFormState>({type: "create", filter: initialFilter, isModalVisible: false, index: null});

  const handleCancel = () => {
    setRTFilterFormState({...rTFilterFormState, isModalVisible: false});
  };

  const showFilterModal = (type: "create" | "edit", filter: ILineResourceTypeFilter, index: number | null) => {
    setRTFilterFormState({type, filter, isModalVisible: true, index})
  }

  const onLineResourceTypeFilterAdd = (item: INewLineResourceTypeFilter) => {
    const newItem: ILineResourceTypeFilter = {
            id: null,
            res_type_id: props.currentResourceType?.id!,
            res_type_filter_name: item.res_type_filter_name,
            res_type_filter_description: null,
            res_type_filter_logicaloperator: '',
            attributes: []
          };

    showFilterModal("create", newItem, null);
  };

  const onItemDelete = (index: number, item: ILineResourceTypeFilter) => {
    setToRemoveIndex(index)
    const input = {filterId: item.id!};
    try{
      let res = DeleteResourceTypeFilter({variables: input, refetchQueries: [{ query: ResourceTypeFilterByResTypeQuery,
        variables: {
          resTypeId: props.currentResourceType?.id
        } }]});
      res.then(res => {
        message.success(res.data?.deleteResourceTypeFilter)
      }).catch(raison => {
        message.error("Failed to delete a filter")
        console.error(raison)
      })
    }catch(error){
      message.error("Failed to delete a filter")
      console.error(error)
    }
    setToRemoveIndex(index)
  };

  const columns: ColumnsType<ILineResourceTypeFilter | ILineResourceTypeFilterInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Filter Name"),
      key: "res_type_filter_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_filter_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <Space direction="horizontal" size="small">
                <Input
                  placeholder={t("Enter filter name...")}
                  onChange={e => setNewFilter(e.target.value)}
                />
                <Button
                  type="primary"
                  loading={loading}
                  disabled={newFilter === ''}
                  onClick={() => onLineResourceTypeFilterAdd({ res_type_filter_name: newFilter })}
                >
                  Add new
                </Button>
              </Space>
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Description"),
      key: "res_type_filter_description",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_filter_description}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Logical Operator"),
      key: "res_type_filter_logicaloperator",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_filter_logicaloperator}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{marginRight: '10px'}}
                loading={toRemoveIndex === index ? loadingDeleting : false}
              />
            </Popconfirm>
            <div style={{position: 'relative'}}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => showFilterModal("edit", row, index)}
              />
            </div>
          </div>
        ),
    },
  ];

  const lineResourceTypeFiltersWithExtra: (ILineResourceTypeFilter | ILineResourceTypeFilterInternal)[] = data?.resourceTypeFilterByResType ? [
    ...data.resourceTypeFilterByResType,
    { thisIsThatExtraRow: true },
  ]: [];

  return (
    <>
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypeFiltersWithExtra}
        pagination={false}
        loading={loading}
        size="small"
      />
    </Form>
    <UxResTypeFilterForm
      visible={rTFilterFormState.isModalVisible}
      mode={rTFilterFormState.type}
      onClose={() => handleCancel()}
      initialFilter={rTFilterFormState.filter}
      resTypeId={props.currentResourceType?.id ?? ""}
      orgId={props.orgId}
    />
    </>
  );
};

export default UxLineResourceTypeFiltersTable;
