import React, { useEffect, useState } from "react";
import { IAuthInfo } from "./Auth";
import { Row, Col, message } from "antd";
import LoadingPage from "./Loading";
import RegistrationForm from "../components/login/Registration";
import { useLocation, Link, Redirect } from "react-router-dom";
// import { verifyEmail } from "../slices/verifyEmail";
import { useAppDispatch, useAppSelector } from "../hooks";
import { VerifyEmail_verifyEmail as VerifyEmailResponse } from "../mutations/__generated__/VerifyEmail";
import { setApolloToken } from "../apolloClient";
import { setAuthToken } from "../authToken";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { RegisterOnInvitationMutation } from "../mutations/registerOnInvitation";
import { VerifyRegistrationInvitationQuery } from "../queries/verifyRegistrationInvitation";
import { RegisterOnInvitation, RegisterOnInvitationVariables } from "../mutations/__generated__/RegisterOnInvitation";
import { VerifyRegistrationInvitation, VerifyRegistrationInvitationVariables, VerifyRegistrationInvitation_verifyRegistrationInvitation as IVerifyRegistrationInvitation } from "../queries/__generated__/VerifyRegistrationInvitation";
import { GraphQLError } from "graphql";
import { RegisterInput } from "../__generated__/globalTypes";
import { flatten } from "../utils/flatten";
import { option } from "ts-option";

interface Props{
  onFinish: (authInfo: IAuthInfo) => void;
}

interface EGraphQLError extends GraphQLError{
  debugMessage: string 
}

export interface IUserJoinOrg {
  invitation_token: string
  email: string | undefined
  org_name: string | null
}

const InvitationVerifyEmail: React.FC<Props> = (props) => {

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token')
  const [user, setUser] = useState<IUserJoinOrg>()
  const [errors, setErrors] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const [RegisterOnInvitation, {data, error, loading}] = useMutation<
    RegisterOnInvitation,
    RegisterOnInvitationVariables
  >(RegisterOnInvitationMutation);
  const [invitationVerify, {
    data: dataVerify,
    loading: loadingVerify,
    error: errorOnVerify,
  }] = useLazyQuery<
    VerifyRegistrationInvitation,
    VerifyRegistrationInvitationVariables
  >(VerifyRegistrationInvitationQuery, {
    variables: {
      token: token!,
    },
    onCompleted: (data) => {
      let res = data.verifyRegistrationInvitation
      if(res.invitation_token && !res.access_token){
        // Registration
        setUser({
          invitation_token: res.invitation_token, 
          email: res.user?.email, 
          org_name: res.org_name
        })
      }else{
        // Login
        VerifyEmailRedirect({access_token: res.access_token, refresh_token: res.refresh_token,
          expires_in: res.expires_in, token_type: res.token_type, __typename: "AuthPayload", user: res.user })
      }
    },
    onError(error) {
      console.log('error')
      console.log(error.graphQLErrors)
    },
  });

  const onRegister = async (values: RegisterInput) => {
    setErrors([]);
    try {
      if(user?.invitation_token){
        let input = {
          invitationToken: user.invitation_token,
          firstName: values.first_name,
          lastName: values.last_name,
          password: values.password
        }
        const res = await RegisterOnInvitation({variables: input})

        if(res.data?.registerOnInvitation.tokens){
          VerifyEmailRedirect(res.data?.registerOnInvitation.tokens)
        }

        if (res.errors) {
          const errorMessages = flatten(
            res.errors.map((error) => {
              return option(error.extensions)
                .map((ext) =>
                  flatten(
                    Object.keys(ext["validation"]).map(
                      (key) => ext["validation"][key] as string[]
                    )
                  )
                )
                .getOrElseValue([error.message]);
            })
          );

          const cleanErrorMessages = errorMessages.map((message: any) =>
            message.replace("input.", "")
          );

          setErrors(cleanErrorMessages);

          message.warn("Registration input error, fix errors and submit again.");
        }
      }
    } catch (error) {
      setErrors(["Registration failed."]);
      console.error(error);
    }
  };

  const VerifyEmailRedirect = (data : VerifyEmailResponse | null) => {
    if(data?.user){
      const { id, email, first_name, last_name, lang, activeOrganization } = data?.user;
      const authToken = data?.access_token;

      if (authToken) {
        setApolloToken(authToken);
        setAuthToken(authToken);
      }

      props.onFinish({
        id,
        lang,
        email,
        first_name,
        last_name,
        active_organization: {
          id: activeOrganization?.id || null,
          org_name: activeOrganization?.org_name || null,
        }
      });
    }
  }

  useEffect(() => {
    if(token){
      invitationVerify()
    }
  }, [token])

  if(loadingVerify){
    return <LoadingPage />; 
  }else if(errorOnVerify){
    return <div style={{textAlign: "center"}}>
      <h1>ERROR</h1>
      <h3>{(errorOnVerify?.graphQLErrors[0] as EGraphQLError).debugMessage}</h3>
      <h3>{errorOnVerify.message}</h3>
      <Link to="/" className="ant-btn ant-btn-primary">Go back to the home page</Link>
    </div>
  }else if(user){
    return (
      <Row
        justify="center"
        align="middle"
        style={{ height: "70vh", margin: "1em" }}
      >
        <Col span={16}>
            <RegistrationForm
              joinOrg={user}
              verifyEmail={false}
              errors={errors}
              loading={loading}
              onFinish={onRegister}
              initialValues={{}}
            />
        </Col>
      </Row>
    );
  }else{
    return <LoadingPage />;
  }
}

export default InvitationVerifyEmail;