import React, { useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload"
import { FormInstance, message, Upload } from 'antd';
import { fetchFilesDataQuery } from "../queries/fetchFilesData";
import {
  fetchFilesData,
  fetchFilesDataVariables,
  fetchFilesData_fetchFilesData as FileData
} from "../queries/__generated__/fetchFilesData";
import { UploadFile } from 'antd/lib/upload/interface';
import { b64toBlob } from './DisplayBinaryIcon';
import { useAppSelector } from '../hooks';

interface Props {
  type: string;
  mode: string;
  code: string;
  form: FormInstance;
  disabled: boolean;
}

export const UploadManyFiles: React.FC<Props> = (props) => {
  const [fileList, setFileList] = useState<UploadFile<unknown>[] | undefined>([]);
  const resourceRecord = useAppSelector(state => state.resourceRecord.data)
  const ids = resourceRecord ? resourceRecord[props.code] : null
  const valField = props.form.getFieldValue(props.code)
  const { data, loading, error } = useQuery<
    fetchFilesData,
    fetchFilesDataVariables
  >(fetchFilesDataQuery, {
    variables: {
      id: ids,
    },
    skip: (ids == null) || (props.mode == 'create') || (!!valField && typeof(valField) == 'object'),
    onCompleted: (data) => {
      if(data.fetchFilesData){
        var newFileList: UploadFile<unknown>[] = data.fetchFilesData.map(elt => {
          var file = new Blob([b64toBlob(elt.code)], {type: elt.mimetype});
          return {
            uid: elt.id,
            name: elt.filename,
            status: 'done',
            url: URL.createObjectURL(file), 
          }
        });
        setFileList(newFileList)
        props.form.setFieldsValue({[props.code]: {fileList: newFileList}})
      }
    }
  });
  
  useEffect(() => {
    if(valField){
      const {fileList} = valField
      if(fileList){
        setFileList(fileList);
      }
    }
  }, [])
  
  const listProps = () => {
    const type = props.type;
    return {
      beforeUpload: (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng && type === "image") {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('File must smaller than 2MB!');
        }
        return !isLt2M || (!isJpgOrPng && type === "image")  ? Upload.LIST_IGNORE : false;
      },
      accept: type === "audio" ? "audio/*" : type === "image" ? "image/*" : "*",
      multiple: true,
      maxCount: 5,
    }
  }
  return (
      <Upload disabled={props.disabled} fileList={fileList ? [...fileList] : []} {...listProps()} onChange={info => {
        props.form.setFieldsValue({[props.code]: info})
        setFileList(info.fileList)
      }}>
        <div className="ant-upload-container">
          <p className="ant-upload-dragger-icon">
            <CloudUploadOutlined />
          </p>
          <p>Select File</p>
        </div>
      </Upload>
  )
}
