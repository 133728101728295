import { Badge, Card, Spin } from 'antd';
import { ReactNode, useState } from 'react';
import UxLineResourceTypeAttributesTable, { ILineFormValues, ILineResourceTypeAttribute } from '../components/generic/UxLineResourceTypeAttributesTable'
import { ILineResourceType } from '../components/generic/UxLineResourceTypesTable';
import { useAppSelector } from '../hooks';
interface Props {
  onDrawerInfosHandle: (elt: ReactNode, title: string) => void;
}

const TableFieldsEdit: React.FC<Props> = (props) => {
  const [currentResourceType, setCurrentResourceType] = useState<ILineResourceType>()
  useAppSelector((state) => {
    const resType = state.resourceType.data;
    if(resType && !currentResourceType){
      setCurrentResourceType({...resType, attributes: []})
    }
  });
  const {data: app, loading: loadingApp} = useAppSelector(state => state.application)

  const setAttributes = (data: ILineResourceTypeAttribute[] | null) => {
      let updateRes = JSON.parse(JSON.stringify(currentResourceType));
      updateRes = {...updateRes, attributes: data};
      setCurrentResourceType(updateRes)
  }

  const onLineResourceTypeAttributeAdd = (item: ILineResourceTypeAttribute) => {
    if(currentResourceType){
      let updateRes = JSON.parse(JSON.stringify(currentResourceType));
      updateRes.attributes.push(item);
      setCurrentResourceType(updateRes)
    }
  };

  const onLineResourceTypeAttributeChange = (index: number, newItem: ILineFormValues) => {
    if(currentResourceType){
      let updateRes = JSON.parse(JSON.stringify(currentResourceType));
      const { res_type_attribute_type, ...updateNewItem} = {...newItem}
      updateRes.attributes[index] = updateNewItem as ILineResourceTypeAttribute
      if(newItem.res_type_attribute_type){
        updateRes.attributes[index].specification.res_type_attribute_type = newItem.res_type_attribute_type
      }
      setCurrentResourceType(updateRes)
    }
  };

  const onLineResourceTypeAttributeRemove = (index: number) => {
    if(currentResourceType){
      let updateRes = JSON.parse(JSON.stringify(currentResourceType));
      updateRes.attributes.splice(index, 1)
      setCurrentResourceType(updateRes)
    }
  };
  
  const onFieldSaveOrUpdate = (item: ILineResourceTypeAttribute, index: number | null) => {
    if(index !== null){
      onLineResourceTypeAttributeChange(index, item)
    }else{
      onLineResourceTypeAttributeAdd(item)
    }
  }

  if(app?.organization?.id && currentResourceType){
    return (
      <Card
        title={<div>
          <span>Edit table fields </span><Badge
          count={currentResourceType.res_type_name}
          style={{ backgroundColor: '#52c41a' }}
        />
        </div>}
      >
        <UxLineResourceTypeAttributesTable
          disableProperties={true}
          onLineAdd={onLineResourceTypeAttributeAdd}
          onLineRemove={onLineResourceTypeAttributeRemove}
          onLineChange={onLineResourceTypeAttributeChange}
          currentResourceType={currentResourceType}
          setAttributes={setAttributes}
          onLineSaveOrUpdate={onFieldSaveOrUpdate}
          orgId={app!.organization.id}
          saveMode='database'
        />
      </Card>
    )
  }else{
    return <Spin size="large" />
  }
}

export default TableFieldsEdit
