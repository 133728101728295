import { gql } from "@apollo/client";

export const CreateResourceTypeFromImportMutation = gql`
    mutation CreateResourceTypeFromImport($appId: String!, $resTypeName: String!, $file: Upload, $csvDelimiter: String){
    createResourceTypeFromImport(input: {
      app_id: $appId
      res_type_name: $resTypeName
      file: $file
      csv_delimiter: $csvDelimiter
    })
  }
`;