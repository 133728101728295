import { useEffect, useState } from "react";
import { Modal, Form, Spin, Steps, Button, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SaveOutlined } from "@ant-design/icons";
import {
  createResourceRecord,
  fetchResourceRecord,
  updateResourceRecord,
} from "../slices/resourceRecordSlice";
import {
  attributeToForm,
  resourceTypeToFormItems,
} from "./helpers/resourceTypeToFormItems";
import userDataForm from "./userDataForm";
import "./resourceRecordForm.css";
import { capitalize, isNil } from "lodash";
import moment from "moment";

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { fetchResourceRecords } from "../slices/resourceRecordsSlice";
import { useQuery } from "@apollo/client";
import { ResourceTypeVariables } from "../queries/__generated__/ResourceType";
import { ResourceTypeQuery } from "../queries/resourceType";
import { fetchApplicationDetails_application_resourceTypes as AppResourceType } from '../queries/__generated__/fetchApplicationDetails';

type Props = {
  mode: "create" | "edit";
};

interface ResourceType {
  resourceType: AppResourceType;
}

export const ResourceRecordForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { applicationId, resourceTypeId, recordId, linkResTypeId, resTypeResourceId, drilledRecordId } =
    useParams<{
      applicationId: string;
      resourceTypeId: string;
      recordId?: string;
      linkResTypeId?: string;
      resTypeResourceId?: string;
      drilledRecordId?: string;
    }>();
  const { loading: loadingResourceType, data: resourceTypeData, refetch } = useQuery<ResourceType, ResourceTypeVariables>(ResourceTypeQuery, {
    variables: {
      id: !isNil(linkResTypeId) ? linkResTypeId : resourceTypeId
    }
  });
  const resourceTypeState = resourceTypeData?.resourceType
  const orgId = useAppSelector(
    (state) => state.application.data?.organization?.id
  );
  const resourceRecordState = useAppSelector((state) => {
    let initialResourceRecord: any = null;
    if (resourceTypeState && state.resourceRecord.data && props.mode === "edit") {
      initialResourceRecord = JSON.parse(
        JSON.stringify(state.resourceRecord.data)
      );
      let formatDate:any = {date: "YYYY-MM-DD", time: "HH:mm:ss", datetime: "YYYY-MM-DD HH:mm:ss", selectYear: "YYYY", selectMonth:"mm"}
      resourceTypeState.attributes.map((attr) => {
        if (
          Object.keys(formatDate).includes(
            attr.specification.res_type_attribute_type
          )
        ) {
          initialResourceRecord[attr.specification.res_type_attribute_code] =
            initialResourceRecord[attr.specification.res_type_attribute_code]
            ? moment(
              initialResourceRecord[
                attr.specification.res_type_attribute_code
              ], formatDate[attr.specification.res_type_attribute_type]) 
            : moment("00:00:00", "HH:mm:ss")
        }
        if(attr.linked_id){
          initialResourceRecord[attr.specification.res_type_attribute_code] =
            initialResourceRecord[attr.specification.res_type_attribute_code]
              ? JSON.parse(initialResourceRecord[
                attr.specification.res_type_attribute_code
              ]).id
              : null;
        }
      });
      // Avoid form refresh when navigating between steps
      const isEmpty = Object.keys(form.getFieldsValue()).length === 0
      if(isEmpty){
        form.setFieldsValue(initialResourceRecord)
      }
    }
    return state.resourceRecord
  });
  const [formState, setFormState] = useState({});
  const [userData, setUserData] = useState({});
  const { Step } = Steps;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    form
      .validateFields()
      .then((data) => {
        // TODO : do not allow user set group_name to "User"
        if (
          arrSteps &&
          arrSteps[current].title.toLocaleLowerCase() === "user"
        ) {
          setUserData(data);
        } else {
          setFormState({ ...formState, ...data });
        }
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (recordId && orgId) {
      if(!isNil(linkResTypeId)){
        dispatch(fetchResourceRecord({ orgId, resourceTypeId: linkResTypeId, recordId }));
      }else{
        dispatch(fetchResourceRecord({ orgId, resourceTypeId, recordId }));
      }
    }
  }, [recordId, orgId, resourceTypeData]);

  const onClose = () => {
    form.resetFields()
    history.push(
      `/view/applications/${applicationId}/tables/${resourceTypeId}`
    );
  }
    

  const onOk = () => {
    if (orgId) {
      form
        .validateFields()
        .then((data) => {
          resourceTypeState?.attributes.map(item => {
            if(item.specification.res_type_attribute_type == 'datetime'){
              data[item.specification.res_type_attribute_code] = moment(data[item.specification.res_type_attribute_code]).format("YYYY-MM-DD hh:mm:ss")
            }else if(item.specification.res_type_attribute_type == 'date'){
              data[item.specification.res_type_attribute_code] = moment(data[item.specification.res_type_attribute_code]).format("YYYY-MM-DD")
            }
          })

          let promise;
          if (
            arrSteps &&
            arrSteps[current].title.toLocaleLowerCase() === "user"
          ) {
            promise =
              props.mode === "create"
                ? dispatch(
                    createResourceRecord({
                      drilledRecordId, // specific record in parent res_type
                      resTypeResourceId,
                      resourceTypeId: !isNil(linkResTypeId) ? linkResTypeId : resourceTypeId, // child res_type
                      orgId,
                      data: formState,
                      user: data,
                    })
                  )
                : dispatch(
                    updateResourceRecord({
                      recordId: recordId!,
                      resourceTypeId: !isNil(linkResTypeId) ? linkResTypeId : resourceTypeId,
                      orgId,
                      data: formState,
                      user: data,
                    })
                  );
          } else {
            data = { ...formState, ...data };
            promise =
              props.mode === "create"
                ? dispatch(
                    createResourceRecord({
                      drilledRecordId,
                      resTypeResourceId,
                      resourceTypeId: !isNil(linkResTypeId) ? linkResTypeId : resourceTypeId,
                      orgId,
                      data,
                      user: userData,
                    })
                  )
                : dispatch(
                    updateResourceRecord({
                      recordId: recordId!,
                      resourceTypeId: !isNil(linkResTypeId) ? linkResTypeId : resourceTypeId,
                      orgId,
                      data,
                      user: userData,
                    })
                  );
          }
          promise.then(
            () => {
              if(props.mode === "create"){
                message.success("Record created !")
              }else{
                message.success("Record updated !")
              }
              dispatch(fetchResourceRecords({ orgId, resourceTypeId }));
              history.push(
                `/view/applications/${applicationId}/tables/${resourceTypeId}`
              )
            },
            (error) => console.error(error)
          );
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  // TODO : do not allow user set group_name to "OTHERS"
  const groupNames = resourceTypeState?.attributes
    .map((attr) => attr.res_type_attribute_type_group)
    ?.map((elt) => (elt ? elt : "OTHERS"));

  const onlyUnique = (
    value: string | null,
    index: number,
    self: (string | null)[]
  ) => {
    return self && value ? self.indexOf(value) === index : false;
  };

  var uniqueGroupNames = groupNames?.filter(onlyUnique);

  const arrSteps = uniqueGroupNames?.map((val) => {
    let formContent = resourceTypeState?.attributes
      .filter((attr) => {
        return (
          attr.res_type_attribute_type_group == val ||
          ((attr.res_type_attribute_type_group == null ||
            attr.res_type_attribute_type_group.length === 0) &&
            val === "OTHERS")
        );
      })
      .map((attr) => attributeToForm(attr, form, props.mode));

    return {
      title: val,
      content: formContent,
    };
  });

  if (resourceTypeState?.res_type_user_map === 1) {
    arrSteps?.push({ title: "User", content: [userDataForm()] });
  }

  const lastStep = arrSteps?.find((elt) => elt.title === "OTHERS");

  if (lastStep) {
    arrSteps?.push(arrSteps?.splice(arrSteps?.indexOf(lastStep), 1)[0]);
  }

  if (resourceTypeState && arrSteps) {
    const formItems = resourceTypeToFormItems(resourceTypeState, form, props.mode);
    return (
    <ConfigProvider locale={enUS}>
      <Modal
        visible={true}
        onCancel={onClose}
        footer={
          arrSteps.length < 2
            ? [
                <>
                  <Button type="primary" icon={<SaveOutlined />} onClick={onOk}>
                    {props.mode === "create" ? "Create" : "Update"}
                  </Button>
                  <Button type="default" onClick={onClose}>
                    {"Cancel"}
                  </Button>
                </>,
              ]
            : []
        }
        title={props.mode === "create" ? "Create new record" : "Update record"}
        width="60%"
        confirmLoading={resourceRecordState.loading}
      >
        {(resourceRecordState.loading || resourceRecordState.data === null) &&
        props.mode == "edit" ? (
          <Spin style={{ textAlign: "center" }} />
        ) : (
          <Form {...layout} form={form}>
            {arrSteps.length < 2 ? (
              formItems
            ) : (
              <>
                <Steps current={current}>
                  {arrSteps.map((item) => (
                    <Step
                      key={item.title}
                      title={item.title ? capitalize(item.title) : ""}
                    />
                  ))}
                </Steps>
                <div className="steps-content">{arrSteps[current].content}</div>
                <div className="steps-action">
                  <Button
                    type="default"
                    onClick={() => onClose()}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  {current < arrSteps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                      Next
                    </Button>
                  )}
                  {current === arrSteps.length - 1 && (
                    <Button type="primary" onClick={() => onOk()}>
                      {props.mode === "create" ? "Create" : "Update"}
                    </Button>
                  )}
                  {current > 0 && (
                    <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                      Previous
                    </Button>
                  )}
                </div>
              </>
            )}
          </Form>
        )}
      </Modal>
    </ConfigProvider>
    );
  } else {
    return <></>;
  }
};
