import React, { useEffect, useState } from "react";
import { Table, Dropdown, Menu, Button, Empty } from 'antd';
import { useAppDispatch, useAppSelector } from "../hooks";
import { Link, useHistory } from "react-router-dom";
import { fetchOrganizations } from "../slices/organizationsSlice";
import { deleteOrganization } from "../slices/organizationSlice";
import LoadingPage from "../pages/Loading";
import InviteUser from "./InviteUser";

export const OrganizationsTable: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.organizations.loading);
  const organizations = useAppSelector((state) => state.organizations.data);
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedOrgId, setSelectedOrgId] = useState("")

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const inviteOrg = (orgId: any) => {
    setSelectedOrgId(orgId)
    setIsModalVisible(true)
  }

  const menu = (orgId:any) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={"/organizations/"+orgId+"/edit"} >Edit</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => (window.confirm("Do you want to delete this org ?") == true) ? deleteOrg(orgId) : ""}>Delete</Menu.Item>
      <Menu.Item key="3" onClick={() => inviteOrg(orgId)}>
        Invite
      </Menu.Item>
    </Menu>
  );
  const deleteOrg = (orgId: any) => {
    dispatch(deleteOrganization({orgId}))
  }
  const activateOrg = (orgId: any) => {
    alert('This feature will be available soon')
  }
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'org_name',
      sorter: (a: any, b: any) => a.org_name.length - b.org_name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Description',
      dataIndex: 'org_description',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => a.org_description - b.org_description,
    },
    {
      title: 'Address',
      dataIndex: 'org_address',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text:any, record:any) => (
        <Dropdown.Button type="primary" overlay={menu(record.id)} onClick={() => activateOrg(record.id)}>Select</Dropdown.Button>
      ),
    },
  ];
  const data: any = !organizations ? [] : organizations.map(org => ({...org, key: org.id})) 

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [organizations]);

  if(organizations && organizations?.length){
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h1>My organizations</h1>
          <Button type="primary" onClick={() => history.push('/organizations/create')}>Create New</Button>
        </div>
        <Table columns={columns} dataSource={data} />
        <InviteUser isModalVisible={isModalVisible} orgId={selectedOrgId} handleCancel={() => handleCancelModal()}  />
      </>
    )
  }else if(loading){
    return <LoadingPage />
  }else{
    return <Empty description="No org to display."></Empty>
  }
  
}

