import { Input, Select, Tabs, Col, Row, Form, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import ResourceTypeAttributeTypeSelect from "./generic/LineResTypeAttributeTypeSelect";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import SimpleUxLineResourceTypeAttributesTable from "./generic/SimpleUxLineResourceTypeAttributesTable";
import { ILineResourceTypeAttribute } from "./generic/UxLineResourceTypeAttributesTable";
import { ResourceTypeQuery } from "../queries/resourceType";
import { useQuery } from "@apollo/client";
import { ResourceType, ResourceTypeVariables, ResourceType_resourceType } from "../queries/__generated__/ResourceType";

interface Props {
  spec: string | null;
  resTypeId: string;
  setSpec: (spec: string) => void;
}

const UxAPICallSpecification: React.FC<Props> = (props) => {
  const [form] = useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  let auth_type = props.spec && JSON.parse(props.spec).authorization ? JSON.parse(props.spec).authorization.auth_type : "no-auth"

  const { data, loading, error } = useQuery<
  ResourceType, ResourceTypeVariables
  >(ResourceTypeQuery, {
    variables: {
      id: props.resTypeId,
    },
    skip: (props.resTypeId == null) || !props.resTypeId,
  });

  useEffect(() => {
    if (props.spec?.length) {
      form.setFieldsValue({
        spec_method: JSON.parse(props.spec).method,
        spec_url: JSON.parse(props.spec).target})

      if(JSON.parse(props.spec).params && JSON.parse(props.spec).params.length){
        JSON.parse(props.spec).params.map((item:any, i:any) => {
          form.setFields([{name: ['param_key', i], value: item.key}, {name: ['param_value', i], value: item.value}])
        })
      }

      if(JSON.parse(props.spec).dynamic_params && JSON.parse(props.spec).dynamic_params.length){
        JSON.parse(props.spec).dynamic_params.map((item:any, i:any) => {
          form.setFields([{name: ['dynamic_param_key', i], value: item.key}, {name: ['dynamic_param_value', i], value: item.value}])
        })
      }

      if(JSON.parse(props.spec).path_variables && JSON.parse(props.spec).path_variables.length){
        JSON.parse(props.spec).path_variables.map((item:any, i:any) => {
          form.setFields([{name: ['path_variable_key', i], value: item.key}, {name: ['path_variable_value', i], value: item.value}])
        })
      }
    }
  }, [props]);

  const onLineResourceTypeAttributeAdd = (item: ILineResourceTypeAttribute) => {
    if(props.spec?.length && JSON.parse(props.spec).fields?.length){
        let fields = JSON.parse(props.spec).fields
        fields.push(item)

        props.setSpec(
          JSON.stringify({
            ...JSON.parse(props.spec),
            fields,
          })
        )
      }else if(props.spec?.length){
        props.setSpec(
          JSON.stringify({
            ...JSON.parse(props.spec),
            fields: [item],
          })
        )
      }else{
        props.setSpec(
          JSON.stringify({
            fields: [item],
          })
        )
      }
  }

  const onLineResourceTypeAttributeRemove = (index: number, item: ILineResourceTypeAttribute) => {
    if(props.spec?.length && JSON.parse(props.spec).fields?.length){
      let fields = JSON.parse(props.spec).fields
      fields.splice(index, 1);
      props.setSpec(
        JSON.stringify({
          ...JSON.parse(props.spec),
          fields,
        })
      )
    }
  }

  return (
    <div>
      <Form form={form}>
        <Input.Group style={{ display: "flex" }}>
          <Form.Item name={"spec_method"} style={{ width: "12%" }}>
            <Select
              defaultValue={"GET"}
              onChange={(val) =>
                props.spec?.length
                  ? props.setSpec(
                      JSON.stringify({ ...JSON.parse(props.spec), method: val })
                    )
                  : props.setSpec(JSON.stringify({ method: val }))
              }
            >
              <Option value={"GET"}>GET</Option>
              <Option value={"POST"}>POST</Option>
              <Option value={"PUT"}>PUT</Option>
            </Select>
          </Form.Item>
          <Form.Item name={"spec_url"} style={{ width: "88%" }}>
            <Input
              type={"url"}
              onChange={(e) =>
                props.spec?.length
                  ? props.setSpec(
                      JSON.stringify({
                        ...JSON.parse(props.spec),
                        target: e.target.value,
                      })
                    )
                  : props.setSpec(JSON.stringify({ target: e.target.value }))
              }
            />
          </Form.Item>
        </Input.Group>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Params" key="1">
            <Row>
              <Col span={11}>KEY</Col>
              <Col span={11}>VALUE</Col>
            </Row>
            {
              (props.spec?.length && JSON.parse(props.spec).params?.length) ? JSON.parse(props.spec).params.map((item:any, i:any) => 
              <Row>
                <Col span={11}>
                  <Form.Item name={["param_key", i]}>
                    <Input
                      onChange={(e) => {
                          if(props.spec?.length && JSON.parse(props.spec).params?.length){
                            let params = JSON.parse(props.spec).params
                            let obj = {...params[i], key: e.target.value}
                            params[i] = obj

                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                params,
                              })
                            )
                          }else if(props.spec?.length){
                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                params: [{ key: e.target.value }],
                              })
                            )
                          }else{
                            props.setSpec(
                              JSON.stringify({
                                params: [{ key: e.target.value }],
                              })
                            )
                          }
                        }
                      }
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item name={["param_value", i]}>
                    <Input
                      onChange={(e) => {
                          if(props.spec?.length && JSON.parse(props.spec).params?.length){
                            let params = JSON.parse(props.spec).params
                            let obj = {...params[i], value: e.target.value}
                            params[i] = obj
                            
                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                params,
                              })
                            )
                          }else if(props.spec?.length){
                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                params: [{ value: e.target.value }],
                              })
                            )
                          }else{
                            props.setSpec(
                              JSON.stringify({
                                params: [{ value: e.target.value }],
                              })
                            )
                          }
                        }
                      }
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={2} style={{display: 'flex', justifyContent: 'end'}}>
                  {(i==0) ? <Button type="primary" onClick={() => (props.spec?.length) ? props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                params: [...JSON.parse(props.spec).params, {}],
                              })
                            ) : ''}><PlusOutlined /></Button> : <Button danger onClick={() => {
                              if(props.spec?.length){
                                var newParams:Array<any> = JSON.parse(props.spec).params
                                newParams.splice(i,1)
                                return props.setSpec(
                                  JSON.stringify({
                                    ...JSON.parse(props.spec),
                                    params: [...newParams],
                                  })
                                )
                              }else{
                                return ''
                              }
                            }}><MinusOutlined /></Button>}
                </Col>
              </Row>
              ) : ''
            }
          </TabPane>
          <TabPane tab="Dynamic params" key="2">
            {
              (props.spec?.length && JSON.parse(props.spec).dynamic_params?.length) ? JSON.parse(props.spec).dynamic_params.map((item:any, i:any) => <Row>
              <Col span={11}>
                <Form.Item name={["dynamic_param_key", i]}>
                  <Input placeholder="key"
                    onChange={(e) => {
                      if(props.spec?.length && JSON.parse(props.spec).dynamic_params?.length){
                        let dynamic_params = JSON.parse(props.spec).dynamic_params
                        let obj = {...dynamic_params[i], key: e.target.value}
                        dynamic_params[i] = obj
                        
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            dynamic_params,
                          })
                        )
                      }else if(props.spec?.length){
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            dynamic_params: [{ key: e.target.value }],
                          })
                        )
                      }else{
                        props.setSpec(
                          JSON.stringify({
                            dynamic_params: [{ key: e.target.value }],
                          })
                        )
                      }
                    }
                  }
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={11}>
                <ResourceTypeAttributeTypeSelect label=""
                  name={["dynamic_param_value", i]} onChange={(val) => {
                    if(props.spec?.length && JSON.parse(props.spec).dynamic_params?.length){
                      let dynamic_params = JSON.parse(props.spec).dynamic_params
                      let obj = {...dynamic_params[i], value: val}
                      dynamic_params[i] = obj
                      
                      props.setSpec(
                        JSON.stringify({
                          ...JSON.parse(props.spec),
                          dynamic_params,
                        })
                      )
                    }else if(props.spec?.length){
                      props.setSpec(
                        JSON.stringify({
                          ...JSON.parse(props.spec),
                          dynamic_params: [{ value: val }],
                        })
                      )
                    }else{
                      props.setSpec(
                        JSON.stringify({
                          dynamic_params: [{ value: val }],
                        })
                      )
                    }
                  }
                }
                resTypeId={props.resTypeId} />
              </Col>
              <Col span={2} style={{display: 'flex', justifyContent: 'end'}}>
                  {(i==0) ? <Button type="primary" onClick={() => (props.spec?.length) ? props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                dynamic_params: [...JSON.parse(props.spec).dynamic_params, {}],
                              })
                            ) : ''}><PlusOutlined /></Button> : <Button danger onClick={() => {
                              if(props.spec?.length){
                                var newDynamicParams:Array<any> = JSON.parse(props.spec).dynamic_params
                                newDynamicParams.splice(i,1)
                                return props.setSpec(
                                  JSON.stringify({
                                    ...JSON.parse(props.spec),
                                    dynamic_params: [...newDynamicParams],
                                  })
                                )
                              }else{
                                return ''
                              }
                            }}><MinusOutlined /></Button>}
                </Col>
            </Row>) : ""
            }
          </TabPane>
          <TabPane tab="Path Variables" key="3">
            {
              (props.spec?.length && JSON.parse(props.spec).path_variables?.length) ? JSON.parse(props.spec).path_variables.map((item:any, i:any) => <Row>
              <Col span={11}>
                <Form.Item name={["path_variable_key", i]}>
                  <Input placeholder="key"
                    onChange={(e) => {
                      if(props.spec?.length && JSON.parse(props.spec).path_variables?.length){
                        let path_variables = JSON.parse(props.spec).path_variables
                        let obj = {...path_variables[i], key: e.target.value}
                        path_variables[i] = obj
                        
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            path_variables,
                          })
                        )
                      }else if(props.spec?.length){
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            path_variables: [{ key: e.target.value }],
                          })
                        )
                      }else{
                        props.setSpec(
                          JSON.stringify({
                            path_variables: [{ key: e.target.value }],
                          })
                        )
                      }
                    }
                  }
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={11}>
                <ResourceTypeAttributeTypeSelect label=""
                  name={["path_variable_value", i]} onChange={(val) => {
                    if(props.spec?.length && JSON.parse(props.spec).path_variables?.length){
                      let path_variables = JSON.parse(props.spec).path_variables
                      let obj = {...path_variables[i], value: val}
                      path_variables[i] = obj
                      
                      props.setSpec(
                        JSON.stringify({
                          ...JSON.parse(props.spec),
                          path_variables,
                        })
                      )
                    }else if(props.spec?.length){
                      props.setSpec(
                        JSON.stringify({
                          ...JSON.parse(props.spec),
                          path_variables: [{ value: val }],
                        })
                      )
                    }else{
                      props.setSpec(
                        JSON.stringify({
                          path_variables: [{ value: val }],
                        })
                      )
                    }
                  }
                }
                resTypeId={props.resTypeId} />
              </Col>
              <Col span={2} style={{display: 'flex', justifyContent: 'end'}}>
                  {(i==0) ? <Button type="primary" onClick={() => (props.spec?.length) ? props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                path_variables: [...JSON.parse(props.spec).path_variables, {}],
                              })
                            ) : ''}><PlusOutlined /></Button> : <Button danger onClick={() => {
                              if(props.spec?.length){
                                var newPathVariables:Array<any> = JSON.parse(props.spec).path_variables
                                newPathVariables.splice(i,1)
                                return props.setSpec(
                                  JSON.stringify({
                                    ...JSON.parse(props.spec),
                                    path_variables: [...newPathVariables],
                                  })
                                )
                              }else{
                                return ''
                              }
                            }}><MinusOutlined /></Button>}
                </Col>
            </Row>) : ""
            }
          </TabPane>
          <TabPane tab="Authorization" key="4">
            <div style={{display: 'flex', justifyContent: "space-around"}}>
              <Form.Item name={"auth-type"} initialValue={auth_type} style={{width: "17%"}}>
                <Select onChange={(val) => {
                        if(props.spec?.length){
                          props.setSpec(
                            JSON.stringify({
                              ...JSON.parse(props.spec),
                              authorization: { auth_type: val },
                            })
                          )
                        }else{
                          props.setSpec(
                            JSON.stringify({
                              authorization: { auth_type: val },
                            })
                          )
                        }
                      }}>
                  <Option value={"basic-auth"}>Basic auth</Option>
                  <Option value={"bearer-token"}>Bearer token</Option>
                  <Option value={"no-auth"}>No auth</Option>
                </Select>
              </Form.Item>
              {
                (auth_type == "bearer-token") ?
                    (<Form.Item name={'token'} style={{width: "80%"}} initialValue={props.spec?.length ? JSON.parse(props.spec).authorization?.token : ''}>
                  <Input placeholder="Enter your token"
                    onChange={(e) => {
                      if(props.spec?.length){
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            authorization: {
                              ...JSON.parse(props.spec).authorization,
                              token: e.target.value
                            },
                          })
                        )
                      }else{
                        props.setSpec(
                          JSON.stringify({
                            authorization: {
                              auth_type: 'basic-token',
                              token: e.target.value
                            },
                          })
                        )
                      }
                    }}
                  ></Input>
                </Form.Item>) : (auth_type == "basic-auth") ? <div style={{width: "80%"}}>
                    <Form.Item name="username" label="Username" initialValue={props.spec?.length ? JSON.parse(props.spec).authorization?.auth_values?.username : ''}>
                      <Input placeholder="username"
                        onChange={(e) => {
                          if(props.spec?.length){
                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                authorization: {
                                  auth_type: 'basic-auth',
                                  auth_values: {
                                    ...JSON.parse(props.spec).authorization.auth_values,
                                    username: e.target.value
                                  }
                                },
                              })
                            )
                          }else{
                            props.setSpec(
                              JSON.stringify({
                                authorization: {
                                  auth_type: 'basic-auth',
                                  auth_values: {
                                    username: e.target.value
                                  }
                                },
                              })
                            )
                          }
                        }}
                      ></Input>
                    </Form.Item>
                    <Form.Item name="password" label="Password" initialValue={props.spec?.length ? JSON.parse(props.spec).authorization?.auth_values?.password : ''}>
                      <Input.Password placeholder="password"
                        onChange={(e) => {
                          if(props.spec?.length){
                            props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                authorization: {
                                  auth_type: 'basic-auth',
                                  auth_values: {
                                    ...JSON.parse(props.spec).authorization.auth_values,
                                    password: e.target.value
                                  }
                                },
                              })
                            )
                          }else{
                            props.setSpec(
                              JSON.stringify({
                                authorization: {
                                  auth_type: 'basic-auth',
                                  auth_values: {
                                    password: e.target.value
                                  }
                                },
                              })
                            )
                          }
                        }}
                      ></Input.Password>
                    </Form.Item>
                  </div> : <p style={{width: "80%"}}>This request does not use any authorization.</p> 
              }
            </div>
          </TabPane>
          <TabPane tab="Headers" key="5">
            {
              (props.spec?.length && JSON.parse(props.spec).headers?.length) ? JSON.parse(props.spec).headers.map((item:any, i:any) => <Row>
              <Col span={11}>
                <Form.Item name={["header_key", i]} initialValue={props.spec?.length ? JSON.parse(props.spec).headers[i].key : ''}>
                  <Input placeholder="key" 
                    onChange={(e) => {
                      if(props.spec?.length && JSON.parse(props.spec).headers?.length){
                        let headers = JSON.parse(props.spec).headers
                        let obj = {...headers[i], key: e.target.value}
                        headers[i] = obj
                        
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            headers,
                          })
                        )
                      }else if(props.spec?.length){
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            headers: [{ key: e.target.value }],
                          })
                        )
                      }else{
                        props.setSpec(
                          JSON.stringify({
                            headers: [{ key: e.target.value }],
                          })
                        )
                      }
                    }
                  }></Input>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name={["header_value", i]} initialValue={props.spec?.length ? JSON.parse(props.spec).headers[i].value : ''}>
                  <Input placeholder="value" 
                    onChange={(e) => {
                      if(props.spec?.length && JSON.parse(props.spec).headers?.length){
                        let headers = JSON.parse(props.spec).headers
                        let obj = {...headers[i], value: e.target.value}
                        headers[i] = obj
                        
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            headers,
                          })
                        )
                      }else if(props.spec?.length){
                        props.setSpec(
                          JSON.stringify({
                            ...JSON.parse(props.spec),
                            headers: [{ value: e.target.value }],
                          })
                        )
                      }else{
                        props.setSpec(
                          JSON.stringify({
                            headers: [{ value: e.target.value }],
                          })
                        )
                      }
                    }
                  }></Input>
                </Form.Item>
              </Col>
              <Col span={2} style={{display: 'flex', justifyContent: 'end'}}>
                  {(i==0) ? <Button type="primary" onClick={() => (props.spec?.length) ? props.setSpec(
                              JSON.stringify({
                                ...JSON.parse(props.spec),
                                headers: [...JSON.parse(props.spec).headers, {}],
                              })
                            ) : ''}><PlusOutlined /></Button> : <Button danger onClick={() => {
                              if(props.spec?.length){
                                var newHeaders:Array<any> = JSON.parse(props.spec).headers
                                newHeaders.splice(i,1)
                                return props.setSpec(
                                  JSON.stringify({
                                    ...JSON.parse(props.spec),
                                    headers: [...newHeaders],
                                  })
                                )
                              }else{
                                return ''
                              }
                            }}><MinusOutlined /></Button>}
                </Col>
            </Row>) : ""
            }
          </TabPane>
          <TabPane tab="Body" key="6">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <ResourceTypeAttributeTypeSelect label="" inputStyle={{width: '200px'}} valType="name" name={"dynamic_param_value"} onChange={(val) => {
                var el = (document.getElementById("area-spec") as HTMLInputElement);
                if(typeof(el.selectionStart)=="number" && typeof(el.selectionEnd)=="number"){
                  const [start, end] = [el.selectionStart, el.selectionEnd];
                  el.setRangeText('['+val+']', start, end, 'select');
                  form.setFieldsValue({body: el.value});
                  if(props.spec?.length){
                    props.setSpec(
                      JSON.stringify({
                        ...JSON.parse(props.spec),
                        body: el.value,
                      })
                    )
                  }else{
                    props.setSpec(
                      JSON.stringify({
                        body: el.value,
                      })
                    )
                  }
                }
              }} resTypeId={props.resTypeId} />
            </div>
            <Form.Item label="Json Data" name={'body'} initialValue={props.spec?.length ? JSON.parse(props.spec).body : ''}>
              <TextArea placeholder="Enter your json data" id="area-spec" rows={10} onChange={(e) => {
                if(props.spec?.length){
                  props.setSpec(
                    JSON.stringify({
                      ...JSON.parse(props.spec),
                      body: e.target.value,
                    })
                  )
                }else{
                  props.setSpec(
                    JSON.stringify({
                      body: e.target.value,
                    })
                  )
                }
              }}></TextArea>
            </Form.Item>
          </TabPane>
          <TabPane tab="Form" key="7">
          {
            data ? <SimpleUxLineResourceTypeAttributesTable
            onLineAdd={onLineResourceTypeAttributeAdd}
            onLineRemove={onLineResourceTypeAttributeRemove}
            attributes={(props.spec && JSON.parse(props.spec).fields) ? JSON.parse(props.spec).fields : []}
            orgId={data.resourceType.org_id}
            resTypeId={data.resourceType.id}
          /> : 'undefined'
          }
          </TabPane>
        </Tabs>
      </Form>
    </div>
  );
};

export default UxAPICallSpecification;
