import React, { useState } from "react";
import { Row, Col, message } from "antd";
import { useApolloClient } from "@apollo/client";
import { option } from "ts-option";

import { IAuthInfo } from "./Auth";
import { RegisterMutation } from "../mutations/register";
import { Register, RegisterVariables } from "../mutations/__generated__/Register";
import { RegisterInput } from "../__generated__/globalTypes";
import RegistrationForm from "../components/login/Registration";
import { flatten } from "../utils/flatten";

import { useTranslation } from 'react-i18next';

interface Props {
  onFinish: (authInfo: IAuthInfo) => void;
}

const RegisterPage: React.FC<Props> = (props) => {
  const { t } = useTranslation('RegisterPage');
  const client = useApolloClient();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
    method: string;
  } | null>(null);

  const onRegister = async (values: RegisterInput) => {
    setLoading(true);
    setErrors([]);
    try {
      const data = await client.mutate<Register, RegisterVariables>({
        mutation: RegisterMutation,
        variables: {
          input: {
            ...values
          },
        },
        errorPolicy: "all",
      });

      if (data.errors) {
        const errorMessages = flatten(
          data.errors.map((error) => {
            return option(error.extensions)
              .map((ext) => {
                if(ext["validation"]){
                  return flatten(
                    Object.keys(ext["validation"]).map(
                      (key) => ext["validation"][key] as string[]
                    )
                  )
                }else{
                  let reason = JSON.parse(ext["reason"])
                  return flatten(
                    Object.keys(reason).map(
                      (key) => reason[key] as string[]
                    )
                  )
                }
              }
              )
              .getOrElseValue([error.message]);
          })
        );

        const cleanErrorMessages = errorMessages.map((message: any) =>
          message.replace("input.", "")
        );

        setErrors(cleanErrorMessages);

        message.warn(t("Registration input error, fix errors and submit again."));
      } else {
        // Check your mailbox to verify you email.
        if(data.data?.register.status === "MUST_VERIFY_EMAIL"){
          setVerifyEmail(true)
        }
      }
    } catch (error) {
      setErrors([t("Registration failed.")]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: "70vh", margin: "1em" }}
    >
      <Col span={16}>
          <RegistrationForm
            verifyEmail={verifyEmail}
            errors={errors}
            loading={loading}
            onFinish={onRegister}
            initialValues={{}}
          />
      </Col>
    </Row>
  );
};

export default RegisterPage;
