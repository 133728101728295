import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchOrganizationQuery } from "../queries/fetchOrganization";
import { fetchOrganizations_organizations_data as organizationsDetails } from "../queries/__generated__/fetchOrganizations"
import { updateOrganizationMutation } from "../mutations/updateOrganization";
import { deleteOrganizationMutation } from "../mutations/DeleteOrganization";
import { createOrganizationMutation } from "../mutations/createOrganization";
import { CreateOrganization, CreateOrganizationVariables } from "../mutations/__generated__/CreateOrganization";
import { UpdateOrganization, UpdateOrganizationVariables } from "../mutations/__generated__/UpdateOrganization";
import { DeleteOrganization, DeleteOrganizationVariables } from "../mutations/__generated__/DeleteOrganization";

interface organizationType {
  organization: organizationsDetails;
}

type State = {
  loading: boolean;
  data: organizationsDetails | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchOrganization = createAsyncThunk(
  "organization/fetch",
  async (arg: { orgId: string }) => {
    const res = await apolloClient.query<organizationType>({
      query: fetchOrganizationQuery,
      variables: { orgId: arg.orgId },
    });
    return res.data.organization ?? null;
  }
);

export const createOrganization = createAsyncThunk(
  "organization/create",
  async (arg: { 
    orgName: string;
    orgDescription: string;
    orgAddress: string; }) => {
      console.log(arg)
    await apolloClient
      .mutate<CreateOrganization, CreateOrganizationVariables>({
        mutation: createOrganizationMutation,
        variables: { 
          orgName: arg.orgName,
          orgDescription: arg.orgDescription,
          orgAddress: arg.orgAddress
        },
      }).then(result => { 
        alert(result?.data?.createOrganization)
        console.log(result) 
      })
      .catch(error => { console.log(error) });;
  }
);

export const updateOrganization = createAsyncThunk(
  "organization/update",
  async (arg: {
    orgId: string;
    orgName: string;
    orgDescription: string;
    orgAddress: string;
  }) => {
    await apolloClient.mutate<UpdateOrganization, UpdateOrganizationVariables>({
      mutation: updateOrganizationMutation,
      variables: {
        orgId: arg.orgId,
        orgName: arg.orgName,
        orgDescription: arg.orgDescription,
        orgAddress: arg.orgAddress,
      },
    }).then(result => { 
      alert(result?.data?.updateOrganization)
      console.log(result) 
    })
    .catch(error => { console.log(error) });
  }
);

export const deleteOrganization = createAsyncThunk(
  "organization/delete",
  async (arg: { orgId: string }) => {
    await apolloClient.mutate<DeleteOrganization, DeleteOrganizationVariables>({ 
      // refetchQueries: [{query: fetchOrganizationsQuery}],
      variables: { orgId: arg.orgId },
      mutation: deleteOrganizationMutation 
    }).then((result) => { 
      // TODO : There is certainly a better way to do this
      alert(result?.data?.deleteOrganization)
      window.location.reload()
      console.log(result) 
    })
    .catch(error => { console.log(error) });
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch
    [fetchOrganization.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchOrganization.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchOrganization.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // create
    [createOrganization.pending.type]: (state, _) => {
      state.loading = true;
    },
    [createOrganization.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [createOrganization.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // update
    [updateOrganization.pending.type]: (state, _) => {
      state.loading = true;
    },
    [updateOrganization.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [updateOrganization.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },

    // delete
    [deleteOrganization.pending.type]: (state, _) => {
      state.loading = true;
    },
    [deleteOrganization.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [deleteOrganization.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = organizationSlice;

export const organizationReducer = reducer;
