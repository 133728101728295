import { Badge, Button, Card, Checkbox, Form, Input, message, Modal, Popconfirm, Radio, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { DeleteOutlined, EditOutlined, PlusSquareOutlined, SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react'
import { ResourceTypeResourcesByResTypeQuery } from '../queries/resourceTypeResourcesByResType';
import { ResourceTypeResourcesByResType, ResourceTypeResourcesByResTypeVariables, ResourceTypeResourcesByResType_resourceTypeResourcesByResType as ResourceTypeResourcesByResTypeDetails } from '../queries/__generated__/ResourceTypeResourcesByResType';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'antd/lib/form/Form';
import LineResourceTypeSearchAndAdd, { INewLineResourceType } from '../components/generic/LineResourceTypeSearchAndAdd';
import { fetchResourceType_resourceType } from '../queries/__generated__/fetchResourceType';
import { CreateResourceTypeResource, CreateResourceTypeResourceVariables } from '../mutations/__generated__/CreateResourceTypeResource';
import { CreateResourceTypeResourceMutation } from '../mutations/createResourceTypeResources';
import { UpdateResourceTypeResource, UpdateResourceTypeResourceVariables } from '../mutations/__generated__/UpdateResourceTypeResource';
import { UpdateResourceTypeResourceMutation } from '../mutations/updateResourceTypeResources';
import { DeleteResourceTypeResource, DeleteResourceTypeResourceVariables } from '../mutations/__generated__/DeleteResourceTypeResource';
import { deleteResourceTypeResourceMutation } from '../mutations/deleteResourceTypeResource';

interface Props{
  resType: fetchResourceType_resourceType
  drilling?: boolean
}

interface IRelationship{
  id: string | null
  res_type_id: string | null
  res_type_parent_id: string | null
  res_type_resources_table_name: string | null,
  res_type_resources_description_up: string | null
  res_type_resources_description_down: string | null
  res_type_resources_transitional_enabled: number
  res_type_resources_transitional_path: string | null
  sourceResource: {
    id: string | null
    res_type_name: string
    res_type_table_name: string
  }
  targetResource: {
    id: string | null
    res_type_name: string
    res_type_table_name: string
  }
}

interface IRelationshipInternal {
  thisIsThatExtraRow: boolean;
}

interface IRelationshipFormState{
  mode: "create" | "edit"
  initialRelationship: IRelationship
  isModalVisible: boolean
  index: number | null
}

const initialRelationship = {
  id: null,
  res_type_id: null,
  res_type_parent_id: null,
  res_type_resources_table_name: '',
  res_type_resources_description_up: '',
  res_type_resources_description_down: '',
  res_type_resources_transitional_enabled: 0,
  res_type_resources_transitional_path: '',
  sourceResource: {
    id: null,
    res_type_name: '',
    res_type_table_name: ''
  },
  targetResource: {
    id: null,
    res_type_name: '',
    res_type_table_name: 's'
  }
}

const TableRelationshipsList: React.FC<Props> = (props) => {
  const [form] = useForm()
  const [relationType, setRelationType] = useState<"parent" | "child">("parent")
  const [relationshipFormState, setRelationshipFormState] = useState<IRelationshipFormState>({mode: "create", initialRelationship, isModalVisible: false, index: null})
  const [CreateResourceTypeResource, {loading: loadingSaving}] = useMutation<CreateResourceTypeResource, CreateResourceTypeResourceVariables>(CreateResourceTypeResourceMutation)

  const [UpdateResourceTypeResource, {loading: loadingUpdating}] = useMutation<UpdateResourceTypeResource, UpdateResourceTypeResourceVariables>(UpdateResourceTypeResourceMutation)

  const [DeleteResourceTypeResource, {loading: loadingDeleting}] = useMutation<DeleteResourceTypeResource, DeleteResourceTypeResourceVariables>(deleteResourceTypeResourceMutation)
  
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>()

  const { loading, data, refetch } = useQuery<ResourceTypeResourcesByResType, ResourceTypeResourcesByResTypeVariables>(ResourceTypeResourcesByResTypeQuery, {
    variables: {
      resTypeId: props.resType.id
    }
  });

  const isNormalRow = (
    row: IRelationship | IRelationshipInternal
  ): row is IRelationship => !("thisIsThatExtraRow" in row);

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onLineRelationshipAdd = (item: INewLineResourceType) => {
    if(relationType == "parent"){
      showModal("create", {...initialRelationship, sourceResource: item, targetResource: props.resType })
    }else{
      showModal("create", {...initialRelationship, sourceResource: props.resType, targetResource: item })
    }
  }

  const resTypeToExclude = (data?.resourceTypeResourcesByResType ? data.resourceTypeResourcesByResType.filter(item => item.sourceResource.id && item.sourceResource.id.length > 0).map(item => item.sourceResource.id!) : []).concat(data?.resourceTypeResourcesByResType ? data.resourceTypeResourcesByResType.filter(item => item.targetResource.id && item.targetResource.id.length > 0).map(item => item.targetResource.id!) : [])

  function onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }
  
  var uniqueData = resTypeToExclude ? resTypeToExclude.filter(onlyUnique) : [];

  const columns: ColumnsType<IRelationship | IRelationshipInternal> = [
    {
      title: "Pos.",
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <>{row.sourceResource.res_type_name}</>
        ) : { props: { colSpan: 3 },
        children: (
          <Space direction='horizontal'>
            <LineResourceTypeSearchAndAdd
              orgId={props.resType.org_id}
              excludeResTypes={[props.resType.id, ...uniqueData]}
              onLineResourceTypeAdd={onLineRelationshipAdd}
              inputStyle={{ width: "300px" }}
            />
            <Radio.Group onChange={e => setRelationType(e.target.value)} value={relationType}>
              <Radio value={"parent"}>as parent</Radio>
              <Radio value={"child"}>as child</Radio>
            </Radio.Group>
          </Space>
        ), };
      } 
    },
    {
      title: "Child",
      dataIndex: "child",
      key: "child",
      render: (_, row) => {
        return isNormalRow(row) ? row.targetResource.res_type_name : { props: { colSpan: 0 } }
      } 
    },
    {
      title: "Path",
      dataIndex: "transitional_path",
      key: "transitional_path",
      render: (_, row) => {
        return isNormalRow(row) ? row.res_type_resources_transitional_path : { props: { colSpan: 0 } }
      }
    },
    {
      title: "Is_enabled",
      dataIndex: "transitional_enabled",
      key: "transitional_enabled",
      render: (_, row) => {
        return isNormalRow(row) ? <Checkbox disabled checked={row.res_type_resources_transitional_enabled ? true : false}></Checkbox> : { props: { colSpan: 0 } }
      }
    },
    {
      title: "Description Down",
      dataIndex: "description_down",
      key: "description_down",
      render: (_, row) => {
        return isNormalRow(row) ? row.res_type_resources_description_down : { props: { colSpan: 0 } }
      } 
    },
    {
      title: "Description Up",
      dataIndex: "description_up",
      key: "description_up",
      render: (_, row) => {
        return isNormalRow(row) ? row.res_type_resources_description_up : { props: { colSpan: 0 } }
      } 
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, row, index) => {
        return isNormalRow(row) ? (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{ marginRight: "10px" }}
                loading={toRemoveIndex === index ? loadingDeleting : false}
              />
            </Popconfirm>
            <div style={{position: 'relative'}}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => showModal("edit", row, index)}
              />
            </div>
          </div>
        ) : { props: { colSpan: 0 } }
      } 
    }
  ]

  const showModal = (mode: "create" | "edit", relationship: IRelationship, index: number | null = null) => {
    setRelationshipFormState({...relationshipFormState, initialRelationship: relationship, isModalVisible: true, mode, index})
  }

  const onClose = () => {
    setRelationshipFormState({...relationshipFormState, isModalVisible: false})
  }
  
  const onItemDelete = (index: number, item: IRelationship) => {
    setToRemoveIndex(index)
    const input = {resTypeResourceId: item.id!}
    try{
        let res = DeleteResourceTypeResource({variables: input, refetchQueries: [{
          query: ResourceTypeResourcesByResTypeQuery,
          variables: {
            resTypeId: props.resType.id
          }
      }]});
        res.then(res => {
          message.success(res.data?.deleteResourceTypeResource)
        }).catch(raison => {
          message.error("Failed to delete a relation")
          console.error(raison)
        })
    }catch(error){
      message.error("Failed to remove relation")
      console.error(error)
    }
  }

  const onSave = () => {

    try{
      if(relationshipFormState.mode == "create"){
        let binding_res_type = relationType == "parent" ? relationshipFormState.initialRelationship.sourceResource : relationshipFormState.initialRelationship.targetResource

        let res_type_resources_table_name = binding_res_type.res_type_table_name+'_'+props.resType.res_type_table_name

        const input = {
          binding_type: relationType,
          config_item_id: props.resType.id,
          res_type_id: binding_res_type.id,
          res_type_name: binding_res_type.res_type_name!,
          org_id: props.resType.org_id,
          res_type_resources_table_name,
          res_type_resources_description_down: relationshipFormState.initialRelationship.res_type_resources_description_down,
          res_type_resources_description_up: relationshipFormState.initialRelationship.res_type_resources_description_up
        }
        let res = CreateResourceTypeResource({variables: input, refetchQueries: [{
            query: ResourceTypeResourcesByResTypeQuery,
            variables: {
              resTypeId: props.resType.id
            }
        }]})
        res.then(res => {
          message.success(res.data?.createResourceTypeResource)
          onClose()
        })
      }else{
        const input = {
          config_sub_item_id: relationshipFormState.initialRelationship.id!,
          res_type_resources_table_name: relationshipFormState.initialRelationship.res_type_resources_table_name,
          res_type_resources_description_down: relationshipFormState.initialRelationship.res_type_resources_description_down,
          res_type_resources_description_up: relationshipFormState.initialRelationship.res_type_resources_description_up,
          res_type_resources_transitional_enabled: relationshipFormState.initialRelationship.res_type_resources_transitional_enabled
        }
        let res = UpdateResourceTypeResource({variables: input, refetchQueries: [{
          query: ResourceTypeResourcesByResTypeQuery,
          variables: {
            resTypeId: props.resType.id
          }
      }]})
        res.then(res => {
          message.success(res.data?.updateResourceTypeResource)
          onClose()
        })
      }
    }catch(error){
      message.error("Failed to create or update a relation")
      console.error(error)
    }

  }

  useEffect(() => {
    form.setFieldsValue({
      parent_name: relationshipFormState.initialRelationship.sourceResource.res_type_name,
      child_name: relationshipFormState.initialRelationship.targetResource.res_type_name,
      ...relationshipFormState.initialRelationship
    })
  }, [relationshipFormState])
  
  const relationshipsWithExtra: (
    | IRelationship
    | IRelationshipInternal
  )[] = data?.resourceTypeResourcesByResType
    ? ( props.drilling ? [...data?.resourceTypeResourcesByResType] : [...data?.resourceTypeResourcesByResType, { thisIsThatExtraRow: true }] )
    : [];

  return (
    <>
    <Card title="Table Relationships">
      <Table
        columns={props.drilling ? columns : columns.filter(col => (col.key !== 'transitional_path') && (col.key !== 'transitional_enabled'))}
        dataSource={relationshipsWithExtra}
        pagination={false}
        size="small"
        loading={loading}
      >

      </Table>
    </Card>
    <Modal
      visible={relationshipFormState.isModalVisible}
      title={relationshipFormState.mode + " relationship"}
      onCancel={onClose}
      width="700px"
      footer={[
        <>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={relationshipFormState.mode == "create" ? loadingSaving : loadingUpdating}
          >
            {(relationshipFormState.mode == "create" ? "Save" : "Update") + " Relationship"}
          </Button>
          <Button
            type="default"
            onClick={onClose}
          >
            {"Cancel"}
          </Button>
        </>
      ]}
    >
      <Form 
      {...formLayout}
      form={form}
      onFinish={() => onSave()}
      >
        <Form.Item name="parent_name" label={<><Badge count={relationshipFormState.initialRelationship.sourceResource.id ? 0 : 'new'} style={{backgroundColor: '#52c41a', marginRight: '5px'}}></Badge><span>Parent table</span></>}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="child_name" label={<><Badge count={relationshipFormState.initialRelationship.targetResource.id ? 0 : 'new'} style={{backgroundColor: '#52c41a', marginRight: '5px'}}></Badge><span> Child table</span></>}>
          <Input disabled />
        </Form.Item>
        {props.drilling ? <Form.Item name="res_type_resources_transitional_path" label={"Path"}>
          <Input disabled />
        </Form.Item> : null}
        <Form.Item name="res_type_resources_description_down" label="Description Down">
          <Input onChange={e => setRelationshipFormState({...relationshipFormState, initialRelationship: {...relationshipFormState.initialRelationship, res_type_resources_description_down: e.target.value}})} />
        </Form.Item>
        <Form.Item name="res_type_resources_description_up" label="Description Up">
          <Input onChange={e => setRelationshipFormState({...relationshipFormState, initialRelationship: {...relationshipFormState.initialRelationship, res_type_resources_description_up: e.target.value}})} />
        </Form.Item>
        {props.drilling ? <Form.Item name={"res_type_resources_transitional_enabled"} valuePropName="checked" label={"Visibility"}>
          <Checkbox onChange={e => setRelationshipFormState({...relationshipFormState, initialRelationship: {...relationshipFormState.initialRelationship, res_type_resources_transitional_enabled: e.target.checked ? 1 : 0}})}>enabled</Checkbox>
        </Form.Item> : null}
      </Form>
    </Modal>
    </>
  )
}

export default TableRelationshipsList