import { Layout, Menu, Button, Collapse } from 'antd';
import { BrowserRouter as Router, Link } from "react-router-dom";
import logoFull from '../digintu-code-logo.png';
import { setApolloToken } from "../apolloClient";
import { getAuthToken } from "../authToken";
import { useQuery } from "@apollo/client";

import { MeQuery } from "../queries/me";
import { Me } from "../queries/__generated__/Me";
import "../App.css"
import '../pages/HomePage.css'
import LoadingPage from '../pages/Loading';
import { IAuthInfo } from '../pages/Auth';
import ScrollToTop from '../components/ScrollToTop';

interface Props {
  onFinish: (authInfo: IAuthInfo) => void;
}

const { Header, Content, Footer, Sider } = Layout;

export const HomeLayout: React.FC<Props> = (props) => {

  const authToken = getAuthToken();

  setApolloToken(authToken || "");

  const { loading: meLoading, data: meData } = useQuery<Me>(MeQuery);

  const { Panel } = Collapse;
  if (meLoading) {
    return <LoadingPage />;
  } else if (meData) {
    const { id, email, first_name, last_name, lang, activeOrganization } = meData.me;

    props.onFinish({
      id,
      lang,
      email,
      first_name,
      last_name,
      active_organization: {
        id: activeOrganization?.id || null,
        org_name: activeOrganization?.org_name || null,
      }
    });

    return <LoadingPage />;
  } else
    return (
      <Router>
        <Layout style={{minHeight: "100vh"}}>
          <Header style={{ position: 'fixed', zIndex: 1, width: '100%', boxShadow: '1px 1px 5px rgb(0 0 0 / 20%)', background: "white", color: "dimgray" }}>
            <div style={{display: 'flex'}}>
              <div className='logo' style={{width: "20%"}}>
                <Link to="/">
                  <img src={logoFull} alt="logo" width={115}/>
                </Link>
              </div>
              <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}  style={{width: "80%"}}>
                <Menu.Item key="3" style={{marginLeft: 'auto'}}>
                  <Link to="/login">Login</Link>
                </Menu.Item>
              </Menu>
            </div>
          </Header>
          <Content className="site-layout">
            {props.children}
          </Content>
          <div className='more' style={{backgroundImage: 'url("./img/img7.jpg")'}}>
            <div className='more-overlay'>
                <h1>Need more information? </h1>
                <Button type='default' size='large'><a href="https://sofie.codes/contact-us">Get support</a></Button>
            </div>
          </div>
          <Footer style={{ textAlign: 'center' }}>Digintu Code © 2022 | <a href="https://sofie.codes/terms">Terms of Service</a> | <a href="https://sofie.codes/privacy">Privacy Policy</a></Footer>
        </Layout>
        <ScrollToTop />
      </Router>
    )
}