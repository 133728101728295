import React from "react";
import { Select, Form } from "antd";

import { useQuery } from "@apollo/client";
import {
  GroupingFunctions
} from "../../queries/__generated__/GroupingFunctions";
import { GroupingFunctionsQuery } from "../../queries/groupingFunctions";

interface Props {
  onChange: (val: string) => void;
  inputStyle?: React.CSSProperties;
  label: string;
  name: string;
}

const GroupingFunctionSelect: React.FC<Props> = (props) => {
  const { Option } = Select;

  const { data, loading, error } = useQuery<
    GroupingFunctions
  >(GroupingFunctionsQuery);

  const groupingFunctions = data?.groupingFunctions?.data || [];

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      required
    >
      <Select onChange={val => props.onChange(val)}>
        {groupingFunctions.map(func => <Option value={func.id}>
                {func.grp_function_name}
              </Option>)}
      </Select>
    </Form.Item>
  )
  
};

export default GroupingFunctionSelect;

