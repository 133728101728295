import { gql } from "@apollo/client";

export const fetchFilesDataQuery = gql`
  query fetchFilesData($id: String!) {
    fetchFilesData(id: $id){
      filename
      mimetype
      code
      id
    }
  }
`;
