import { Col, Divider, Menu, Row } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Route, useHistory, useParams } from 'react-router-dom';
import { TableSettingsEdit } from './TableSettingsEdit';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import TableFieldsEdit from './TableFieldsEdit';
import { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchResourceType } from '../slices/resourceTypeSlice';
import TableFiltersEdit from './TableFiltersEdit';
import TableRelationshipsList from './TableRelationshipsList';
import LoadingPage from './Loading';
import TableChartsEdit from './TableChartsEdit';
import TableStatisticsEdit from './TableStatisticsEdit';
import TableCustomActionsEdit from './TableCustomActionsEdit';

interface Props {
  onDrawerInfosHandle: (elt: ReactNode, title: string) => void;
}

const TableEdit: React.FC<Props> = (props) => {
  const history = useHistory();
  const { appId, tableId } = useParams<{ appId: string; tableId: string; }>();
  const {data: currentResourceType, loading} = useAppSelector(state => state.resourceType)
  const dispatch = useAppDispatch();

  const onDrawerInfos = (elt: ReactNode, title: string) => {
    props.onDrawerInfosHandle(elt, title)
  }

  const goTo = (nav: string) => history.push("/applications/"+appId+"/tables/"+tableId+"/"+nav)

  useEffect(() => {
    dispatch(fetchResourceType({tableId}))
  }, [dispatch, tableId]);

  return (
    <div>
      <Divider orientation='center'><h3>Edit table <b>{currentResourceType?.res_type_name}</b></h3></Divider>
      <Row justify='space-around'>
        <Col span={4}>
          <Menu
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
          >
            <SubMenu key="sub1" icon={<MailOutlined />} title="Table stucture">
              <Menu.Item key="1" onClick={() => goTo("settings")}>Settings</Menu.Item>
              <Menu.Item key="2" onClick={() => goTo("fields")}>Fields</Menu.Item>
              <Menu.Item key="3" onClick={() => goTo("relationships")}>Relationships</Menu.Item>
              <Menu.Item key="4" onClick={() => goTo("drillings")}>Data Drillings</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<AppstoreOutlined />} title="User Interface">
              <Menu.Item key="5" onClick={() => goTo("filters")}>Filters</Menu.Item>
              <Menu.Item key="6" onClick={() => goTo("charts")}>Charts</Menu.Item>
              <Menu.Item key="7" onClick={() => goTo("statistics")}>Statistics</Menu.Item>
              {/* <Menu.Item key="8">Reports</Menu.Item> */}
              <Menu.Item key="9" onClick={() => goTo("custom-actions")}>Custom actions</Menu.Item>
            </SubMenu>
            {/*
            <SubMenu key="sub3" icon={<SettingOutlined />} title="Advanced">
              <Menu.Item key="10">Permissions</Menu.Item>
              <Menu.Item key="11">Translations</Menu.Item>
              </SubMenu>
            */}
          </Menu>
        </Col>
        <Col span={20}>
          {loading ? <LoadingPage /> : currentResourceType ? <>
            <Route path="/applications/:appId/tables/:tableId" exact>
            <TableSettingsEdit />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/settings">
            <TableSettingsEdit />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/relationships">
            <TableRelationshipsList resType={currentResourceType} />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/drillings">
            <TableRelationshipsList resType={currentResourceType} drilling={true} />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/fields">
            <TableFieldsEdit
              onDrawerInfosHandle={onDrawerInfos}
            />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/filters">
            <TableFiltersEdit />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/charts">
            <TableChartsEdit />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/custom-actions">
            <TableCustomActionsEdit />
          </Route>
          <Route path="/applications/:appId/tables/:tableId/statistics">
            <TableStatisticsEdit />
          </Route>
          </> : ''}
        </Col>
      </Row>
    </div>
    
  )
}

export default TableEdit
