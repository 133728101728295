import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  Link,
  match,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import ProTable, {
  ConfigProvider,
  enUSIntl,
  ProColumns
} from "@ant-design/pro-table";
import { isNil, parseInt } from "lodash";
import ButtonGroup from "antd/lib/button/button-group";
import { Button, DatePicker, Input, InputRef, Progress, Slider, Space, Tag, Typography, Switch as TSwitch, Dropdown, Menu, Popconfirm, message } from "antd";
import { SearchOutlined, FilePdfOutlined, MoreOutlined, EditOutlined, FileSearchOutlined, DeleteOutlined, FileTextOutlined, MenuFoldOutlined, ClusterOutlined } from '@ant-design/icons';
import moment from "moment";

import { fetchApplicationDetails_application_resourceTypes, fetchApplicationDetails_application_resourceTypes_attributes } from "../queries/__generated__/fetchApplicationDetails";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchResourceRecords } from "../slices/resourceRecordsSlice";
import { ResourceLinkDetail } from "./resourceLinkDetail";
import { ResourceRecordForm } from "./resourceRecordForm";
import DisplayBinaryData from "./DisplayBinaryData";
import DisplayBinaryIcon from "./DisplayBinaryIcon";
import Highlighter from 'react-highlight-words';
import { FilterConfirmProps, FilterDropdownProps, Key } from "antd/lib/table/interface";
import HiddenPassword from "./HiddenPassword";
import type {RangeValue} from "rc-picker/lib/interface"
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";
import { mapConstraints } from "./helpers/resourceTypeToFormItems";
import { useReactToPrint } from 'react-to-print';
import {CSVLink} from "react-csv"
import ReactExport from "react-data-export";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ResourceTypeResourcesByResTypeQuery } from "../queries/resourceTypeResourcesByResType";
import { ResourceTypeResourcesByResType, ResourceTypeResourcesByResTypeVariables } from "../queries/__generated__/ResourceTypeResourcesByResType";
import { ResourceTypeLinkDetail } from "./ResourceTypeLinkDetail";
import ChartStats from "./ChartStats";
import { ResourceTypeChartsListQuery } from "../queries/resourceTypeChartsList";
import { ResourceTypeChartsList, ResourceTypeChartsListVariables } from "../queries/__generated__/ResourceTypeChartsList";
import { ResourceTypeStatisticsListQuery } from "../queries/resourceTypeStatisticsList";
import { ResourceTypeStatisticsList, ResourceTypeStatisticsListVariables } from "../queries/__generated__/ResourceTypeStatisticsList";
import { ResourceRecordDelete } from "./resourceRecordDelete";
import FilterBtn from "./FilterBtn";
import { ResourceRecordHistory } from "./ResourceRecordHistory";
import { ResourceRecordTrash } from "./ResourceRecordTrash";
import { EmptyResourceType, EmptyResourceTypeVariables } from "../mutations/__generated__/EmptyResourceType";
import { EmptyResourceTypeMutation } from "../mutations/emptyResourceType";
import { ResourceRecordFiles } from "./ResourceRecordFiles";
import usePrevious from "./usePrevious";
import CustomActionBtn from "./CustomActionBtn";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { fontAwesomeList } from "./helpers/iconList";
import { executeCustomActionMutation } from "../mutations/executeCustomAction";
import { ExecuteCustomAction, ExecuteCustomActionVariables } from "../mutations/__generated__/ExecuteCustomAction";
import CustomActionModal from "./CustomActionModal";
import CustomActionFormModal from "./CustomActionFormModal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = (ReactExport.ExcelFile as any).ExcelSheet;
const ExcelColumn = (ReactExport.ExcelFile as any).ExcelColumn;

const { RangePicker } = DatePicker;

const { Text } = Typography;

export type LinkedAttr = {
  id: string;
  value: string;
  res_type_id: string;
};

export interface BinaryDataAttr {
  description: string;
  filename: string;
  id: string;
  mimetype: string;
  public_access: number;
  size: number;
  system_filename: string;
  type: string;
}

export enum WeekDays {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
  _Last,
}

export function isValidHttpUrl(str: string) {
  let url;
  
  try {
    url = new URL(str);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function resourceTypeToColumns(
  tableType: 'history' | 'trash' | 'default',
  routeMatch: match<{}>,
  resourceType: fetchApplicationDetails_application_resourceTypes,
  getColumnSearchProps: (text: string) => {},
  getColumnDateRangeProps: (text: string) => {},
  getColumnSelectRangeProps: (text: string, min?: number, max?: number) => {},
  customActions: (customActionId: string | undefined, recordId: string | null) => void
): ProColumns[] {
  const resourceColumns = [...resourceType.attributes]
    // order by res_type_attribute_type_order
    .sort(
      (a, b) =>
        (a.res_type_attribute_type_order ?? 0) -
        (b.res_type_attribute_type_order ?? 0)
    )
    // show only res_type_attribute_type_detail != 1
    .filter((attribute) => !attribute.res_type_attribute_type_detail)
    // transform for table
    .map((attribute) => {
      const render: ProColumns["render"] = attribute.linked_id
        ? (_, record) => {
            const linkedAttrJson =
              record[attribute.specification.res_type_attribute_code];
            if (linkedAttrJson) {
              try{
                const linkedAttr: LinkedAttr = JSON.parse(linkedAttrJson);
                return (
                  <Link
                    to={`${routeMatch.url}/link/${linkedAttr.res_type_id}/${linkedAttr.id}`}
                  >
                    {linkedAttr.value}
                  </Link>
                );
              } catch (e){
                return "-";
              }
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "image"
        ? (_, record) => {
            const image =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(image)) {
              const imageAttr: BinaryDataAttr = JSON.parse(image);
              return (
                <DisplayBinaryData data={imageAttr} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "audio"
        ? (_, record) => {
            const audio =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(audio)) {
              const audioAttr: BinaryDataAttr = JSON.parse(audio);
              return (
                <DisplayBinaryData data={audioAttr} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "file"
        ? (_, record) => {
            const fileIds =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(fileIds)) {
              return (
                <DisplayBinaryIcon ids={fileIds} />
              );
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "datetime"
        ? (_, record) => {
            const date =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(date)) {
              return moment(date).format("YYYY-MM-DD hh:mm:ss");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "date"
        ? (_, record) => {
            const date =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(date)) {
              return moment(date).format("YYYY-MM-DD");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "time"
        ? (_, record) => {
            const time =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(time)) {
              return moment(time, "HH:mm:ss").format("hh:mm:ss");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectYear"
        ? (_, record) => {
            const year =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(year)) {
              return moment(year).format("YYYY");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectMonth"
        ? (_, record) => {
            const month =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(month)) {
              return moment(month).format("YYYY-MM");
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "radio"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <Tag>{text}</Tag>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "url"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return isValidHttpUrl(text) ? <a href={text} target="_blank">{text}</a> : <span>{text}</span>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "checkbox"
        ? (_, record) => {
            const val =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(val)) {
              return val ? <Tag color={"green"}>True</Tag> : <Tag color={"red"}>False</Tag>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "email"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <a href={"mailto:"+text}>{text}</a>;
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "password"
        ? (_, record) => {
            const text =
              record[attribute.specification.res_type_attribute_code];
            if (!isNil(text)) {
              return <HiddenPassword text={text} />
            } else {
              return "-";
            }
          }
        : attribute.specification.res_type_attribute_type === "selectRange"
        ? (_, record) => {
            const val = record[attribute.specification.res_type_attribute_code];
            if (!isNil(val)) {
              return <Progress percent={val} format={percent => percent} size="small" />;
            } else {
              return "-";
            }
          }
        : undefined;

      const valueType =
        attribute.specification.res_type_attribute_type === "date"
          ? "date"
          : undefined;

      const sorter =
        attribute.specification.res_type_attribute_type === "text"
          ? // attribute.specification.res_type_attribute_type === "date"
            (a: any, b: any) => {
              return (
                a[attribute.specification.res_type_attribute_code] -
                b[attribute.specification.res_type_attribute_code]
              );
            }
          : undefined;

      const columnProps = {
        dataIndex: attribute.specification.res_type_attribute_code,
        title: <span style={{textTransform: 'capitalize'}}>{attribute.specification.res_type_attribute_name}</span>,
        ellipsis: true,
        valueType,
        sorter,
        render,
      }
      if(attribute.specification.res_type_attribute_type === "date" || attribute.specification.res_type_attribute_type === "datetime"){
        return {...columnProps, ...getColumnDateRangeProps(attribute.specification.res_type_attribute_code) }
      }

      if(attribute.specification.res_type_attribute_type === "selectRange"){
        const constraints: any = mapConstraints(attribute.specification.res_type_attribute_constraints)
        return {...columnProps, ...getColumnSelectRangeProps(attribute.specification.res_type_attribute_code, parseInt(constraints.min) || 0, parseInt(constraints.max) || 100) }
      }

      if(attribute.specification.res_type_attribute_type === "text" && attribute.linked_id == null){
        return {...columnProps, ...getColumnSearchProps(attribute.specification.res_type_attribute_code) }
      }

      if(attribute.specification.res_type_attribute_type === "checkbox"){
        return {...columnProps, filters: [
            {
              text: 'True',
              value: 1,
            },
            {
              text: 'False',
              value: 0,
            }
          ],
          onFilter: (value: string, record: any) => record[attribute.specification.res_type_attribute_code] === value,
        }
      }
      return columnProps;
    });

    
  const moreEditRecordActions = (record: any) => (
    <Menu mode="inline">
      <Menu.Item>
        <Link to={`${routeMatch.url}/delete/${record.id}`}>
          <DeleteOutlined /> Delete
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`${routeMatch.url}/history/${record.id}`}>
          <MenuFoldOutlined /> History
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`${routeMatch.url}/gallery/${record.id}`}>
        <FileSearchOutlined /> Gallery
        </Link>
      </Menu.Item>
      <Menu.SubMenu title={<Link to={`${routeMatch.url}/custom-action/${record.id}`}>
        <ClusterOutlined /> Custom Action
        </Link>}>
        {resourceType.customActions?.data.filter(action => !action.action_global).map(action => <Menu.Item onClick={() => customActions(action.id, record.id)}><div style={{display: 'flex'}}><IconPickerItem icon={action.action_icon && fontAwesomeList.includes(action.action_icon) ? action.action_icon as IconList : "FaThLarge"} color="gray" size={18} containerStyles={{display: 'flex', marginRight: '5px'}} />{action.action_name}</div></Menu.Item>)}
      </Menu.SubMenu>
     
  </Menu>
)

  const actionsColumn: ProColumns = {
    title: "Actions",
    render: (_, record) => {
      return (
          <Dropdown.Button overlay={moreEditRecordActions(record)}>
            <Link to={`${routeMatch.url}/edit/${record.id}`}>
              <EditOutlined /> Edit
            </Link>
          </Dropdown.Button>
      );
    },
  };
  //@ts-ignore
  return tableType == 'default' ? [...resourceColumns, actionsColumn] : [...resourceColumns];
}

export interface ICustomActionRecordData {
  customActionId: string,
  orgId: string,
  resTypeId: string,
  recordId: string | null,
}

export const ResourceTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const routeMatch = useRouteMatch();

  const loading = useAppSelector((state) => state.resourceRecords.loading);
  const resourceRecords = useAppSelector((state) => state.resourceRecords.data);
  const { resourceTypeId } = useParams<{ resourceTypeId: string }>();
  const [displayChart, setDisplayChart] = useState(true)
  const orgId = useAppSelector(
    (state) => state.application.data?.organization?.id
  );
  const resourceType = useAppSelector((state) =>
    state.application.data?.resourceTypes?.find(
      (rt) => rt.id === resourceTypeId
    )
  );

  const [customActionResponse, setCustomActionResponse] = useState('');
  const [customActionResponseModalIsShow, setCustomActionResponseModalIsShow] = useState(false);
  const [customActionFormModalIsShow, setCustomActionFormModalIsShow] = useState(false);
  const [customActionForm, setCustomActionForm] = useState('');
  const [customActionRecordData, setCustomActionRecordData] = useState<ICustomActionRecordData>();

  const prevResTypeId = usePrevious(resourceTypeId);

  // Request to list relationship
  const { loading: loadingRelations, data: relationData, refetch } = useQuery<ResourceTypeResourcesByResType, ResourceTypeResourcesByResTypeVariables>(ResourceTypeResourcesByResTypeQuery, {
    variables: {
      resTypeId: resourceTypeId
    }
  });

  const expandedRowRender = (record: any) => {
    const resourceColumns = !isNil(resourceType)
      ? [...resourceType!.attributes]
          // order by res_type_attribute_type_order
          .sort(
            (a, b) =>
              (a.res_type_attribute_type_order ?? 0) -
              (b.res_type_attribute_type_order ?? 0)
          )
          // show only res_type_attribute_type_detail === 1
          .filter((attribute) => attribute.res_type_attribute_type_detail)
      : [];

      const relationsLink = !isNil(resourceType) && !isNil(relationData) ? relationData?.resourceTypeResourcesByResType?.filter(rlt => rlt.res_type_resources_table_name.length>0).map(relation => {
        if(relation.sourceResource.res_type_name.toLowerCase() === resourceType?.res_type_name.toLowerCase()){
          return <Link to={routeMatch.url+'/record/'+record['id']+'/relation/'+relation.id+'/'+relation.targetResource.id} style={{marginRight: "10px"}}>{relation.targetResource.res_type_name}</Link>
        }else{
          return <Link to={routeMatch.url+'/record/'+record['id']+'/relation/'+relation.id+'/'+relation.sourceResource.id} style={{marginRight: "10px"}}>{relation.sourceResource.res_type_name}</Link>
        }
      }) : []

    return (
      <>
        {relationsLink?.length ? <div>Relationships : {relationsLink}</div> : ''}
        <table>
          <thead style={{backgroundColor: "rgb(126 203 217 / 27%)"}}>
            <tr>
              {resourceColumns.map((attribute, index) => {
                return (
                    <td style={{padding: '10px 10px', borderRight: '1px solid lightgray'}}>
                      {attribute.specification.res_type_attribute_name}
                    </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr style={{backgroundColor: "white"}}>
              {resourceColumns.map((attribute, index) => {
                return (
                    <td style={{padding: '10px 10px', borderRight: '1px solid lightgray'}}>
                      {record[attribute.specification.res_type_attribute_code]}
                    </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const [searchState, setSearchState] = useState<{searchText: string|number, searchedColumn: string}>({
    searchText: '',
    searchedColumn: '',
  })

  let searchInput: (InputRef | null) = null

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#3b5ea9' : undefined }} />,
    onFilter: (value: string, record: any) => {
      return record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''
    }
      ,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text: ReactElement) =>
      searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={(typeof(searchState.searchText) !== 'number') ? [searchState.searchText] : ['']}
          autoEscape
          textToHighlight={text ? text.props.children : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps | undefined) => void, dataIndex: string) => {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    if(clearFilters){
      clearFilters();
    }
    setSearchState({ ...searchState, searchText: '' });
  };

  let dateRangeInput: ((React.Component<RangePickerProps<moment.Moment>, unknown, any> & CommonPickerMethods) | null) = null

  const getColumnDateRangeProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      let val = selectedKeys[0] ? (selectedKeys[0] as string).split(',') : []
      return (
      <div style={{ padding: 8 }}>
        <div style={{display: 'block'}}>
          <RangePicker 
            ref={node => {
              dateRangeInput = node;
            }}
            value={val.length ? [moment(val[0]), moment(val[1])] : undefined}
            onChange={(dates: RangeValue<moment.Moment>, dateString: [string, string]) => setSelectedKeys(dateString.length ? [[dateString].join(',')] : [])}  
            style={{ marginBottom: 8}}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateRange(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateRange(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    )},
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#3b5ea9' : undefined }} />,
    onFilter: (value: string, record: any) => {
      let interval = value.split(',')
      return record[dataIndex]
      ? moment(record[dataIndex]).isBetween(interval[0], interval[1])
      : ''
    }
      ,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => dateRangeInput?.focus(), 100);
      }
    },
  });

  const handleSearchDateRange = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps | undefined) => void, dataIndex: string) => {
    confirm();
  };

  const handleResetDateRange = (clearFilters: (() => void) | undefined) => {
    if(clearFilters){
      clearFilters();
    }
  };

  const getColumnSelectRangeProps = (dataIndex: string, min: number = 0, max: number = 100) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      let val = selectedKeys[0] ? (selectedKeys[0] as string).split(',') : []
      return (
      <div style={{ padding: 8 }}>
        <div style={{display: 'block'}}>
          <Slider min={min} max={max} value={val.length ? [parseInt(val[0]), parseInt(val[1])] : undefined} range={{ draggableTrack: true }} defaultValue={[0, 10]} onChange={(values:[number, number]) => {
              setSelectedKeys(values.length ? [values.join(',')] : [])
            }} style={{ marginBottom: 8}} />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchSelectRange(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetSelectRange(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    )},
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#3b5ea9' : undefined }} />,
    onFilter: (value: string, record: any) => {
      let interval = value.split(',').map(val => parseInt(val))
      return record[dataIndex]
      ? parseInt(record[dataIndex]) >= interval[0] && parseInt(record[dataIndex]) <= interval[1]
      : ''
    }
      ,
  });

  const handleSearchSelectRange = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps | undefined) => void, dataIndex: string) => {
    confirm();
  };

  const handleResetSelectRange = (clearFilters: (() => void) | undefined) => {
    if(clearFilters){
      clearFilters();
    }
  };

  const [ExecuteCustomAction] = useMutation<
  ExecuteCustomAction,
  ExecuteCustomActionVariables
  >(executeCustomActionMutation);

  const [EmptyResourceType] = useMutation<
  EmptyResourceType,
  EmptyResourceTypeVariables
  >(EmptyResourceTypeMutation);

  const onDeleteTableData = async () => {
    if (resourceType?.id){
      try {
          const input: EmptyResourceTypeVariables = {
            resTypeId: resourceType?.id
          };
          let res = await EmptyResourceType({ variables: input });
          message.success(res.data?.emptyResourceType);
          document.location.reload();      
      } catch (error) {
        message.error("Failed to delete data");
        console.error(error);
      }
    }
  };

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const customActions = async (customActionId: string | undefined, recordId: string | null) => {
    if(resourceType && customActionId){
      var customAction = resourceType.customActions?.data.find(action => action.id == customActionId)
      
      if(customAction?.action_type && (customAction?.action_type == "api-call")){
        setCustomActionRecordData({
          customActionId: customActionId,
          orgId: orgId!,
          resTypeId: resourceTypeId,
          recordId: recordId,
        })
        setCustomActionForm(JSON.parse(customAction?.action_spec!).fields)
        setCustomActionFormModalIsShow(true)
      }else{
        try {
          const input: ExecuteCustomActionVariables = {
            customActionId,
            orgId: orgId!,
            resTypeId: resourceTypeId,
            recordId,
            params: ''
          };
          let res = await ExecuteCustomAction({ variables: input });
          setCustomActionResponse(res.data?.executeCustomAction)
          setCustomActionResponseModalIsShow(true)
          // document.location.reload();
        } catch (error) {
          message.error("Failed to execute custom action");
          console.error(error);
        }
      }
    }
  };

  const handleCloseCustomActionResponseModal = () => {
    setCustomActionResponseModalIsShow(false)
  }

  const handleCloseCustomActionFormModal = () => {
    setCustomActionFormModalIsShow(false)
  }

  const columns: ProColumns[] = resourceType
    ? resourceTypeToColumns('default', routeMatch, resourceType, getColumnSearchProps, getColumnDateRangeProps, getColumnSelectRangeProps, customActions)
    : [];
    
  const [filterId, setFilterId] = useState<string | undefined>(undefined)

  const filterRecords = (filterId: string | undefined) => {
    setFilterId(filterId)
  };

  const [dataStatisticsList, setDataStatisticsList] = useState()

  const client = useApolloClient();

  // Request chartsList
  const {data: dataChartsList, loading: loadingChart, error } = useQuery<ResourceTypeChartsList, ResourceTypeChartsListVariables>(ResourceTypeChartsListQuery, {
    variables: {
      orgId: orgId!,
      resTypeId: resourceTypeId
    },
    skip: isNil(orgId)
  })

  // const {data: dataStatisticsList, loading: loadingStatistic, error: errorStatistic } = useQuery<ResourceTypeStatisticsList, ResourceTypeStatisticsListVariables>(ResourceTypeStatisticsListQuery, {
  //   variables: {
  //     orgId: orgId!,
  //     resTypeId: resourceTypeId,
  //     selectedFilterId: filterId,
  //   },
  //   skip: isNil(orgId)
  // })

  client.query<
    ResourceTypeStatisticsList,
    ResourceTypeStatisticsListVariables
  >({
    query: ResourceTypeStatisticsListQuery,
    variables: {
      orgId: orgId!,
      resTypeId: resourceTypeId,
      selectedFilterId: filterId,
    }
  }).then(res => {
    setDataStatisticsList(res.data.resourceTypeStatisticsList)
  })

  function omit<T, K extends keyof T>(obj: T, props: K[]): Omit<T, K> {
    return props.reduce((o, k) => { delete o[k]; return o; }, { ...obj });
  }
  const attrToExclude = ["USER_ID", "id", "username", "org_id", "user_id", "linked_res_type_id", "linked_res_id"]

  const moreNewRecordActions = () => (
    <Menu mode="inline">
      <Menu.Item>
        <Link to={`${routeMatch.url}/trash`}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              
              e.stopPropagation();
            }}
          >
            View Trash
          </Button>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm placement="right" title="Sure to delete all data from this table?" onConfirm={() => onDeleteTableData()}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              
              e.stopPropagation();
            }}
          >
            Delete all data
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const moreExportActions = () => (
    <Menu mode="inline">
      <Menu.Item>
        <Button type="link">
        <CSVLink
            separator={";"}
            filename={resourceType?.res_type_name}
            data={
              resourceRecords?.data.map(record => {
                return omit(record, attrToExclude) 
              })
            || []}
            className="btn btn-primary"
          >
           <FileTextOutlined /> Export to CSV
          </CSVLink>
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button onClick={handlePrint} type="link" ><FilePdfOutlined /> Export to PDF </Button>
      </Menu.Item>
    </Menu>
  );

  const toolbarActions = [
    <TSwitch unCheckedChildren="Show charts" checkedChildren="Hide charts" checked={displayChart} onChange={() => setDisplayChart(!displayChart)} />,
    <ButtonGroup>
      <ExcelFile filename={resourceType?.res_type_name} element={<Button type="default">Export to xlsx</Button>}>
        <ExcelSheet data={resourceRecords?.data || []} name={resourceType?.res_type_name}>
          {resourceType?.attributes.map(attr => {
            let attrType = attr.specification.res_type_attribute_type
            if(attrType === 'file' || attrType === 'image' || attrType === 'audio'){
              return null
            }else if(attr.linked_id){
              return <ExcelColumn label={attr.specification.res_type_attribute_name} value={(col:any) => {
                const linkedAttrJson = col[attr.specification.res_type_attribute_code] 
                if (linkedAttrJson) {
                  const linkedAttr: LinkedAttr = JSON.parse(linkedAttrJson);
                  return linkedAttr.value;
                } else {
                  return "-";
                }
              }}/>
            }else{
              return <ExcelColumn label={attr.specification.res_type_attribute_name} value={attr.specification.res_type_attribute_code}/>
            }
          }).filter(elt => !!elt)}
        </ExcelSheet>
      </ExcelFile>
      <Dropdown overlay={moreExportActions()} trigger={["click"]}>
        <Button
          type="primary"
          ghost
          icon={<MoreOutlined />}
          onClick={(e) => e.stopPropagation()}
        ></Button>
      </Dropdown>      
    </ButtonGroup>,
    <ButtonGroup>
      <Link to={`${routeMatch.url}/new`}>
        <Button type="primary">Add record</Button>
      </Link>
      <Dropdown overlay={moreNewRecordActions()} trigger={["click"]}>
        <Button
          type="primary"
          ghost
          icon={<MoreOutlined />}
          onClick={(e) => e.stopPropagation()}
        ></Button>
      </Dropdown>
    </ButtonGroup>,
    <FilterBtn filterRecords={(filterId) => filterRecords(filterId)} resourceType={resourceType} />,
    <CustomActionBtn customActions={(customActionId) => customActions(customActionId, null)} resourceType={resourceType} />,
  ];

  useEffect(() => {
    if (orgId) {
      if(resourceTypeId !== prevResTypeId){
        filterRecords(undefined)
      }
      dispatch(fetchResourceRecords({ orgId, resourceTypeId, selectedFilterId: filterId }));
    }

  }, [resourceTypeId, orgId, filterId]);

  return (
    <div ref={componentRef}>
      <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        {displayChart ? <ChartStats chartsList={dataChartsList?.resourceTypeChartsList ?? []} statisticsList={dataStatisticsList ?? []} orgId={orgId} resTypeId={resourceTypeId} filterId={filterId} /> : null}
        <ProTable
          // TODO Promise "adapter": params, pagination
          // request={() => {
          //   return Promise.resolve({
          //     data: resourceRecords?.data || undefined,
          //     success: resourceRecords ? true : false,
          //   });
          // }}
          locale={{ 
            triggerDesc: 'descend sort text',
            triggerAsc: 'ascend sort text', 
            cancelSort: 'cancel sort text'
          }}
          toolbar={{
            actions: !resourceType?.res_type_create_disabled
              ? toolbarActions
              : [],
          }}
          dataSource={resourceRecords?.data || []}
          expandable={{ expandedRowRender }}
          search={false}
          rowKey="id"
          columns={columns}
          loading={loading}
          // pagination={{ locale: {  total: {total: "items/page"}} }}
          // columnState={{}}
          title={() => <h1 style={{textTransform: 'capitalize'}}>{resourceType?.res_type_name ?? ""}</h1>}
        ></ProTable>
        <Switch>
          <Route path={`${routeMatch.path}/link/:linkResourceId/:linkId`}>
            <ResourceLinkDetail />
          </Route>
          <Route path={`${routeMatch.path}/record/:drilledRecordId/relation/:resTypeResourceId/:linkResTypeId`} exact>
            <ResourceTypeLinkDetail />
          </Route>
          <Route path={`${routeMatch.path}/record/:drilledRecordId/relation/:resTypeResourceId/:linkResTypeId/new`}>
            <ResourceRecordForm mode="create" />
          </Route>
          <Route path={`${routeMatch.path}/new`}>
            <ResourceRecordForm mode="create" />
          </Route>
          <Route path={`${routeMatch.path}/record/:drilledRecordId/relation/:resTypeResourceId/:linkResTypeId/edit/:recordId`}>
            <ResourceRecordForm mode="edit" />
          </Route>
          <Route path={`${routeMatch.path}/edit/:recordId`}>
            <ResourceRecordForm mode="edit" />
          </Route>
          <Route path={`${routeMatch.path}/history/:recordId`}>
            <ResourceRecordHistory filterId={filterId} expandable={{ expandedRowRender }} resourceTypeToColumns={resourceType
    ? resourceTypeToColumns('history', routeMatch, resourceType, getColumnSearchProps, getColumnDateRangeProps, getColumnSelectRangeProps, customActions)
    : []} />
          </Route>
          <Route path={`${routeMatch.path}/trash`}>
            <ResourceRecordTrash filterId={filterId} expandable={{ expandedRowRender }} resourceTypeToColumns={resourceType
    ? resourceTypeToColumns('trash', routeMatch, resourceType, getColumnSearchProps, getColumnDateRangeProps, getColumnSelectRangeProps, customActions)
    : []} />
          </Route>
          <Route path={`${routeMatch.path}/gallery/:recordId`}>
            <ResourceRecordFiles filterId={filterId} />
          </Route>
          <Route path={`${routeMatch.path}/delete/:recordId`}>
            <ResourceRecordDelete />
          </Route>
        </Switch>
      </ConfigProvider>
      <CustomActionModal content={customActionResponse} modalIsShow={customActionResponseModalIsShow} handleCloseModal={handleCloseCustomActionResponseModal}/>
      <CustomActionFormModal setCustomActionResponse={setCustomActionResponse} setCustomActionResponseModalIsShow={setCustomActionResponseModalIsShow} customActionRecordData={customActionRecordData} customActionForm={customActionForm as unknown as fetchApplicationDetails_application_resourceTypes_attributes[]} modalIsShow={customActionFormModalIsShow} handleCloseModal={handleCloseCustomActionFormModal}/>
    </div>
  );
};