import { Table, Tag } from 'antd';
import React from 'react'
import { Link, Route } from 'react-router-dom';

const Invoices: React.FC = () => {
  const dataSource = [
    {
      key: '1',
      number: 'IN1234',
      invoice_date: "2022-04-18 12:05:10",
      due_date: "2022-04-18 12:05:10",
      status: "paid",
      amount_due: "0 $"
    },
    {
      key: '2',
      number: 'IN5645',
      invoice_date: "2022-04-18 12:05:10",
      due_date: "2022-04-18 12:05:10",
      status: "paid",
      amount_due: "0 $"
    },
    {
      key: '2',
      number: 'IN6567',
      invoice_date: "2022-04-18 12:05:10",
      due_date: "2022-04-18 12:05:10",
      status: "paid",
      amount_due: "0 $"
    },
  ];
  
  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      render: (number: any) => <Link to="/invoices/4545">{number}</Link>
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
    },
    {
      title: 'Due data',
      dataIndex: 'due_date',
      key: 'due_date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => <Tag color={"green"}>
        {status}
      </Tag>
    },
    {
      title: 'Amount Due',
      dataIndex: 'amount_due',
      key: 'amount_due',
    },
  ];
  
  return (
    <div>
      <h2>Invoices</h2>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default Invoices