import { gql } from "@apollo/client"

export const ResourceTypeChartsListQuery = gql`
  query ResourceTypeChartsList($orgId: String!, $resTypeId: String!){
    resourceTypeChartsList(
      input: {
        org_id: $orgId, 
        res_type_id: $resTypeId
      }
    )
  }
`;