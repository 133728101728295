import { Dropdown, Menu, Tooltip } from 'antd'
import { ClusterOutlined } from '@ant-design/icons'
import React from 'react'
import { fetchApplicationDetails_application_resourceTypes } from '../queries/__generated__/fetchApplicationDetails';
import { IconList, IconPickerItem } from 'react-fa-icon-picker';
import { fontAwesomeList } from './helpers/iconList';

interface Props{
  resourceType: fetchApplicationDetails_application_resourceTypes | undefined;
  customActions: (customActionId: string | undefined, recordId: string | null) => void;
}

const CustomActionBtn: React.FC<Props> = (props) => {

  const menu = (
    <Menu>
      { props.resourceType?.customActions?.data.filter(action => action.action_global).map((customAction, i) => <Menu.Item key={i} onClick={() => props.customActions(customAction.id, null)}><div style={{display: 'flex'}}><IconPickerItem icon={customAction.action_icon && fontAwesomeList.includes(customAction.action_icon) ? customAction.action_icon as IconList : "FaThLarge"} color="gray" size={18} containerStyles={{display: 'flex', marginRight: '5px'}} />{customAction.action_name}</div></Menu.Item>) }
    </Menu>
  );
  return (
    <Tooltip placement="top" title={'CustomActions'}>
      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <ClusterOutlined style={{fontSize: '18px', marginLeft: '10px'}} />
      </Dropdown>
    </Tooltip>
  )
}

export default CustomActionBtn