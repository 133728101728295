import { gql } from "@apollo/client";

export const fetchResourceTypeQuery = gql`
    query fetchResourceType($resTypeId: String!){
      resourceType(
        id: $resTypeId
      ) {
        id
        org_id
        res_meta_type_id
        res_type_name
        res_type_table_name
        res_type_description
        res_type_icon
        res_type_user_map
        res_type_user_registration_email_template
        res_type_user_registration_org_id
        res_type_table_name
        res_type_disabled
        res_type_gallery_disabled
        res_type_history_disabled
        res_type_details_disabled
        res_type_view_disabled
        res_type_edit_disabled
        res_type_delete_disabled
        res_type_rows_selectable
        res_type_datatable_width
        res_type_filter_sql
        res_type_display_rows
        created_at
      }
    }
`;