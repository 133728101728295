import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Col, Input, message, Row } from 'antd'
import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { CreateResetPasswordTokenMutation } from '../mutations/createResetPasswordToken';
import { ResetPasswordMutation } from '../mutations/resetPassword';
import { CreateResetPasswordToken, CreateResetPasswordTokenVariables } from '../mutations/__generated__/CreateResetPasswordToken';
import { ResetPassword, ResetPasswordVariables } from '../mutations/__generated__/ResetPassword';
import { fetchPasswordResetQuery } from '../queries/fetchPasswordReset';
import { FetchPasswordReset, FetchPasswordResetVariables } from '../queries/__generated__/FetchPasswordReset';
import LoadingPage from './Loading';

const PasswordReset: React.FC = () => {

  const [email, setEmail] = useState('')
  const {token} = useParams<{token: string}>();
  const history = useHistory();
  const [messageToken, setMessageToken] = useState<string | undefined>(undefined)
  const [passwordReset, setPasswordReset] = useState<any>()
  const [newPassword, setNewPassword] = useState<string>()
  const [confirmPassword, setConfirmPassword] = useState<string>()

  const { data: dataResetPassword, loading: loadingResetPassword, error } = useQuery<
    FetchPasswordReset,
    FetchPasswordResetVariables
  >(fetchPasswordResetQuery, {
    variables: {
      token: token,
    },
    skip: !token,
    onCompleted: (res => {
      if(res.fetchPasswordReset.data){
        setEmail(JSON.parse(res.fetchPasswordReset.data).email)
        setPasswordReset(JSON.parse(res.fetchPasswordReset.data))
      }
    }),
    onError: (error => {
      message.error(error.message)
    })
  });

  const [ResetPassword, {loading: loadingReseting}] = useMutation<
  ResetPassword,
  ResetPasswordVariables
  >(ResetPasswordMutation);

  const [CreateResetPasswordToken, {loading: loadingCreating}] = useMutation<
  CreateResetPasswordToken,
  CreateResetPasswordTokenVariables
  >(CreateResetPasswordTokenMutation);

  const createResetPasswordToken = async (email: string) => {
    try {
        const input: CreateResetPasswordTokenVariables = {
          email,
        };
        let res = await CreateResetPasswordToken({ variables: input });
        setMessageToken(res.data?.createResetPasswordToken.message)
        message.success(res.data?.createResetPasswordToken.message);
    } catch (error) {
      message.error("Failed to create reset password token");
      console.error(error);
    }
  };

  const resetPassword = async () => {
    if(newPassword != confirmPassword){
      message.error('Password confirmation error')
      throw ('Password confirmation error')
    }
    try {
      const input: ResetPasswordVariables = {
        input: {
          email,
          token: token,
          password: newPassword
        },
      };
      let res = await ResetPassword({ variables: input });
      message.success(res.data?.resetPassword.message);
      setTimeout(() => history.push('/login'), 3000)
    } catch (error) {
      message.error("Failed to reset password");
      console.error(error);
    }
  }

  if(token && loadingResetPassword){
    return <LoadingPage />
  }else if(token && !loadingResetPassword && !error){
    return (
      <div style={{marginTop: '5em'}}>
        <h2 style={{textAlign: 'center'}}>New password</h2>
        <Row style={{margin: '0.5em 0'}}>
          <Col offset={6} span={12}>
            <Input disabled placeholder='Email' value={passwordReset ? passwordReset.email : ''}></Input>
          </Col>
        </Row>
        <Row style={{margin: '0.5em 0'}}>
          <Col offset={6} span={12}>
            <Input.Password placeholder='New Password' onChange={e => setNewPassword(e.target.value)}></Input.Password>
          </Col>
        </Row>
        <Row style={{margin: '0.5em 0'}}>
          <Col offset={6} span={12}>
            <Input.Password placeholder='Confirm Password' onChange={e => setConfirmPassword(e.target.value)}></Input.Password>
          </Col>
        </Row>
        <Row>
          <Col offset={6} span={12} style={{textAlign: 'end'}}>
            <Button loading={loadingReseting} onClick={() => resetPassword()}>Submit</Button>
          </Col>
        </Row>
      </div>
    )
  }else if(token && !loadingResetPassword && error){
    return (
      <div style={{marginTop: '5em'}}>
        <Alert type='error' description={error.message} showIcon />
        <div style={{textAlign: 'center'}}>
          <Button size="large" type="link">
            <Link to="/password-reset">Forgot your password ?</Link>
          </Button>
        </div>
      </div>
    )
  }else{
    return (
      <Row style={{marginTop: '5em'}}>
        <Col offset={6} span={12}>
          <h2>Password Reset</h2>
          {messageToken ? <Alert message={messageToken} showIcon type='success' closable /> : ''}
          <h4>Enter your email</h4>
          <div style={{display: 'flex'}}>
            <Input placeholder='Enter email' style={{marginRight: '10px'}} value={email} onChange={(e) => setEmail(e.target.value)} ></Input>
            <Button type='primary' onClick={() => createResetPasswordToken(email)} loading={loadingCreating}>Send</Button>
          </div>
        </Col>
      </Row>
    )
  }

}

export default PasswordReset