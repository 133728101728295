import React, { useEffect, useState } from "react";
import { Button, AutoComplete, Input, Space } from "antd";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchResourceTypeAttributes } from "../../slices/resourceTypeAttributesSlice";

export type INewLineResourceTypeAttribute = ISelectedResourceTypeAttribute | INewResourceTypeAttribute;

export interface ISelectedResourceTypeAttribute {
  id: string;
  res_type_attribute_name: string;
  res_type_attribute_type: string;
  res_type_attribute_constraints: string;
  res_type_attribute_description: string;
}

export interface INewResourceTypeAttribute {
  res_type_attribute_name: string;
}

interface ISelection {
  resourceTypeAttribute: ISelectedResourceTypeAttribute;
  value: string;
}

interface Props {
  onLineResourceTypeAttributeAdd: (line: INewLineResourceTypeAttribute) => void;
  inputStyle?: React.CSSProperties;
  excludeResTypeAttributes: string[];
  orgId: string;
}

const LineResourceTypeAttributeSearchAndAdd: React.FC<Props> = (props) => {
  const { t } = useTranslation('LineResourceTypeAttributeSearchAndAdd');
  const { loading, data } = useAppSelector(state => state.resourceTypeAttributes)
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState('');
  const [selection, setSelection] = useState<ISelectedResourceTypeAttribute | null>(null);

  const options: (ISelection | {value: ''})[] = (data || []).filter(item => props.excludeResTypeAttributes.indexOf(item.id) < 0).map((resourceTypeAttribute: any) => {
      return {
        resourceTypeAttribute: {
          id: resourceTypeAttribute.id!,
          res_type_attribute_name: resourceTypeAttribute.res_type_attribute_name,
          res_type_attribute_type: resourceTypeAttribute.res_type_attribute_type,
          res_type_attribute_constraints: resourceTypeAttribute.res_type_attribute_constraints,
        },
        value: resourceTypeAttribute.res_type_attribute_name!,
      };
  });

  const onAdd = () => {
    if (selection) {
      props.onLineResourceTypeAttributeAdd(selection);
    } else if (search) {
      props.onLineResourceTypeAttributeAdd({ res_type_attribute_name: search });
    }
  };

  const onSelect = (_: string, selected: any) => {
    setSelection((selected as ISelection).resourceTypeAttribute);
    setSearch(null);
  };

  const onSearch = (input: string) => {
    if (search !== input) { // only if input really changed
      // setSelection(null);
      if (input.trim() === "") {
        setSearch(null);
      } else {
        setSearch(input);
      }
    }
  };

  const onChange = (val: string) => {
    let existingResTypeAttribute = (data || []).filter(item => props.excludeResTypeAttributes.indexOf(item.id) < 0).find(item => item.res_type_attribute_name.trim().replace(/[' ']/g, '').toLocaleLowerCase() == val.trim().replace(/[' ']/g, '').toLocaleLowerCase())
    if(existingResTypeAttribute){
      setSelection({ id: existingResTypeAttribute.id,
        res_type_attribute_name: existingResTypeAttribute.res_type_attribute_name,
        res_type_attribute_type: existingResTypeAttribute.res_type_attribute_type,
        res_type_attribute_constraints: existingResTypeAttribute.res_type_attribute_constraints ? existingResTypeAttribute.res_type_attribute_constraints : '',
        res_type_attribute_description: existingResTypeAttribute.res_type_attribute_description ? existingResTypeAttribute.res_type_attribute_description : ''})
    }else{
      setSelection(null);
    }
    setValue(val);
  };

  useEffect(() => {
    dispatch(fetchResourceTypeAttributes({orgId: props.orgId}))
    setValue('');
  }, [dispatch, props.orgId])

  const buttonContent =
    selection || search ? (selection ? t("Add selected") : t("Add new")) : t("Search");

  const buttonDisabled = !selection && !search;

  const dropShouldOpen = search !== null;

  return (
    <Space direction="horizontal" size="small">
      <AutoComplete
        value={value}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(inputValue, option) =>
          inputValue.trim() !== "" &&
          option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        open={dropShouldOpen}
      >
        <Input
          placeholder={t("Search or add fields...")}
          style={props.inputStyle}
        />
      </AutoComplete>
      <Button
        type="primary"
        loading={loading}
        disabled={buttonDisabled}
        onClick={onAdd}
      >
        {buttonContent}
      </Button>
    </Space>
  );
};

export default LineResourceTypeAttributeSearchAndAdd;
