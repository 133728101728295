import React, { useEffect, useState } from "react";
import { Form, Table, Button, Checkbox, Spin, message, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import LineResourceTypeAttributeSearchAndAdd, {
  INewLineResourceTypeAttribute,
} from "./LineResourceTypeAttributeSearch";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { ILineResourceType } from "./UxLineResourceTypesTable";
import { ResourceTypeAttributeTypeByResTypeQuery } from "../../queries/resourceTypeAttributeTypeByResType";
import {
  ResourceTypeAttributeTypeByResType,
  ResourceTypeAttributeTypeByResTypeVariables,
  ResourceTypeAttributeTypeByResType_resourceTypeAttributeTypeByResType as IResourceTypeAttributeTypeByResTypeDetails,
} from "../../queries/__generated__/ResourceTypeAttributeTypeByResType";
import { useMutation, useQuery } from "@apollo/client";
import UxResTypeFieldForm from "./UxResTypeFieldForm";
import { DeleteResourceTypeAttributeType, DeleteResourceTypeAttributeTypeVariables } from "../../mutations/__generated__/DeleteResourceTypeAttributeType";
import { deleteResourceTypeAttributeTypeMutation } from "../../mutations/deleteResourceTypeAttributeType";

export interface ILineResourceTypeAttribute {
  id: string | null;
  linked_id: string | null;
  res_type_id: string | null;
  res_type_attribute_type_mandatory: boolean;
  res_type_attribute_type_printable: boolean;
  res_type_attribute_type_editable: boolean;
  res_type_attribute_type_hidden: boolean | null;
  res_type_attribute_type_speech_input?: string | null;
  res_type_attribute_type_default_value?: string | null;
  res_type_attribute_type_formula?: string | null;
  res_type_attribute_type_group?: string | null;
  res_type_attribute_type_detail: boolean;
  specification: {
    id: string | null;
    res_type_attribute_name: string;
    res_type_attribute_type: string;
    res_type_attribute_description: string | null;
    res_type_attribute_constraints: string | null;
  };
}

interface ILineResourceTypeAttributeInternal {
  thisIsThatExtraRow: boolean;
}

const initialField = {
  id: null,
  linked_id: null,
  res_type_id: null,
  res_type_attribute_type_mandatory: false,
  res_type_attribute_type_printable: false,
  res_type_attribute_type_editable: true,
  res_type_attribute_type_hidden: false,
  res_type_attribute_type_speech_input: null,
  res_type_attribute_type_default_value: null,
  res_type_attribute_type_formula: null,
  res_type_attribute_type_group: null,
  res_type_attribute_type_detail: false,
  specification: {
    id: null,
    res_type_attribute_name: "",
    res_type_attribute_description: "",
    res_type_attribute_type: "",
    res_type_attribute_constraints: null,
  }
}

export interface ILineFormValues extends Partial<ILineResourceTypeAttribute> {
  res_type_attribute_type?: string;
}

interface Props {
  onLineSaveOrUpdate: (item: ILineResourceTypeAttribute, index: number | null) => void;
  onLineAdd: (item: ILineResourceTypeAttribute) => void;
  onLineRemove: (index: number, item: ILineResourceTypeAttribute) => void;
  onLineChange: (index: number, item: ILineFormValues) => void;
  saveMode: 'database' | 'state';
  currentResourceType: ILineResourceType | undefined;
  orgId: string;
  disableProperties: boolean;
  setAttributes: (
    data: IResourceTypeAttributeTypeByResTypeDetails[] | null
  ) => void;
}

const isNormalRow = (
  row: ILineResourceTypeAttribute | ILineResourceTypeAttributeInternal
): row is ILineResourceTypeAttribute => !("thisIsThatExtraRow" in row);

interface IRTFieldFormState {
  type: "create" | "edit"
  initialField: ILineResourceTypeAttribute
  isModalVisible: boolean
  index: number | null
}

const UxLineResourceTypeAttributesTable: React.FC<Props> = (props) => {
  const [DeleteResourceTypeAttributeType, {loading: loadingDeleteField}] = useMutation<DeleteResourceTypeAttributeType, DeleteResourceTypeAttributeTypeVariables>(deleteResourceTypeAttributeTypeMutation)
  const [rTFieldFormState, setRTFieldFormState] = useState<IRTFieldFormState>({type: "create", initialField, isModalVisible: false, index: null});

  const showFieldModal = (type: "create" | "edit", field: ILineResourceTypeAttribute, index: number | null) => {
    // Field redefined to exclude res_type_attribute_code
    setRTFieldFormState({
      type, initialField: {...field, specification: {
        id: field.specification.id, 
        res_type_attribute_constraints: field.specification.res_type_attribute_constraints,
        res_type_attribute_description: field.specification.res_type_attribute_description,
        res_type_attribute_name: field.specification.res_type_attribute_name,
        res_type_attribute_type: field.specification.res_type_attribute_type,
      }}, 
      isModalVisible: true, 
      index
    })
  };

  const handleCancel = () => {
    setRTFieldFormState({...rTFieldFormState, isModalVisible: false});
  };

  const { t } = useTranslation("UxLineResourceTypeAttributesTable");
  const [form] = useForm();
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
    ResourceTypeAttributeTypeByResType,
    ResourceTypeAttributeTypeByResTypeVariables
  >(ResourceTypeAttributeTypeByResTypeQuery, {
    variables: {
      resTypeId: props.currentResourceType?.id!,
    },
    onCompleted: (data) =>
      setAttributes(data.resourceTypeAttributeTypeByResType),
    skip: props.currentResourceType?.id == null,
  });

  const setAttributes = (
    data: IResourceTypeAttributeTypeByResTypeDetails[] | null
  ) => {
    props.setAttributes(data);
  };

  const onResourceTypeAttributeSaveOrUpdate = (item: ILineResourceTypeAttribute) => {
    props.onLineSaveOrUpdate(item, rTFieldFormState.index)
  }

  const onLineResourceTypeAttributeAdd = (
    item: INewLineResourceTypeAttribute
  ) => {
    const newItem: ILineResourceTypeAttribute =
      "id" in item
        ? {
            id: null,
            specification: {
              id: item.id,
              res_type_attribute_name: item.res_type_attribute_name,
              res_type_attribute_type: item.res_type_attribute_type ?? "text",
              res_type_attribute_constraints: item.res_type_attribute_constraints,
              res_type_attribute_description: item.res_type_attribute_description
            },
            linked_id: null,
            res_type_id: null,
            res_type_attribute_type_mandatory: false,
            res_type_attribute_type_printable: false,
            res_type_attribute_type_editable: false,
            res_type_attribute_type_hidden: false,
            res_type_attribute_type_speech_input: null,
            res_type_attribute_type_default_value: null,
            res_type_attribute_type_formula: null,
            res_type_attribute_type_group: null,
            res_type_attribute_type_detail: false
          }
        : {
            id: null,
            specification: {
              id: null,
              res_type_attribute_name: item.res_type_attribute_name,
              res_type_attribute_constraints: "maxlength=255", // default value
              res_type_attribute_type: "text", // default value
              res_type_attribute_description: ""
            },
            linked_id: null,
            res_type_id: null,
            res_type_attribute_type_mandatory: false,
            res_type_attribute_type_printable: false,
            res_type_attribute_type_editable: false,
            res_type_attribute_type_hidden: false,
            res_type_attribute_type_speech_input: null,
            res_type_attribute_type_default_value: null,
            res_type_attribute_type_formula: null,
            res_type_attribute_type_group: null,
            res_type_attribute_type_detail: false
          };

    showFieldModal("create", newItem, null)
  };

  const onItemDelete = (index: number, item: ILineResourceTypeAttribute) => {
    setToRemoveIndex(index)
    if(props.saveMode === 'database' && props.currentResourceType?.id !== null && item.id !== null){
      const input = {fieldId: item.id!, resTypeId: props.currentResourceType?.id!};
      try{
        let res = DeleteResourceTypeAttributeType({variables: input, refetchQueries: [
          {
            query: ResourceTypeAttributeTypeByResTypeQuery,
            variables: {
              resTypeId: input.resTypeId,
            }
          }]});
        res.then(res => {
          message.success(res.data?.deleteResourceTypeAttributeType)
        }).catch(raison => {
          message.error("Failed to delete a field")
          console.error(raison)
        })
      }catch(error){
        message.error("Failed to delete a field")
        console.error(error)
      }
    }else{
      props.onLineRemove(index, item);
    }
    setToRemoveIndex(index)
  };

  const onLineChanged = (index: number) => {
    const allResourceTypeAttributes = Object.entries(form.getFieldsValue()).map(
      ([_, entry]) => entry
    );
    const changedLine = allResourceTypeAttributes[index] as ILineFormValues;
    props.onLineChange(index, {
      ...props.currentResourceType!.attributes[index],
      ...changedLine,
    });
  };

  const properties = [
    { label: "mandatory", value: "res_type_attribute_type_mandatory" },
    { label: "editable", value: "res_type_attribute_type_editable" },
    { label: "hidden", value: "res_type_attribute_type_hidden" },
    { label: "detail", value: "res_type_attribute_type_detail" },
  ];

  const columns: ColumnsType<
    ILineResourceTypeAttribute | ILineResourceTypeAttributeInternal
  > = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Field Name"),
      key: "res_type_attribute_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <LineResourceTypeAttributeSearchAndAdd
                excludeResTypeAttributes={props.currentResourceType ? props.currentResourceType?.attributes.filter(item => item.id && item.id.length > 0).map(item => item.specification.id!) : []}
                orgId={props.orgId}
                onLineResourceTypeAttributeAdd={onLineResourceTypeAttributeAdd}
                inputStyle={{ width: "300px" }}
              />
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Type"),
      key: "res_type_attribute_type",
      render: (_, row, index) => {
        if (isNormalRow(row)) {
          return (
            <React.Fragment>
              {row.specification.res_type_attribute_type}
            </React.Fragment>
          );
        } else {
          // collapsed in "extra" row
          return { props: { colSpan: 0 } };
        }
      },
    },
    {
      title: t("Properties"),
      key: "res_type_attribute_type_properties",
      render: (_, row, index) => {
        return isNormalRow(row) ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Form.Item
              name={[index, "res_type_attribute_type_mandatory"]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) => onLineChanged(index)}
                disabled={props.disableProperties}
              >
                mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={[index, "res_type_attribute_type_editable"]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={() => onLineChanged(index)}
                disabled={props.disableProperties}
              >
                editable
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={[index, "res_type_attribute_type_hidden"]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={() => onLineChanged(index)}
                disabled={props.disableProperties}
              >
                hidden
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={[index, "res_type_attribute_type_detail"]}
              valuePropName="checked"
            >
              <Checkbox
                onChange={() => onLineChanged(index)}
                disabled={props.disableProperties}
              >
                detail
              </Checkbox>
            </Form.Item>
          </div>
        ) : (
          { props: { colSpan: 0 } }
        );
      },
    },
    {
      title: (
        <div style={{textAlign: "center"}}>
          <Button type="primary" onClick={() => showFieldModal("create", initialField, null)}>
          <PlusOutlined /> New Field
          </Button>
        </div>
      ),
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{ marginRight: "10px" }}
                loading={props.saveMode === 'database' && toRemoveIndex === index ? loadingDeleteField : false}
              />
            </Popconfirm>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => showFieldModal("edit", row, index)}
            />
          </div>
        ),
    },
  ];

  const lineResourceTypeAttributesWithExtra: (
    | ILineResourceTypeAttribute
    | ILineResourceTypeAttributeInternal
  )[] = props.saveMode === "database" ? (data?.resourceTypeAttributeTypeByResType
    ? [...data?.resourceTypeAttributeTypeByResType, { thisIsThatExtraRow: true }]
    : []) : (props.currentResourceType?.attributes
      ? [...props.currentResourceType?.attributes, { thisIsThatExtraRow: true }]
      : []);

  useEffect(() => {
    if (props.currentResourceType?.attributes) {
      props.currentResourceType?.attributes.forEach((attr, i) => {
        properties.forEach((pp) => {
          let attrMap: { [key: string]: string } = JSON.parse(
            JSON.stringify(attr)
          );
          form.setFields([{ name: [i, pp.value], value: attrMap[pp.value] }]);
        });
      });
    }
  }, [props.currentResourceType]);

  if (loading) {
    return <Spin size="large" />;
  } else {
    return (
      <>
        <Form form={form}>
          <Table
            className="ux-line-items-table"
            columns={columns}
            dataSource={lineResourceTypeAttributesWithExtra}
            pagination={false}
            size="small"
          />
        </Form>
        <UxResTypeFieldForm
          properties={properties}
          visible={rTFieldFormState.isModalVisible}
          mode={rTFieldFormState.type}
          onClose={() => handleCancel()}
          initialField={rTFieldFormState.initialField}
          resTypeId={props.currentResourceType?.id ?? ""}
          onLineSaveOrUpdate={onResourceTypeAttributeSaveOrUpdate}
          saveMode={props.saveMode}
          orgId={props.orgId}
        />
      </>
    );
  }
};

export default UxLineResourceTypeAttributesTable;
