import { useMutation } from '@apollo/client';
import { Button, Input, Modal, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { InternalNamePath } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { sendRegisterInvitationMutation } from '../mutations/sendRegisterInvitation';
import { SendRegisterInvitation, SendRegisterInvitationVariables } from '../mutations/__generated__/SendRegisterInvitation';

type Props = {
  isModalVisible: boolean;
  orgId: string;
  handleCancel: () => void;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const InviteUser: React.FC<Props> = (props) => {
  const [emailInputCount, setEmailInputCount] = useState<string[]>([""])
  const [form] = useForm();

  const [SendRegisterInvitation, {loading}] = useMutation<
  SendRegisterInvitation,
  SendRegisterInvitationVariables
  >(sendRegisterInvitationMutation);

  const reset = () => {
    form.resetFields();
    setEmailInputCount([""]);
  };

  const handleOk = async () => {
  
    try {
        const input: SendRegisterInvitationVariables = {
          email: emailInputCount,
          orgId: props.orgId!,
          message: form.getFieldValue('message')
        };
        let res = await SendRegisterInvitation({ variables: input });
        message.success(res.data?.sendRegisterInvitation);
    } catch (error) {
      message.error("Failed to invite users");
      console.error(error);
    }
    reset()
    props.handleCancel()
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {};

  return (
    <>
      <Modal
        title="Invite users to join organization"
        visible={props.isModalVisible}
        onOk={form.submit}
        okText='Send Invitation'
        confirmLoading={loading}
        onCancel={() => {
          reset()
          props.handleCancel()
        }}
      >
        <Form
          {...formLayout}
          form={form}
          onFinish={() => handleOk()}
          onFinishFailed={({ errorFields }) => {
            onValidationError(errorFields.map((ef) => ef.name))
          }
          }
        >
          {emailInputCount.map((v, i) => (
            <Form.Item
              label={"Email "+(i+1)}
              name={"email_"+(i+1)}
              style={{marginBottom: '5px'}}
              rules={[{type: 'email', required: true}]}
            >
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <Input
                  value={emailInputCount[i]}
                  onChange={(elt) => {
                    var arrEm = JSON.parse(JSON.stringify(emailInputCount))
                    arrEm[i] = elt.target.value
                    setEmailInputCount([...arrEm])
                  }}
                />
                {i>0 ? <Button danger style={{marginLeft: '5px'}} onClick={() => {
                  emailInputCount.splice(i,1)
                  setEmailInputCount([...emailInputCount])
                }}><MinusOutlined /></Button> : <Button type='primary' style={{marginLeft: '5px'}} onClick={() => setEmailInputCount([...emailInputCount, ""])}><PlusOutlined /></Button>}
              </div>
            </Form.Item>
          ))}
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InviteUser;