import { Modal, Form, Input, Select, Upload, Button, message, Drawer, Badge } from "antd";
import { useForm } from "antd/lib/form/Form";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { ReactNode, useState } from "react";
import { UploadChangeParam } from "antd/lib/upload";
import {
  CloudUploadOutlined,
  SaveOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { CreateResourceTypeFromImportMutation } from "../mutations/createResourceTypeFromImport";
import {
  CreateResourceTypeFromImport,
  CreateResourceTypeFromImportVariables,
} from "../mutations/__generated__/CreateResourceTypeFromImport";
import { useMutation } from "@apollo/client";
import "./UploadInput.less";
import { useAppSelector } from "../hooks";
import { useHistory } from "react-router-dom";

interface Props {
  visible: boolean;
  onClose: () => void;
}

interface ITableImport {
  app_id: string;
  res_type_name: string;
  file: UploadFile | null;
  csv_delimiter: string;
}

interface IDrawerState {title: string, content: ReactNode, visible: boolean}

const initTable = {
  app_id: "",
  res_type_name: "",
  file: null,
  csv_delimiter: ";"
};

const TableImport: React.FC<Props> = (props) => {
  const [table, setTable] = useState<ITableImport>(initTable);
  const [CreateResourceTypeFromImport] = useMutation<
    CreateResourceTypeFromImport,
    CreateResourceTypeFromImportVariables
  >(CreateResourceTypeFromImportMutation);
  const [drawerState, setDrawerState] = useState<IDrawerState>({title: '', content: '', visible: false})
  const [form] = useForm();
  const history = useHistory();

  const app = useAppSelector(state => state.application.data)

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const reset = () => {
    form.resetFields();
    setTable(initTable);
  };

  const onValidationError = (errorFields: InternalNamePath[]) => {};

  const onSave = async () => {
    try {
      const input = {
        appId: app?.id!,
        resTypeName: table.res_type_name,
        csvDelimiter: table.csv_delimiter,
        ...table
      };
      let res = await CreateResourceTypeFromImport({ variables: input });
      message.success(res.data?.createResourceTypeFromImport);
      onClose();
      history.push("/applications/" + app?.id + "/tables");
    } catch (error) {
      message.error("Failed to create an app");
      console.error(error);
    }
    onClose();
  };

  const onDrawerInfos = (elt: ReactNode, title: string) => {
    setDrawerState({title, content: elt, visible: true});
  };

  const onDrawerClose = () => {
    setDrawerState({...drawerState, visible: false});
  }

  const onClose = () => {
    reset();
    props.onClose();
  };

  const upProps = {
    beforeUpload: (file: UploadFile<any>) => {
      setTable({ ...table, file });
      return false;
    },
    onRemove: () => {
      setTable({ ...table, file: null });
    },
    name: "table_data",
    multiple: false,
    maxCount: 1,
    accept: ".csv,.xlsx,text/csv",
    onChange: (e: UploadChangeParam<UploadFile<any>>) =>
      setTable({ ...table, file: e.file }),
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      visible={props.visible}
      title="Create table from file"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <>
          <Button type="primary" icon={<SaveOutlined />} onClick={form.submit}>
            {"Save Table"}
          </Button>
          <Button type="default" onClick={onClose}>
            {"Cancel"}
          </Button>
        </>,
      ]}
      width="800px"
    >
      <Form
        {...formLayout}
        form={form}
        onFinish={() => onSave()}
        onFinishFailed={({ errorFields }) =>
          onValidationError(errorFields.map((ef) => ef.name))
        }
      >
        <Form.Item
          label="Table Name"
          name="res_type_name"
          rules={[{ required: true, message: "Please input your table name!" }]}
        >
          <Input
            onChange={(elt) =>
              setTable({ ...table, res_type_name: elt.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          required
          name="upload"
          label="Upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra={
            <div>
              Select csv{" "}
              <QuestionCircleTwoTone
                onClick={() =>
                  onDrawerInfos(
                    <p>In the csv file, make sure to separate each data with a semicolon ( <b>;</b> ) and not a comma ( <b>,</b> )</p>,
                    "File stucture"
                  )
                }
              />
            </div>
          }
        >
          <Upload {...upProps}>
            <div className="ant-upload-container">
              <p className="ant-upload-dragger-icon">
                <CloudUploadOutlined />
              </p>
              <p>Select File</p>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          label="CSV Delimiter"
          name="csv_delimiter"
          rules={[{ required: false, message: "Please enter CSV delimiter" }]}
        >
          <Input
            defaultValue=";"
            onChange={(elt) =>
              setTable({ ...table, csv_delimiter: elt.target.value })
            }
          />
        </Form.Item>
      </Form>
      <Drawer title={drawerState.title} width={500} placement="right" onClose={onDrawerClose} visible={drawerState.visible}>
        {drawerState.content}
      </Drawer>
    </Modal>
  );
};

export default TableImport;
