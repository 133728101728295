import {gql} from '@apollo/client'

export const executeCustomActionMutation = gql`
  mutation ExecuteCustomAction($customActionId: String!, $orgId: String!, $resTypeId: String, $recordId: String, $params: String){
    executeCustomAction(input: {
      action_id: $customActionId
      org_id: $orgId
      res_type_id: $resTypeId
      record_id: $recordId
      params: $params 
    }) 
  }
`;