import { Checkbox, Form, Input, Select } from "antd";

const userDataForm = () => {
  const { Option } = Select
  return (
    <>
      <Form.Item name={"user_id"} hidden>
        <Input type={"hidden"} />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input maxLength={20} />
      </Form.Item>
      <Form.Item name="password" label="Password">
        <Input.Password maxLength={20} />
      </Form.Item>
      <Form.Item name="password_repeat" label="Password Repeat">
        <Input.Password maxLength={20} />
      </Form.Item>
      <Form.Item name="admin" label="Administrator Rights" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name="superuser" label="Superuser Rights" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name="root_org_id" label="Root Organization">
        <Select>
          <Option value="org-1">Fit 1 for ISO 20022</Option>
          <Option value="org-2">Fit 2 for ISO 20022</Option>
        </Select>
      </Form.Item>
      <Form.Item name="lang" label="Default Language" initialValue={'fr'}>
        <Select>
          <Option value="en">English</Option>
          <Option value="fr">Français</Option>
        </Select>
      </Form.Item>
      <Form.Item name="phone_nr" label="Phone Number">
        <Input maxLength={20} />
      </Form.Item>
      <Form.Item initialValue={true} name="enabled" label="Account Enabled" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item initialValue={true} name="auth_basic" label="Basic Authentication" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name="auth_authy" label="2-Factor Authentication (auth_authy)" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name="country_code" label="Country Code">
        <Input />
      </Form.Item>
      <Form.Item initialValue={true} name="display_org_tree" label="Display Org Tree" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name="concurrent" label="Concurrent User" valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  )
}

export default userDataForm