import { gql } from "@apollo/client";

export const fetchPasswordResetQuery = gql`
    query FetchPasswordReset($token: String!){
      fetchPasswordReset(
        token: $token
      ){
        message
        success
        data
      }
    }
`;