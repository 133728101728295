import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { apolloClient } from "../apolloClient";
import { fetchOrganizationsQuery  } from "../queries/fetchOrganizations";
import { fetchOrganizations_organizations_data as organizationsDetails } from "../queries/__generated__/fetchOrganizations"

interface organizationsTypes {
  organizations: {
    data: organizationsDetails
  }
}

type State = {
  loading: boolean;
  data: organizationsDetails[] | null;
  error: Error | null;
};

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

export const fetchOrganizations = createAsyncThunk("organizations/fetch", () =>
  apolloClient.query<organizationsTypes>({ query: fetchOrganizationsQuery, variables: {} }).then((res) => res.data.organizations.data)
);

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrganizations.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchOrganizations.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchOrganizations.rejected.type]: (state, action) => {
      console.error(action);
      state.loading = false;
    },
  },
});

const { reducer, actions } = organizationsSlice;

export const organizationsReducer = reducer;
