import { gql } from "@apollo/client";

export const GroupingFunctionsQuery = gql`
  query GroupingFunctions {
    groupingFunctions(first: 10) {
      data {
        id
        grp_function_name
        grp_function_code
        grp_function_description
        created_at
        updated_at
      }
    }
  }
`;
