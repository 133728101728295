import React, { useEffect, useState } from "react";
import { Button, AutoComplete, Input, Space } from "antd";

import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ResourceTypeAttributeTypeByResType, ResourceTypeAttributeTypeByResTypeVariables } from "../../queries/__generated__/ResourceTypeAttributeTypeByResType";
import { ResourceTypeAttributeTypeByResTypeQuery } from "../../queries/resourceTypeAttributeTypeByResType";
import { ILineResourceTypeAttribute } from "./UxLineResourceTypeAttributesTable";

export interface IResourceTypeAttributeType {
  id: string;
  res_type_id: string;
  specification: {
    id: string;
    res_type_attribute_name: string;
  };
}

interface ISelection {
  resourceTypeAttributeTypeByResType: ILineResourceTypeAttribute;
  value: string;
}

interface Props {
  onLineResourceTypeAttributeTypeAdd: (line: ILineResourceTypeAttribute) => void;
  resTypeId: string | null;
  inputStyle?: React.CSSProperties;
  excludeResTypeAttributes: string[];
}

const ResourceTypeAttributeTypeSearchAndAdd: React.FC<Props> = (props) => {
  const { t } = useTranslation('ResourceTypeAttributeTypeSearchAndAdd');
  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState('');
  const [selection, setSelection] = useState<ILineResourceTypeAttribute | null>(null);

  const { data, loading, error } = useQuery<
  ResourceTypeAttributeTypeByResType,
  ResourceTypeAttributeTypeByResTypeVariables
>(ResourceTypeAttributeTypeByResTypeQuery, {
  variables: {
    resTypeId: props.resTypeId!,
  },
  skip: props.resTypeId == null,
});

  const options: (ISelection | {value: ''})[] = (data?.resourceTypeAttributeTypeByResType || []).filter(item => props.excludeResTypeAttributes.indexOf(item.id) < 0).map((item: ILineResourceTypeAttribute) => {
      return {
        resourceTypeAttributeTypeByResType: item,
        value: item.specification.res_type_attribute_name,
      };
  });
  const onAdd = () => {
    if (selection) {
      props.onLineResourceTypeAttributeTypeAdd(selection);
    }
  };

  const onSelect = (_: string, selected: any) => {
    setSelection((selected as ISelection).resourceTypeAttributeTypeByResType);
    setSearch(null);
  };

  const onSearch = (input: string) => {
    if (search !== input) { // only if input really changed
      setSelection(null);
      if (input.trim() === "") {
        setSearch(null);
      } else {
        setSearch(input);
      }
    }
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  const buttonContent = selection ? t("Add selected") : t("Search");

  const buttonDisabled = !selection;

  const dropShouldOpen = search !== null;

  return (
    <Space direction="horizontal" size="small">
      <AutoComplete
        value={value}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(inputValue, option) =>
          inputValue.trim() !== "" &&
          option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        open={dropShouldOpen}
      >
        <Input
          placeholder={t("Search table fields...")}
          style={props.inputStyle}
        />
      </AutoComplete>
      <Button
        type="primary"
        loading={loading}
        disabled={buttonDisabled}
        onClick={onAdd}
      >
        {buttonContent}
      </Button>
    </Space>
  );
};

export default ResourceTypeAttributeTypeSearchAndAdd;
