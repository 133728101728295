import { Button, Divider, Drawer, Space } from "antd";
import React, { ReactNode, useState, useEffect } from "react";
import {
  ApartmentOutlined,
  InsertRowAboveOutlined,
  LikeOutlined,
  RobotOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import "./ApplicationCreate.less";
import AppFromScratch from "./AppFromScratch";
import AppFromTemplate from "./AppFromTemplate";
import AppImport from "./AppImport";
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchMe } from '../slices/meSlice';
import RestrictionModal from "../components/RestrictionModal";

declare global {
  interface Window {
    userGuiding:any;
  }
}

const ApplicationCreate: React.FC = () => {
  const [actionVisible, setActionVisible] = useState<string | null>(null);
  const organizations = useAppSelector(state => state.me.data?.organizations)
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const showRestrictionModal = () => {
    setOpen(true);
  };

  const hideRestrictionModal = () => {
    setOpen(false);
  };

  const openModal = (modalName: string) => {
    if(organizations?.some(
      (org) => org.is_subscribe
    )){
      setActionVisible(modalName)
    }else{
      showRestrictionModal()
    }
  }

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch])

  return (
    <div style={{ textAlign: "center" }}>
      <RestrictionModal handleCloseModal={hideRestrictionModal} open={open} />
      <Divider orientation="center">
        <h3>Create a new app</h3>
      </Divider>
      <Space size={64} wrap={true} align="start">
        <div className="app-create-btn-container">
          <QuestionCircleOutlined size={32} onClick={() => window.userGuiding.previewGuide(54830)} />
          <div className="app-create-btn" onClick={() => openModal("AppFromTemplate")}>
            <LikeOutlined />
            <h2>Build from template</h2>
            <p>Pick a common use case to start with, then add your own data</p>
          </div>
        </div>
        <div className="app-create-btn-container">
          <QuestionCircleOutlined size={32} onClick={() => window.userGuiding.previewGuide(54894)} />
          <div className="app-create-btn" onClick={() => openModal("AppFromScratch")}>
            <ApartmentOutlined />
            <h2>Start from scratch</h2>
            <p>Define the tables and fields to build your own solution</p>
          </div>
        </div>
        <div className="app-create-btn-container">
          <QuestionCircleOutlined size={32} onClick={() => window.userGuiding.previewGuide(54893)} />
          <div className="app-create-btn" onClick={() => openModal("AppImport")}>
            <InsertRowAboveOutlined />
            <h2>Import a spreadsheet</h2>
            <p>Turn your spreadsheet data into a new app</p>
          </div>
        </div>
        <div className="app-create-btn-container">
          <QuestionCircleOutlined size={32} onClick={() => window.userGuiding.previewGuide(54849)} />
          <div className="app-create-btn" onClick={() => openModal("AppFromText")}>
            <RobotOutlined />
            <h2>Build from text</h2>
            <p>Describe your idea, then let AI generate your app</p>
          </div>
        </div>
      </Space>
      <AppFromScratch
        visible={actionVisible === "AppFromScratch"}
        onClose={() => setActionVisible(null)}
        organizations={organizations?.filter(org => org.is_subscribe)}
      />
      <AppImport
        visible={actionVisible === "AppImport"}
        onClose={() => setActionVisible(null)}
        organizations={organizations?.filter(org => org.is_subscribe)}
      />
      <AppFromTemplate 
        visible={actionVisible === "AppFromTemplate"}
        onClose={() => setActionVisible(null)}
        organizations={organizations?.filter(org => org.is_subscribe)}
      />
      <AppFromScratch
        visible={actionVisible === "AppFromText"}
        onClose={() => setActionVisible(null)}
        organizations={organizations?.filter(org => org.is_subscribe)}
        mode="ai"
      />
    </div>
  );
};

export default ApplicationCreate;
