import { gql } from "@apollo/client";

export const RegisterMutation = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      tokens {
        access_token
        refresh_token
        expires_in
        token_type
        user {
            id
            email
            username
            created_at
            updated_at
        }
      }
      status
    }
  }
`;
