import { gql } from "@apollo/client";

export const updateResourceTypeChartMutation = gql`
  mutation UpdateResourceTypeChart($config_sub_item_id: String!,
    $res_type_chart_name: String!,
    $res_type_chart_description: String,
    $res_type_attribute_type_id_X: String!,
    $res_type_attribute_type_id_Y: String!,
    $grp_function_id: String,
    $chart_type: String) {
    updateResourceTypeChart(input: {
      config_sub_item_id: $config_sub_item_id
      res_type_chart_name: $res_type_chart_name
      res_type_chart_description: $res_type_chart_description
      res_type_attribute_type_id_X: $res_type_attribute_type_id_X,
      res_type_attribute_type_id_Y: $res_type_attribute_type_id_Y,
      grp_function_id: $grp_function_id,
      chart_type: $chart_type
    })
  }
`;
