import React, { ReactNode, useState } from "react";
import { Form, Table, Button, Spin, Input, Space, message, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";

import "./UxLineResourceTypesTable.less";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';
import { ILineResourceType } from "./UxLineResourceTypesTable";
import { ResourceTypeChartByResTypeQuery } from "../../queries/resourceTypeChartByResType";
import { ResourceTypeChartByResType, ResourceTypeChartByResTypeVariables, ResourceTypeChartByResType_resourceTypeChartByResType as IResourceTypeChartByResTypeDetails  } from "../../queries/__generated__/ResourceTypeChartByResType";
import { useMutation, useQuery } from "@apollo/client";
import UxResTypeChartForm from "./UxResTypeChartForm";
import { DeleteResourceTypeChart, DeleteResourceTypeChartVariables } from "../../mutations/__generated__/DeleteResourceTypeChart";
import { deleteResourceTypeChartMutation } from "../../mutations/deleteResourceTypeChart";


type INewLineResourceTypeChart = ILineResourceTypeChart | INewResourceTypeChart;

export interface INewResourceTypeChart {
  res_type_chart_name: string;
}

export interface ILineResourceTypeChart {
  id: string | null;
  res_type_id: string | null;
  res_type_chart_name: string;
  res_type_chart_description: string | null;
  res_type_attribute_type_id_X: string;
  res_type_attribute_type_id_Y: string;
  grp_function_id: string | null;
  grp_function?: {
    grp_function_name: string
  }
  chart_type: string | null;
  attribute_x?: {
    specification: {
      res_type_attribute_name: string
    }
  }
  attribute_y?: {
    specification: {
      res_type_attribute_name: string
    }
  }
}

interface ILineResourceTypeChartInternal {
  thisIsThatExtraRow: boolean;
}

export interface ILineFormValues extends Partial<ILineResourceTypeChart> {
  res_type_chart_description: string | null;
}

interface Props {
  currentResourceType: ILineResourceType | undefined;
  orgId: string;
}

interface IRTChartFormState {
  type: "create" | "edit"
  chart: ILineResourceTypeChart
  isModalVisible: boolean
  index: number | null
}

const initialChart = {
  id: null,
  res_type_id: null,
  res_type_chart_name: '',
  res_type_chart_description: null,
  res_type_attribute_type_id_X: '',
  res_type_attribute_type_id_Y: '',
  grp_function_id: '',
  chart_type: ''
}

const isNormalRow = (row: ILineResourceTypeChart | ILineResourceTypeChartInternal): row is ILineResourceTypeChart =>
  !("thisIsThatExtraRow" in row);

const UxLineResourceTypeChartsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation('UxLineResourceTypeChartsTable');
  const [form] = useForm();
  const [newChart, setNewChart] = useState('');
  const [toRemoveIndex, setToRemoveIndex] = useState<number | null>();
  const { data, loading, error } = useQuery<
    ResourceTypeChartByResType,
    ResourceTypeChartByResTypeVariables
  >(ResourceTypeChartByResTypeQuery, {
    variables: {
      resTypeId: props.currentResourceType?.id!
    },
    skip: props.currentResourceType?.id == null
  });

  const [DeleteResourceTypeChart, {loading: loadingDeleting}] = useMutation<DeleteResourceTypeChart, DeleteResourceTypeChartVariables>(deleteResourceTypeChartMutation)
  const [rTChartFormState, setRTChartFormState] = useState<IRTChartFormState>({type: "create", chart: initialChart, isModalVisible: false, index: null});

  const handleCancel = () => {
    setRTChartFormState({...rTChartFormState, isModalVisible: false});
  };

  const showChartModal = (type: "create" | "edit", chart: ILineResourceTypeChart, index: number | null) => {
    setRTChartFormState({type, chart, isModalVisible: true, index})
  }

  const onLineResourceTypeChartAdd = (item: INewLineResourceTypeChart) => {
    const newItem: ILineResourceTypeChart = {
            id: null,
            res_type_id: props.currentResourceType?.id!,
            res_type_chart_name: item.res_type_chart_name,
            res_type_chart_description: null,
            res_type_attribute_type_id_X: '',
            res_type_attribute_type_id_Y: '',
            grp_function_id: '',
            chart_type: '',
          };

    showChartModal("create", newItem, null);
  };

  const onItemDelete = (index: number, item: ILineResourceTypeChart) => {
    setToRemoveIndex(index)
    const input = {chartId: item.id!};
    try{
      let res = DeleteResourceTypeChart({variables: input, refetchQueries: [{ query: ResourceTypeChartByResTypeQuery,
        variables: {
          resTypeId: props.currentResourceType?.id
        } }]});
      res.then(res => {
        message.success(res.data?.deleteResourceTypeChart)
      }).catch(raison => {
        message.error("Failed to delete a chart")
        console.error(raison)
      })
    }catch(error){
      message.error("Failed to delete a chart")
      console.error(error)
    }
    setToRemoveIndex(index)
  };

  const columns: ColumnsType<ILineResourceTypeChart | ILineResourceTypeChartInternal> = [
    {
      title: t("Pos."),
      key: "position",
      align: "right",
      width: "20px",
      render: (_, row, index: number) => {
        return isNormalRow(row) ? index + 1 : <PlusSquareOutlined />;
      },
    },
    {
      title: t("Chart Name"),
      key: "res_type_chart_name",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.res_type_chart_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 3,
            },
            children: (
              <Space direction="horizontal" size="small">
                <Input
                  placeholder={t("Enter chart name...")}
                  onChange={e => setNewChart(e.target.value)}
                />
                <Button
                  type="primary"
                  loading={loading}
                  disabled={newChart === ''}
                  onClick={() => onLineResourceTypeChartAdd({ res_type_chart_name: newChart })}
                >
                  Add new
                </Button>
              </Space>
            ),
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Attribute X"),
      key: "res_type_attribute_type_id_X",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.attribute_x?.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Attribute Y"),
      key: "res_type_attribute_type_id_Y",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.attribute_y?.specification.res_type_attribute_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Chart Type"),
      key: "chart_type",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.chart_type}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      width: "15%",
      title: t("Aggregation"),
      key: "grp_function_id",
      render: (_, row) => {
        return isNormalRow(row) ? (
          <React.Fragment>
            {row.grp_function?.grp_function_name}
          </React.Fragment>
        ) : (
          {
            props: {
              colSpan: 0,
            }
          }
        );
      },
    },
    {
      key: "actions",
      render: (_, row, index) =>
        isNormalRow(row) && (
          <div style={{display: 'flex'}}>
            <Popconfirm title="Sure to delete?" onConfirm={() => onItemDelete(index, row)}>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                style={{marginRight: '10px'}}
                loading={toRemoveIndex === index ? loadingDeleting : false}
              />
            </Popconfirm>
            <div style={{position: 'relative'}}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => showChartModal("edit", row, index)}
              />
            </div>
          </div>
        ),
    },
  ];

  const lineResourceTypeChartsWithExtra: (ILineResourceTypeChart | ILineResourceTypeChartInternal)[] = data?.resourceTypeChartByResType ? [
    ...data.resourceTypeChartByResType,
    { thisIsThatExtraRow: true },
  ]: [];

  return (
    <>
    <Form form={form}>
      <Table
        className="ux-line-items-table"
        columns={columns}
        dataSource={lineResourceTypeChartsWithExtra}
        pagination={false}
        loading={loading}
        size="small"
      />
    </Form>
    <UxResTypeChartForm
      visible={rTChartFormState.isModalVisible}
      mode={rTChartFormState.type}
      onClose={() => handleCancel()}
      initialChart={rTChartFormState.chart}
      resTypeId={props.currentResourceType?.id ?? ""}
      orgId={props.orgId}
    />
    </>
  );
};

export default UxLineResourceTypeChartsTable;
