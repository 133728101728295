import { gql } from "@apollo/client";

export const RestoreVersionControlMutation = gql`
  mutation RestoreVersionControl($orgId: String!, $resTypeId: String!, $recordId: String!, $versionId: String!){
    restoreVersionControl(
      input: {
        org_id: $orgId
        res_type_id: $resTypeId
        record_id: $recordId
        version_id: $versionId
      }
    )
  }
`;