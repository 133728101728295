import { ApolloClient, DefaultOptions, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
  uri: "/backend/graphql",
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
}

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({addTypename: false}),
  connectToDevTools: process.env.NODE_ENV !== "production",
  defaultOptions
});

export const setApolloToken = (token: string | null) => {
  const authLink = setContext((_, { headers }) => {
    return token
      ? { headers: { ...headers, authorization: `Bearer ${token}` } }
      : {};
  });

  apolloClient.setLink(authLink.concat(uploadLink));
};

// initial client setup
setApolloToken(null);
